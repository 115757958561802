import React from "react";
import { ReactComponent as HelpIcon } from "../../assets/help.svg";
import styles from "./TopicCard.module.css";

const TopicCard = ({ index, cardTitle, onHelpOutlet }) => {
  return (
    <div
      className={styles.topicCard__topicCard}
      onClick={() => onHelpOutlet(index, cardTitle)}
    >
      <div className={styles.topicCard__iconContainer}>
        <HelpIcon width="100%" color="#121a2d" />
      </div>
      <h2>{cardTitle}</h2>
    </div>
  );
};

export default TopicCard;
