import React, { useState, useRef, useEffect, useContext } from "react";
import FillListController from "./FillListController";
import { composeInitialProps, useTranslation } from "react-i18next";
import SearchBar from "../../../components/SearchBar";
import GetSite from "../../../components/SelectSiteWithDevice/SelectSiteWithDevice";
import { ReactComponent as BackIcon } from "../../../assets/back_icon.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/subtract-circle-icon.svg";
import Button from "../../../components/Button";
import styles from "./FillList.module.css";
import FullBackdropWithModal from "../../../components/FullBackdropWithModal/FullBackdropWithModal";
import SelectDevice from "../../../components/SelectDevice/SelectDevice";
import PDFReportViewerFillList from "./PDFReportViewerFillList";
import WaitIndicator from "../../../components/WaitIndicator";
import ProductController from "../../../controllers/ProductController";
import FillListCard from "./Cards/FillListCard";
import FillListExternalStockedItemCard from "./Cards/FillListExternalStockedItemCard";
import ProductBulkCard from "./Cards/ProductBulkCard";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const FillList = ({ user, onHandleSetCurrentAction, onClose }) => {
  const _controllerRef = useRef(null);
  const [expandableContainers, setExpandableContainers] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [readyForPullList, setReadyForPullList] = useState(false);
  const [sortedFillList, setSortedFillList] = useState([]);
  const [canisterReplenishmentData, setCanisterReplenishmentData] = useState(
    []
  );
  const [filterText, setFilterText] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardClickIndex, setCardClickIndex] = useState(-1);

  const [locationClickObject, setLocationClickObject] = useState({
    type: null,
    index: -1,
  });

  const { t } = useTranslation("reportsFillList");
  const pcRef = useRef(null);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);

  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    const callback = (error, results) => {
      if (!error) {
        setCanisterReplenishmentData(results);
      } else {
        console.log("error:", error);
      }
    };

    _controllerRef.current.getCanisterReplenishmentData(callback);
  }, []);

  useEffect(() => {
    if (smScreen && (!selectedSite || !readyForPullList)) {
      handleShowSmScreenHeader(false);
    } else if (smScreen && selectedSite && readyForPullList && !isLoading) {
      handleShowSmScreenHeader(true);
    }
  }, [smScreen, selectedSite, readyForPullList, isLoading]);

  useEffect(() => {
    selectedSite.devices?.length > 0 &&
      setDevices(
        selectedSite.devices.map((device, index) => {
          return device;
        })
      );
  }, [selectedSite]);

  const handleParentCardClick = (cardIndex) => {
    if (cardClickIndex === -1) {
      handleExpansionClick(cardIndex);
      handleCardClickIndex(cardIndex);
    }
  };

  const handleCardClickIndex = (index) => {
    setCardClickIndex(index);
  };

  const handleLocationClick = (e, locationObj) => {
    e.stopPropagation();
    setLocationClickObject(locationObj);
  };

  const handleExternalItemCardClick = (e) => {
    e.stopPropagation();
    setLocationClickObject({
      type: null,
      index: -1,
    });
  };

  const handleScrollAreaClick = (e) => {
    e.stopPropagation();
  };

  const handleResetSelectedSite = () => {
    setSelectedSite("");
  };

  const handleResetSelectedDevice = () => {
    setSelectedDevice("");
  };

  const collaspeAllExpandableContainers = () => {
    let tempArray = [...expandableContainers];

    tempArray.forEach((element, index) => {
      tempArray[index] = "collapsed";
    });

    setExpandableContainers(tempArray);
  };

  useEffect(() => {
    pcRef.current = new ProductController();
  }, []);

  _controllerRef.current = new FillListController(user);

  // calculateFillListByProduct
  const callback = (fillList) => {
    if (fillList) {
      const compare = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const productA = pcRef.current.getDefaultName(a.product);
        const productB = pcRef.current.getDefaultName(b.product);

        let comparison = 0;
        if (productA > productB) {
          comparison = 1;
        } else if (productA < productB) {
          comparison = -1;
        }
        return comparison;
      };

      let tempArray1 = [...fillList];

      tempArray1.sort(compare);

      setSortedFillList(tempArray1);

      let tempArr2 = [];

      fillList.forEach(() => {
        tempArr2.push("collapsed");
      });
      setExpandableContainers(tempArr2);
    } else {
      console.log("Error calling calculateNeedsByProduct");
    }
    setIsLoading(false);
  };

  const reportHeaders = [
    "Location",
    "Package ID",
    "Description",
    // "Position",
    "Last Filled Date",
    "User",
    "QOH",
    "Max",
    "Need",
  ];
  const reportTitle = selectedDevice.name + "Replenishment";

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleGoBack = () => {
    if (
      !smScreen ||
      (smScreen && cardClickIndex === -1 && !locationClickObject.type)
    ) {
      setSelectedDevice("");
      setReadyForPullList("");
    } else if (smScreen && cardClickIndex !== -1 && !locationClickObject.type) {
      handleCardClickIndex(-1);
      collaspeAllExpandableContainers();
    } else if (smScreen && cardClickIndex !== -1 && locationClickObject.type) {
      setLocationClickObject({
        index: -1,
        type: null,
      });
    }
  };

  const handleSetSelectedDevice = (str) => {
    setSelectedDevice(str);
  };

  const handleGoBackFromSelectDevice = () => {
    handleResetSelectedDevice();
    handleResetSelectedSite();
  };

  const handleSetSelectedSite = (obj) => {
    setSelectedSite(obj);
  };

  const handleCloseSelectDeviceModal = () => {
    setSelectedSite("");
    setSelectedDevice("");
  };

  const handleGenerateList = () => {
    if (!selectedDevice) {
      setSelectedDevice(selectedSite.devices[0]);
    }

    const getSelectedDevice = () => {
      return selectedDevice || selectedSite.devices[0];
    };

    setReadyForPullList(true);
    _controllerRef.current.initializeSocket();
    setIsLoading(true);
    _controllerRef.current.calculateFillListByProduct(
      getSelectedDevice(),
      callback
    );
    return () => {
      _controllerRef.current.releaseSocket();
    };
  };

  const handleExpansionClick = (index) => {
    if (sortedFillList[index].externalStockedItems.length >= 0) {
      let tempArray = [...expandableContainers];
      if (expandableContainers[index] === "expanded") {
        tempArray[index] = "collapsed";
      } else {
        tempArray.fill("collapsed");
        tempArray[index] = "expanded";
      }
      setExpandableContainers(tempArray);
    }
  };

  const findLastFilledAndLastUser = (fillItem) => {
    let matchedCanisterObj = undefined;

    if (canisterReplenishmentData.length) {
      matchedCanisterObj = canisterReplenishmentData.find((obj) => {
        return obj.barcode === fillItem.canisterStockLocation.barcode;
      });
    }
    return matchedCanisterObj
      ? {
          lastFilled: matchedCanisterObj.lastFilled,
          lastUser: matchedCanisterObj.lastUser,
        }
      : { lastFilled: "", lastUser: "" };
  };

  const handlePrintReport = () => {
    let tempArray = [];
    sortedFillList.forEach((fillItem) => {
      if (
        !filterText ||
        fillItem.canisterStockLocation.name
          .toUpperCase()
          .includes(filterText) ||
        pcRef.current
          .getDefaultName(fillItem.product)
          .toUpperCase()
          .includes(filterText)
      ) {
        let totalExternalQty = 0;

        const { lastFilled, lastUser } = findLastFilledAndLastUser(fillItem);

        tempArray.push([
          fillItem.canisterStockLocation.name || "",
          fillItem.product.packageId || "",
          pcRef.current.getDefaultName(fillItem.product) || "",
          lastFilled,
          lastUser,
          fillItem.qoh,
          fillItem.max,
          fillItem.needQty,
        ]);
        fillItem.externalStockedItems.forEach((subItem) => {
          totalExternalQty += subItem.quantity;
          tempArray.push([
            subItem.stockLocation.type.description || "",
            subItem.stockLocation.name || "",
            subItem.stockLocation.barcode || "",
            "",
            "",
            subItem.quantity,
          ]);
        });
        if (fillItem.needQty - totalExternalQty > 0) {
          tempArray.push([
            "Bulk",
            `${pcRef.current.getDefaultName(fillItem.product)} (${
              fillItem.product.packageId
            })` || "",
            "",
            "",
            "",
            fillItem.needQty - totalExternalQty,
          ]);
        }
      }
    });
    setReportData(tempArray);
    setShowPDFViewer(true);
  };

  const handleOnBackPDFReport = () => {
    setShowPDFViewer(false);
    setFilterText("");
  };

  const mainHeading = (
    <div className={styles.fillList__mainHeading}>
      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item1"],
        ].join(" ")}
      >
        Location
      </p>
      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item1b"],
        ].join(" ")}
      >
        Package ID
      </p>
      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item2"],
        ].join(" ")}
      >
        Description
      </p>

      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item4"],
        ].join(" ")}
      >
        Last Filled
      </p>

      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item5"],
        ].join(" ")}
      >
        User
      </p>

      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item6"],
        ].join(" ")}
      >
        QOH
      </p>
      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item7"],
        ].join(" ")}
      >
        Max
      </p>
      <p
        className={[
          styles["fillList__mainColumnName"],
          styles["fillList__mainColumnName--item8"],
        ].join(" ")}
      >
        Need
      </p>
    </div>
  );

  const subHeading = (
    <div className={styles.fillList__subHeading}>
      <p
        className={[
          styles["fillList__subColumnName"],
          styles["fillList__subColumnName--item1"],
        ].join(" ")}
      >
        Type
      </p>
      <p
        className={[
          styles["fillList__subColumnName"],
          styles["fillList__subColumnName--item2"],
        ].join(" ")}
      >
        Name
      </p>
      <p
        className={[
          styles["fillList__subColumnName"],
          styles["fillList__subColumnName--item3"],
        ].join(" ")}
      >
        Barcode
      </p>
      <p
        className={[
          styles["fillList__subColumnName"],
          styles["fillList__subColumnName--item4"],
        ].join(" ")}
      >
        Qty
      </p>
    </div>
  );

  if (!smScreen) {
    if (!selectedSite) {
      return (
        <GetSite
          user={user}
          onHandleSetCurrentAction={onHandleSetCurrentAction}
          controllerRefCurrent={_controllerRef.current}
          onHandleSetSelectedSite={handleSetSelectedSite}
          onClose={onClose}
          title="Replenishment"
        />
      );
    } else if (!readyForPullList) {
      return (
        <SelectDevice
          devices={devices}
          onHandleSetSelectedDevice={handleSetSelectedDevice}
          onHandleCloseSelectDeviceModal={handleCloseSelectDeviceModal}
          onHandleGoBack={handleGoBackFromSelectDevice}
          buttonTitle="Generate Replenishment"
          onHandleProcessing={handleGenerateList}
        />
      );
    } else if (showPDFViewer) {
      return (
        <PDFReportViewerFillList
          title={reportTitle}
          headers={reportHeaders}
          data={reportData}
          onBack={handleOnBackPDFReport}
        />
      );
    } else if (isLoading) {
      let name = selectedDevice
        ? selectedDevice.name
        : selectedSite.devices[0].name;
      return (
        <WaitIndicator message={`Calculating Replenishment for ${name}`} />
      );
    } else {
      return (
        <div className={styles.fillList__view}>
          <div className={styles.fillList__searchRowContainer}>
            <div className={styles.fillList__searchRow}>
              <SearchBar
                placeholder={t("searchPlaceholder")}
                onSearch={handleSearch}
              />
            </div>
            <Button
              labelName="Print"
              isPrimary={true}
              onClick={() => handlePrintReport()}
            />
          </div>
          <div className={styles.fillList__deviceTitleRow}>
            <BackIcon
              className={styles.fillList__backIcon}
              onClick={handleGoBack}
            />
            <div className={styles.fillList__selectedDeviceRow}>
              <p
                className={[
                  styles["fillList__selectedDeviceRowItem"],
                  styles["fillList__selectedDeviceRowItem--rightPadded"],
                ].join(" ")}
              >
                {selectedDevice.name}
              </p>
              <p className={styles.fillList__selectedDeviceRowItem}>
                {t("title")}
              </p>
            </div>
          </div>
          <section className={styles.fillList__container}>
            {mainHeading}

            <div className={styles.fillList__scrollArea}>
              {sortedFillList.map((fillItem, index) => {
                let totalExternalQty = 0;

                const { lastFilled, lastUser } =
                  findLastFilledAndLastUser(fillItem);

                return !filterText ||
                  fillItem.canisterStockLocation.name
                    .toUpperCase()
                    .includes(filterText) ||
                  pcRef.current
                    .getDefaultName(fillItem.product)
                    .toUpperCase()
                    .includes(filterText) ? (
                  <section
                    key={fillItem.canisterStockLocation._id}
                    onClick={() => handleExpansionClick(index)}
                    className={
                      expandableContainers[index] === "collapsed"
                        ? styles.fillList__mainContainer
                        : [
                            styles["fillList__mainContainer"],
                            styles["fillList__mainContainer--expanded"],
                          ].join(" ")
                    }
                  >
                    <div
                      className={
                        expandableContainers[index] === "collapsed"
                          ? styles.fillList__productInfo
                          : [
                              styles["fillList__productInfo"],
                              styles["fillList__productInfo--expanded"],
                            ].join(" ")
                      }
                    >
                      {fillItem.externalStockedItems &&
                        fillItem.externalStockedItems.length >= 0 && (
                          <div className={styles.fillList__boxIconContainer}>
                            {expandableContainers[index] === "expanded" ? (
                              <CollapseIcon
                                className={styles.fillList__boxIcon}
                              />
                            ) : (
                              <ExpandIcon
                                className={styles.fillList__boxIcon}
                              />
                            )}
                          </div>
                        )}
                      <p className={styles["fillList__mainColumnVal--item1"]}>
                        {fillItem.canisterStockLocation.name || ""}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item1b"]}>
                        {fillItem.product.packageId || ""}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item2"]}>
                        {pcRef.current.getDefaultName(fillItem.product) || ""}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item4"]}>
                        {lastFilled || ""}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item5"]}>
                        {lastUser || ""}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item6"]}>
                        {fillItem.qoh}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item7"]}>
                        {fillItem.max}
                      </p>
                      <p className={styles["fillList__mainColumnVal--item8"]}>
                        {fillItem.needQty}
                      </p>
                    </div>

                    {expandableContainers[index] === "expanded" && (
                      <div className={styles.fillList__subContainer}>
                        {fillItem.externalStockedItems &&
                          fillItem.externalStockedItems.length > 0 &&
                          subHeading}

                        {fillItem.externalStockedItems.map((subItem, index) => {
                          totalExternalQty += subItem.quantity;
                          return subItem.stockLocation ? (
                            <div
                              key={subItem._id}
                              className={styles.fillList__stockedItemContainer}
                            >
                              <p
                                className={
                                  styles["fillList__subColumnVal--item1"]
                                }
                              >
                                {subItem.stockLocation.type.description || ""}
                              </p>
                              <p
                                className={
                                  styles["fillList__subColumnVal--item2"]
                                }
                              >
                                {subItem.stockLocation.name || ""}
                              </p>
                              <p
                                className={
                                  styles["fillList__subColumnVal--item3"]
                                }
                              >
                                {subItem.stockLocation.barcode || ""}
                              </p>
                              <p
                                className={
                                  styles["fillList__subColumnVal--item4"]
                                }
                              >
                                {subItem.quantity || ""}
                              </p>
                            </div>
                          ) : null;
                        })}

                        {fillItem.needQty - totalExternalQty > 0 && (
                          <div
                            className={styles.fillList__stockedItemContainer}
                          >
                            <p
                              className={
                                styles["fillList__subColumnVal--item1"]
                              }
                            >
                              Bulk
                            </p>
                            <p
                              className={
                                styles["fillList__subColumnVal--item2"]
                              }
                            >
                              {`${pcRef.current.getDefaultName(
                                fillItem.product
                              )} (${fillItem.product.packageId})` || ""}
                            </p>
                            <p
                              className={
                                styles["fillList__subColumnVal--item4"]
                              }
                            >
                              {fillItem.needQty - totalExternalQty}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </section>
                ) : null;
              })}
            </div>
          </section>
        </div>
      );
    }
  } else {
    if (!selectedSite) {
      return (
        <>
          <div className={styles.fillList__overlay}>
            <GetSite
              user={user}
              onHandleSetCurrentAction={onHandleSetCurrentAction}
              controllerRefCurrent={_controllerRef.current}
              onHandleSetSelectedSite={handleSetSelectedSite}
              onClose={onClose}
              title="Replenishment"
            />
          </div>
        </>
      );
    } else if (!readyForPullList) {
      {
        return (
          <SelectDevice
            devices={devices}
            onHandleSetSelectedDevice={handleSetSelectedDevice}
            onHandleCloseSelectDeviceModal={handleCloseSelectDeviceModal}
            onHandleGoBack={handleGoBackFromSelectDevice}
            buttonTitle="Generate Replenishment"
            onHandleProcessing={handleGenerateList}
          />
        );
      }
    } else if (isLoading) {
      let name = selectedDevice
        ? selectedDevice.name
        : selectedSite.devices[0].name;
      return (
        <WaitIndicator message={`Calculating Replenishment for ${name}`} />
      );
    } else {
      return (
        <div className={styles.fillList__view}>
          <div className={styles.fillList__searchBarContainer}>
            <SearchBar
              placeholder={t("searchPlaceholder")}
              onSearch={handleSearch}
            />
          </div>
          <div className={styles.fillList__deviceTitleRow}>
            <BackIcon
              className={styles.fillList__backIcon}
              onClick={handleGoBack}
            />

            <div className={styles.fillList__selectedDeviceRow}>
              <p
                className={[
                  styles["fillList__selectedDeviceRowItem"],
                  styles["fillList__selectedDeviceRowItem--rightPadded"],
                ].join(" ")}
              >
                {selectedDevice.name}
              </p>
              <p className={styles.fillList__selectedDeviceRowItem}>
                {t("title")}
              </p>
            </div>
          </div>
          <section className={styles.fillList__container}>
            <div
              className={styles.fillList__scrollArea}
              onClick={(e) => handleScrollAreaClick(e)}
            >
              {sortedFillList.map((fillItem, index) => {
                let totalExternalQty = 0;
                const { lastFilled, lastUser } =
                  findLastFilledAndLastUser(fillItem);
                return !filterText ||
                  fillItem.canisterStockLocation.name
                    .toUpperCase()
                    .includes(filterText) ||
                  pcRef.current
                    .getDefaultName(fillItem.product)
                    .toUpperCase()
                    .includes(filterText) ? (
                  <section
                    key={fillItem.canisterStockLocation._id}
                    onClick={() => handleParentCardClick(index)}
                  >
                    <div>
                      {fillItem.externalStockedItems &&
                        fillItem.externalStockedItems.length >= 0 && (
                          <>
                            {cardClickIndex === -1 && (
                              <FillListCard
                                expandIcon={
                                  fillItem.externalStockedItems &&
                                  fillItem.externalStockedItems.length > 0
                                    ? true
                                    : false
                                }
                                data={{
                                  idHeading: "Location",
                                  descriptionHeading: "Description",
                                  positionHeading: "Position",
                                  lastFilledHeading: "Last Filled",
                                  userHeading: "User",
                                  qohHeading: "QOH",
                                  maxHeading: "Max",
                                  needHeading: "Need",
                                  id: fillItem.canisterStockLocation.name || "",
                                  description:
                                    (pcRef.current.getDefaultName(
                                      fillItem.product
                                    ) || "") +
                                    ` (${fillItem.product.packageId || ""})`,
                                  position:
                                    fillItem.canisterStockLocation
                                      .devicePosition || "",
                                  lastFilled: lastFilled,
                                  lastUser: lastUser,
                                  qoh: fillItem.qoh,
                                  max: fillItem.max,
                                  need: fillItem.needQty,
                                }}
                              />
                            )}
                          </>
                        )}
                    </div>

                    {expandableContainers[index] === "expanded" && (
                      <div className={styles.fillList__subContainer}>
                        <h2 className={styles.fillList__externalItems}>
                          {!locationClickObject.type &&
                          locationClickObject.index === -1
                            ? "External Items"
                            : "Selected External Item"}
                        </h2>

                        {!locationClickObject.type &&
                          locationClickObject.index === -1 &&
                          fillItem.externalStockedItems.map(
                            (subItem, childIndex) => {
                              totalExternalQty += subItem.quantity;
                              return (
                                <div
                                  key={subItem._id}
                                  className={
                                    styles.fillList__stockedItemContainer
                                  }
                                  onClick={(e) => {
                                    handleLocationClick(e, {
                                      type: "canister",
                                      index: childIndex,
                                    });
                                  }}
                                >
                                  <p
                                    className={
                                      styles["fillList__subColumnVal--item1"]
                                    }
                                  >
                                    {subItem.stockLocation.name || ""}
                                  </p>
                                </div>
                              );
                            }
                          )}

                        {locationClickObject.type === "canister" && (
                          <FillListExternalStockedItemCard
                            onCardClick={handleExternalItemCardClick}
                            data={{
                              heading1: "Type",
                              heading2: "Qty",
                              heading3: "Name",
                              heading4: "Barcode",
                              item1:
                                fillItem.externalStockedItems[
                                  locationClickObject.index
                                ].stockLocation.type.description || "",
                              item2:
                                fillItem.externalStockedItems[
                                  locationClickObject.index
                                ].quantity || "",
                              item3:
                                fillItem.externalStockedItems[
                                  locationClickObject.index
                                ].stockLocation.name || "",
                              item4:
                                fillItem.externalStockedItems[
                                  locationClickObject.index
                                ].stockLocation.barcode || "",
                            }}
                          />
                        )}

                        {locationClickObject.index === -1 &&
                          fillItem.needQty - totalExternalQty > 0 && (
                            <div
                              className={styles.fillList__stockedItemContainer}
                              onClick={(e) => {
                                handleLocationClick(e, {
                                  type: "bulk",
                                  index: 0,
                                });
                              }}
                            >
                              <p
                                className={
                                  styles["fillList__subColumnVal--item1"]
                                }
                              >
                                Bulk
                              </p>
                            </div>
                          )}

                        {locationClickObject.type === "bulk" && (
                          <ProductBulkCard
                            onCardClick={handleExternalItemCardClick}
                            data={{
                              heading3: "Name",
                              heading4: "Package ID",
                              heading1: "Type",
                              heading2: "Qty",
                              item3:
                                pcRef.current.getDefaultName(
                                  fillItem.product
                                ) || "",
                              item4: fillItem.product.packageId || "",
                              item1: "Bulk",
                              item2: fillItem.needQty - totalExternalQty,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </section>
                ) : null;
              })}
            </div>
          </section>
        </div>
      );
    }
  }
};

export default FillList;
