import React, { useState, useEffect, useRef } from "react";
import styles from "./QtyInput.module.css";

const Input = (props) => {
  const [value, setValue] = useState(0);

  const qtyInputElement = useRef(null);

  useEffect(() => {
    if (!isNaN(parseInt(value))) {
      setValue(props.inputExtras.startValue);
    }
  }, [props.inputExtras.startValue]);

  const onChangeHandler = (e) => {
    let newValue = e.target.value;

    // Limit the input quantity to the stocked-item quantity (start value)
    if (newValue > props.inputExtras.startValue) {
      newValue = props.inputExtras.startValue;
    }

    if (newValue < 0) {
      // Do not allow negative numbers
      newValue = 0;
    }

    if (!isNaN(newValue) && props.inputExtras.handleSetQuantities) {
      props.inputExtras.handleSetQuantities(
        parseInt(newValue),
        props.inputExtras.index
      );
    } else if (!isNaN(newValue) && props.inputExtras.handleSetPullQuantity) {
      props.inputExtras.handleSetPullQuantity(parseInt(newValue));
    }

    setValue(newValue);
  };

  const onKeyUpHandler = (event) => {
    // Change a negative (minus sign) to 0
    if (event.key && event.key === "-") {
      setValue(0);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.inputContainer}>
        <input
          ref={qtyInputElement}
          className={styles.input}
          onKeyUp={onKeyUpHandler}
          onChange={onChangeHandler}
          value={value}
          {...props.inputFields}
        ></input>
      </div>
    </React.Fragment>
  );
};

export default Input;
