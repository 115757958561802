import React from "react";
import { ReactComponent as ExpandIcon } from "../../../../assets/add-icon-small.svg";
import styles from "./ProductCard.module.css";

const ProductCard = ({ data, expandIcon }) => {
  return (
    <div className={styles.productCard__container}>
      <div className={styles.productCard__dataList}>
        <div className={styles.productCard__rowGroup}>
          <div
            className={`${styles.productCard__colGroup} ${styles["productCard__colGroup--1"]}`}
          >
            <div className={`${styles["productCard__heading"]}`}>
              {data.packageIDHeading}
            </div>
            <div className={`${styles["productCard__value"]}`}>
              {data.packageID}
            </div>
          </div>

          <div
            className={`${styles.productCard__colGroup} ${styles["productCard__colGroup--2"]}`}
          >
            <div className={`${styles["productCard__heading"]}`}>
              {data.needHeading}
            </div>
            <div className={`${styles["productCard__value"]}`}>{data.need}</div>
          </div>
          {expandIcon && (
            <div className={styles.productCard__expandIconContainer}>
              <ExpandIcon className={styles.productCard__expandIcon} />
            </div>
          )}
        </div>

        <div className={styles.productCard__rowGroup}>
          <div
            className={`${styles.productCard__colGroup} ${styles["productCard__colGroup--1"]}`}
          >
            <div className={`${styles["productCard__heading"]}`}>
              {data.descriptionHeading}
            </div>
            <div className={`${styles["productCard__value"]}`}>
              {data.description}
            </div>
          </div>
        </div>

        <div className={styles.productCard__rowGroup}>
          <div
            className={`${styles.productCard__colGroup} ${styles["productCard__colGroup--1"]} `}
          >
            <div className={`${styles["productCard__heading"]}`}>
              {data.lotHeading}
            </div>
            <div className={`${styles["productCard__value"]}`}>{data.lot}</div>
          </div>

          <div
            className={`${styles.productCard__colGroup} ${styles["productCard__colGroup--2"]}`}
          >
            <div className={`${styles["productCard__heading"]}`}>
              {data.expirationHeading}
            </div>
            <div className={`${styles["productCard__value"]}`}>
              {data.expiration}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
