import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as ExpandIcon } from "../../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/subtract-circle-icon.svg";
import styles from "./ListDisplayByLocation.module.css";
import PickListMainHeadingByLocation from "./PickListMainHeadingByLocation";
import ProductController from "../../../controllers/ProductController";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import LocationCard from "./Cards/LocationCard";
import LocationProductCard from "./Cards/LocationProductCard";

const ListDisplayByLocation = ({
  demands,
  stockLocationGroups,
  filterText,
  handleExpansionClick,
  expandableContainers,
  totalQtyForProductArray,
  handleSetTotalQtyForProductArray,
  cardClickIndex,
  onHandleCardClickIndex,
  locationClickObject,
  onHandleLocationClick,
}) => {
  const pcRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const handleParentCardClick = (cardIndex) => {
    if (cardClickIndex === -1) {
      handleExpansionClick(cardIndex);
      onHandleCardClickIndex(cardIndex);
    }
  };

  const handleLocationProductCardClick = (e) => {
    e.stopPropagation();
    onHandleLocationClick({
      type: null,
      index: -1,
    });
  };

  useEffect(() => {
    pcRef.current = new ProductController();
    getTotalQuantities();
  }, []);

  const getTotalQuantities = () => {
    let tempArray = [];

    demands.forEach((demand) => {
      let obj = {};
      let key = demand.product.packageId;
      obj[key] = demand.totalQty;

      tempArray = [...tempArray, { ...obj }];
    });
    handleSetTotalQtyForProductArray(tempArray);
  };

  const getTotalQty = (list, stockedItem) => {
    let i;
    const key = stockedItem.product.packageId;
    for (i = 0; i < list.length; i++) {
      if (key in list[i]) {
        return list[i][key];
      }
    }
    return null;
  };

  const handleScrollAreaClick = (e) => {
    e.stopPropagation();
  };

  const handleLocationClick = (e, locationObject) => {
    e.stopPropagation();
    onHandleLocationClick(locationObject);
  };

  if (!smScreen) {
    return (
      <div className={styles.pickList__view}>
        <section className={styles.pickList__container}>
          <PickListMainHeadingByLocation />
          <div className={styles.scrollArea}>
            {stockLocationGroups.map((group, index) => {
              return !filterText ||
                group.stockLocation.name.toUpperCase().includes(filterText) ||
                group.stockLocation.type.description
                  .toUpperCase()
                  .includes(filterText) ? (
                <section
                  key={group.stockLocation._id}
                  onClick={
                    group.stockedItems && group.stockedItems.length > 0
                      ? () => handleExpansionClick(index)
                      : null
                  }
                  className={
                    expandableContainers[index] === "collapsed"
                      ? styles.pickList__mainContainer
                      : [
                          styles["pickList__mainContainer"],
                          styles["pickList__mainContainer--expanded"],
                        ].join(" ")
                  }
                >
                  <div
                    className={
                      expandableContainers[index] === "collapsed"
                        ? styles.pickList__productInfo
                        : [
                            styles["pickList__productInfo"],
                            styles["pickList__productInfo--expanded"],
                          ].join(" ")
                    }
                  >
                    {group.stockedItems && group.stockedItems.length > 0 && (
                      <div className={styles.pickList__boxIconContainer}>
                        {expandableContainers[index] === "expanded" ? (
                          <CollapseIcon className={styles.pickList__boxIcon} />
                        ) : (
                          <ExpandIcon className={styles.pickList__boxIcon} />
                        )}
                      </div>
                    )}
                    <p className={styles["pickList__mainColumnVal--item1"]}>
                      {group.stockLocation.name || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item2"]}>
                      {group.stockLocation.type.description || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item3"]}>
                      {group.stockLocation.barcode || ""}
                    </p>
                  </div>

                  {expandableContainers[index] === "expanded" && (
                    <div className={styles.pickList__subContainer}>
                      {group.stockedItems.map((item) => {
                        return (
                          <div
                            key={item._id}
                            className={styles.pickList__stockedItemContainer}
                          >
                            <p
                              className={
                                styles["pickList__subColumnVal--item1"]
                              }
                            >
                              {pcRef.current.getDefaultName(item.product) || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item2"]
                              }
                            >
                              {item.quantity || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item3"]
                              }
                            >
                              {getTotalQty(totalQtyForProductArray, item)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </section>
              ) : null;
            })}
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div
        className={styles.pickList__scrollArea}
        onClick={(e) => handleScrollAreaClick(e)}
      >
        {stockLocationGroups.map((group, index) => {
          return (cardClickIndex === -1 ||
            expandableContainers[index] === "expanded") &&
            (!filterText ||
              group.stockLocation.name.toUpperCase().includes(filterText) ||
              group.stockLocation.type.description
                .toUpperCase()
                .includes(filterText)) ? (
            <section
              key={group.stockLocation._id}
              onClick={
                group.stockedItems && group.stockedItems.length > 0
                  ? () => handleParentCardClick(index)
                  : null
              }
            >
              {cardClickIndex === -1 && (
                <LocationCard
                  expandIcon={
                    group.stockedItems && group.stockedItems.length > 0
                      ? true
                      : false
                  }
                  data={{
                    nameHeading: "Name",
                    typeHeading: "Type",
                    barcodeHeading: "Barcode",
                    name: group.stockLocation.name || "",
                    type: group.stockLocation.type.description || "",
                    barcode: group.stockLocation.barcode || "",
                  }}
                />
              )}

              {expandableContainers[index] === "expanded" && (
                <div className={styles.pickList__subContainer}>
                  <h2 className={styles.pickList__productsTitle}>
                    {!locationClickObject.type &&
                    locationClickObject.index === -1
                      ? "Products"
                      : "Selected Product"}
                  </h2>

                  {!locationClickObject.type &&
                    locationClickObject.index === -1 &&
                    group.stockedItems.map((item, childIndex) => {
                      return (
                        <div
                          key={item._id}
                          className={styles.pickList__stockedItemContainer}
                          onClick={(e) => {
                            handleLocationClick(e, {
                              type: "locationProduct",
                              index: childIndex,
                            });
                          }}
                        >
                          <p
                            className={styles["pickList__subColumnVal--item1"]}
                          >
                            {pcRef.current.getDefaultName(item.product) || ""}
                          </p>
                        </div>
                      );
                    })}

                  {locationClickObject.type === "locationProduct" && (
                    <LocationProductCard
                      onLocationProductCardClick={
                        handleLocationProductCardClick
                      }
                      data={{
                        heading1: "Product Description",
                        heading2: "QOH",
                        heading3: "Need",
                        item1:
                          pcRef.current.getDefaultName(
                            group.stockedItems[locationClickObject.index]
                              .product
                          ) || "",
                        item2:
                          group.stockedItems[locationClickObject.index]
                            .quantity || "",
                        item3: getTotalQty(
                          totalQtyForProductArray,
                          group.stockedItems[locationClickObject.index]
                        ),
                      }}
                    />
                  )}
                </div>
              )}
            </section>
          ) : null;
        })}
      </div>
    );
  }
};

export default ListDisplayByLocation;
