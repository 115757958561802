import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Spin, Space, Input, notification, Modal } from "antd";

import Button from "../../../components/Button";
import TrayListTable from "./TrayListTable";
import TrayListFilteredTable from "./TrayListFilteredTable";

import useTrayChecksController from "../../../controllers/TrayChecksController";
import { isOrderListEqual } from "../../../utils/functions/trayChecks";

const TrayList = ({ user, site, device }) => {
  // Definitions
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getOrdersList, resetTray, deleteTray } = useTrayChecksController();

  // Orders list states
  const [ordersList, setOrdersList] = useState([]);
  const [loadsCounter, setLoadsCounter] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [filteredOrdersList, setFilteredOrdersList] = useState([]);

  // Table handling states
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isProcessingAction, setIsProcessingAction] = useState(false);
  const [trayToDelete, setTrayToDelete] = useState(null);

  // Reference to the barcode
  const barcodeRef = useRef("");

  // Search handlers
  const [searchText, setSearchText] = useState("");
  const onSearch = (value, _e, _info) => setSearchText(value);
  useEffect(() => {
    if (searchText) {
      const text = searchText.toUpperCase();
      const newFilteredList = [];
      setFilteredOrdersList(
        ordersList.forEach((order) => {
          order.trayChecks.forEach((tChk) => {
            if (
              tChk.dispensingOrder.routedToName.toUpperCase().includes(text) ||
              tChk.assignedTrayId.toUpperCase().includes(text) ||
              tChk.dispensingOrder.dispensingOrderId
                .toUpperCase()
                .includes(text)
            ) {
              newFilteredList.push(tChk);
            }
          });
        })
      );
      setFilteredOrdersList(newFilteredList);
    } else {
      setFilteredOrdersList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // Barcode scan handler
  const barcodeHandler = (e) => {
    if (e.key === "Enter") {
      const barcode = barcodeRef.current;
      barcodeRef.current = "";
      processBarcode(barcode);
    } else if (e.key !== "Shift") {
      barcodeRef.current += e.key;
    }
  };

  // Barcode processing function
  const processBarcode = (bc) => {
    if (device.device.settings.barcodeFormat) {
      const delimiter = device.device.settings.barcodeFormat.delimiter;
      const fieldPosition =
        device.device.settings.barcodeFormat.rxNumberPosition;
      if (delimiter) {
        const parts = bc.split(delimiter);
        bc = parts[fieldPosition || 0];
      }

      if (device.device.settings.barcodeFormat.prefix) {
        console.log(
          `Processing prefix ${device.device.settings.barcodeFormat.prefix}`
        );
        const length = device.device.settings.barcodeFormat.prefix.length;
        bc = bc.substring(length);
      }

      if (device.device.settings.barcodeFormat.suffix) {
        console.log(
          `Processing suffix ${device.device.settings.barcodeFormat.sufix}`
        );
        const length = device.device.settings.barcodeFormat.suffix.length;
        bc = bc.substring(0, bc.length - length);
      }
      console.log(`The revised barcode is ${bc}`);
    }

    let trayCheck = null;
    let order = null;
    ordersList.forEach((or) => {
      trayCheck = or.trayChecks.find((tChk) => tChk.assignedTrayId === bc);
      if (trayCheck) {
        order = or;
      }
    });
    if (trayCheck && order) {
      navigate(
        `/site/${site._id}/device/${device._id}/order/${order.dispensingOrder._id}/trayCheck/${trayCheck._id}`
      );
    }
  };

  // Side effect to start loading orders every 10 seconds and to add the barcode scan handler
  useEffect(() => {
    if (ordersList.length === 0 && !isLoadingOrders) {
      loadOrders(isFirstLoad);
      setIsFirstLoad(false);
    }
    const interval = setInterval(loadOrders, 10000);
    window.addEventListener("keyup", barcodeHandler);
    return () => {
      clearInterval(interval);
      window.removeEventListener("keyup", barcodeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersList, isLoadingOrders]);

  // Side effect to automatically select the order if the user comes from the orders page
  useEffect(() => {
    if (state && state.order && ordersList.length > 0) {
      const foundOrder = ordersList.find(
        (o) => o.dispensingOrder._id === state.order
      );
      if (foundOrder) {
        setSelectedOrder(foundOrder);
        window.history.replaceState({ ...state, order: null }, "");
      }
    }
  }, [state, ordersList]);

  // Function to fetch orders
  const loadOrders = async (showStatus = false) => {
    if (!isLoadingOrders) {
      if (showStatus) setIsLoadingOrders(true);
      try {
        const newList = await getOrdersList(user, site, device);
        if (newList && !isOrderListEqual(ordersList, newList)) {
          setOrdersList(newList);
          setLoadingError(false);
          setLoadsCounter((prev) => prev + 1);
        } else if (!newList) {
          setLoadingError(true);
        } else {
          setLoadingError(false);
        }
      } catch (error) {
        setLoadingError(true);
        console.error("Error loading orders:", error);
      } finally {
        if (showStatus) setIsLoadingOrders(false);
      }
    }
  };

  // Tray reset handler
  const resetTrayCheck = async (tray) => {
    if (tray && tray.slots) {
      setIsProcessingAction(true);
      const resettedTray = await resetTray(user, site, device, tray);
      setIsProcessingAction(false);
      if (resettedTray) {
        setSelectedOrder(null);
        await loadOrders(true);
      }
    } else {
      notification.warning({ message: "Malformed object" });
    }
  };

  // Tray deletion handler
  const deleteTrayCheck = async (tray) => {
    setTrayToDelete(tray);
  };

  return loadingError ? (
    <div>
      <h3>Something went wrong while loading the orders</h3>
      <Button isPrimary onClick={loadOrders} labelName="Retry" />
    </div>
  ) : isLoadingOrders || isProcessingAction ? (
    <div
      style={{
        gap: "10px",
        width: "100%",
        height: "100px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Spin />
      <h2>
        {isLoadingOrders
          ? "Loading orders..."
          : isProcessingAction
          ? "Processing action..."
          : null}
      </h2>
    </div>
  ) : ordersList.length > 0 ? (
    <Space
      size="large"
      direction="vertical"
      style={{ width: "100%" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Modal
        width={350}
        title="Are you sure?"
        open={!!trayToDelete}
        onClose={() => setTrayToDelete(null)}
        onCancel={() => setTrayToDelete(false)}
        onOk={async () => {
          if (trayToDelete && trayToDelete.slots) {
            setIsProcessingAction(true);
            const deletedTray = await deleteTray(
              user,
              site,
              device,
              trayToDelete
            );
            setIsProcessingAction(false);
            if (deletedTray) {
              setSelectedOrder(null);
              await loadOrders(true);
            }
          } else {
            notification.warning({ message: "Malformed object" });
          }
        }}
      >
        Do you really want to delete this tray?
      </Modal>
      <Row justify="end">
        <Input.Search
          allowClear
          size="large"
          onSearch={onSearch}
          placeholder="Type to search"
          style={{ width: 500 }}
        />
      </Row>
      {!!searchText ? (
        <TrayListFilteredTable
          key={loadsCounter}
          site={site}
          device={device}
          ordersList={filteredOrdersList}
          selectedOrder={selectedOrder}
          isProcessingAction={isProcessingAction}
          resetTray={resetTrayCheck}
          deleteTray={deleteTrayCheck}
        />
      ) : (
        <TrayListTable
          key={loadsCounter}
          site={site}
          device={device}
          ordersList={ordersList}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          isProcessingAction={isProcessingAction}
          resetTray={resetTrayCheck}
          deleteTray={deleteTrayCheck}
        />
      )}
    </Space>
  ) : (
    <h1>No orders to show.</h1>
  );
};

export default TrayList;
