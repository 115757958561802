import React, { useState, useEffect, useRef } from "react";
import StockLocationController from "../../../../../controllers/StockLocationController";
import styles from "./StockLocationCard.module.css";

const StockLocationCard = ({
  user,
  site,
  productScanned,
  filteredProductAssociation,
  numBottlesNeeded,
  location,
}) => {
  const _stockLocationController = useRef(null);

  const [hold, setHold] = useState(true);

  const [need, setNeed] = useState(0);

  useEffect(() => {
    // getStockedItems
    _stockLocationController.current = new StockLocationController(user, site);

    const callback = (error, stockedItems) => {
      if (!error) {
        if (true && stockedItems.length >= 0) {
          const filteredStockedItems = stockedItems.filter((stockedItem) => {
            return stockedItem.product.packageId === productScanned.packageId;
          });

          let totalQty = 0;

          filteredStockedItems.forEach((filteredStockedItem) => {
            totalQty += filteredStockedItem.quantity;
          });

          setNeed(
            totalQty - filteredProductAssociation.min >= 0
              ? 0
              : filteredProductAssociation.min - totalQty
          );

          setHold(false);
        }
      } else {
        console.log("error:", error);
      }
    };
    _stockLocationController.current.getStockedItems(location, callback);
  }, []);

  if (true || !hold) {
    return (
      <React.Fragment>
        <div className={styles.locationCard__nameMinMaxNeedGroup}>
          <div className={styles.locationCard__nameMinMaxGroup}>
            <div className={styles.locationCard__name}>{location.name}</div>
            <div className={styles.locationCard__minAndMax}>
              <span className={styles.locationCard__min}>
                min: {filteredProductAssociation.min}
              </span>
              <span className={styles.locationCard__max}>
                max: {filteredProductAssociation.max}
              </span>
            </div>
          </div>

          <div className={styles.locationCard__needGroup}>
            <span className={styles.locationCard__need}>{need}</span>
            <span className={styles.locationCard__numBottles}>
              /{(need / productScanned.packageQuantity).toFixed(2)}
              {numBottlesNeeded !== 1 ? " bottles" : " bottle" + " "}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default StockLocationCard;
