import React, { Fragment, useState, useEffect, useRef } from "react";
import stylesAll from "../PullStockForInternalTransfer/view.module.css";
import styles from "./InventoryPlaceProductView.module.css";
import InventoryTransferPlaceViewController from "./InventoryTransferPlaceViewController";
import Header from "./InventoryPlaceHeader";
import ErrorBanner from "../../../components/ErrorBanner";
import Modal from "../commonUI/ModalTransparent";
import Button from "../commonUI/Button";
import Button2 from "../commonUI/Button2";
import QtyInput from "./QtyInput";
import { containsObject } from "../../../functions/utilities";
import { ReactComponent as Pills } from "../../../assets/pills.svg";
import ProductController from "../../../controllers/ProductController";

const InventoryPlaceStockedItemToRegularStockLocation = ({
  receivedBarcode,
  onStateBack,
  onStateBackDone,
  user,
  site,
  userStockedItems,
  scannedStockLocation,
  onHideModal,
  resetLocationStates,
}) => {
  const [useEffect5Set, setUseEffect5Set] = useState(false);

  const pcRef = useRef(null);
  const [initialBarcode, setInitialBarcode] = useState(receivedBarcode);
  const [barcodeInitialized, setBarcodeInitialized] = useState(false);
  const [userStockedItemsUpdated, setUserStockedItemsUpdated] = useState([]);
  const [userStockedItemsUpdatedPrev, setUserStockedItemsUpdatedPrev] =
    useState([]);

  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [stockedItemsForPlacement, setStockedItemsForReplacement] = useState(
    []
  );
  const [activateErrorModal, setActivateErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const latestQuantities = useRef(null);
  const [enforceInputFocus, setEnforceInputFocus] = useState(true);
  const [activeStockedItemQty, setActiveStockedItemQty] = useState(-1);
  const [listStockedItems, setlistStockedItems] = useState();

  const [activateUserMsg, setActivateUserMsg] = useState("");
  const [stockLocationStockedItems, setStockLocationStockedItems] = useState(
    []
  );
  const [inputMsg, setInputMsg] = useState("");
  const [btn2Text, setbtn2Text] = useState("");
  const [stockedItemFound, setStockedItemFound] = useState(true);
  const [nonUserStockedItems, setNonUserStockedItems] = useState([]);
  const [blankInput, setBlankInput] = useState(false);
  const [toggleInputChange, setToggleInputChange] = useState(false);

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [scannedBarcodePrev, setScannedBarcodePrev] = useState("");

  const [productPackageId, setProductPackageId] = useState("");
  const [productPackageIdPrev, setProductPackageIdPrev] = useState("");

  const _controller = useRef(null);

  const [productState, setProductState] = useState({});
  const [productStatePrev, setProductStatePrev] = useState({});

  const [stockedItemInUserListToAddState, setStockedItemInUserListToAddState] =
    useState({});
  const [
    stockedItemInUserListToAddStatePrev,
    setStockedItemInUserListToAddStatePrev,
  ] = useState({});

  const [
    stockedItemNotInUserListToAddState,
    setStockedItemNotInUserListToAddState,
  ] = useState({});

  const [updateSmartCanProdAssoc, setUpdateSmartCanProdAssoc] = useState(false);

  const [processDataToggle, setProcessDataToggle] = useState(false);

  const handleSetToggleInputChange = () => {
    if (!errorMsg) {
      setToggleInputChange(!toggleInputChange);
    }
  };

  const restoreStateUponCancelClick = () => {
    setUserStockedItemsUpdated([...userStockedItemsUpdatedPrev]);
    setStockedItemInUserListToAddState({
      ...stockedItemInUserListToAddStatePrev,
    });
    setScannedBarcode(scannedBarcodePrev);
    setProductPackageId(productPackageIdPrev);
    setProductState({ ...productStatePrev });
  };

  const resetProductStates = () => {
    setUserStockedItemsUpdated([]);
    setProducts([]);
    setQuantities([]);
    setStockedItemsForReplacement([]);
    setActivateErrorModal(false);
    setErrorMsg("");
    setEnforceInputFocus(true);
    setActiveStockedItemQty(-1);
    setlistStockedItems();
    setActivateUserMsg("");
  };

  useEffect(() => {
    pcRef.current = new ProductController(user);
  }, []);

  useEffect(() => {
    let tempArrayItems;
    let tempArrayQuantities;

    const processUserData = (product) => {
      const updateDataForStockedItemInUserList = (product) => {
        tempArrayItems = [...stockedItemsForPlacement];
        tempArrayItems.unshift(
          stockedItemInUserListToAddState.stockedItemToAdd
        );
        tempArrayQuantities = [...quantities];

        tempArrayQuantities.unshift(
          stockedItemInUserListToAddState.stockedItemToAdd.quantity
        );

        setStockedItemsForReplacement(tempArrayItems);

        setQuantities(tempArrayQuantities);
      };

      if (
        !scannedStockLocation.isControlled &&
        product.controlLevel.type !== 0
      ) {
        setErrorMsg(
          `You are not allowed to place a controlled product to the scanned location, ${scannedStockLocation.barcode}.`
        );
        setActivateErrorModal(true);
        return false;
      }

      if (stockedItemInUserListToAddState) {
        if (
          // Make sure the stocked item is not already in the stockedItemsForPlacement list
          (stockedItemInUserListToAddState &&
            containsObject(
              stockedItemInUserListToAddState.stockedItemToAdd,
              stockedItemsForPlacement
            )) ||
          (stockedItemNotInUserListToAddState &&
            containsObject(
              stockedItemNotInUserListToAddState.stockedItemToAdd,
              stockedItemsForPlacement
            ))
        ) {
          setErrorMsg(
            `Stocked item ${product.packageId} has already been selected for placement.`
          );
          setActivateErrorModal(true);
          return false;
        }
      }
      if (
        stockedItemInUserListToAddState &&
        !scannedStockLocation.type.type.toLowerCase().includes("canister")
      ) {
        updateDataForStockedItemInUserList(product);
        return true;

        /**************************************************************/
        /***  Stocked item NOT in user list and NOT smart canister  ***/
        /**************************************************************/
      } else if (!stockedItemInUserListToAddState) {
        setStockedItemFound(false);
        setInputMsg(
          "This item was not previously pulled by you.  Do you want to place it anyway?"
        );
        setActivateUserMsg(true);
        setbtn2Text("Place");
        setProductState(product);
        updateData(product, stockedItemInUserListToAddState);
      }
    };

    const callback = (error, product) => {
      const failureCallback = (error) => {
        setErrorMsg("There was an error processing the product barcode.");
        setActivateErrorModal(true);
      };

      if (!error && product) {
        if (!productPackageId) {
          setProductPackageIdPrev(productPackageId);
          setProductPackageId(product.packageId);
        }
        if (processUserData(product)) {
          let tempArraya = [...products];
          tempArraya.unshift(product);
          setProducts(tempArraya);
        }
      } else {
        failureCallback();
      }
    };
    if (scannedBarcode) {
      _controller.current.getProductByBarcode(scannedBarcode, callback);
    }
  }, [processDataToggle]);

  useEffect(() => {
    if (userStockedItems.length > 0) {
      let tempArray = [...userStockedItems];
      setUserStockedItemsUpdated(tempArray);
    }
  }, [userStockedItems]);

  useEffect(() => {
    _controller.current = new InventoryTransferPlaceViewController(user, site);
  }, []);

  useEffect(() => {
    const callback = (error, stockedItems) => {
      if (!error && stockedItems) {
        // console.log("stockedItems", stockedItems);
        setStockLocationStockedItems(stockedItems);
      } else {
        console.log("error", error);
      }
    };
    _controller.current.getStockedItems(scannedStockLocation, callback);
  }, [scannedStockLocation]);

  latestQuantities.current = quantities;

  useEffect(() => {
    setUseEffect5Set(true);
    setlistStockedItems(
      stockedItemsForPlacement.map((stockedItem, index) => {
        const product = products[index];
        // Use productScanned.defaultImg
        let imgSource = null;
        let srcDBImage =
          imgSource && imgSource.mimeType
            ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
            : imgSource && imgSource.base64EncodedString;
        let productDesc;
        if (pcRef.current.getDefaultName(product) < 16) {
          productDesc = pcRef.current.getDefaultName(product);
        } else {
          productDesc = product.primaryName + " " + product.strength;
        }

        let packageDesc = `${product.packageDescription}`;
        // if (packageDesc.length > 22) {
        //   packageDesc = packageDesc.substring(0, 22) + "...";
        // }
        return (
          <li
            key={stockedItem.barcode + index.toString()}
            className={
              activeStockedItemQty === index
                ? [styles["listItem"], styles["listItem--active"]].join(" ")
                : styles.listItem
            }
          >
            <div
              className={[
                styles["imageContainer"],
                styles["imageContainer--default"],
              ].join(" ")}
            >
              {imgSource ? (
                <img
                  className={styles.product__image}
                  src={srcDBImage}
                  alt=""
                />
              ) : (
                <Pills stroke={"#A7BBCF"} />
              )}
            </div>
            <div className={styles.descriptionGroup}>
              <div className={styles.productDesc}>{productDesc}</div>
              <div className={styles.packageDesc}>{packageDesc}</div>
            </div>
            <div className={styles.stockedItemQtyGroup}>
              <QtyInput
                smartCanister={null}
                smartProduct={null}
                inputFields={{
                  type: "number",
                  min: "0",
                  onClick: qtyInputOnClickHandler,
                  onFocus: () => {
                    qtyInputOnFocusHandler(index);
                  },
                  onBlur: () => {
                    qtyInputOnBlurHandler(index);
                  },
                }}
                inputExtras={{
                  index: index,
                  startValue:
                    quantities[index] || quantities[index] === 0
                      ? quantities[index]
                      : stockedItem.quantity,
                  updatedValue: quantities[index],
                  handleSetQuantities: handleSetQuantities,
                  handleSetToggleInputChange: handleSetToggleInputChange,
                  stockedItem: stockedItem,
                }}
              />
              {products[index].packageQuantity &&
              products[index].packageQuantity > 0 ? (
                <span className={styles.stockedItemQtyNumOfItems}>
                  (
                  {(quantities[index]
                    ? quantities[index] / products[index].packageQuantity
                    : 0
                  ).toFixed(2)}{" "}
                  item)
                </span>
              ) : null}
            </div>
          </li>
        );
      })
    );
  }, [products, activeStockedItemQty]);

  useEffect(() => {
    if (
      useEffect5Set &&
      !barcodeInitialized &&
      initialBarcode &&
      userStockedItemsUpdated &&
      productState &&
      stockedItemInUserListToAddState
    ) {
      const eventSimulation = {
        which: 13,
        target: {
          value: initialBarcode,
        },
      };
      setBarcodeInitialized(true);
      inputKeyUpHandler(eventSimulation);
    }
  }, [
    useEffect5Set,
    barcodeInitialized,
    initialBarcode,
    userStockedItemsUpdated,
    productState,
    stockedItemInUserListToAddState,
  ]);

  const backArrowHandler = () => {
    onStateBack();
  };

  const ErrorModal = () => {
    return (
      <div className={stylesAll.errorBannerContainer}>
        <ErrorBanner
          message={errorMsg}
          onClose={() => {
            setActivateErrorModal(false);
            setErrorMsg("");
          }}
        />
      </div>
    );
  };

  const handleSetQuantities = (newValue, index) => {
    const tempArray = [...latestQuantities.current];
    if (isNaN(parseInt(newValue))) {
      newValue = 0;
    }
    tempArray[index] = newValue;
    setQuantities(tempArray);
  };

  const qtyInputOnClickHandler = () => {
    setEnforceInputFocus(false);
  };

  const qtyInputOnFocusHandler = (index) => {
    setActiveStockedItemQty(index);
  };

  const qtyInputOnBlurHandler = (index) => {
    const quantity = latestQuantities.current[index];
    if (isNaN(quantity)) {
      let tempArray = [...latestQuantities.current];
      tempArray[index] = 0;
      setQuantities(tempArray);
    }
    setActiveStockedItemQty(-1);
  };

  const doneBtnHandler = () => {
    if (!errorMsg) {
      resetProductStates();
      resetLocationStates();
      const callback = (error, stockedItem) => {
        if (!error && stockedItem) {
          // console.log("stocked item placed", stockedItem);
        } else {
          console.log("error", error);
        }
      };

      stockedItemsForPlacement.forEach((originalStockedItem, index) => {
        let modifiedStockedItem = {
          user: null,
          quantity: quantities[index],
          stockLocation: scannedStockLocation,
          account: originalStockedItem.account,
          site: originalStockedItem.site,
          product: originalStockedItem.product,
          packageDescription: originalStockedItem.packageDescription,
          deviceCompatability: originalStockedItem.deviceCompatability,
          lotNumber: originalStockedItem.lotNumber,
          expiration: originalStockedItem.expiration,
          barcode: originalStockedItem.barcode,
        };

        if (!containsObject(originalStockedItem, userStockedItems)) {
          originalStockedItem = null;
        }

        const productAssociationMatch = (products) => {
          const ProductAssociations = [
            ...scannedStockLocation.productAssociations,
          ];
          const packageId = products[index].packageId;

          function isPresent(value) {
            return value.product.packageId === packageId;
          }

          const result = ProductAssociations.filter(isPresent);
          return result && result.length > 0 ? true : false;
        };

        const getUpdatedStockLocation = (product) => {
          const newProductAssociation = {
            min: 0,
            max: 0,
            product: product,
          };

          let updatedStockLocation = { ...scannedStockLocation };

          if (
            scannedStockLocation.type.type.toLowerCase().includes("canister")
          ) {
            updatedStockLocation.productAssociations[0] = newProductAssociation;
          } else {
            updatedStockLocation.productAssociations.push(
              newProductAssociation
            );
          }

          return updatedStockLocation;
        };

        if (
          modifiedStockedItem &&
          modifiedStockedItem.quantity > 0 &&
          !productAssociationMatch(products)
        ) {
          const product = products[index];
          const updatedStockLocation = getUpdatedStockLocation(product);

          updateProductsAssociation(updatedStockLocation);
        }

        if (modifiedStockedItem && modifiedStockedItem.quantity > 0) {
          //console.log("originalStockedItem", originalStockedItem);
          //console.log("modifiedStockedItem", modifiedStockedItem);
          _controller.current.processPlace(
            originalStockedItem,
            modifiedStockedItem,
            callback
          );
        }
      });

      onStateBackDone();
    }
  };

  const updateData = (
    product,
    stockedItemInUserListToAdd,
    preventToggle = true
  ) => {
    // Note that stockedItemInUserListToAdd is false if the
    // entered barcode is not a stocked item in userStockedItems.
    let stockedItemToAdd = null;

    if (stockedItemInUserListToAdd) {
      stockedItemToAdd = stockedItemInUserListToAdd.stockedItemToAdd;
    }

    const tempArrayProducts = [...products];
    tempArrayProducts.unshift(product);

    const tempArrayItems = [...stockedItemsForPlacement];

    if (stockedItemToAdd) {
      tempArrayItems.unshift(stockedItemToAdd);
    }
  };

  const updateProductsAssociation = (updatedStockLocation) => {
    const callback = (error, stockLocation) => {
      if (!error && stockLocation) {
        // console.log("updatedStockLocation", stockLocation);
      } else {
        console.log(error);
      }
    };

    _controller.current.updateStockLocation(updatedStockLocation, callback);
  };

  const proceedBtnHandlerNew = () => {
    let holdArrayProductsVar;
    let holdArrayItemsVar;

    if (updateSmartCanProdAssoc) {
      const newProductAssociation = {
        min: 0,
        max: 0,
        product: productState,
      };

      let updatedStockLocation = { ...scannedStockLocation };

      updatedStockLocation.productAssociations[0] = newProductAssociation;
      updateProductsAssociation(updatedStockLocation);
    }

    const updateDataNew = () => {
      // Note that stockedItemInUserListToAdd is false if the
      // entered barcode is not a stocked item in userStockedItems.
      let stockedItemToAdd = null;

      if (stockedItemInUserListToAddState) {
        stockedItemToAdd = stockedItemInUserListToAddState.stockedItemToAdd;
      }

      const tempArrayProducts = [...products];
      tempArrayProducts.unshift(productState);

      const tempArrayItems = [...stockedItemsForPlacement];

      if (stockedItemToAdd) {
        tempArrayItems.unshift(stockedItemToAdd);
      }

      holdArrayProductsVar = tempArrayProducts;
      holdArrayItemsVar = tempArrayItems;
    };

    updateDataNew();

    const product = holdArrayProductsVar[0];

    setProductPackageIdPrev(productPackageId);
    setProductPackageId(product.packageId);
    const tempProductsArray = [...products];
    tempProductsArray.unshift(holdArrayProductsVar[0]);

    const tempItemsArray = [...stockedItemsForPlacement];

    let newStockedItem = {};

    if (stockedItemFound) {
      tempItemsArray.unshift(holdArrayItemsVar[0]);
    } else {
      newStockedItem = {
        account: user.account,
        site: site,
        product: product,
        stockLocation: null,
        user: user,
        packageDescription: "Smart Canister",
        quantity: product.packageQuantity,
        barcode: scannedBarcode,
      };
      let tempNonUserStockedItems = [...nonUserStockedItems];
      tempNonUserStockedItems.push(newStockedItem);
      setNonUserStockedItems(tempNonUserStockedItems);

      tempItemsArray.unshift(newStockedItem);
    }

    const tempQuantitiesArray = [...quantities];

    if (stockedItemFound) {
      tempQuantitiesArray.unshift(holdArrayItemsVar[0].quantity);
    } else {
      tempQuantitiesArray.unshift(newStockedItem.quantity);
    }

    setProducts(tempProductsArray);
    setStockedItemsForReplacement(tempItemsArray);
    setQuantities(tempQuantitiesArray);
    setActivateUserMsg(false);
  };

  const cancelBtnHandler = () => {
    setActivateUserMsg(false);
    restoreStateUponCancelClick();
    setUpdateSmartCanProdAssoc(false);
  };

  const inputKeyUpHandler = (e) => {
    if (e.which === 13) {
      setScannedBarcode(e.target.value);
      setUserStockedItemsUpdatedPrev([...userStockedItemsUpdated]);
      setProductStatePrev({ ...productState });
      setStockedItemInUserListToAddStatePrev({
        ...stockedItemInUserListToAddState,
      });

      setBlankInput(true);
      let barcode = e.target.value;

      setScannedBarcodePrev(scannedBarcode);

      if (barcode) {
        const containsItem = (barcode, list) => {
          let i;
          for (i = 0; i < list.length; i++) {
            if (list[i].barcode.toLowerCase() === barcode.toLowerCase()) {
              return { index: i, stockedItemToAdd: list[i] };
            }
          }
          return null;
        };

        // Checks to see if the scanned barcode has a matching stocked item (same product) in userStockedItems.
        // If true, it returns a matching stocked item (the first it finds) in the form {index: num, stockedItemToAdd: {...}}.
        // If false, it returns null.
        const stockedItemInUserListToAdd = containsItem(
          barcode,
          userStockedItemsUpdated
        );

        setStockedItemInUserListToAddState(stockedItemInUserListToAdd);

        if (stockedItemInUserListToAdd) {
          let i;
          let tempArray = [...userStockedItemsUpdated];
          for (i = 0; i < tempArray.length; i++) {
            if (tempArray[i] === stockedItemInUserListToAdd.stockedItemToAdd) {
              tempArray.splice(i, 1);
              break;
            }
          }
          setUserStockedItemsUpdated(tempArray);
        }

        // Checks to see if the scanned barcode has a matching stocked item (same product) in nonUserStockedItems.
        // If true, it returns a matching stocked item (the first it finds) in the form {index: num, stockedItemToAdd: {...}}.
        // If false, it returns null.
        const stockedItemNotInUserListToAdd = containsItem(
          barcode,
          nonUserStockedItems
        );

        setStockedItemNotInUserListToAddState(stockedItemNotInUserListToAdd);
        setProcessDataToggle(!processDataToggle);
      }
    }
  };

  const handleSetBlankInput = () => {
    return setBlankInput();
  };

  const userMsg = (
    <div className={styles.messageContainer}>
      <p className={styles.message}>{inputMsg}</p>
      <div className={styles.btnGroup}>
        <Button2 invStyle onClickHandler={cancelBtnHandler}>
          Cancel
        </Button2>
        <Button2 className={styles.btn} onClickHandler={proceedBtnHandlerNew}>
          {btn2Text}
        </Button2>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className={stylesAll.container}>
        {activateErrorModal && <ErrorModal />}
        {activateErrorModal && <Modal />}
        {
          <Header
            headerTitle="Place"
            backArrowHandler={backArrowHandler}
            useInput={!activateUserMsg ? true : false}
            onHideModal={onHideModal}
            smartCanister={null}
            smartProduct={null}
            inputFields={{
              onKeyUp: inputKeyUpHandler,
              placeholder: "Scan item to place",
              type: "text",
              disabled: `${errorMsg}`,
            }}
            inputExtras={{
              enforceFocus: enforceInputFocus,
              blankInput: blankInput,
              onSetBlankInput: handleSetBlankInput,
            }}
          />
        }

        {activateUserMsg && userMsg}

        {!activateUserMsg && (
          <Fragment>
            <div className={styles.listItemsContainer}>
              <p className={styles.stockLocationName}>
                {scannedStockLocation.name}
              </p>
              <ul className={`${styles.scrollArea} ${styles.listItems}`}>
                {listStockedItems}
              </ul>
            </div>
            {products && products.length > 0 && (
              <div
                className={
                  errorMsg
                    ? `${styles.doneButtonContainer} ${stylesAll["buttonNoHoverCursor"]}`
                    : `${styles.doneButtonContainer} ${stylesAll["buttonHoverCursor"]}`
                }
              >
                <Button onClickHandler={doneBtnHandler}>Done</Button>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default InventoryPlaceStockedItemToRegularStockLocation;
