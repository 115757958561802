import React, { useState } from "react";
import { Modal, Input, Radio } from "antd";
import Button from "../../components/Button";

import styles from "./TrayCellModal.module.css";

const TrayCellItem = ({ drug, image, isSelected, onSelect }) => {
  return (
    <div
      onClick={() => onSelect(drug)}
      className={
        isSelected
          ? styles.TrayCellSelectedItem
          : drug.approvalType === "correctedApproved"
          ? styles.CorrectedTrayCellItem
          : styles.TrayCellItem
      }
    >
      <div className={styles.TrayCellItemInfo}>
        <div className={styles.TableWrapper}>
          <table className={styles.MedicineInfoTable}>
            <tbody>
              <tr>
                <td className={styles.MedicineInfoLabelCell}>Name:</td>
                <td className={styles.MedicineInfoCell}>
                  {drug.product.primaryName}
                </td>
              </tr>
              <tr>
                <td className={styles.MedicineInfoLabelCell}>Description:</td>
                <td className={styles.MedicineInfoCell}>
                  {drug.product.comments}
                </td>
              </tr>
              <tr>
                <td className={styles.MedicineInfoLabelCell}>Manufacturer:</td>
                <td className={styles.MedicineInfoCell}>
                  {drug.product.manufacturer.abbrName}
                </td>
              </tr>
              <tr>
                <td className={styles.MedicineInfoLabelCell}>NDC:</td>
                <td className={styles.MedicineInfoCell}>
                  {drug.product.productId}
                </td>
              </tr>
              <tr>
                <td className={styles.MedicineInfoLabelCell}>Fill quantity:</td>
                <td className={styles.MedicineInfoCell}>{drug.quantity}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.MedicineImageWrapper}>
          {image ? (
            <div className={styles.MedicineImageContainer}>
              <img
                alt="drug"
                className={styles.MedicineImage}
                src={
                  image.mimeType
                    ? `data:${image.mimeType};base64,${image.base64EncodedString}`
                    : image.base64EncodedString
                }
              />
            </div>
          ) : (
            <div className={styles.NoImageContainer}>
              <h4>No image</h4>
            </div>
          )}
        </div>
      </div>
      {drug.approvalType === "corrected approved" &&
      drug.correctedReason &&
      drug.correctedComments ? (
        <table className={styles.MedicineCorrectedInfoTable}>
          <tbody>
            <tr>
              <td className={styles.MedicineInfoLabelCell}>
                Corrected Reason:
              </td>
              <td className={styles.MedicineInfoCell}>
                {drug.correctedReason}
              </td>
            </tr>
            <tr>
              <td className={styles.MedicineInfoLabelCell}>
                Corrected Comments:
              </td>
              <td className={styles.MedicineInfoCell}>
                {drug.correctedComments}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

const CorrectedApprovalModal = ({ open, onOk, onCancel, drug, slot }) => {
  const [reason, setReason] = useState("Too many");
  const [comments, setComments] = useState("");

  return (
    <Modal
      open={open}
      onOk={onOk}
      width={600}
      onCancel={onCancel}
      title={
        <div className={styles.CorrectedApprovalModalHeader}>
          {drug
            ? `Corrected approve ${drug.product.primaryName}`
            : "Loading..."}
        </div>
      }
      classNames={{
        header: styles.TrayCellCorrectedApprovelModalBackground,
        content: styles.TrayCellCorrectedApprovalModalBackground,
      }}
      footer={
        <div className={styles.TrayCellModalFooter}>
          <Button isInverse labelName="Cancel" onClick={onCancel} />
          <Button
            isPrimary
            labelName="Confirm"
            onClick={() => onOk(slot, drug, reason, comments)}
          />
        </div>
      }
    >
      <div className={styles.CorrectedApprovalModalRadioGroupContainer}>
        <h4>Select the reason:</h4>
        <Radio.Group onChange={(e) => setReason(e.target.value)} value={reason}>
          <div className={styles.CorrectedApprovalModalRadioOptionsRow}>
            <Radio value="Too many">Too many</Radio>
            <Radio value="Too few">Too few</Radio>
            <Radio value="Wrong pill">Wrong pill</Radio>
          </div>
          <div className={styles.CorrectedApprovalModalRadioOptionsRow}>
            <Radio value="Broken pill">Broken pill</Radio>
            <Radio value="Missing pill">Missing pill</Radio>
            <Radio value="Other">Other</Radio>
          </div>
        </Radio.Group>
      </div>
      <Input.TextArea
        value={comments}
        placeholder="Add corrected comments here"
        onChange={(e) => setComments(e.target.value)}
      />
    </Modal>
  );
};

const TrayCellModal = ({
  open,
  slot,
  tray,
  onCancel,
  totalView,
  drugsImages,
  selectedDrug,
  handleDrugSelection,
  handleCorrectedApprove,
}) => {
  const [correctedApprovalModalOpen, setCorrectedApprovalModalOpen] =
    useState(false);

  const localHandleCorrectedApprove = (slot, drug, reason, comments) => {
    handleCorrectedApprove(slot, drug, reason, comments);
    setCorrectedApprovalModalOpen(false);
  };

  return (
    <>
      <CorrectedApprovalModal
        slot={slot}
        drug={selectedDrug}
        open={correctedApprovalModalOpen}
        onOk={localHandleCorrectedApprove}
        onCancel={() => setCorrectedApprovalModalOpen(false)}
      />
      <Modal
        open={open}
        width={900}
        onCancel={onCancel}
        classNames={{
          content: styles.TrayCellModalBackground,
          header: styles.TrayCellModalBackground,
        }}
        title={
          <div className={styles.TrayCellModalHeader}>
            {slot ? `Tray Cell #${slot.slotNumber}` : "Loading..."}
          </div>
        }
        footer={
          <div className={styles.TrayCellModalFooter}>
            {!totalView &&
              slot?.drugs?.find(
                (drug) =>
                  drug.product._id === selectedDrug.product._id &&
                  drug.partial === selectedDrug.partial
              ) && (
                <Button
                  isOrange
                  labelName="Approve Correction"
                  onClick={() => setCorrectedApprovalModalOpen(true)}
                />
              )}
            <Button labelName="OK" isPrimary onClick={onCancel} />
          </div>
        }
      >
        <div
          className={styles.TrayCellModalContainer}
          key={`${selectedDrug._id}-${
            slot?.drugs?.find(
              (drug) =>
                drug.product._id === selectedDrug.product._id &&
                drug.partial === selectedDrug.partial
            )
              ? "true"
              : "false"
          }`}
        >
          {!!slot
            ? slot.drugs.map((drug) => (
                <TrayCellItem
                  drug={drug}
                  key={drug.product._id}
                  image={
                    drugsImages.find((i) => i.productId === drug.product._id)
                      ?.image || null
                  }
                  onSelect={handleDrugSelection}
                  isSelected={
                    drug.product._id === selectedDrug.product._id &&
                    drug.partial === selectedDrug.partial
                  }
                />
              ))
            : null}
        </div>
      </Modal>
    </>
  );
};

export default TrayCellModal;
