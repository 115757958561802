import { Font } from "@react-pdf/renderer";

export const registerPoppins = () => {
  return Font.register({
    family: "Poppins",
    fonts: [
      {
        src: "/fonts/Poppins-Regular.ttf",
        fontWeight: "normal",
      },
      {
        src: "/fonts/Poppins-Italic.ttf",
        fontWeight: "normal",
        fontStyle: "italic",
      },

      {
        src: "/fonts/Poppins-Bold.ttf",
        fontWeight: "bold",
      },
      {
        src: "/fonts/Poppins-BoldItalic.ttf",
        fontWeight: "bold",
        fontStyle: "italic",
      },
    ],
  });
};

export const registerRoboto = () => {
  return Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "/fonts/Roboto-Regular.ttf",
        fontWeight: "normal",
      },
      {
        src: "/fonts/Roboto-Italic.ttf",
        fontWeight: "normal",
        fontStyle: "italic",
      },

      {
        src: "/fonts/Roboto-Bold.ttf",
        fontWeight: "bold",
      },
      {
        src: "/fonts/Roboto-BoldItalic.ttf",
        fontWeight: "bold",
        fontStyle: "italic",
      },
    ],
  });
};

export const registerOpenSans = () => {
  return Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: "/fonts/OpenSans-Regular.ttf",
        fontWeight: "normal",
      },
      {
        src: "/fonts/OpenSans-Italic.ttf",
        fontWeight: "normal",
        fontStyle: "italic",
      },

      {
        src: "/fonts/OpenSans-Bold.ttf",
        fontWeight: "bold",
      },
      {
        src: "/fonts/OpenSans-BoldItalic.ttf",
        fontWeight: "bold",
        fontStyle: "italic",
      },
    ],
  });
};