import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { ReactComponent as BackArrow } from "../../../assets/mobile-back-arrow.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import Input from "./InventoryPlaceInput";
import styles from "./InventoryPlaceHeader.module.css";

const InventoryPlaceHeader = (props) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  let tallHeader = true;
  let fullBottomBorderRadius = props.showFullBottomBorderRadius;

  if (
    (props.smartCanister && props.smartProduct) ||
    (!smScreen && !props.useInput)
  ) {
    tallHeader = false;
  }
  let headerStyles;

  if (!tallHeader && !fullBottomBorderRadius) {
    headerStyles = [styles["header"], styles["header--short"]].join(" ");
  } else if (!tallHeader && fullBottomBorderRadius) {
    headerStyles = [
      styles["header"],
      styles["header--short"],
      styles["header--fullBottomBorderRadius"],
    ].join(" ");
  } else if (tallHeader && fullBottomBorderRadius) {
    headerStyles = [
      styles["header"],
      styles["header--fullBottomBorderRadius"],
    ].join(" ");
  } else {
    headerStyles = styles.header;
  }

  return smScreen ? (
    <div className={styles.header}>
      <div className={styles.backArrow} onClick={props.backArrowHandler}>
        <BackArrowMobile className={styles.backArrow__icon} />
        <p className={styles.goBackTitle}>{"Back"}</p>
      </div>

      <h1 className={styles.headerTitle}>{props.headerTitle}</h1>
      {props.useInput && !(props.smartCanister && props.smartProduct) && (
        <div className={styles.inputContainer}>
          <Input
            inputFields={props.inputFields}
            enforceFocus={props.inputExtras.enforceFocus}
            blankInput={props.inputExtras.blankInput}
            onSetBlankInput={props.inputExtras.onSetBlankInput}
            onInputValue={props.onInputValue}
          />
        </div>
      )}
    </div>
  ) : (
    <div className={headerStyles}>
      <div className={styles.headerTitleGroup}>
        <BackArrow
          onClick={props.backArrowHandler}
          className={styles.backArrow}
        />

        <CloseModalIcon
          className={styles.closeModalIcon}
          onClick={props.onHideModal}
        />

        <span className={styles.headerTitle}>{props.headerTitle}</span>
      </div>
      {props.useInput && !(props.smartCanister && props.smartProduct) && (
        <div className={styles.inputContainer}>
          <Input
            inputFields={props.inputFields}
            enforceFocus={props.inputExtras.enforceFocus}
            blankInput={props.inputExtras.blankInput}
            onSetBlankInput={props.inputExtras.onSetBlankInput}
            onInputValue={props.onInputValue}
          />
        </div>
      )}
    </div>
  );
};

export default InventoryPlaceHeader;
