import AuthService from "../../services/AuthService";

class AuthenticateController {

    /*
    isAuthorized - check to see if a set of credentials are authorized
    username: can be an email or username
    password: password
    callback: function to handle results
     */
    isAuthorized = (username, password, callback) => {
        AuthService.logIn({username: username, password: password})
            .then(authorizedUser => {
                callback(null, authorizedUser);
            })
            .catch(error=>{
                callback(error);
            })
    }
}

export default AuthenticateController;
