import UserService from "../../services/UserService";
import StockLocationService from "../../services/StockLocationService";
import Node from "../../functions/Node"

class ProductStockLocationController {
    getAccessibleStockLocationsForUser = async (user, product, cb) => {
        if (product?._id) {
            const sites = await UserService.getAccessibleSites(user);
            let stockLocations = [];
            for (let i = 0; i < sites.length; i++) {
                const temp = await StockLocationService.getByProduct(user, sites[i], product);
                stockLocations = stockLocations.concat(temp);
            }
            cb(sites, stockLocations);
        }
    }

    getStockLocationNodes = async (user, site, product) => {
        const nodes = [];
        const stockLocations = await StockLocationService.list(user, site); // this get all (parents and children)
        let filteredStockLocations = stockLocations
            .filter(sl=> !sl.productAssociations.find(pa => pa?.product?._id === product._id));

        if(product.controlLevel?.type > 0){
            filteredStockLocations.filter(sl=>sl.isControlled);
        }

        const topLevels = filteredStockLocations.filter(sl=>sl.parent === null || sl.parent === 'undefined');
        for(let i = 0; i < topLevels.length; i++){
            const node = new Node();
            node.obj = topLevels[i];
            this._getChildStockLocations(node, filteredStockLocations);
            nodes.push(node);
        }
        return nodes;
    }

    _getChildStockLocations =  (parent, stockLocations) => {
        const children = stockLocations.filter(sl=> {
            return sl.parent?._id === parent.objId
        });
        for(let i = 0; i < children.length; i++){
            const child = new Node();
            child.parent = parent;
            child.obj = children[i];
            parent.addChild(child);
            this._getChildStockLocations(child, stockLocations);
        }
    }

    associate = async (user, product, stockLocation) => {
        stockLocation.productAssociations.push({
            product: product,
            min: 0,
            max: 0
        })

        await StockLocationService.update(user, stockLocation.site, stockLocation);
    }

    update = async (user, stockLocation) => {
        await StockLocationService.update(user, stockLocation.site, stockLocation);
    }
}

export default ProductStockLocationController;
