import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./StockLocationsHeadings.module.css";

const StockLocationsHeadings = () => {
  const { t, i18n } = useTranslation("stockLocations");

  return (
    <div className={styles.mainHeading}>
      <div className={styles.mainHeading__name}>{t("col0")}</div>
      <div className={styles.mainHeading__type}>{t("col1")}</div>
      <div className={styles.mainHeading__position}>{t("col2")}</div>
      <div className={styles.mainHeading__controlled}>{t("col3")}</div>
      <div className={styles.mainHeading__barcode}>{t("col4")}</div>
      {/*<div className={styles.mainHeading__qoh}>{t("col5")}</div>*/}
      {/*<div className={styles.mainHeading__min}>{t("col6")}</div>*/}
      {/*<div className={styles.mainHeading__max}>{t("col7")}</div>*/}
    </div>
  );
};

export default StockLocationsHeadings;
