import React from "react";
import TruPakInventoryCard from "./TruPakInventoryCard";
import styles from "./TruPakInventoryCardList.module.css";

const TruPakInventoryCardList = ({ headings, data }) => {
  const headingsObj = {
    device: headings[0],
    canister: headings[1],
    product: headings[2],
    min: headings[3],
    max: headings[4],
    currentQty: headings[5],
  };

  if (data.length) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.TruPakInventoryCardList__container}>
        <TruPakInventoryCard
          headingsObj={headingsObj}
          dataObj={{
            device: dataRow[0],
            canister: dataRow[1],
            product: dataRow[2],
            min: dataRow[3],
            max: dataRow[4],
            currentQty: dataRow[5],
            actions: dataRow[6],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.TruPakInventoryCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.TruPakInventoryCardList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default TruPakInventoryCardList;
