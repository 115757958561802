
import { Table } from "antd";
import styles from "./PrintMedList.module.css";
import Loader from "react-loader-spinner";

const PatientList = ({
  patients,
  selectedPatients,
  setSelectedPatients,
}) => {

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    }
  ]

  if (!patients.length) {
    return <Loader type="Rings" color="#089BAB" height={50} width={50} />
  }
  
  return (
    <div className={styles.PrintMedList__table}>
      <Table
        rowKey={"_id"}
        dataSource={patients}
        columns={columns}
        rowSelection={{
          selectedRowKeys: selectedPatients,
          onChange: setSelectedPatients
        }}
        pagination={
          patients.length > 10
            ? {
                pageSize: 10,
                total: patients.length,
              }
            : false
        }
      />
    </div>
  )
}

export default PatientList;