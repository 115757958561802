import React, { useState, useEffect, useRef } from "react";
import styles from "./QtyInput.module.css";

const Input = (props) => {
  const [value, setValue] = useState(0);

  const qtyInputElement = useRef(null);

  useEffect(() => {
    if (!isNaN(parseInt(value))) {
      setValue(props.inputExtras.startValue);
    }
  }, [props.inputExtras.startValue]);

  const onChangeHandler = (e) => {
    let newValue = e.target.value;

    if (props.inputExtras.stockedItem) {
      // TRUE FOR PLACE STOCKED ITEM
      const stockedItem = props.inputExtras.stockedItem;

      // If a stocked item belongs to userStockedItems (in the user's possession) it will possess an id and will have a null stock location.  For user stockedItems, the positive quantity cannot be higher than the initial quantity (pulled quantity).
      // A stockedItem set for creation and placement (one not in the userStockedItems) will not yet have an id. There is no positive quantity limit on stocked items created for placement.
      if (
        !(props.smartProduct && props.smartCanister) &&
        stockedItem.user &&
        stockedItem.id &&
        !stockedItem.stockLocation &&
        newValue > props.inputExtras.stockedItem.quantity
      ) {
        newValue = props.inputExtras.stockedItem.quantity;
      } else if (
        props.smartProduct &&
        props.smartCanister &&
        newValue > props.inputExtras.startValue
      ) {
        newValue = props.inputExtras.startValue;
      }
    } else {
      // TRUE FOR PULL STOCKED ITEM
      // Limit the input quantity to the stocked-item quantity (start value)
      if (newValue > props.inputExtras.stockedItem.quantity) {
        newValue = props.inputExtras.stockedItem.quantity;
      }
    }

    if (newValue < 0) {
      // Do not allow negative numbers
      newValue = 0;
    }

    if (!isNaN(newValue)) {
      props.inputExtras.handleSetQuantities(
        parseInt(newValue),
        props.inputExtras.index
      );
    }

    if (props.inputExtras.handleSetToggleInputChange) {
      props.inputExtras.handleSetToggleInputChange();
    }

    setValue(newValue);
  };

  const onKeyUpHandler = (event) => {
    // Change a negative (minus sign) to 0
    if (event.key && event.key === "-") {
      setValue(0);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.inputContainer}>
        <input
          ref={qtyInputElement}
          className={styles.input}
          onKeyUp={onKeyUpHandler}
          onChange={onChangeHandler}
          value={value}
          {...props.inputFields}
        ></input>
      </div>
    </React.Fragment>
  );
};

export default Input;
