import { Font } from "@react-pdf/renderer";
import PoppinsRegular400 from "./Poppins-Regular.ttf";
import PoppinsItalic400 from "./Poppins-Italic.ttf";
import PoppinsBold700 from "./Poppins-Bold.ttf";
import PoppinsBoldItalic700 from "./Poppins-BoldItalic.ttf";
import PoppinsRegular500 from "./Poppins-Medium.ttf";
import PoppinsRegular600 from "./Poppins-SemiBold.ttf";
import PoppinsRegular300 from "./Poppins-Light.ttf";
import RobotoRegular400 from "./Roboto-Regular.ttf";
import RobotoItalic400 from "./Roboto-Italic.ttf";
import RobotoBold700 from "./Roboto-Bold.ttf";
import RobotoBoldItalic700 from "./Roboto-BoldItalic.ttf";
import OpenSansRegular400 from "./OpenSans-Regular.ttf";
import OpenSansItalic400 from "./OpenSans-Italic.ttf";
import OpenSansBold700 from "./OpenSans-Bold.ttf";
import OpenSansBoldItalic700 from "./OpenSans-BoldItalic.ttf";

export const registerPoppins = () => {
  return Font.register({
    family: "Poppins",
    fonts: [
      {
        src: PoppinsRegular400,
        fontWeight: 400,
        fontStyle: "normal",
      },
      {
        src: PoppinsItalic400,
        fontWeight: 400,
        fontStyle: "italic",
      },

      {
        src: PoppinsBold700,
        fontWeight: 700,
        fontStyle: "normal",
      },

      {
        src: PoppinsBoldItalic700,
        fontWeight: 700,
        fontStyle: "italic",
      },
      {
        src: PoppinsRegular500,
        fontWeight: 500,
        fontStyle: "normal",
      },

      {
        src: PoppinsRegular600,
        fontWeight: 600,
        fontStyle: "normal",
      },

      {
        src: PoppinsRegular300,
        fontWeight: 300,
        fontStyle: "normal",
      },
    ],
  });
};

export const registerRoboto = () => {
  return Font.register({
    family: "Roboto",
    fonts: [
      {
        src: RobotoRegular400,
        fontWeight: 400,
        fontStyle: "normal",
      },
      {
        src: RobotoItalic400,
        fontWeight: 400,
        fontStyle: "italic",
      },

      {
        src: RobotoBold700,
        fontWeight: 700,
        fontStyle: "normal",
      },
      {
        src: RobotoBoldItalic700,
        fontWeight: 700,
        fontStyle: "italic",
      },
    ],
  });
};

export const registerOpenSans = () => {
  return Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: OpenSansRegular400,
        fontWeight: 400,
        fontStyle: "normal",
      },
      {
        src: OpenSansItalic400,
        fontWeight: 400,
        fontStyle: "italic",
      },

      {
        src: OpenSansBold700,
        fontWeight: 700,
        fontStyle: "normal",
      },
      {
        src: OpenSansBoldItalic700,
        fontWeight: 700,
        fontStyle: "italic",
      },
    ],
  });
};
