import React, {useEffect, useRef, useState} from 'react';
import {Button, Flex, Space, Table, Tooltip} from 'antd';
import StockLocationService from "../../../services/StockLocationService";
import StockedItemService from "../../../services/StockedItemService";
import ProductFieldController from "../../../controllers/ProductFieldController";
import WaitIndicator from "../../../components/WaitIndicator";

const TruPakInventory = ({user, stockLocation}) => {
    const timerRef = useRef(null);
    const pfController = useRef(new ProductFieldController());
    const [canisters, setCanisters] = useState([]);
    const [fetching, setFetching]  = useState(false);

    const fetchData = () => {

        StockedItemService.getCanistersForSite(user, stockLocation.site, 'POUCH_PACKAGER').then((data)=>{
            console.log(data);
            const temp = data.filter(o=>o.parent === stockLocation._id);
            temp.sort((a,b)=>{
                if(a.devicePosition < b.devicePosition){return -1;}
                if(b.devicePosition < a.devicePosition){return 1;}
                return 0;
            })
            setCanisters(temp);
            setFetching(false)
        }).catch(error=>{
            console.log(error);
            setFetching(false)
        })

    }

    useEffect(() => {
        setFetching(true);
        fetchData();
        timerRef.current = setInterval(()=>{
            fetchData();
        }, 60000);
        return ()=>{
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [stockLocation]);

    const columns = [
        {
            title: "Position",
            dataIndex: "_id",
            render: (text, canister) => (
                <p>{canister.devicePosition} </p>
            ),

        },
        {
            title: "RFID",
            dataIndex: "_id",
            render: (text, canister) => (
                <p>{canister.barcode} </p>
            ),
        },
        {
            title: "Name",
            dataIndex: "_id",
            render: (text, canister) => (
                <p>{canister.name} </p>
            ),
        },
        {
            title: "Size",
            dataIndex: "_id",
            render: (text, canister) => {
                if(canister.type.type === 'PROUD_SMART_CANISTER_SMALL'){
                    return (
                        <p>S</p>
                    )
                }
                return (
                    <p>L</p>
                )
            }
        },
        {
            title: "Product",
            dataIndex: "_id",
            render: (text, canister) => {
                if(canister.productAssociations.length === 0){return (<p></p>)}
                return (
                    <p>{pfController.current.getDefaultName(canister.productAssociations[0].product)}</p>
                )
            }
        },
        {
            title: "Qty",
            dataIndex: "_id",
            render: (text, canister) => {
                let qty = 0;
                for(let i = 0; i < canister.stockedItems.length; i++) {
                    qty += canister.stockedItems[i].quantity;
                }
                return (
                    <p>{qty}</p>
                )
            }
        },
        {
            title: "Lot",
            dataIndex: "_id",
            render: (text, canister) => {
                if(canister.stockedItems.length === 0){return (<p></p>)}
                return(<p>{canister.stockedItems[0].lotNumber} </p>)
            }
        },
        {
            title: "Exp",
            dataIndex: "_id",
            render: (text, canister) => {
                if(canister.stockedItems.length === 0){return (<p></p>)}
                return(<p>{canister.stockedItems[0].expiration} </p>)
            }
        },

    ];

    if(fetching){
        return <WaitIndicator message={'Fetching canister information.  Please be patient.'}/>
    }
    return (
        <Space
            size="large"
            direction="vertical"
            style={{ width: "100%" }}
        >
            <Flex gap={"middle"}>
                <Table  columns={columns} dataSource={canisters || []} />
            </Flex>
        </Space>
    )
}

export default TruPakInventory;