import React from "react";
import { Svg, View, Rect, Text } from "@react-pdf/renderer";
import { registerPoppins } from "../registerFonts";

registerPoppins();

const BarcodeComponent = ({ node, barcodeStringSVGS, PDF_SIZE_FACTOR }) => {
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

  if (Number(xPosmm >= 0) && Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) <= node.parent.widthmm &&
    Number(xPosmm) + Number(widthmm) >
      Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm >= 0) && Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) <= node.parent.heightmm &&
    Number(yPosmm) + Number(heightmm) >
      Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  let width = (widthmm * 3.7795275591).toString() + "px";

  let barcodeTextMarginTop;

  // constants below are experimentally determined for proper vertical placement
  switch (node.symbology) {
    case "Code128A":
      barcodeTextMarginTop = (heightmm * 0.79).toString() + "mm";
      break;
    case "Code128B":
      barcodeTextMarginTop = (heightmm * 0.79).toString() + "mm";
      break;
    case "Code39":
      barcodeTextMarginTop = (heightmm * 0.79).toString() + "mm";
      break;
    case "DataMatrix":
      barcodeTextMarginTop = (heightmm * 0.76).toString() + "mm";
      break;
    case "QRBarcode":
      barcodeTextMarginTop = (heightmm * 0.76).toString() + "mm";
      break;
    default:
      barcodeTextMarginTop = (heightmm * 0.78).toString() + "mm";
  }

  const barcodeValue = node.value;
  const showValue = node.showValue;

  const getBarcodeStringSVG = () => {
    const index = barcodeStringSVGS.findIndex((stringSVG) => {
      return stringSVG.includes(`svgBarcode-${node.nodeId.toString()}`);
    });
    if (index !== -1) {
      return barcodeStringSVGS[index];
    } else {
      return null;
    }
  };

  const barcodeStringSVG = getBarcodeStringSVG();
  const barcodeSVGSplitArray = barcodeStringSVG.split(">").filter((item) => {
    return (
      !item.includes("</rect") &&
      !item.includes("<text") &&
      !item.includes("</text") &&
      !item.includes("<svg") &&
      !item.includes("</svg") &&
      item
    );
  });

  const resultArray = barcodeSVGSplitArray
    .map(function (item) {
      return item.replace("<rect", "");
    })
    .map((item) => {
      return item.replace('style="shape-rendering: crispEdges"', "").trim();
    });

  let Barcode = (
    <Svg
      id={node.nodeId}
      x={0}
      top={0}
      style={{
        background: "transparent",
        fontSize: `${12 * PDF_SIZE_FACTOR}px`,
        fontFamily: "Poppins",
        fontWeight: "normal",
        padding: 0,
        margin: 0,
        position: "absolute",
        left: 0,
        top: 0,
        textAlign: "center",
      }}
    >
      {resultArray.map((item, index) => {
        const xValueStartIndex = item.indexOf("x=") + 3;
        const yValueStartIndex = item.indexOf("y=") + 3;
        const widthValueStartIndex = item.indexOf("width=") + 7;
        const heightValueStartIndex = item.indexOf("height=") + 8;
        const fillValueStartIndex = item.indexOf("fill=") + 6;

        const xValueEndIndex = yValueStartIndex - 6;
        const yValueEndIndex = widthValueStartIndex - 10;
        const widthValueEndIndex = heightValueStartIndex - 11;
        const heightValueEndIndex = fillValueStartIndex - 9;
        const fillValueEndIndex = item.length - 2;

        const xValue = (
          parseFloat(item.substring(xValueStartIndex, xValueEndIndex + 1)) *
          PDF_SIZE_FACTOR
        ).toString();
        const yValue = (
          parseFloat(item.substring(yValueStartIndex, yValueEndIndex + 1)) *
          PDF_SIZE_FACTOR
        ).toString();
        const widthValue = (
          parseFloat(
            item.substring(widthValueStartIndex, widthValueEndIndex + 1)
          ) * PDF_SIZE_FACTOR
        ).toString();
        const heightValue = (
          parseFloat(
            item.substring(heightValueStartIndex, heightValueEndIndex + 1)
          ) * PDF_SIZE_FACTOR
        ).toString();
        const fillValue = item.substring(
          fillValueStartIndex,
          fillValueEndIndex + 1
        );

        return (
          <Rect
            key={`${x}-${node.nodeId}-${index}`}
            width={`${widthValue}`}
            height={`${heightValue}`}
            x={`${xValue}`}
            y={`${yValue}`}
            fill={`${fillValue}`}
            style={{
              fontSize: "6px",
              fontFamily: "Poppins",
              fontWeight: "normal",
              padding: 0,
              margin: 0,
            }}
          ></Rect>
        );
      })}
    </Svg>
  );

  return (
    <View
      style={{
        background: "transparent",
        position: "absolute",
        left: x,
        top: y,
        width: (parseInt(width) * PDF_SIZE_FACTOR).toString(),
        height: "100%",
      }}
    >
      {Barcode}
      <Text
        fill="black"
        style={{
          fontSize: `${10 * PDF_SIZE_FACTOR}px`,
          fontFamily: "Poppins",
          fontWeight: "normal",
          marginTop: barcodeTextMarginTop,
          textAlign: "center",
        }}
      >
        {showValue && barcodeValue}
      </Text>
    </View>
  );
};

export default BarcodeComponent;
