import i18n from "../../i18nextConf";

export const translateError = (response) => {
    if (!response) {
        return "";
    } else if (response.data) {
        return i18n.t(response.data.message, {...response.data.values, ns: "errors"});
    } else {
        return i18n.t(response, {ns: "errors"});
    }
}