
import { useNavigate } from "react-router-dom";
import styles from "./PrintMedList.module.css";
import { Table } from "antd";

const FacilitiesRedirect = ({
  facilities,
  selectedSiteIndex,
  dispensingOrder,
}) => {
  const navigate = useNavigate()
  
  const columns = [
    {
      title: "Facility",
      dataIndex: "name",
      align: "center",
    },
    {
      title: 'Action',
      key: 'action',
      align: "center",
      render: (_, record) => ( 
        <button
          onClick={() => {
            navigate("/settings/facilities/create", {
              state: {
                facility: record,
                previousPath: "/dispensing-orders",
                previousState: {
                  selectedSiteIndex,
                  dispensingOrder,
                }
              }
            });
          }}
          className={styles.PrintMedList__buttonLink}
        >
          Edit facility
        </button>
      ),
    },
  ]

  return (
    <div className={styles.PrintMedList__table}>
      <Table
        rowKey={"_id"}
        dataSource={facilities}
        columns={columns}          
        pagination={
          facilities.length > 10
            ? {
                pageSize: 10,
                total: facilities.length,
              }
            : false
        }
      />
    </div>
  )
}


export default FacilitiesRedirect;