import * as React from "react";
import { useContext } from "react";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getYAxisScale } from "./graphFunctions";
import {
  ChartComponent,
  SeriesCollectionDirective,
  AxesDirective,
  AxisDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  LineSeries,
} from "@syncfusion/ej2-react-charts";
function BarLineGraph({ graphData, graphId, viewWidth }) {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  // yp represents the primary Y axis
  // ys represents the secondary Y axis (yAxis1)
  // Initializations
  let ypMin = 0; // min data value
  let ysMin = 0; // min data value
  let ypMax = 0; // max data value
  let ysMax = 0; // max data value
  let minimumYp = 0; // min scale label value
  let minimumYs = 0; // min scale label value
  let maximumYp = 0; // max scale label value
  let maximumYs = 0; // max scale label value
  let incYp = 0; // yAxis scale interval
  let incYs = 0; // yAxis scale interval

  // variable as desired
  let numYpScaleValues = 6;
  let numYsScaleValues = 6;

  if (graphData && graphData.length) {
    graphData.forEach((graphObj) => {
      if (graphObj.y > ypMax) {
        ypMax = graphObj?.y;
      }
      if (graphObj.y1 > ysMax) {
        ysMax = graphObj?.y1;
      }
    });

    incYp = getYAxisScale(ypMin, ypMax, numYpScaleValues);
    incYs = getYAxisScale(ysMin, ysMax, numYsScaleValues);

    maximumYp = parseInt(minimumYp + (numYpScaleValues - 1) * incYp);
    maximumYs = parseInt(minimumYs + (numYsScaleValues - 1) * incYs);
  }

  const primaryxAxis = { valueType: "Category" };

  const primaryyAxis = {
    // title: "Temperature (Fahrenheit)",
    minimum: 0,
    maximum: maximumYp,
    interval: incYp,
    lineStyle: { width: 1 },
  };
  const marker = {
    visible: true,
    width: 3,
    height: 3,
    border: {
      width: 2,
      color: "rgb(72, 116, 196)",
      fill: "rgb(72, 116, 196)",
    },
  };

  const palette = ["#F28C28"];

  return (
    <ChartComponent
      style={
        smScreen
          ? { width: `${viewWidth - 54}px`, height: "300px" }
          : { width: "450px", height: "300px" }
      }
      titleStyle={{
        size: "16px",
        fontFamily: "Poppins",
        fontWeight: "Normal",
      }}
      id={graphId}
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      title="Qty & Value Sorted"
      palettes={palette}
    >
      <Inject
        services={[
          ColumnSeries,
          LineSeries,
          Legend,
          Tooltip,
          DataLabel,
          Category,
        ]}
      />
      <AxesDirective>
        <AxisDirective
          rowIndex={0}
          name="yAxis1"
          opposedPosition={true}
          lineStyle={0}
          minimum={0}
          maximum={maximumYs}
          interval={incYs}
          labelFormat="c"
          majorGridLines={{ width: 0 }}
        ></AxisDirective>
      </AxesDirective>
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={graphData}
          xName="x"
          yName="y"
          name="Qty sorted"
          type="Column"
        ></SeriesDirective>
        <SeriesDirective
          dataSource={graphData}
          xName="x"
          yName="y1"
          name="Cost of pills"
          type="Line"
          fill="#318ce7"
          width={2}
          marker={marker}
          yAxisName="yAxis1"
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
}
export default BarLineGraph;
