import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/magnifying-glass.svg";
import AssociateProductBarcodeController from "../../../../AssociateProductBarcode/AssociateProductBarcodeController";
import Input from "./Input";
import styles from "./ScanInput.module.css";

const SearchForProducts = ({
  user,
  setProductsSearchText,
  enforceInputFocus,
  setEnforceInputFocus,
  productsSearchText,
  onSetErrorHandler,
  errorMsg,
}) => {
  const [value, setValue] = useState("");
  const _controller = useRef(null);
  const placeHolder = "Search By Id (NDC) or Description";

  useEffect(() => {
    _controller.current = new AssociateProductBarcodeController(user);
  }, []);

  useEffect(() => {
    if (!productsSearchText) {
      setValue("");
    }
  }, [productsSearchText]);

  const changeScanInputHandler = (e) => {
    setValue(e.target.value);
  };

  // searchForProducts
  const callback = (error, products) => {
    if (!error && products && products.length > 0) {
      // console.log("products", products);
    } else if (!error && products && products.length === 0) {
      onSetErrorHandler("No matching products were found.");
    } else {
      console.log("error:", error);
      onSetErrorHandler(
        "Server error. No matching products could be retrieved."
      );
    }
  };

  const onKeyUpHandler = (e) => {
    if (e.which === 13) {
      setProductsSearchText(e.target.value);
      _controller.current.searchForProducts(e.target.value, callback);
    }
  };

  return (
    <section className={styles.scanInput__container}>
      <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
      <Input
        className={styles.scanInput__field}
        onChangeHandler={changeScanInputHandler}
        onKeyUpHandler={onKeyUpHandler}
        value={value}
        placeHolder={placeHolder}
        enforceInputFocus={enforceInputFocus}
        setEnforceInputFocus={setEnforceInputFocus}
        errorMsg={errorMsg}
      />
    </section>
  );
};

export default SearchForProducts;
