import React, { useState, useEffect, useContext } from "react";
import Button from "./Button";
import { ReactComponent as CloseIcon } from "../assets/x.svg";
import Dropzone from "react-dropzone";
import Spacer from "./Spacer";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./ImageSelector.module.css";

const ImageSelector = ({
  isSingle,
  title,
  images,
  placeholder,
  buttonLabel,
  onAdd,
  onRemove,
  pendingFile,
}) => {
  const [sources, setSources] = useState([]);

  const { smallScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    images.forEach((image) => {
      if (!sources.some((o) => o._id === image._id)) {
        setSources((prevState) => {
          return [...prevState, image]; // `data:image/${image.mimeType};base64,${image.base64EncodedString}`];
        });
      }
    });

    if (pendingFile) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        if (!sources.some((o) => o.base64EncodedString === reader.result)) {
          setSources((prevState) => {
            return [
              ...prevState,
              {
                base64EncodedString: reader.result,
              },
            ];
          });
        }
      };
      reader.readAsDataURL(pendingFile);
    }
  }, [images]);

  const onDrop = async (files) => {
    let fileArray = files;
    if (isSingle) {
      fileArray = [files[0]];
    }
    fileArray.forEach((file) => {
      onAdd(file);
      // const reader = new FileReader();
      // reader.onloadend = (e) => {
      //     setSources(prevState=>{return [...prevState, {
      //         base64EncodedString: reader.result
      //     }]});
      //     onAdd(file);
      // }
      // reader.readAsDataURL(file);
    });
  };

  const handleDelete = (index) => {
    let newArray = [...sources];
    newArray.splice(index, 1);
    setSources(newArray);

    let image = images[index];
    if (image) {
      onRemove(image);
    }
  };

  return (
    <div className={styles.imageSelector__container}>
      <div className={styles.imageSelector__header}>
        <p className={styles.imageSelector__title}>{title || "Image"}</p>

        {sources.length === 0 && (
          <Dropzone
            accept={"image/jpeg, image/png, image/svg+xml"}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    labelName={buttonLabel || "Upload Image"}
                    isInverse={true}
                    isDisabled={false}
                    isPrimary={true}
                    minWidth={smScreen ? 320 : 213}
                  />
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </div>
      <Spacer space={smScreen ? 0 : 12} unit={"px"} />
      <div className={styles.imageSelector__col}>
        {sources.length === 0 ? (
          <Dropzone
            accept={"image/jpeg, image/png, image/svg+xml"}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={styles.imageSelector__dropContainer}>
                    {lgScreen && (
                      <p className={styles.imageSelector__text}>
                        {placeholder || "Drag and drop image here"}
                      </p>
                    )}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        ) : (
          <section className={styles.imageSelector__card}>
            {sources.length > 0 &&
              sources.map((source, index) => {
                return (
                  <div className={styles.imageSelector__cardStyle} key={index}>
                    <div className={styles.imageSelector__cardHeader}>
                      <CloseIcon
                        fill={"#121A2D"}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      />
                    </div>
                    <div className={styles.imageSelector__cardBody}>
                      <img
                        className={styles.imageSelector__cardImage}
                        src={
                          source.mimeType
                            ? `data:image/${source.mimeType};base64,${source.base64EncodedString}`
                            : source.base64EncodedString
                        }
                        alt={"logo"}
                      />
                    </div>
                  </div>
                );
              })}
          </section>
        )}
      </div>
    </div>
  );
};

export default ImageSelector;
