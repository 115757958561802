import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchBar from "../../components/SearchBar";
import UserProfile from "../../components/UserProfile/UserProfile";
import styles from "./InventoryItems.module.css";
import { InventoryContext } from "../../contexts/InventoryContext";
//import AlarmDateTime from "../../components/AlarmDateTime/AlarmDateTime";
import { ReactComponent as ReceiveIcon } from "../../assets/receive-icon.svg";
import { ReactComponent as TransferIcon } from "../../assets/transfer-icon.svg";
import Stock from "../Stock/Stock";
import InventoryList from "./InventoryList/InventoryList";
import RoleCheckService from "../../services/RoleCheckService";
import ErrorBanner from "../../components/ErrorBanner";
import DropdownMultiSelectInput from "../../components/DropdownMultiSelectInput";
import ConfigService from "../../services/ConfigService";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import InventoryIconMenu from "./InventoryIconMenu";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const InventoryItems = ({ user, onLogOut }) => {
  const { recordedStockItemId, recordedStockItemDescription } =
    useContext(InventoryContext);

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("invIntelligence");
  const [filterList, setFilterList] = useState("");
  const [filterText, setFilterText] = useState("");
  const [sites, setSites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [menuChoice, setMenuChoice] = useState("Home");
  const [siteList, setSiteList] = useState([]);
  const [error, setError] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [automationFilter, setAutomationFilter] = useState(null);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  // GET THE PHARMACY SITES THIS USER IS ALLOWED TO ACCESS
  useEffect(() => {
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        for (let i = 0; i < sites.length; i++) {
          sites[i].description = sites[i].name; // for dropdown conformance
        }
        setSiteList(
          sites.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (b.name.toUpperCase() < a.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })
        );
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  useEffect(() => {
    ConfigService.stockLocations()
      .then((stockLocations) => {
        setDeviceList(
          stockLocations.filter((sl) => {
            return sl.canDispense;
          })
        );
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  useEffect(() => {
    if (location.pathname === "/inventory/receive") {
      setMenuChoice("Receive");
      setShowModal(true);
    } else if (location.pathname === "/inventory/transfer") {
      setMenuChoice("Transfer");
      setShowModal(true);
    }
  }, [location]);

  useEffect(() => {
    setFilterList("");
  }, []);

  useEffect(() => {
    if (location.pathname === "/inventory/items") {
      setFilterList("");
      setFilterText("");
      setSites([]);
      setShowModal(false);
      setMenuChoice("Home");
      setDevices([]);
      setAutomationFilter(null);
    }
  }, [location.pathname]);

  const receiveOnClickHandler = () => {
    navigate("/inventory/receive");
  };

  const transferOnClickHandler = () => {
    navigate("/inventory/transfer");
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const menuChoiceHandler = (choice) => {
    setMenuChoice(choice);
  };

  const Dropdowns = (
    <div className={styles.inventory__dropdowns}>
      <div className={styles.inventory__dropdownContainer}>
        <DropdownMultiSelectInput
          labelName={"Select one or more pharmacies"}
          onAddItem={(item) => {
            setSites((prev) => {
              return [...prev, item];
            });
          }}
          onRemoveItem={(item) => {
            const temp = [...sites];
            const index = temp.findIndex((s) => s._id === item._id);
            temp.splice(index, 1);
            setSites(temp);
          }}
          selectedItems={sites}
          listElements={siteList}
          validationText={""}
        />
      </div>
      <div className={styles.inventory__dropdownContainer}>
        <DropdownMultiSelectInput
          labelName={"Device filter"}
          onAddItem={(item) => {
            setDevices((prev) => {
              return [...prev, item];
            });
          }}
          onRemoveItem={(item) => {
            const temp = [...devices];
            const index = temp.findIndex((s) => s.type === item.type);
            temp.splice(index, 1);
            setDevices(temp);
          }}
          selectedItems={devices}
          listElements={deviceList}
          validationText={""}
        />
      </div>

      <div
        className={styles.inventory__dropdownContainer}
        style={{ marginTop: "6px" }}
      >
        <DropdownInput
          validationText={""}
          listElements={[
            { type: "No Automation", description: "No Automation" },
            { type: "All Automation", description: "All Automation" },
            { type: "Clear", description: "Clear Filter" },
          ]}
          labelName={"Other filters"}
          onSelection={(item) => {
            if (item.type === "Clear") {
              setAutomationFilter(null);
            } else {
              setAutomationFilter(item);
            }
          }}
          value={automationFilter?.description || ""}
        />
      </div>
    </div>
  );

  const InventoryListGroup = (
    <div className={styles.inventory__body}>
      <InventoryList
        user={user}
        selectedSites={sites}
        selectedDevices={devices}
        searchText={filterText}
        automationFilter={automationFilter}
        onError={() => setError(error)}
      />
    </div>
  );

  const hasSite = user.sites.length > 0 || user.isEnterprise === true;

  if (recordedStockItemId === -1) {
    return (
      <>
        {!smScreen && !showModal && (
          <main className={styles.inventory}>
            <section className={styles.inventory__headerRow}>
              <h1 className={styles.inventory__title}>Inventory</h1>
              {/*<AlarmDateTime />*/}
            </section>
            <br />

            {hasSite && (
              <>
                <div className={styles.inventory__searchBarContainer}>
                  <SearchBar
                    placeholder={
                      smScreen
                        ? t("searchPlaceholderMobile")
                        : t("searchPlaceholder")
                    }
                    onSearch={handleSearch}
                    focus={true}
                  />
                </div>
                <br />
                <br />
              </>
            )}

            {hasSite && Dropdowns}

            {hasSite && (
              <div className={styles.inventory__iconsGroup}>
                <button
                  className={styles.inventory__iconBox}
                  onClick={receiveOnClickHandler}
                >
                  <ReceiveIcon className={styles.inventory__icon} />

                  <p
                    className={[
                      styles["inventory__iconText"],
                      styles["inventory__iconText--extraMargin"],
                    ].join(" ")}
                  >
                    Receive
                  </p>
                </button>
                <button
                  className={styles.inventory__iconBox}
                  onClick={transferOnClickHandler}
                >
                  <TransferIcon className={styles.inventory__icon} />
                  <p className={styles.inventory__iconText}>Transfer</p>
                </button>
              </div>
            )}

            {!hasSite && (
              <div className={styles.inventory__noSitesMessage}>
                <p>No sites have been assigned.</p>
              </div>
            )}

            {hasSite && InventoryListGroup}
          </main>
        )}

        {smScreen && !showModal && (
          <>
            {!hasSite && (
              <>
                <h1 class={styles.inventory__pageTitleForNoSites}>Inventory</h1>
                <div className={styles.inventory__noSitesMessage}>
                  <p>No sites have been assigned.</p>
                </div>
              </>
            )}

            {hasSite && (
              <>
                <div className={styles.inventory__searchBarContainer}>
                  <SearchBar
                    placeholder={
                      smScreen
                        ? t("searchPlaceholderMobile")
                        : t("searchPlaceholder")
                    }
                    onSearch={handleSearch}
                    focus={true}
                  />
                </div>
                {error && (
                  <>
                    <ErrorBanner message={error} onClose={() => setError("")} />
                    <br />
                  </>
                )}
                <div className={styles.inventory__dropdownsContainer}>
                  {Dropdowns}
                </div>

                <div className={styles.inventory__scroll}>
                  {InventoryListGroup}
                </div>

                <InventoryIconMenu
                  onReceiveOnClickHandler={receiveOnClickHandler}
                  onTransferOnClickHandler={transferOnClickHandler}
                />
              </>
            )}
          </>
        )}

        {!smScreen && showModal && (
          <div className={styles.inventory__overlay}>
            <div className={styles.inventory__view}>
              <Stock
                user={user}
                onHideModal={hideModalHandler}
                menuChoice={menuChoice}
                onMenuChoiceHandler={menuChoiceHandler}
              />
            </div>
          </div>
        )}

        {smScreen && showModal && (
          <Stock
            user={user}
            onHideModal={hideModalHandler}
            menuChoice={menuChoice}
            onMenuChoiceHandler={menuChoiceHandler}
          />
        )}
      </>
    );
  }
};

export default InventoryItems;
