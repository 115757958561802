import React from "react";
import TruPakAlarmsCard from "./TruPakAlarmsCard";
import styles from "./TruPakAlarmsCardList.module.css";

const TruPakAlarmsCardList = ({ headings, data, fetchingAlarmsData }) => {
  const headingsObj = {
    description: headings[0],
    occurred: headings[1],
  };

  if (data.length || fetchingAlarmsData) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.TruPakAlarmsCardList__container}>
        <TruPakAlarmsCard
          headingsObj={headingsObj}
          dataObj={{
            description: dataRow[0],
            occurred: dataRow[1],
            actions: dataRow[2],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.TruPakAlarmsCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.TruPakAlarmsCardList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default TruPakAlarmsCardList;
