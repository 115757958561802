import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { ReactComponent as BackArrow } from "../../assets/back-icon-mobile.svg";
import styles from "./GetProfilePhoto.module.css";

const GetProfilePhoto = ({
  photoSize,
  onHandlePhotoCapture,
  onHandleCameraStreaming,
}) => {
  // Settings
  const imgWidth = photoSize;
  const imgHeight = photoSize;

  const [mediaSupport, setMediaSupport] = useState(null);
  const [cameraStream, setCameraStream] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [imgArray, setImgArray] = useState([]);

  const { t } = useTranslation("editProfile");

  const [error, setError] = useState(null);

  useEffect(() => {
    const mediaSupported = "mediaDevices" in navigator;
    setMediaSupport(mediaSupported);
  }, []);

  useEffect(() => {
    if (mediaSupport) {
      startStreaming();
    }
  }, [mediaSupport]);

  // The stream & capture
  const stream = document.getElementById("stream");
  const capture = document.getElementById("capture");

  useEffect(() => {
    if (cameraStream) {
      stream.srcObject = cameraStream;
      stream.play(cameraStream);
    }
  }, [cameraStream]);

  const startStreaming = () => {
    const vid_constraints = {
      video: { width: imgWidth, height: imgHeight },
    };

    if (mediaSupport && null == cameraStream) {
      navigator.mediaDevices
        .getUserMedia(vid_constraints)
        .then(function (mediaStream) {
          setCameraStream(mediaStream);
        })
        .catch(function (err) {
          setError("Unable to access camera: " + err);
        });
    } else {
      return;
    }
    setImageCaptured(false);
  };

  const stopStreaming = () => {
    if (cameraStream !== null) {
      const track = cameraStream.getTracks()[0];

      track.stop();
      stream.load();

      setCameraStream(null);
      // setImageCaptured(false);
    }
  };

  const cancelStreaming = () => {
    startStreaming();
  };

  const captureSnapshot = () => {
    if (cameraStream !== null) {
      const ctx = capture.getContext("2d");
      // Draw captured image from camera stream in canvas
      ctx.drawImage(stream, 0, 0, imgWidth, imgHeight);

      // Represent the drawn image in the canvas by a stored image (capturedImg)
      const capturedImg = new Image();
      capturedImg.width = imgWidth;
      // 'capture' is the canvas id
      capturedImg.src = capture.toDataURL("image/png");

      /***********************************************************
         Build a Blob representation (imageBlob) of the stored 
         image (capturedImg)
      ***********************************************************/

      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        const buffer = new ArrayBuffer(byteString.length);
        const data = new DataView(buffer);

        for (let i = 0; i < byteString.length; i++) {
          data.setUint8(i, byteString.charCodeAt(i));
        }

        return new Blob([buffer], { type: mimeString });
      };

      const dataURI = capturedImg.src;
      const imageBlob = dataURItoBlob(dataURI);

      const blobToBase64 = (blob) => {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      };

      // Store imageBlob mime type and base64 string into an array
      (async () => {
        const base64 = await blobToBase64(imageBlob);
        const base64Array = base64.split("base64,");
        setImgArray(base64Array);
      })();
    }
    stopStreaming();
    setImageCaptured(true);
  };

  const handlePhotoCapture = () => {
    onHandlePhotoCapture(imgArray);
    stopStreaming();
  };

  const goBackHandler = () => {
    stopStreaming();
    onHandleCameraStreaming(false);
  };

  return (
    <Fragment>
      <section className={styles.header}>
        <div className={styles.backArrow} onClick={goBackHandler}>
          <BackArrow className={styles.backArrow__icon} />
          <p className={styles.goBackTitle}>{t("goBackTitle")}</p>
        </div>
        <p className={styles.title}>{t("titleTakeProfilePhoto")}</p>
      </section>

      <section className={styles.appContainer}>
        <div
          className={
            cameraStream && !imageCaptured
              ? styles["photoArea"]
              : styles["photoArea--hide"]
          }
        >
          <video
            id="stream"
            className={styles.photoArea__image}
            width={imgWidth}
            height={imgHeight}
            muted
            playsInline
            preload="auto"
          ></video>
        </div>

        <div
          className={
            imageCaptured ? styles["photoArea"] : styles["photoArea--hide"]
          }
        >
          <canvas
            id="capture"
            className={styles.photoArea__image}
            width={imgWidth}
            height={imgHeight}
          ></canvas>
        </div>

        <div className={styles.buttonGroup}>
          {imageCaptured && (
            <div className={styles.buttonPadRight}>
              <Button
                labelName={"Cancel"}
                isPrimary={false}
                isDisabled={false}
                onClick={() => cancelStreaming()}
                minWidth={122}
              />
            </div>
          )}

          {imageCaptured && (
            <div className={styles.buttonPadLeft}>
              <Button
                labelName={"Accept"}
                isPrimary={true}
                isDisabled={false}
                onClick={() => handlePhotoCapture()}
                minWidth={122}
              />
            </div>
          )}
        </div>

        {cameraStream && (
          <Button
            labelName={"Capture"}
            isPrimary={true}
            isDisabled={false}
            onClick={() => captureSnapshot()}
            minWidth={122}
          />
        )}
      </section>
    </Fragment>
  );
};

export default GetProfilePhoto;
