import React from "react";
import styles from "./PickListSubHeadingByProduct.module.css";

const PickListSubHeadingByProduct = () => {
  return (
    <div className={styles.pickList__subHeading}>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item1"],
        ].join(" ")}
      >
        Type
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item2"],
        ].join(" ")}
      >
        Name
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item3"],
        ].join(" ")}
      >
        Barcode
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item4"],
        ].join(" ")}
      >
        Qty
      </p>
    </div>
  );
};

export default PickListSubHeadingByProduct;
