import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.username) {
        errors.username = 'missingEmailOrUsername'
    }

   if(!values.password) {
       errors.password = 'missingPassword'
   }

    return errors;
}
