import React, { useEffect, useState, useContext, Fragment } from "react";
import Loader from "react-loader-spinner";
import ProductsWOPriceList from "./MobileViews/ProductsWOPrice/ProductsWOPriceList";
import Button from "../../../../components/Button";
import DropdownInput from "../DropDownInput/DropdownInput";
import Table from "../../../../components/Table/Table";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as Checkmark } from "../../../../assets/checkmark.svg";
import { ReactComponent as Xmark } from "../../../../assets/x.svg";
import ProductService from "../../../../services/ProductService";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./NDCTable.module.css";

const NDCTable = ({
  goBackHandler,
  deviceName,
  _controllerRef,
  user,
  selectedSite,
  windowHeight,
}) => {
  const [dropdownSelections, setDropdownSelections] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [records, setRecords] = useState([]);
  const [tableRowInEdit, setTableRowInEdit] = useState(-1);
  const [activeCost, setActiveCost] = useState(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const loader = (
    <div className={styles.NDCTable__loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>Fetching data...</p>
    </div>
  );

  useEffect(() => {
    setFetchingData(true);
    _controllerRef.current
      .getPillSortProductCountWithNoPrice(user, selectedSite, true)
      .then((results) => {
        let tempRawData = [];
        results.forEach((result, index) => {
          tempRawData[index] = result;
        });
        setRawData(tempRawData);

        if (!dropdownSelections.length) {
          let tempDropdownSelections = Array(tempRawData.length).fill({
            description: "LTC Awp Item",
            type: "ltcAwpItem",
          });

          setDropdownSelections(tempDropdownSelections);
          setFetchingData(false);
        }
      })
      .catch((error) => {
        setFetchingData(false);
        console.log(error);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    /* Update table if raw data changes or a new dropdown selection is made */
    if (rawData.length && dropdownSelections.length) {
      let tempCosts = [];
      let costType;
      let cost;
      rawData.forEach((result, index) => {
        costType = dropdownSelections[index].type;
        cost = result[costType];
      });
    }
  }, [rawData, dropdownSelections]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const handleCost = (cost, index) => {
    setActiveCost(cost);
    setTableRowInEdit(index);
  };

  const dropdownList = [
    { description: "Retail 340b Item", type: "retailThree40bItem" },
    { description: "Retail 340b Bulk", type: "retailThree40bBulk" },
    { description: "Retail Awp Item", type: "retailAwpItem" },
    { description: "Retail Awp Bulk", type: "retailAwpBulk" },
    { description: "Retail GPO Item", type: "retailGpoItem" },
    { description: "Retail GPO Bulk", type: "retailGpoBulk" },
    { description: "Retail Acquisition Item", type: "retailAcquisitionItem" },
    { description: "Retail Acquisition Bulk", type: "retailAcquisitionBulk" },
    { description: "LTC 340b Item", type: "ltcThree40bItem" },
    { description: "LTC 340b Bulk", type: "ltcThree40bBulk" },
    { description: "LTC Awp Item", type: "ltcAwpItem" },
    { description: "LTC Awp Bulk", type: "ltcAwpBulk" },
    { description: "LTC Gpo Item", type: "ltcGpoItem" },
    { description: "LTC Gpo Bulk", type: "ltcGpoBulk" },
    { description: "LTC Acquisition Bulk", type: "ltcAcquisitionBulk" },
    { description: "LTC Acquisition Item", type: "ltcAcquisitionItem" },
  ];

  const handleDropdownSelections = (priceFileCategory, index) => {
    let tempProductsDropdownSelections = [...dropdownSelections];

    tempProductsDropdownSelections[index] = priceFileCategory;

    setDropdownSelections(tempProductsDropdownSelections);
    setTableRowInEdit(-1);
    setActiveCost(null);
  };

  useEffect(() => {
    let tempItems = [];
    let costType;
    let costValue;

    rawData.map((tableRow, index) => {
      const priceFile = (
        <DropdownInput
          value={dropdownSelections[index]?.description || "LTC Awp Item"}
          validationText={""}
          isEnabled={true}
          placeholder=""
          listElements={dropdownList || null}
          labelName={null}
          onSelection={(listItem) => {
            handleDropdownSelections(listItem, index);
          }}
        />
      );

      const handleProcessEditBoxClick = (e) => {
        e.stopPropagation();
        if (activeCost?.length && activeCost !== "" && activeCost !== ".") {
          setTableRowInEdit(-1);
          setActiveCost(null);
        }

        let product = rawData[index];
        let costTypeToUpdate = dropdownSelections[index].type;

        product[costTypeToUpdate] = activeCost;

        const updateProduct = async () => {
          const receivedProduct = await ProductService.update(user, {
            ...product,
          });
          return receivedProduct;
        };
        updateProduct();
      };

      const handleRevertBackBoxClick = (e) => {
        e.stopPropagation();
        setTableRowInEdit(-1);
        setActiveCost(null);
      };

      const processEditBox = (
        <div
          className={styles.NDCTable__processEditBox}
          onClick={handleProcessEditBoxClick}
        >
          <Checkmark stroke="#5cb85c" />
        </div>
      );

      const revertBackBox = (
        <div
          className={styles.NDCTable__revertBackBox}
          onClick={handleRevertBackBoxClick}
        >
          <Xmark width="16px" height="16px" stroke="red" />
        </div>
      );

      costType = dropdownSelections[index]?.type;

      costValue =
        tableRowInEdit === index
          ? `$${activeCost}`
          : rawData?.length && costType
          ? "$" + Number(rawData[index][costType]).toFixed(2).toString()
          : "$" + (0.0).toString();

      const cost = (
        <div
          width="130px" // This is a prop used by the Table component
          className={styles.NDCTable__inputContainer}
          onClick={() => {
            if (tableRowInEdit !== index && tableRowInEdit !== -1) {
              setTableRowInEdit(index);
            }
            setTableRowInEdit(-1);
          }}
        >
          <InputCustomType
            styles={styles}
            userInput={activeCost}
            onHandleUserInput={(val, rowIndex = index) => {
              handleCost(val, rowIndex);
            }}
            customType="dollar value"
            enforceFocus={false}
            maxValue={1000}
            autoZero={true}
            inputFields={{
              type: "text",
              value: costValue,
            }}
          />
          {tableRowInEdit === index && (
            <div className={styles.NDCTable__costBoxesContainer}>
              {processEditBox}
              {revertBackBox}
            </div>
          )}
        </div>
      );

      tempItems.push([
        tableRow.packageId,
        tableRow.primaryName,
        priceFile,
        cost,
      ]);

      return (
        <div key={tableRow.id}>
          <div className={styles.NDCTable__dataButton}>
            <button onClick={() => handleDropdownSelections(tableRow.id)}>
              {tableRow.priceFile}
            </button>
          </div>
        </div>
      );
    });
    setRecords(tempItems);
  }, [rawData, dropdownSelections, activeCost, tableRowInEdit]);

  const headers = ["Package Id", "Product", "Price file", "Cost"];

  const DesktopView = (
    <Fragment>
      {fetchingData && loader}
      <div className={styles.NDCTable__header}>
        <h1>Products Without Price Information</h1>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.NDCTable__mainContainer}
      >
        <div className={styles.NDCTable__tableContainer}>
          <Table padScrollBar={true} headers={headers} records={records} />
        </div>
        <div className={styles.NDCTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName="Back"
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <Fragment>
      {fetchingData && loader}
      <div className={styles.NDCTable__mainContainer}>
        <div className={styles.NDCTable__header}>
          <div className={styles.NDCTable__backButton}>
            <img src={backIcon} onClick={goBackHandler} />
          </div>
          <p className={styles.NDCTable__reportTitle}>
            Products Without Price Information
          </p>
        </div>
        {
          <div className={styles.NDCTable__cardListContainer}>
            <ProductsWOPriceList
              headings={headers}
              data={records}
              fetchingData={fetchingData}
            />
          </div>
        }
      </div>
    </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default NDCTable;
