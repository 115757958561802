import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18nextConf";
import { HashRouter } from "react-router-dom";
import { registerLicense } from '@syncfusion/ej2-base';

// trying to force a bild
// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfR1xjSHtQf0VnWHldcQ==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH9Td0BgUH9acXNSRw==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtSdkRmWnZedHVcR2E=;MjAwMDU4MUAzMjMxMmUzMjJlMzNOb2s2QVMrVE1HYi85bTY5a0hUV3dlVEc1ckpTSEgwdUZWc0RIVjk0R1lvPQ==;MjAwMDU4MkAzMjMxMmUzMjJlMzNOOFRrUUtPemN5bG5sNEdBeVExMGx1b3dEcC9HM1l0WXpIUnRlbEQyZ3dZPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Wd0ViXHxXcHBWQGhd;MjAwMDU4NEAzMjMxMmUzMjJlMzNqODFHQ3NtUkZHOE9telN5bmprL0lEbWk1dG0vdkJoNG5IaFR5TUx5V0NRPQ==;MjAwMDU4NUAzMjMxMmUzMjJlMzNsNFdGTXRnV25xRDV1a3lzV3lHMWFWTTdYSVZsdnY0alExeGxQRzh1UU84PQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtSdkRmWnZedHBWQGc=;MjAwMDU4N0AzMjMxMmUzMjJlMzNVamFlZVJTaVg0dEpOd3hOa2FGc3lTYjk4cVl5dXgwMXU5a2x6QmthRWJzPQ==;MjAwMDU4OEAzMjMxMmUzMjJlMzNiYS9TZklneFlDOExjZ041YmdNTCs2cC8yOUFFMWNmRzVtdlUxdDREc084PQ==;MjAwMDU4OUAzMjMxMmUzMjJlMzNqODFHQ3NtUkZHOE9telN5bmprL0lEbWk1dG0vdkJoNG5IaFR5TUx5V0NRPQ==');

ReactDOM.render(
    <HashRouter>
        <App />
    </HashRouter>,
    document.getElementById("root")
);

