import React from "react";
import { ReactComponent as CloseIcon } from "../assets/x.svg";

const ErrorBannerMobile = ({ message, onClose }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "66px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#d9534f",
    opacity: "0.9",
    overflow: "no-content",
  };

  const messageContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "66px",
    height: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  const messageStyle = {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "normal",
    color: "white",
    marginLeft: "25px",
  };

  const iconStyle = {
    marginRight: "25px",
    color: "white",
    cursor: "pointer",
  };

  return (
    <section style={containerStyle}>
      <section style={messageContainer}>
        <p style={messageStyle}>{message}</p>
      </section>
      <CloseIcon
        fill={"white"}
        stroke={"white"}
        style={iconStyle}
        onClick={onClose}
      />
    </section>
  );
};

export default ErrorBannerMobile;
