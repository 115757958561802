import React from "react";
import styles from "./EV54CanisterDetailsCard.module.css";

const EV54CanisterDetailsCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.EV54CanisterDetailsCard__reportList}>
      <li className={styles.EV54CanisterDetailsCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.EV54CanisterDetailsCard__dataContainer}>
          <h1>{headingsObj.deviceName}</h1>
          <p>{dataObj.deviceName}</p>
        </div>
        <div className={styles.EV54CanisterDetailsCard__dataContainer}>
          <h1>{headingsObj.canisterNum}</h1>
          <p>{dataObj.canisterNum}</p>
        </div>
      </li>
      <li className={styles.EV54CanisterDetailsCard__reportListItem}>
        <div className={styles.EV54CanisterDetailsCard__dataContainer}>
          <h1>{headingsObj.associatedProduct}</h1>
          <p>{dataObj.associatedProduct}</p>
        </div>
      </li>
      <li className={styles.EV54CanisterDetailsCard__reportListItem}>
        <div className={styles.EV54CanisterDetailsCard__dataContainer}>
          <h1>{headingsObj.numScripts}</h1>
          <p>{dataObj.numScripts}</p>
        </div>
      </li>
    </ul>
  );
};

export default EV54CanisterDetailsCard;
