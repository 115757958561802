import React from 'react';
import styles from "./CardFlexCol.module.css";

const CardFlexCol = (props) => {
  return (
    <div className={`${styles.cardFlexCol} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default CardFlexCol;
