import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as AddButton } from "../assets/add-icon-small.svg";
import { ReactComponent as EditButton } from "../assets/edit-icon-small.svg";
import { ReactComponent as RemoveButton } from "../assets/trash-icon-small.svg";
import Button from "./Button";
import Spacer from "./Spacer";
import { useTranslation } from "react-i18next";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { Popconfirm } from "antd";

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ListHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #121a2d;
  box-sizing: border-box;
  padding-bottom: 4px;
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089bab;
`;
const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 16px 4px 16px;
`;
const ListText = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
`;
const ListIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35px;
  align-items: center;
`;
const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 60;
`;
const Modal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 200px;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  z-index: 61;
  box-sizing: border-box;
  padding: 60px 40px 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;
const AddListItemInput = styled.input`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
  color: var(--black);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid;
  border-color: #9cd7dd;
  outline: none;
  flex-direction: row;
  max-height: 36px;
  width: 300px;
  max-width: 100%;
  background: transparent;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ListBox = ({
                     title,
                     listItems,
                     displayProperty,
                     onAdd,
                     onEdit,
                     onRemove,
                     onRemoveConfirmationText,
                     height,
                 }) => {
    const { t } = useTranslation("listBox");

    const [showEditModal, setShowEditModal] = useState(false);
    const [editText, setEditText] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [listItemIndex, setListItemIndex] = useState(-1);

    const handleUpdate = () => {
        if (editText && isEdit) {
            // const regex = new RegExp( listItems.join( "|" ), "i");
            // if( !regex.test( editText ) ){
            //     onEdit( listItems[ listItemIndex ], editText)
            // }
            if (
                !listItems.some((o) => {
                    return o.toUpperCase() === editText.toUpperCase();
                })
            ) {
                //!regex.test( editText ) ){
                onEdit(listItems[listItemIndex], editText);
            }
        } else if (editText) {
            //const regex = new RegExp( listItems.join( "|" ), "i");
            if (
                listItems.length === 0 ||
                !listItems.some((o) => {
                    return o.toUpperCase() === editText.toUpperCase();
                })
            ) {
                //!regex.test( editText ) ){
                onAdd(editText);
            }
        }
        setShowEditModal(false);
        setEditText("");
        setListItemIndex(-1);
    };

    const { smallScreen } = useContext(ScreenContext);
    const smScreen = useMediaQuery(smallScreen);

    let listBodyHeight, listBodyMinHeight, listBodyMaxHeight;

    listBodyHeight = smScreen ? "100%" : "120px";
    listBodyMinHeight = smScreen ? "24px" : "120px";
    listBodyMaxHeight = smScreen ? "120px" : "120px";

    return (
        <List>
            <ListHeader>
                <Title>{title || "Title"}</Title>
                <AddButton
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setIsEdit(false);
                        setShowEditModal(true);
                    }}
                />
            </ListHeader>
            <ListBody
                style={{
                    height: height || listBodyHeight,
                    maxHeight: height || listBodyMaxHeight,
                    minHeight: height || listBodyMinHeight,
                }}
            >
                {listItems.map((listItem, index) => {
                    return (
                        <ListItem
                            key={index}
                            style={
                                index % 2 === 0
                                    ? { backgroundColor: "#EEF3F4" }
                                    : { backgroundColor: "#ffffff" }
                            }
                        >
                            <ListText>
                                {displayProperty ? listItem[displayProperty] : listItem}
                            </ListText>
                            <ListIcons>
                                {onEdit && (
                                    <EditButton
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setEditText(listItem);
                                            setListItemIndex(index);
                                            setIsEdit(true);
                                            setShowEditModal(true);
                                        }}
                                    />
                                )}
                                <Popconfirm
                                    okText={t("removeButtonLabel")}
                                    cancelText={t("cancelButtonLabel")}
                                    onConfirm={() => onRemove(listItem)}
                                    title={onRemoveConfirmationText || t("areYouSure")}
                                    okButtonProps={{ type: "link", style: { color: "#d9534f" } }}
                                >
                                    <RemoveButton style={{ cursor: "pointer" }} />
                                </Popconfirm>
                            </ListIcons>
                        </ListItem>
                    );
                })}
            </ListBody>

            {showEditModal && (
                <ModalOverlay>
                    <Modal>
                        <AddListItemInput
                            autoFocus={true}
                            type={"text"}
                            value={editText}
                            onChange={(e) => {
                                let val = e.currentTarget.value;
                                setEditText(val);
                            }}
                        />
                        <Footer>
                            <Button
                                minWidth={"100px"}
                                labelName={t("cancelButtonLabel")}
                                isPrimary={false}
                                isDisabled={false}
                                onClick={() => {
                                    setEditText("");
                                    setShowEditModal(false);
                                }}
                            />
                            <Spacer space={20} unit={"px"} />
                            <Button
                                minWidth={"100px"}
                                labelName={t("updateButtonLabel")}
                                isPrimary={true}
                                isDisabled={false}
                                onClick={handleUpdate}
                            />
                        </Footer>
                    </Modal>
                </ModalOverlay>
            )}
        </List>
    );
};

export default ListBox;

