import { io } from "socket.io-client";
const RefillReportService = require("./RefillReportService");

class RefillReportController {
  constructor(user) {
    this.user = user;
  }

  initializeSocket() {
    this.socket = io("/inventory");
    this.socket.on("connect", () => {
      console.log(`Client Fill List Socket ${this.socket.id} is connected`);
    });
    this.socket.on("disconnect", () => {
      console.log(`Socket ${this.socket.id} has disconnected`);
    });
    this.socket.on("sites", (data) => this.sitesListener(this, data));
  }

  releaseSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  getSites(sitesCallback) {
    this.sitesCallback = sitesCallback;
    if (this.socket) {
      const data = {
        account: this.user.account,
      };
      this.socket.emit("get-sites", data);
    }
  }

  // SOCKET LISTENERS
  sitesListener(sender, data) {
    sender.sitesCallback(data.sites);
  }

  getCanisterReplenishmentData = async (callback) => {
    RefillReportService.default
      .getCanisterReplenishmentData(this.user)
      .then((results) => {
        callback(null, results);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getBatches = async (
    callback,
    user,
    siteId,
    stockLocationId,
    stockLocation,
    startDate,
    endDate
  ) => {
    await RefillReportService.default
      .getBatches(
        user,
        siteId,
        stockLocationId,
        stockLocation,
        startDate,
        endDate
      )
      .then((results) => {
        callback(null, results);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getNeeds = async (
    callback,
    user,
    siteId,
    stockLocationId,
    stockLocation,
    startDate,
    endDate,
    batches
  ) => {
    await RefillReportService.default
      .getNeeds(
        user,
        siteId,
        stockLocationId,
        stockLocation,
        startDate,
        endDate,
        batches
      )
      .then((results) => {
        callback(null, results);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export default RefillReportController;
