export default function validate(values) {
    let errors = {};

    if(!values.oldPassword) {
        errors.oldPassword = 'Old password is required';
    }

    if(!values.newPassword) {
        errors.newPassword = "New password is required";
    }

    if(!values.confirmPassword) {
        errors.confirmPassword = "Confirmation password is required";
    }

    if(values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Confirmation password must match the new password";
    }

    return errors;
}
