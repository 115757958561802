import React, { useEffect, useState } from "react";
import { Table, Spin, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";

import NestedTable from "../../../components/NestedTable/NestedTable";
import styles from "./TrayList.module.css";

const TrayListTable = ({
  site,
  device,
  ordersList,
  selectedOrder,
  setSelectedOrder,
  isProcessingAction,
  resetTray,
  deleteTray,
}) => {
  const navigate = useNavigate();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    setExpandedRowKeys(
      selectedOrder ? [selectedOrder.dispensingOrder._id] : []
    );
  }, [selectedOrder]);

  const columns = [
    {
      title: "Order ID",
      render: (order) => order.dispensingOrder.dispensingOrderId,
    },
    {
      title: "Device #",
      render: (order) => order.dispensingOrder.routedToName,
    },
    {
      align: "center",
      title: "Facility",
      render: (order) => order.dispensingOrder.facility.name,
    },
  ];

  const childTable = () => (
    <Table
      bordered
      rowKey="_id"
      pagination={false}
      dataSource={selectedOrder?.trayChecks || []}
      columns={[
        {
          title: "Tray Seq #",
          render: (row) =>
            `${row.rangeId}${
              selectedOrder && selectedOrder.trayNeeds
                ? ` of ${selectedOrder.trayNeeds.totalTrays}`
                : ""
            }`,
        },
        {
          title: "Tray #",
          render: (row) => row.assignedTrayId,
        },
        {
          align: "center",
          title: "Tray Status",
          render: (row) => row.status,
        },
        {
          width: 10,
          align: "center",
          title: "Actions",
          onCell: (_) => {
            return {
              onClick: (e) => e.stopPropagation(),
            };
          },
          render: (row) =>
            isProcessingAction ? (
              <Spin size="small" />
            ) : (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "reset-option",
                      label: "Reset",
                    },
                    {
                      key: "delete-option",
                      label: "Delete",
                    },
                  ],
                  onClick: async ({ key, domEvent }) => {
                    domEvent.preventDefault();
                    if (key === "reset-option") {
                      await resetTray(row);
                    } else if (key === "delete-option") {
                      await deleteTray(row);
                    }
                    setExpandedRowKeys([]);
                  },
                }}
              >
                <EllipsisOutlined style={{ padding: "5px 10px" }} />
              </Dropdown>
            ),
        },
      ]}
      rowClassName={(record) =>
        record.approved
          ? record.status.toUpperCase() === "CORRECTED APPROVED"
            ? styles.CorrectedApprovedRow
            : styles.ApprovedRow
          : record.slots.some((slot) =>
              slot.drugs.some(
                (drug) =>
                  drug.approvalType &&
                  drug.approvalType.toUpperCase() === "CORRECTED APPROVED"
              )
            )
          ? styles.CorrectedApprovedRow
          : record.status.toUpperCase() === "NOT FILLED"
          ? styles.NotFilledRow
          : styles.NeedCheckRow
      }
      onRow={(record, _) => {
        return {
          onClick: (_) => {
            if (
              !record.isLocked &&
              (!record.approved ||
                (record.approved &&
                  record.slots.some((slot) =>
                    slot.drugs.some(
                      (drug) =>
                        drug.approvalType &&
                        drug.approvalType.toUpperCase() === "CORRECTED APPROVED"
                    )
                  )))
            ) {
              navigate(
                `/site/${site._id}/device/${device._id}/order/${record.dispensingOrder._id}/trayCheck/${record._id}`
              );
            }
          },
        };
      }}
    />
  );

  return (
    <NestedTable
      data={ordersList}
      columns={columns}
      childTable={childTable}
      expandedRowKeys={expandedRowKeys}
      onRowContract={() => setSelectedOrder(null)}
      rowKey={(record) => record.dispensingOrder._id}
      onRowExpand={(order) => setSelectedOrder(order)}
      rowClassName={(record) =>
        record.trayChecks.some((item) => !item.approved)
          ? record.trayChecks.some(
              (item) => item.status.toUpperCase() === "NOT FILLED"
            )
            ? styles.NotFilledRow
            : record.trayChecks.some((item) =>
                item.slots.some((slot) =>
                  slot.drugs.some(
                    (drug) =>
                      drug.approvalType &&
                      drug.approvalType.toUpperCase() === "CORRECTED APPROVED"
                  )
                )
              )
            ? styles.CorrectedApprovedRow
            : styles.NeedCheckRow
          : record.trayChecks.some(
              (item) => item.status.toUpperCase() === "CORRECTED APPROVED"
            )
          ? styles.CorrectedApprovedRow
          : styles.ApprovedRow
      }
    />
  );
};

export default TrayListTable;
