import { Col, Input, Row, Space, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import Button from "../../../components/Button";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import ErrorBanner from "../../../components/ErrorBanner";
import SearchBar from "../../../components/SearchBar";
import tableStyles from "../../../components/Table/Table.module.css";
import WaitIndicator from "../../../components/WaitIndicator";
import { ScreenContext } from "../../../contexts/ScreenContext";
import ProductFieldController from "../../../controllers/ProductFieldController";
import { dateSelectionRangeTimeAdjustment } from "../../../functions/dates";
import DispensingOrderService from "../../../services/DispensingOrderService";
import CreateProduct from "../../Product/CreateProduct2";
import AdjustQuantity from "./AdjustQuantity";
import AdminDateTime from "./AdminDateTime";
import ChangePatientLocation from "./ChangePatientLocation";
import ChangePriority from "./ChangePriority";
import styles from "./PendingDispensingOrder.module.css";
import PendingDispensingOrderController from "./PendingDispensingOrderController";
import PendingDispensingOrderItem from "./PendingDispensingOrderItem";
import SubstituteProduct from "./SubstituteProduct";

const PendingDispensingOrder = ({
  user,
  site,
  dispensingOrder,
  selectedPackagingMethods,
  readonly,
}) => {
  const productFieldController = new ProductFieldController();

  const { smallScreen } = useContext(ScreenContext);
  //const smScreen = useMediaQuery(smallScreen);

  const navigate = useNavigate();
  const _controllerRef = useRef(null);
  const [error, setError] = useState("");

  const [updateTable, setUpdateTable] = useState(false);
  const updateTableRef = useRef(null);
  updateTableRef.current = updateTable;

  const [localDispensingOrderItems, setLocalDispensingOrderItems] = useState(
    []
  );
  const localDispensingOrderItemsRef = useRef(null);
  localDispensingOrderItemsRef.current = localDispensingOrderItems;

  const { t } = useTranslation("pendingDispensingOrder");
  const [searchText, setSearchText] = useState("");
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [filterType, setFilterType] = useState("ALL");
  const [dispensingData, setDispensingData] = useState([]);
  const [itemError, setItemError] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [patientSpecialDateInitialized, setPatientSpecialDateInitialized] =
    useState(false);

  const [displaySubstituteProductModal, setDisplaySubstituteProductModal] =
    useState(false);
  const displaySubstituteProductModalRef = useRef(null);
  displaySubstituteProductModalRef.current = displaySubstituteProductModal;

  const [displayAddProductModal, setDisplayAddProductModal] = useState(false);
  const displayAddProductModalRef = useRef(null);
  displayAddProductModalRef.current = displayAddProductModal;

  const [displayAdminDateTimeModal, setDisplayAdminDateTimeModal] =
    useState(false);
  const displayAdminDateTimeModalRef = useRef(null);
  displayAdminDateTimeModalRef.current = displayAdminDateTimeModal;

  const [displayAdjustQuantityModal, setDisplayAdjustQuantityModal] =
    useState(false);
  const displayAdjustQuantityModalRef = useRef(null);
  displayAdjustQuantityModalRef.current = displayAdjustQuantityModal;

  const [
    displayChangePatientLocationModal,
    setDisplayChangePatientLocationModal,
  ] = useState(null);
  const displayChangePatientLocationModalRef = useRef(null);
  displayChangePatientLocationModalRef.current =
    displayChangePatientLocationModal;

  const [selectedItem, setSelectedItem] = useState("");
  const [displayChangePriorityModal, setDisplayChangePriorityModal] =
    useState(false);

  const [formatedCreatedAtLocalTime, setFormatedCreatedAtLocalTime] =
    useState("");

  const [fetching, setFetching] = useState(false);
  const defaultWaitIndicatorMessage = "Fetching order details...";
  const [waitIndicatorMessage, setWaitIndicatorMessage] = useState(
    defaultWaitIndicatorMessage
  );
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);
  const [startDateString, setStartDateString] = useState("");
  const [stopDateString, setStopDateString] = useState("");
  const [dispensingOrderId, setDispensingOrderId] = useState(
    dispensingOrder?.dispensingOrderId
  );

  _controllerRef.current = new PendingDispensingOrderController(user);

  // TIMER FOR REFRESH
  useEffect(() => {
    let timerId = setInterval(() => {
      updateDispensingOrderItems(false);
    }, 30000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const updateDispensingOrderItems = (fetching = true) => {
    setFetching(fetching);
    _controllerRef.current
      .getDetails(dispensingOrder, site)
      .then((details) => {
        if (details.dispensingOrder?.createdAt) {
          const date = new Date(details.dispensingOrder.createdAt);
          setFormatedCreatedAtLocalTime(formatDate(date));
        }

        setLocalDispensingOrderItems(details.dispensingOrderItems);
        setUpdateTable(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  // Revised by RWD on 7/31/2024
  useEffect(() => {
    // Used only for "Patient - Special" date initialization
    if (!patientSpecialDateInitialized && filterType === "Patient - Special") {
      if (dispensingData?.length) {
        // Date initialization to max date
        let startDateLocal = new Date(new Date(8640000000000000));

        dispensingData.forEach((arrayItem) => {
          // arrayItem: [{…}, {…}, 'Retail', 'LOW', 'FFF, FFF', '', '12345', '00904530680', 'DIPHENHYDRAMINE 25mg', '2024-07-27', '11:55', 1, '', {…}, '66a51880a8d1d44d494bbb46']

          const yearDayMonthArray = arrayItem[9].split("-");
          const [year, month, day] = yearDayMonthArray;
          const nextDateLocal = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

          // assign the earliest date in the array
          if (nextDateLocal.getTime() < startDateLocal.getTime()) {
            startDateLocal = nextDateLocal;
          }
        });

        let stopDateLocal = new Date(startDateLocal);

        // get a 7-day date range
        stopDateLocal.setDate(startDateLocal.getDate() + 6);

        setSelectionRange(
          dateSelectionRangeTimeAdjustment(startDateLocal, stopDateLocal)
        );
        setPatientSpecialDateInitialized(true);
      }
    }
  }, [filterType, dispensingData, patientSpecialDateInitialized]);

  useEffect(() => {
    if (selectionRange?.startDate && selectionRange?.endDate) {
      const startDateObj = new Date(selectionRange.startDate);
      const stopDateObj = new Date(selectionRange.endDate);

      setStartDateString(
        `${
          startDateObj.getMonth() + 1
        }/${startDateObj.getDate()}/${startDateObj.getFullYear()}`
      );
      setStopDateString(
        `${
          stopDateObj.getMonth() + 1
        }/${stopDateObj.getDate()}/${stopDateObj.getFullYear()}`
      );
    }
  }, [selectionRange]);

  useEffect(() => {
    updateDispensingOrderItems();
  }, []);

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
  };

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      `${date.getFullYear().toString().substr(-2)} ${padTo2Digits(
        date.getHours()
      )}:${padTo2Digits(date.getMinutes())}`,
    ].join("/");
  };

  useEffect(() => {
    parseData();
  }, [
    localDispensingOrderItems,
    searchText,
    filterType,
    displayChangePriorityModal,
    startDateString,
    stopDateString,
  ]);

  const parseData = () => {
    if (dispensingOrder.errorExists) {
      setItemError(true);
    }

    const records = localDispensingOrderItems

      .filter((o) =>
        filterType === "ALL" || filterType === "Patient - Special"
          ? true
          : o.errorExists
      )

      .filter((o) => {
        if (
          filterType === "Patient - Special" &&
          o?.adminDate &&
          selectionRange?.startDate &&
          selectionRange?.endDate
        ) {
          const [year, month, day] = o.adminDate.split("-");

          const adminDateObject = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

          return (
            adminDateObject >= selectionRange.startDate &&
            adminDateObject <= selectionRange.endDate
          );
        }
        return true;
      })

      .filter((o) => {
        if (!searchText) return true;

        const { patientInfo, product } = o;
        const upperSearchText = searchText.toUpperCase();

        return (
          o.facility?.name?.toUpperCase().includes(upperSearchText) ||
          o.priority?.toUpperCase().includes(upperSearchText) ||
          patientInfo?.id?.includes(searchText) ||
          patientInfo?.name?.toUpperCase().includes(upperSearchText) ||
          o.rxNumber?.toUpperCase().includes(upperSearchText) ||
          product?.packageId?.toUpperCase().includes(upperSearchText) ||
          product?.primaryName?.toUpperCase().includes(upperSearchText) ||
          product?.secondaryName?.toUpperCase().includes(upperSearchText) ||
          o.adminDate?.toUpperCase().includes(upperSearchText) ||
          o.adminTime?.toUpperCase().includes(upperSearchText)
        );
      })

      .map((item) => {
        if (!itemError && item.errorExists) {
          setItemError(true);
        }
        let patientNameTemplateString = item.patientInfo?.id
          ? `${item.patientInfo.name} (${item.patientInfo.id}),`
          : `${item.patientInfo.name}`;

        return [
          "_selectedObject" ? item : "",
          <div
            className={
              !item.errorExists
                ? styles.PendingDispensingOrders_itemStatusIndicator
                : [
                    styles["PendingDispensingOrders_itemStatusIndicator"],
                    styles[
                      "PendingDispensingOrders_itemStatusIndicator--withError"
                    ],
                  ].join(" ")
            }
          ></div>,
          item.facility?.name ?? "",
          item.priority ?? "",
          patientNameTemplateString,
          item.patientInfo?.room ?? "",
          item.rxNumber ? item.rxNumber : "",
          item.product?.packageId ?? "",
          item.product
            ? productFieldController.getDefaultName(item.product)
            : "",
          item.adminDate ? item.adminDate : "",
          item.adminTime ? item.adminTime.substring(0, 5) : "",
          item.qtyNeeded ? item.qtyNeeded : "",
          item.errorDescription ? item.errorDescription : "",
          <PendingDispensingOrderItem
            user={user}
            item={item}
            onActivateSubstituteProductModal={() =>
              handleSubstituteProductModal(true)
            }
            onActivateAddProductModal={() => handleAddProductModal(true)}
            onActivateAdminDateTimeModal={() => handleAdminDateTimeModal(true)}
            onActivateAdjustQuantityModal={() =>
              handleAdjustQuantityModal(true)
            }
            onActivateChangePatientLocationModal={() =>
              handleChangePatientLocationModal(true)
            }
            onActivatePriorityModal={() => handleChangePriorityModal(item)}
            onHandleSelectedItem={handleSelectedItem}
          ></PendingDispensingOrderItem>,
          item._id,
        ];
      });

    setDispensingData(records);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText.toUpperCase());
  };

  const filterListObjects = [
    { type: t("filterListAll"), description: t("filterListAll") },
    {
      type: t("filterListSpecialCustomer"),
      description: t("filterListSpecialCustomer"),
    },
    { type: t("filterListError"), description: t("filterListError") },
  ];

  const navigateToDispensingOrders = () => {
    navigate("/dispensing-orders", {
      state: {
        selectedSite: site,
        selectedPackagingMethods: selectedPackagingMethods,
      },
    });
  };

  const handleUpdateTable = (bool) => {
    setUpdateTable(bool);
  };

  // Revised by RWD on 02/07/2024
  // const handleReject = async () => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to reject ${dispensingOrder.dispensingOrderId}? This cannot be undone.`
  //     )
  //   ) {
  //     await DispensingOrderService.resetOrder(
  //       user,
  //       site,
  //       dispensingOrder,
  //       dispensingOrder.state
  //     );
  //     navigateToDispensingOrders();
  //   }
  // };

  // const handleApprove = () => {
  //   _controllerRef.current.approveOrder(dispensingOrder, site);
  //   navigateToDispensingOrders();
  // };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  const handleSubstituteProductModal = (bool) => {
    setDisplaySubstituteProductModal(bool);
  };

  const handleAddProductModal = (bool) => {
    setDisplayAddProductModal(bool);
  };

  const handleAdminDateTimeModal = (bool) => {
    setDisplayAdminDateTimeModal(bool);
  };

  const handleAdjustQuantityModal = (bool) => {
    setDisplayAdjustQuantityModal(bool);
  };

  const handleChangePatientLocationModal = (bool) => {
    setDisplayChangePatientLocationModal(bool);
  };

  const handleChangePriorityModal = (item) => {
    setDisplayChangePriorityModal(item);
  };

  const handleUpdateItem = (originalItem, modifiedItem) => {
    const matchCriteria = (element) => element === originalItem;

    const index = localDispensingOrderItems.findIndex(matchCriteria);
    if (index !== -1) {
      let tempArray = [...localDispensingOrderItems];
      tempArray[index] = modifiedItem;
      setLocalDispensingOrderItems(tempArray);
    }
  };

  const handleProcessSplitOrder = async () => {
    if (localDispensingOrderItems.length >= 1) {
      setWaitIndicatorMessage("Splitting dispensing orders...");
      setFetching(true);

      const selectedDispensingOrderItems = localDispensingOrderItems.filter(
        (x) => selectedRowKeys.includes(x._id)
      );

      await _controllerRef.current.splitOrder(
        dispensingOrder,
        localDispensingOrderItems,
        selectedDispensingOrderItems
      );

      if (filterType === "Patient - Special") {
        // Get patient for selected items group
        // Delete any pending dispensing order items for this patient outside of the selected date range.
        const patientInfo = selectedDispensingOrderItems[0]?.patientInfo;
        const patientLastName = patientInfo?.lastName;
        const patientFirstName = patientInfo?.firstName;
        const facilityId = selectedDispensingOrderItems[0]?.facility?._id;

        const dispensingOrderItemsToRemove = localDispensingOrderItems
          .map((o) => o)
          .filter((o) => {
            const adminDateArray = o.adminDate.split("-");
            const adminDateYear = adminDateArray[0];
            const adminDateMonth = adminDateArray[1];
            const adminDateDay = adminDateArray[2];

            const adminDateObject = new Date(
              `${adminDateYear}, ${adminDateMonth}, ${adminDateDay}`
            );

            return (
              o?.patientInfo?.lastName === patientLastName &&
              o?.patientInfo?.firstName === patientFirstName &&
              o?.facility?._id === facilityId &&
              !(
                adminDateObject >= selectionRange.startDate &&
                adminDateObject <= selectionRange.endDate
              )
            );
          });

        // console.log(
        //   "dispensingOrderItemsToRemove: ",
        //   dispensingOrderItemsToRemove
        // );

        async function removeDispensingOrderItems() {
          for (let i = 0; i < dispensingOrderItemsToRemove.length; i++) {
            await _controllerRef.current
              .deleteDispensingOrderItem(dispensingOrderItemsToRemove[i])
              .then((results) => {
                console.log("results: ", results);
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log("closed");
        }

        await removeDispensingOrderItems();
      }

      navigateToDispensingOrders();
    } else {
      setError(
        "Orders can only be split when there is more than one dispensing transaction."
      );
    }
  };

  const handleToggleSwitch = () => {
    if (filterType === "ALL") {
      setFilterType("ERROR");
    } else {
      setFilterType("ALL");
    }
  };

  const handleUpdateDispensingOrderId = async () => {
    if (
      !dispensingOrderId ||
      dispensingOrderId === dispensingOrder?.dispensingOrderId
    ) {
      return;
    }

    try {
      setWaitIndicatorMessage("Updating Dispensing Order ID...");
      setFetching(true);
      await DispensingOrderService.updateDispensingOrderId(
        user,
        site,
        dispensingOrder._id,
        dispensingOrderId
      );

      dispensingOrder.dispensingOrderId = dispensingOrderId;
      localDispensingOrderItems.forEach(
        (item) => (item.dispensingOrderId = dispensingOrderId)
      );
    } catch (error) {
      setError(error);
    } finally {
      setWaitIndicatorMessage(defaultWaitIndicatorMessage);
      setFetching(false);
    }
  };

  const distinctByIndex = (array, index) => {
    const uniqueItems = new Set(array.map((record) => record[index]));
    return [...uniqueItems].map((value) => ({
      text: value,
      value: value,
    }));
  };

  const onTableChange = (_pagination, _filters, _sorter, extra) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  // smScreen ? (
  //   <Fragment>
  //     <div className={styles.PendingDispensingOrder__header}>
  //       <div className={styles.PendingDispensingOrder__headerTitleGroup}>
  //         <div className={styles.PendingDispensingOrder__backButtonMobile}>
  //           <BackButtonMobile onClick={navigateToDispensingOrders} />
  //         </div>
  //         <h1>Order Details</h1>
  //       </div>
  //       <div className={styles.PendingDispensingOrder__errorToggleGroup}>
  //         <span>Errors only</span>
  //         <ToggleSwitch
  //           id={1}
  //           onChangeHandler={handleToggleSwitch}
  //           checkState={filterType === "ERROR"}
  //         />
  //       </div>
  //     </div>

  //     <div className={styles.PendingDispensingOrder__searchBarContainer}>
  //       <SearchBar
  //         smFullWidth={true}
  //         placeholder={t("searchBarText")}
  //         onSearch={handleSearch}
  //         focus={true}
  //       />
  //     </div>
  //   </Fragment>
  // ) :

  let HeaderComponent = (
    <div className={styles.PendingDispensingOrder__header}>
      <BackButton
        className={styles.PendingDispensingOrder__button}
        onClick={navigateToDispensingOrders}
      />
      <h1>
        {site.name} <span>{t("pendingDispensingOrderTitle")}</span>
      </h1>
    </div>
  );

  const pagination = {
    pageSize: 100,
  };

  if (fetching) {
    return <WaitIndicator message={waitIndicatorMessage} />;
  } else if (displayChangePriorityModal) {
    return (
      <ChangePriority
        user={user}
        dispensingOrderItem={displayChangePriorityModal}
        onCloseModal={(updateTable) => {
          handleChangePriorityModal(null);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
        pendDisOrderControllerRef={_controllerRef.current}
      />
    );
  } else if (displaySubstituteProductModal) {
    return (
      <SubstituteProduct
        pendDisOrderControllerRef={_controllerRef.current}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        onCloseSubstituteProductModal={(updateTable) => {
          handleSubstituteProductModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayAddProductModal) {
    return (
      <CreateProduct
        user={user}
        product={null}
        dispensingOrderItem={selectedItem}
        onCloseAddProductModal={(updateTable) => {
          handleAddProductModal(false);
          //update table is not need
          //after create a product user is redirected to products page
        }}
        isNew={true}
      />
    );
  } else if (displayAdminDateTimeModal) {
    return (
      <AdminDateTime
        pendDisOrderControllerRef={_controllerRef.current}
        user={user}
        selectedItem={selectedItem}
        onCloseAdminDateTimeModal={(updateTable) => {
          handleAdminDateTimeModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayAdjustQuantityModal) {
    return (
      <AdjustQuantity
        pendDisOrderControllerRef={_controllerRef.current}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        onCloseAdjustQuantityModal={(updateTable) => {
          handleAdjustQuantityModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayChangePatientLocationModal) {
    return (
      <ChangePatientLocation
        pendDisOrderControllerRef={_controllerRef.current}
        user={user}
        selectedItem={selectedItem}
        onCloseChangePatientLocationModal={(updateTable) => {
          handleChangePatientLocationModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else {
    return (
      <div
      //className={styles.PendingDispensingOrder__container}
      >
        {showDateTimeRangePicker && (
          <div className={styles.PendingDispensingOrder__overlay}>
            <div className={styles.PendingDispensingOrder__modal}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleDateRangeSelect}
                rangeColors={["#089BAB"]}
              />
              <br />
              <Button
                labelName={"Continue"}
                isPrimary={true}
                onClick={() => setShowDateTimeRangePicker(false)}
              />
            </div>
          </div>
        )}

        {HeaderComponent}

        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Row>
            <Col span={24}>
              {error && (
                <div>
                  <br />
                  <ErrorBanner
                    message={error}
                    onClose={() => {
                      setError("");
                    }}
                  />
                  <br />
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row align="middle" gutter={5}>
                <Col flex="15px">
                  <div
                    className={
                      !dispensingOrder?.errorExists
                        ? styles.PendingDispensingOrders__groupStatusIndicator
                        : [
                            styles[
                              "PendingDispensingOrders__groupStatusIndicator"
                            ],
                            styles[
                              "PendingDispensingOrders__groupStatusIndicator--withError"
                            ],
                          ].join(" ")
                    }
                  ></div>
                </Col>
                <Col flex="150px">
                  <span
                    className={`${styles.PendingDispensingOrders__orderInfoItemText}`}
                  >
                    Dispensing Order ID:
                  </span>
                </Col>
                <Col flex="auto">
                  <Input
                    className={`${styles.PendingDispensingOrders__orderInfoItemValue}`}
                    size="large"
                    defaultValue={dispensingOrderId}
                    onChange={(e) => {
                      setDispensingOrderId(e.target.value);
                    }}
                    isEnabled={readonly === false}
                  />
                </Col>
                <Col flex="auto">
                  {readonly === false && (
                    <Button
                      className={styles.PendingDispensingOrders__saveButton}
                      labelName={"Save"}
                      isPrimary={true}
                      isDisabled={
                        !dispensingOrderId ||
                        dispensingOrderId === dispensingOrder?.dispensingOrderId
                      }
                      minWidth={100}
                      minHeight={40}
                      onClick={async () =>
                        await handleUpdateDispensingOrderId()
                      }
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row align="middle" gutter={5}>
                <Col flex="15px"></Col>
                <Col flex="150px">
                  <span
                    className={`${styles.PendingDispensingOrders__orderInfoItemText}`}
                  >
                    Received:
                  </span>
                </Col>
                <Col flex="auto">
                  <span
                    className={`${styles.PendingDispensingOrders__orderInfoItemValue}`}
                  >
                    {formatedCreatedAtLocalTime}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="start" align={"bottom"}>
            <Col span={8}>
              <SearchBar
                placeholder={t("searchBarText")}
                onSearch={handleSearch}
                focus={true}
              />
            </Col>
            <Col span={8}></Col>
            <Col span={4}>
              {filterType === "Patient - Special" && (
                <div className={styles.PendingDispensingOrder__dateRangeGroup}>
                  <p className={styles.PendingDispensingOrder__dateRangeLabel}>
                    Start and End Date
                  </p>
                  <p
                    className={styles.PendingDispensingOrder__dateRange}
                    onClick={() => setShowDateTimeRangePicker(true)}
                  >
                    {startDateString || ""}-{stopDateString || ""}
                  </p>
                </div>
              )}
            </Col>

            <Col span={4}>
              <DropdownInput
                labelName={t("typeLabel")}
                value={filterType ? filterType : ""}
                validationText={""}
                placeholder={""}
                isEnabled={true}
                listElements={filterListObjects}
                onSelection={(value) => setFilterType(value.type)}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Table
                columns={[
                  {
                    title: " ",
                    key: "status",
                    render: (record) => record[1],
                    width: "25px",
                  },
                  {
                    title: "Facility",
                    key: "Facility",
                    render: (record) => record[2],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      2
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[2].includes(value),
                    ellipsis: true,
                  },
                  {
                    title: "Priority",
                    key: "Priority",
                    render: (record) => record[3],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      3
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[3].includes(value),
                    width: "95px",
                    ellipsis: true,
                  },
                  {
                    title: "Patient",
                    key: "Patient",
                    render: (record) => record[4],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      4
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[4].includes(value),
                    ellipsis: true,
                  },
                  {
                    title: "Location",
                    key: "Location",
                    render: (record) => record[5],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      5
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[5].includes(value),
                    //width: "100px",
                    ellipsis: true,
                  },
                  {
                    title: "Rx Number",
                    key: "Rx Number",
                    render: (record) => record[6],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      6
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[6].includes(value),
                    ellipsis: true,
                  },
                  {
                    title: "ID",
                    key: "ID",
                    render: (record) => record[7],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      7
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[7].includes(value),
                    ellipsis: true,
                  },
                  {
                    title: "Product",
                    key: "Product",
                    render: (record) => record[8],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      8
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[8].includes(value),
                    ellipsis: true,
                  },
                  {
                    title: "Admin Date",
                    key: "Admin Date",
                    render: (record) => record[9],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      9
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[9].includes(value),
                    //width: "125px",
                    ellipsis: true,
                  },
                  {
                    title: "Admin Time",
                    key: "Admin Time",
                    render: (record) => record[10],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      10
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[10].includes(value),
                    //width: "130px",
                    ellipsis: true,
                  },
                  {
                    title: "Qty",
                    key: "Qty",
                    render: (record) => record[11],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      11
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[11].includes(value),
                    width: "65px",
                    ellipsis: true,
                  },
                  {
                    title: "Error",
                    key: "Error",
                    render: (record) => record[12],
                    filters: distinctByIndex(
                      currentDataSource ?? dispensingData,
                      12
                    ),
                    filterMode: "tree",
                    filterSearch: true,
                    onFilter: (value, record) => record[12].includes(value),
                    onCell: () => {
                      return {
                        style: {
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        },
                      };
                    },
                  },
                  {
                    title: " ",
                    key: "Actions",
                    render: (record) => record[13],
                    width: "70px",
                    hidden: readonly === true,
                  },
                ]}
                dataSource={dispensingData}
                pagination={pagination}
                rowClassName={(record) => {
                  if (record[15] === true) {
                    return `${tableStyles.Table__row} ${tableStyles.Table__selectedRow}`;
                  }
                  return `${tableStyles.Table__row} ${tableStyles.Table__unSelectedRow}`;
                }}
                rowKey={(record) => record[14]}
                scroll={
                  (!dispensingData || dispensingData.length === 0) &&
                  (!currentDataSource || currentDataSource.length === 0)
                    ? undefined
                    : { y: window.innerHeight - 425 }
                }
                size="small"
                onChange={onTableChange}
                rowSelection={
                  readonly === true
                    ? undefined
                    : {
                        selectedRowKeys: selectedRowKeys,
                        columnTitle: (checkbox) => {
                          setSelectAllSelected(checkbox.props.checked);
                          return checkbox;
                        },
                        onChange: (newSelectedRowKeys, _selectedRows, info) => {
                          if (info.type === "all") {
                            const dataSource =
                              currentDataSource ?? dispensingData;

                            const currentKeys = dataSource.map(
                              (record) => record[14]
                            );

                            if (selectAllSelected) {
                              //unselect all
                              const unselected = selectedRowKeys.filter(
                                (item) => !currentKeys.includes(item)
                              );
                              setSelectedRowKeys(unselected);
                            } else {
                              //select all
                              const mySet = new Set([
                                ...selectedRowKeys,
                                ...currentKeys,
                              ]);
                              setSelectedRowKeys([...mySet]);
                            }
                          } else if (info.type === "single") {
                            //select line
                            setSelectedRowKeys(newSelectedRowKeys);
                          }
                        },
                      }
                }
              ></Table>
            </Col>
          </Row>

          {/* {smScreen && (
          <div className={styles.PendingDispensingOrder__orderDetailsGroup}>
            <h2 className={styles.PendingDispensingOrder__heading}>
              {site.name}
            </h2>
            <div
              className={
                selectedRowKeys && selectedRowKeys.length > 1
                  ? `${styles.PendingDispensingOrder__splitGroup}  ${styles["PendingDispensingOrder__splitGroup--showSplitBtn"]}`
                  : styles.PendingDispensingOrder__splitGroup
              }
            >
              <div className={styles.PendingDispensingOrders__orderInfo}>
                <p
                  className={
                    !dispensingOrder.errorExists
                      ? styles.PendingDispensingOrders__groupStatusIndicator
                      : [
                          styles[
                            "PendingDispensingOrders__groupStatusIndicator"
                          ],
                          styles[
                            "PendingDispensingOrders__groupStatusIndicator--withError"
                          ],
                        ].join(" ")
                  }
                ></p>

                <p
                  className={
                    styles[
                      "PendingDispensingOrders__orderInfoItem--dispensingOrderId"
                    ]
                  }
                >
                  {dispensingOrder?.dispensingOrderId &&
                  dispensingOrder?.createdAt
                    ? `${dispensingOrder.dispensingOrderId} (${formatedCreatedAtLocalTime})`
                    : dispensingOrder?.dispensingOrderId
                    ? `${dispensingOrder.dispensingOrderId}`
                    : null}
                </p>
              </div>
              {selectedRowKeys && selectedRowKeys.length > 1 && (
                <button
                  className={styles.PendingDispensingOrder__splitBtn}
                  onClick={async () => await handleProcessSplitOrder()}
                >
                  Split
                </button>
              )}
            </div>

            {dispensingOrder?.errorExists && (
              <p className={styles.PendingDispensingOrders__errorMsg}>
                {dispensingOrder.errorDescription}
              </p>
            )}

            <PendingDispensingOrderCardList
              onClickHandler={handleSplitOrders}
              searchText={searchText}
              headers={[
                " ",
                "Facility",
                "Priority",
                "Patient",
                "Location",
                "Rx Number",
                "ID",
                "Product",
                "Admin Date",
                "Admin Time",
                "Qty",
                "Error",
                " ",
              ]}
              records={dispensingData}
              errorDescription={
                dispensingOrder?.errorExists
                  ? dispensingOrder.errorDescription
                  : ""
              }
            />
          </div>
        )} */}

          <Row>
            <Col span={12}>
              <Row justify="start" gutter={12}>
                <Col>
                  {readonly === false &&
                    selectedRowKeys &&
                    selectedRowKeys.length >= 1 && (
                      <Button
                        labelName={t("splitOrderButtonLabel")}
                        minWidth={"213px"}
                        isPrimary={true}
                        isDisabled={false}
                        onClick={() => handleProcessSplitOrder()}
                      />
                    )}
                </Col>
              </Row>
            </Col>
            {/* <Col span={12}>
              <Row justify="end" gutter={12}>
                <Col>
                  <Button
                    labelName={t("rejectButtonLabel")}
                    isPrimary={false}
                    isDisabled={false}
                    onClick={() => handleReject()}
                    //minWidth={smScreen ? 122 : null}
                  />
                </Col>
                <Col>
                  {!itemError ? (
                    <Button
                      labelName={t("approveButtonLabel")}
                      isPrimary={true}
                      isDisabled={false}
                      onClick={() => handleApprove()}
                      //minWidth={smScreen ? 122 : 213}
                    />
                  ) : (
                    <ButtonGen
                      buttonClass={
                        styles.PendingDispensingOrders__disabledButton
                      }
                    >
                      {t("approveButtonLabel")}
                    </ButtonGen>
                  )}
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Space>
      </div>
    );
  }
};

export default PendingDispensingOrder;
