import React from "react";
import OptiSortDailyReportCard from "./OptiSortDailyReportCard";
import styles from "./OptiSortDailyReportCardList.module.css";

const OptiSortDailyReportCardList = ({ headings, data, fetchingData }) => {
  const headingsObj = {
    siteName: headings[0],
    deviceName: headings[1],
    processedOn: headings[2],
    packageId: headings[3],
    product: headings[4],
    manufacturer: headings[5],
    controlLevel: headings[6],
    cost: headings[7],
    quantity: headings[8],
    totalCost: headings[9],
  };

  if (data.length || fetchingData) {
    return data.map((dataRow, index) => (
      <div
        key={index}
        className={styles.OptiSortDailyReportCardList__container}
      >
        <OptiSortDailyReportCard
          headingsObj={headingsObj}
          dataObj={{
            siteName: dataRow[0],
            deviceName: dataRow[1],
            processedOn: dataRow[2],
            packageId: dataRow[3],
            product: dataRow[4],
            manufacturer: dataRow[5],
            controlLevel: dataRow[6],
            cost: dataRow[7],
            quantity: dataRow[8],
            totalCost: dataRow[9],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.OptiSortDailyReportCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.OptiSortDailyReportCardList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default OptiSortDailyReportCardList;
