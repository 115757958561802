import React from "react";
import styles from "./ProductsWOPriceCard.module.css";

const ProductsWOPriceCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.ProductsWOPriceCard__reportList}>
      <li className={styles.ProductsWOPriceCard__reportListItem}>
        <div className={styles.ProductsWOPriceCard__dataContainer}>
          <h1>{headingsObj.packageId}</h1>
          <p>{dataObj.packageId}</p>
        </div>
      </li>
      <li className={styles.ProductsWOPriceCard__reportListItem}>
        <div className={styles.ProductsWOPriceCard__dataContainer}>
          <h1>{headingsObj.product}</h1>
          <p>{dataObj.product}</p>
        </div>
      </li>
      <li className={styles.ProductsWOPriceCard__reportListItem}>
        <div className={styles.ProductsWOPriceCard__dataContainer}>
          <h1>{headingsObj.priceFile}</h1>
          {dataObj.priceFile}
        </div>
      </li>
      <li className={styles.ProductsWOPriceCard__reportListItem}>
        <div className={styles.ProductsWOPriceCard__dataContainer}>
          <h1>{headingsObj.cost}</h1>
          {dataObj.cost}
        </div>
      </li>
    </ul>
  );
};

export default ProductsWOPriceCard;
