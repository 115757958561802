import React, { useEffect, useRef, useContext } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import ImageCarousel from "../../components/ImageCarousel";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const CreateProductLTCCosts = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onError,
  onAddImage,
  onDeleteImage,
}) => {
  const { t } = useTranslation("createProduct");
  const productControllerRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  return (
    <Accordion title={"LTC Costs"}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"AWP Bulk"}
            isEnabled={true}
            value={p?.ltcAwpBulk || ""}
            validationText={errors.ltcAwpBulk}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAwpBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"AWP Item"}
            isEnabled={true}
            value={p?.ltcAwpItem || ""}
            validationText={errors.ltcAwpItem}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAwpItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"Acquisition Bulk"}
            isEnabled={true}
            value={p?.ltcAcquisitionBulk || ""}
            validationText={errors.ltcAcquisitionBulk}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAcquisitionBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"Acquisition Item"}
            isEnabled={true}
            value={p?.ltcAcquisitionItem || ""}
            validationText={errors.ltcAcquisitionItem}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAcquisitionItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"GPO Bulk"}
            isEnabled={true}
            value={p?.ltcGpoBulk || ""}
            validationText={errors.ltcGpoBulk}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcGpoBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"GPO Item"}
            isEnabled={true}
            value={p?.ltcGpoItem || ""}
            validationText={errors.ltcGpoItem}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcGpoItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"340b Bulk"}
            isEnabled={true}
            value={p?.ltcThree40bBulk || ""}
            validationText={errors.ltcThree40bBulk}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcThree40bBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={"340b Item"}
            isEnabled={true}
            value={p?.ltcThree40bItem || ""}
            validationText={errors.ltcThree40bItem}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcThree40bItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductLTCCosts;
