import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./InventoryEquivDataCard.module.css";

const InventoryEquivDataCard = ({ data }) => {
  const { t, i18n } = useTranslation("pharmacySites");
  return (
    <div className={styles.inventoryEquivDataCard__container}>
      <div className={styles.inventoryEquivDataCard__dataList}>
        <div className={styles.inventoryEquivDataCard__rowGroup}>
          <div
            className={`${styles.inventoryEquivDataCard__colGroup} ${styles["inventoryEquivDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryEquivDataCard__heading"]}`}>
              {data.idHeading}:
            </div>
            <div className={`${styles["inventoryEquivDataCard__value"]}`}>
              {data.id}
            </div>
            <div className={styles.inventoryEquivDataCard__actions}>
              <div className={`${styles["inventoryEquivDataCard__value"]}`}>
                {data.actions}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.inventoryEquivDataCard__rowGroup}>
          <div
            className={`${styles.inventoryEquivDataCard__colGroup} ${styles["inventoryEquivDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryEquivDataCard__heading"]}`}>
              {data.descriptionHeading}:
            </div>
            <div className={`${styles["inventoryEquivDataCard__value"]}`}>
              {data.description}
            </div>
          </div>
        </div>

        <div className={styles.inventoryEquivDataCard__rowGroup}>
          <div
            className={`${styles.inventoryEquivDataCard__colGroup} ${styles["inventoryEquivDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryEquivDataCard__heading"]}`}>
              {data.manufacturerHeading}:
            </div>
            <div className={`${styles["inventoryEquivDataCard__value"]}`}>
              {data.manufacturer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryEquivDataCard;
