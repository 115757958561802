import React from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import Button from "../../../../components/Button";
import Spacer from "../../../../components/Spacer";
import backIcon from "../../../../assets/back_icon.svg";

//region CSS
const MyView = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const ExcelReportExporter = ({ headings, data, onBack }) => {
  const reportData = [];
  let reportDataObj = {};

  data.forEach((dataRow) => {
    headings.forEach((heading, headingIndex) => {
      const key = heading;
      const objToAdd = {};
      objToAdd[key] = dataRow[headingIndex];
      reportDataObj = { ...reportDataObj, ...objToAdd };
    });
    reportData.push(reportDataObj);
  });
  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>Excel Exporter</ReportTitle>
        </TitleGroup>
      </Header>
      <Spacer unit={"px"} space={50} />
      <CSVLink data={reportData}>
        <Button isPrimary={true} minWidth={"123px"} labelName={"Download"} />
      </CSVLink>
    </MyView>
  );
};

export default ExcelReportExporter;
