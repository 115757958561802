import closestIndexTo from "date-fns/esm/closestIndexTo";
import React, { useEffect, useState, useRef } from "react";
import { composeInitialProps } from "react-i18next";
import styles from "./Table.module.css";

const Table = ({
  id,
  headers,
  records,
  searchText,
  padScrollBar = false,
  onClickHandler = null,
  singleSelectionOnly,
  tabColor,
  /********************************/
  // These 3 props are used for auto scrolling when an action-item popup is opened
  showPopup = false,
  popupIndex = -1,
  tableRowHeight = null,
  scrollAdjustConstant = null,
  /********************************/
}) => {
  const [sortOrder, setSortOrder] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedExternalObjects, setSelectedExternalObjects] = useState([]);
  const [pageToggle, setPageToggle] = useState(false);

  const tableContainerEl = useRef();

  const handlePageRerender = () => {
    setPageToggle((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    // Used for auto scrolling when an action-item popup is opened
    if (showPopup && popupIndex >= 0 && scrollAdjustConstant) {
      let diff =
        (popupIndex + 1) * tableRowHeight +
        scrollAdjustConstant -
        (tableContainerEl?.current?.scrollTop +
          tableContainerEl?.current?.offsetHeight);

      const scrollDelay = setTimeout(scrollFunction, 100);

      function scrollFunction() {
        if (diff > 0) {
          tableContainerEl.current.scrollTo(
            0,
            tableContainerEl.current.scrollTop + diff
          );
        }
        clearTimeout(scrollDelay);
      }
    }
  }, [showPopup]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < headers.length; i++) {
      arr.push(1);
    }
    setSortOrder(arr);
  }, [headers]);

  useEffect(() => {
    let table = document.querySelector("tbody");
    if (id) {
      table = document.querySelector("#" + id);
    }
    resizableGrid(table);
  });

  useEffect(() => {
    if (searchText) {
      const arr = [...records];
      const arr2 = [];
      for (let r = 0; r < arr.length; r++) {
        const record = arr[r];
        for (let i = 0; i < record.length; i++) {
          if (
            record[i] &&
            record[i]
              .toString()
              .toUpperCase()
              .includes(searchText.toUpperCase())
          ) {
            arr2.push(record);
            break;
          }
        }
      }
      setFilteredRecords(arr2);
    } else {
      setFilteredRecords(records);
    }
  }, [searchText, records]);

  const resizableGrid = (table) => {
    var row = table.getElementsByTagName("tr")[0],
      cols = row ? row.children : undefined;
    //console.log("cols: ", cols);
    if (!cols) return;
    for (var i = 0; i < cols.length; i++) {
      //cols[i].innerHTML = "";
      let divs = cols[i].getElementsByClassName("divider");
      //console.log("divs: ", divs);
      for (let d = 0; d < divs.length; d++) {
        cols[i].removeChild(divs[d]);
      }
      var div = createDiv(table.offsetHeight);
      cols[i].appendChild(div);
      cols[i].style.position = "relative";
      setListeners(div);
    }
  };

  function createDiv(height) {
    var div = document.createElement("div");
    div.className = "divider";
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = "4px";
    div.style.position = "absolute";
    div.style.cursor = "col-resize";
    /* remove backGroundColor later */
    // div.style.backgroundColor = "lightGrey";
    div.style.userSelect = "none";
    /* table height */
    div.style.height = height + "px";
    return div;
  }

  function setListeners(div) {
    var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
    div.addEventListener("mousedown", function (e) {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;
      curColWidth = curCol.offsetWidth;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
    });

    document.addEventListener("mousemove", function (e) {
      if (curCol) {
        var diffX = e.pageX - pageX;

        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

        curCol.style.width = curColWidth + diffX + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      curCol = undefined;
      nxtCol = undefined;
      pageX = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined;
    });
  }

  const handleColumnSort = (index) => {
    let arr = [...sortOrder];
    let arr2 = [...filteredRecords];
    arr[index] *= -1;
    arr2 = arr2.sort((rowA, rowB) => {
      if (rowA[index] < rowB[index]) {
        if (arr[index] > 0) {
          return -1;
        }
        return 1;
      }
      if (rowB[index] < rowA[index]) {
        if (arr[index] > 0) {
          return 1;
        }
        return -1;
      }
      return 0;
    });

    setFilteredRecords(arr2);
    setSortOrder(arr);
  };

  const handleRowClick = (record) => {
    let tempArraySelectedRecords = [...selectedExternalObjects];

    let recordMatchCriteria = (element) => element === record[0];
    let matchedIndex = tempArraySelectedRecords.findIndex(recordMatchCriteria);

    if (matchedIndex === -1) {
      if (singleSelectionOnly) {
        tempArraySelectedRecords = [record[0]];
      } else {
        tempArraySelectedRecords.push(record[0]);
      }
    } else {
      tempArraySelectedRecords.splice(matchedIndex, 1);
    }

    onClickHandler(tempArraySelectedRecords);
    setSelectedExternalObjects(tempArraySelectedRecords);
  };

  return (
    <div
      ref={tableContainerEl}
      className={
        !padScrollBar
          ? styles.Table__container
          : `${styles.Table__container} ${styles["Table__container--rightPadding"]}`
      }
    >
      <table className={styles.Table__table}>
        {headers && headers.length > 0 && (
          <thead>
            <tr className={styles.Table__header_row}>
              {headers.map((header, index) => {
                if (header !== "_selectedObject") {
                  return (
                    <th
                      key={"h_" + index}
                      onClick={() => handleColumnSort(index)}
                    >
                      {header}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
        )}
        <tbody id={id || "table"}>
          {filteredRecords &&
            filteredRecords.map((row, index) => {
              let rowIsSelected;
              rowIsSelected = onClickHandler
                ? selectedExternalObjects.includes(row[0])
                : null;

              return (
                <tr
                  key={"r_" + index}
                  className={
                    !rowIsSelected
                      ? onClickHandler
                        ? `${styles.Table__row} ${styles.Table__unSelectedRow} `
                        : `${styles.Table__row} ${styles.Table__unSelectedRow}`
                      : onClickHandler
                      ? `${styles.Table__row} ${styles.Table__selectedRow}`
                      : `${styles.Table__row} ${styles.Table__selectedRow}`
                  }
                  onClick={onClickHandler ? () => handleRowClick(row) : null}
                >
                  {row &&
                    row.length &&
                    row.map((col, index) => {
                      if (!onClickHandler || (onClickHandler && !col._id)) {
                        return (
                          <td
                            key={"c_" + index}
                            style={
                              col?.props?.width
                                ? { width: col.props.width }
                                : null
                            }
                            className={styles.Table__dataCell}
                          >
                            {col}
                          </td>
                        );
                      }
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
