import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as PicFrame } from "../assets/pic-frame-empty.svg";
import MenuItem from "../components/MenuItem";
import { NavbarContext } from "../contexts/NavbarContext";
import { ReactComponent as DashboardIcon } from "../assets/dashboard-menu.svg";
import { ReactComponent as InventoryIcon } from "../assets/inventory-menu.svg";
import { ReactComponent as OrdersIcon } from "../assets/order-menu.svg";
import { ReactComponent as ReportsIcon } from "../assets/reports-menu.svg";
import { ReactComponent as SettingsIcon } from "../assets/settings-menu.svg";
import { ReactComponent as DispenserIcon } from "../assets/Dispenser.svg";
import { ReactComponent as HelpIcon } from "../assets/help.svg";
import Spacer from "../components/Spacer";
import { useTranslation } from "react-i18next";
import ImageService from "../services/ImageService";
import projectInfo from "../../package.json";
import Popup from "../components/PopupMenu/Popup";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./NavBar.module.css";
import RoleCheckService from "../services/RoleCheckService";
import { faHeadset, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

const DASHBOARD_MENU_ITEM = 0;
const INVENTORY_MENU_ITEM = 1;
const ORDERS_MENU_ITEM = 2;
//const HISTORY_MENU_ITEM = 3;
const REPORTS_MENU_ITEM = 4;
const SETTINGS_MENU_ITEM = 5;
const PROFILE_MENU_ITEM = 6;
const LOGOUT_MENU_ITEM = 7;
const CHANGE_PASSWORD_MENU_ITEM = 8;
const TEST_MENU_ITEM = 9;
const DEVICES_MENU_ITEM = 10;
const HELP_MENU_ITEM = 11;
const SUPPORT_MENU_ITEM = 12;
const SUPER_ADMIN_MENU_ITEM = 13;

const NavBar = ({
  user,
  onMenuSelection,
  menuSelection,
  onCloseDropDownMenu,
}) => {
  const { t } = useTranslation("navBar");
  const location = useLocation();

  const {
    activeNavbarMenuItemViaContext,
    handleActiveNavbarMenuItemViaContext,
    labelModificationInProgressViaContext,
  } = useContext(NavbarContext);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  const [activeMenuItem, setActiveMenuItem] = useState(menuSelection);
  //const [contextMenu, setContextMenu] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    setActiveMenuItem(menuSelection);
  }, [menuSelection]);

  useEffect(() => {
    if (user.profilePic) {
      ImageService.read(user, user.profilePic).then((imageDoc) => {
        setProfilePic(imageDoc);
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      activeNavbarMenuItemViaContext >= 0 &&
      !labelModificationInProgressViaContext
    ) {
      // setActiveMenuItem(activeNavbarMenuItemViaContext);
    }
  }, [activeNavbarMenuItemViaContext, labelModificationInProgressViaContext]);

  const handleMenuSelection = (menuSelection) => {
    if (!labelModificationInProgressViaContext) {
      // setActiveMenuItem(menuSelection);
      handleActiveNavbarMenuItemViaContext(menuSelection);
    }
    onMenuSelection(menuSelection);
    if (smScreen && onCloseDropDownMenu) {
      onCloseDropDownMenu();
    }
  };

  const actionListItems = [
    {
      type: "logout",
      description: t("action0"),
    },
    {
      type: "editProfile",
      description: t("action1"),
    },
    {
      type: "changePassword",
      description: t("action2"),
    },
  ];

  const getActionMenu = () => {
    return actionListItems;
  };

  const handleAction = (action) => {
    switch (action.type) {
      case "logout":
        onMenuSelection(LOGOUT_MENU_ITEM);
        break;
      case "editProfile":
        onMenuSelection(PROFILE_MENU_ITEM);
        break;
      case "changePassword":
        onMenuSelection(CHANGE_PASSWORD_MENU_ITEM);
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event) => {
    // If a the user profile is focused, open it if the Enter key is pressed
    const code = event.keyCode || event.which;
    if (code === 13) {
      setShowPopup(true);
    }
  };

  const profile = (
    <section
      className={styles.navBar__profile}
      onClick={(e) => {
        document.activeElement.blur();
        setShowPopup(true);
      }}
    >
      <div
        className={styles.navBar__userGroup}
        tabIndex="0"
        onKeyUp={handleKeyUp}
      >
        {profilePic ? (
          <img
            className={styles.navBar__profilePic}
            src={
              profilePic.mimeType
                ? `data:image/${profilePic.mimeType};base64,${profilePic.base64EncodedString}`
                : profilePic.base64EncodedString
            }
            alt={"logo"}
          />
        ) : (
          <PicFrame className={styles.navBar__profilePic} fill={"white"} />
        )}

        {!mdScreen && (
          <p className={styles.navBar__text}>
            {user.firstName} {user.lastName}
            {lgScreen && <br />}
            {lgScreen && user.account.companyName}
          </p>
        )}
      </div>

      {lgScreen && (
        <p className={styles.navBar__text}>v{projectInfo.version}</p>
      )}

      {showPopup && (
        <div className={styles.navBar__popup}>
          <Popup
            rightJustify={false}
            onAction={(menuItem) => {
              setShowPopup(false);
              handleAction(menuItem);
            }}
            menuItems={getActionMenu()}
            onClose={() => setShowPopup(false)}
            onCloseDropDownMenu={
              onCloseDropDownMenu ? () => onCloseDropDownMenu() : null
            }
          />
        </div>
      )}
    </section>
  );

  return (
    <>
      <section className={styles.navBar__container}>
        <section className={styles.navBar__top}>
          {lgScreen && <Spacer space={40} unit={"px"} />}
          {/* <div className={smScreen ? styles.navBar__logoContainer : null}>
            {<CVSTestLogo />}
          </div> */}
          <Spacer space={smScreen ? 20 : 80} unit={"px"} />

          <MenuItem
            forSmallScreen={smScreen}
            padLeft={smScreen ? "1.5px" : null}
            icon={
              <DashboardIcon
                width={smScreen ? "24px" : "25px"}
                height={smScreen ? "24px" : "24px"}
              />
            }
            needsConfirmation={location.pathname.includes("trayCheck")}
            confirmationText={
              location.pathname.includes("trayCheck")
                ? t("trayCheckConfirmation")
                : ""
            }
            menuName={t("dashboardMenuName")}
            isActive={activeMenuItem === DASHBOARD_MENU_ITEM}
            onMenuSelect={() => {
              handleMenuSelection(DASHBOARD_MENU_ITEM);
            }}
          />

          {RoleCheckService.viewDevices(user) && (
            <>
              {lgScreen && <Spacer space={20} unit={"px"} />}
              {mdScreen && <Spacer space={10} unit={"px"} />}
              <MenuItem
                forSmallScreen={smScreen}
                padLeft={smScreen ? "3.5px" : null}
                icon={<DispenserIcon fill={"#ffffff"} />}
                needsConfirmation={location.pathname.includes("trayCheck")}
                confirmationText={
                  location.pathname.includes("trayCheck")
                    ? t("trayCheckConfirmation")
                    : ""
                }
                menuName={t("devicesMenuName")}
                isActive={activeMenuItem === DEVICES_MENU_ITEM}
                onMenuSelect={() => {
                  handleMenuSelection(DEVICES_MENU_ITEM);
                }}
              />
            </>
          )}
          {RoleCheckService.viewInventory(user) && (
            <>
              {lgScreen && <Spacer space={20} unit={"px"} />}
              {mdScreen && <Spacer space={10} unit={"px"} />}
              <MenuItem
                forSmallScreen={smScreen}
                padLeft={smScreen ? "1px" : null}
                icon={
                  <InventoryIcon
                    width={smScreen ? "22px" : "18px"}
                    height={smScreen ? "22px" : "16px"}
                  />
                }
                needsConfirmation={location.pathname.includes("trayCheck")}
                confirmationText={
                  location.pathname.includes("trayCheck")
                    ? t("trayCheckConfirmation")
                    : ""
                }
                menuName={t("inventoryMenuName")}
                isActive={activeMenuItem === INVENTORY_MENU_ITEM}
                onMenuSelect={() => {
                  handleMenuSelection(INVENTORY_MENU_ITEM);
                }}
              />
            </>
          )}
          {RoleCheckService.viewOrders(user) && (
            <>
              {lgScreen && <Spacer space={20} unit={"px"} />}
              {mdScreen && <Spacer space={10} unit={"px"} />}
              <MenuItem
                forSmallScreen={smScreen}
                padLeft={smScreen ? "3px" : null}
                icon={
                  <OrdersIcon
                    width={smScreen ? "20px" : "20px"}
                    height={smScreen ? "18px" : "19px"}
                  />
                }
                needsConfirmation={location.pathname.includes("trayCheck")}
                confirmationText={
                  location.pathname.includes("trayCheck")
                    ? t("trayCheckConfirmation")
                    : ""
                }
                menuName={t("ordersMenuName")}
                isActive={activeMenuItem === ORDERS_MENU_ITEM}
                onMenuSelect={() => {
                  handleMenuSelection(ORDERS_MENU_ITEM);
                }}
              />
            </>
          )}
          {lgScreen && <Spacer space={20} unit={"px"} />}
          {mdScreen && <Spacer space={10} unit={"px"} />}

          <MenuItem
            forSmallScreen={smScreen}
            padLeft={smScreen ? "4px" : null}
            icon={
              <ReportsIcon
                width={smScreen ? "20px" : "18px"}
                height={smScreen ? "16px" : "17px"}
              />
            }
            needsConfirmation={location.pathname.includes("trayCheck")}
            confirmationText={
              location.pathname.includes("trayCheck")
                ? t("trayCheckConfirmation")
                : ""
            }
            menuName={t("reportsMenuName")}
            isActive={activeMenuItem === REPORTS_MENU_ITEM}
            onMenuSelect={() => {
              handleMenuSelection(REPORTS_MENU_ITEM);
            }}
          />

          {RoleCheckService.viewSettings(user) && (
            <>
              {lgScreen && <Spacer space={20} unit={"px"} />}
              {mdScreen && <Spacer space={10} unit={"px"} />}
              <MenuItem
                forSmallScreen={smScreen}
                padLeft={smScreen ? "4.3px" : null}
                lastChild={true}
                icon={
                  <SettingsIcon
                    width={smScreen ? "17.4px" : "18px"}
                    height={smScreen ? "17.88px" : "19px"}
                  />
                }
                needsConfirmation={location.pathname.includes("trayCheck")}
                confirmationText={
                  location.pathname.includes("trayCheck")
                    ? t("trayCheckConfirmation")
                    : ""
                }
                menuName={t("settingsMenuName")}
                isActive={activeMenuItem === SETTINGS_MENU_ITEM}
                onMenuSelect={() => {
                  handleMenuSelection(SETTINGS_MENU_ITEM);
                }}
              />
            </>
          )}

          {lgScreen && <Spacer space={20} unit={"px"} />}
          {mdScreen && <Spacer space={10} unit={"px"} />}
          {
            <MenuItem
              forSmallScreen={smScreen}
              padLeft={smScreen ? "4px" : null}
              icon={<HelpIcon width="100%" color="#fff" />}
              needsConfirmation={location.pathname.includes("trayCheck")}
              confirmationText={
                location.pathname.includes("trayCheck")
                  ? t("trayCheckConfirmation")
                  : ""
              }
              menuName={t("helpMenuName")}
              isActive={activeMenuItem === HELP_MENU_ITEM}
              onMenuSelect={() => {
                handleMenuSelection(HELP_MENU_ITEM);
              }}
            />
          }
          {lgScreen && <Spacer space={20} unit={"px"} />}
          {mdScreen && <Spacer space={10} unit={"px"} />}
          {(RoleCheckService.isSuperAdmin(user) ||
            RoleCheckService.isCustomerService(user)) && (
            <MenuItem
              forSmallScreen={smScreen}
              padLeft={smScreen ? "4px" : null}
              icon={
                <FontAwesomeIcon
                  icon={faHeadset}
                  color={"#FFFFFF"}
                  size={"1x"}
                  width={"100%"}
                />
              }
              needsConfirmation={location.pathname.includes("trayCheck")}
              confirmationText={
                location.pathname.includes("trayCheck")
                  ? t("trayCheckConfirmation")
                  : ""
              }
              menuName={t("supportMenuName")}
              isActive={activeMenuItem === SUPPORT_MENU_ITEM}
              onMenuSelect={() => {
                handleMenuSelection(SUPPORT_MENU_ITEM);
              }}
            />
          )}
          {RoleCheckService.isSuperAdmin(user) && (
            <MenuItem
              forSmallScreen={smScreen}
              padLeft={smScreen ? "4px" : null}
              icon={
                <FontAwesomeIcon
                  icon={faWarning}
                  color={"#FFFFFF"}
                  size={"1x"}
                  width={"100%"}
                />
              }
              needsConfirmation={location.pathname.includes("trayCheck")}
              confirmationText={
                location.pathname.includes("trayCheck")
                  ? t("trayCheckConfirmation")
                  : ""
              }
              menuName={t("superAdminMenuName")}
              isActive={activeMenuItem === SUPER_ADMIN_MENU_ITEM}
              onMenuSelect={() => {
                handleMenuSelection(SUPER_ADMIN_MENU_ITEM);
              }}
            />
          )}
          {(smScreen || mdScreen) && profile}
        </section>

        {lgScreen && (
          <section className={styles.navBar__bottom}>{profile}</section>
        )}
      </section>
    </>
  );
};

export {
  NavBar as default,
  PROFILE_MENU_ITEM,
  REPORTS_MENU_ITEM,
  SETTINGS_MENU_ITEM,
  ORDERS_MENU_ITEM,
  INVENTORY_MENU_ITEM,
  DASHBOARD_MENU_ITEM,
  LOGOUT_MENU_ITEM,
  CHANGE_PASSWORD_MENU_ITEM,
  TEST_MENU_ITEM,
  DEVICES_MENU_ITEM,
  HELP_MENU_ITEM,
  SUPPORT_MENU_ITEM,
  SUPER_ADMIN_MENU_ITEM,
};
