import React, { useContext, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import Button from "../../../components/Button";
import ErrorBanner from "../../../components/ErrorBanner";
import InputCustomType from "../../../components/InputCustomType/InputCustomType";
import Spacer from "../../../components/Spacer";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./AdminDateTime.module.css";
import customInputStyles from "./AdminDateTimeInput.module.css";

const AdminDateTime = ({
  pendDisOrderControllerRef,
  selectedItem,
  onCloseAdminDateTimeModal,
}) => {
  const { t, i18n } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const originalDay =
    selectedItem && selectedItem.adminDate
      ? selectedItem.adminDate.substring(8, 10)
      : "";
  const originalMonth =
    selectedItem && selectedItem.adminDate
      ? selectedItem.adminDate.substring(5, 7)
      : "";
  const originalYear =
    selectedItem && selectedItem.adminDate
      ? selectedItem.adminDate.substring(0, 4)
      : "";

  const originalMinute =
    selectedItem && selectedItem.adminTime
      ? selectedItem.adminTime.substring(3, 5)
      : "";

  const originalHour =
    selectedItem && selectedItem.adminTime
      ? selectedItem.adminTime.substring(0, 2)
      : "";

  const [day, setDay] = useState(originalDay);
  const [month, setMonth] = useState(originalMonth);
  const [year, setYear] = useState(originalYear);
  const [minute, setMinute] = useState(originalMinute);
  const [hour, setHour] = useState(originalHour);

  const [userError, setUserError] = useState("");

  const handleDay = (dayEntry) => {
    setDay(dayEntry);
  };

  const handleMonth = (monthEntry) => {
    setMonth(monthEntry);
  };

  const handleYear = (yearEntry) => {
    setYear(yearEntry);
  };

  const handleMinute = (dayEntry) => {
    setMinute(dayEntry);
  };

  const handleHour = (dayEntry) => {
    setHour(dayEntry);
  };

  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
  // but by using 0 as the day it will give the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const handleSubmit = () => {
    // Verify correct data format
    let error;
    if (
      !(
        day.length === 2 &&
        month.length === 2 &&
        year.length === 4 &&
        minute.length === 2 &&
        hour.length === 2
      )
    ) {
      error = "Incorrect data format";
    } else {
      // Creates the current datetime in UTC format.
      let currentDate = new Date();
      currentDate.setSeconds(0);

      // When creating a Date with arguments, the month value is zero-indexed.
      // Also, datetimes created with arguments are local time, not UTC unless a special UTC format is speciifed.
      const modifiedDate = new Date(
        year,
        Number(month) - 1,
        day,
        hour,
        minute,
        0,
        0
      );

      // ERROR CHECKING
      let monthName;
      let maxDays;
      switch (Number(month)) {
        case 1:
          monthName = "January";
          maxDays = daysInMonth(1, year);
          break;
        case 2:
          monthName = "February";
          maxDays = daysInMonth(2, year);
          break;
        case 3:
          monthName = "March";
          maxDays = daysInMonth(3, year);
          break;
        case 4:
          monthName = "April";
          maxDays = daysInMonth(4, year);
          break;
        case 5:
          monthName = "May";
          maxDays = daysInMonth(5, year);
          break;
        case 6:
          monthName = "June";
          maxDays = daysInMonth(6, year);
          break;
        case 7:
          monthName = "July";
          maxDays = daysInMonth(7, year);
          break;
        case 8:
          monthName = "August";
          maxDays = daysInMonth(8, year);
          break;
        case 9:
          monthName = "September";
          maxDays = daysInMonth(9, year);
          break;
        case 10:
          monthName = "October";
          maxDays = daysInMonth(10, year);
          break;
        case 11:
          monthName = "November";
          maxDays = daysInMonth(11, year);
          break;
        case 12:
          monthName = "December";
          maxDays = daysInMonth(12, year);
          break;

        default:
        // code block
      }

      if (day > maxDays && monthName !== "February") {
        error = `${monthName} only has ${maxDays} days.`;
      } else if (day > maxDays && monthName === "February") {
        error = `${monthName} of ${year} only has ${maxDays} days.`;
        // Even though 'modifiedDate' is in local-time format and 'currentDate' is in UTC format, this test is valid because the getTime method always returns the time in local format.
      } else if (modifiedDate.getTime() < currentDate.getTime()) {
        error = "The date/time entered is before the current date/time.";
      }
    }
    if (!error) {
      setUserError("");

      let modifiedDispensingOrderItem = { ...selectedItem };
      modifiedDispensingOrderItem.adminDate = `${year}-${month}-${day}`;
      modifiedDispensingOrderItem.adminTime = `${hour}:${minute}:00`;

      pendDisOrderControllerRef.updateAdminDateTime(
        selectedItem,
        modifiedDispensingOrderItem
      );
      
      onCloseAdminDateTimeModal(true);
    } else {
      setUserError(error);
    }
  };

  // en is English; es is Spanish; fr is French;
  const languageDetected = i18n.language;

  let packageIdClass;
  let defaultDrugNameClass;
  let adminDateClass;
  let adminTimeClass;

  let packageId;
  let productDefaultName;

  try {
    packageId = selectedItem.product.packageId;
  } catch (tryError) {
    packageId = "";
  }

  try {
    productDefaultName = selectedItem.product.defaultName;
  } catch (tryError) {
    productDefaultName = "";
  }

  switch (languageDetected) {
    case "en":
      packageIdClass = "AdminDateTime__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEn";
      adminDateClass = "AdminDateTime__basicInfo--adminDateEn";
      adminTimeClass = "AdminDateTime__basicInfo--adminTimeEn";
      break;
    case "es":
      packageIdClass = "AdminDateTime__basicInfo--packageIdEs";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEs";
      adminDateClass = " AdminDateTime__basicInfo--adminDateEs";
      adminTimeClass = "AdminDateTime__basicInfo--adminTimeEs";
      break;
    case "fr":
      packageIdClass = "AdminDateTime__basicInfo--packageIdFr";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameFr";
      adminDateClass = " AdminDateTime__basicInfo--adminDateFr";
      adminTimeClass = " AdminDateTime__basicInfo--adminTimeFr";
      break;
    default:
      packageIdClass = "AdminDateTime__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEn";
      adminDateClass = "AdminDateTime__basicInfo--adminDateEn";
      adminTimeClass = " AdminDateTime__basicInfo--adminTimeEn";
  }

  const goBackHandler = () => {
    onCloseAdminDateTimeModal(false);
  };

  return (
    <div className={styles.AdminDateTime__overlay}>
      <div className={styles.AdminDateTime__view}>
        <div className={styles.AdminDateTime__container}>
          <div className={styles.AdminDateTime__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseAdminDateTimeModal(false)}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.AdminDateTime__header}>
              <div
                className={styles.AdminDateTime__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.AdminDateTime__backArrowIcon}
                />
                <p className={styles.AdminDateTime__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.AdminDateTime__title}>
                {t("adminTimeDateLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.AdminDateTime__header}>
              <p className={styles.AdminDateTime__title}>
                {t("adminTimeDateLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.AdminDateTime__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section className={styles.AdminDateTime__productSelectionContainer}>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--id"],
                styles[packageIdClass],
              ].join(" ")}
            >
              {`${t("originalPackageIdLabel")}: `}
              <span>{packageId}</span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--drugName"],
                styles[defaultDrugNameClass],
              ].join(" ")}
            >
              {smScreen ? "" : `${t("defaultDrugNameLabel")}: `}
              <span className={styles.AdminTimeDate__drugName}>
                {productDefaultName}
              </span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--date"],
                styles[adminDateClass],
              ].join(" ")}
            >
              {`${t("originalAdminDateLabel")}: `}
              <span>{selectedItem.adminDate}</span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--time"],
                styles[adminTimeClass],
              ].join(" ")}
            >
              {`${t("originalAdminTimeLabel")}: `}
              <span>{selectedItem?.adminTime?.substring(0, 5)}</span>
            </p>
          </section>

          <div className={styles.adminTimeDate__dateTimeInputsContainer}>
            <section className={styles.adminDateTime__dateInputsContainer}>
              <div className={styles.AdminDateTime__dateInputHeadings}>
                <p className={styles.AdminDateTime__monthHeading}>Mo.</p>
                <p className={styles.AdminDateTime__dayHeading}>Day</p>
                <p className={styles.AdminDateTime__yearHeading}>Year</p>
              </div>

              <div className={styles.AdminDateTime__dateInputSubHeadings}>
                <p className={styles.AdminDateTime__monthSubHeading}>(MM)</p>
                <p className={styles.AdminDateTime__daySubHeading}>(DD)</p>
                <p className={styles.AdminDateTime__yearSubHeading}>(YYYY)</p>
              </div>

              <div className={styles.AdminDateTime__dateInputs}>
                <div className={styles.AdminDateTime__monthInput}>
                  <InputCustomType
                    userInput={month}
                    onHandleUserInput={handleMonth}
                    customType="date-time"
                    styles={customInputStyles}
                    maxLength={2}
                    maxValue={12}
                    autoZero={true}
                    inputFields={{
                      type: "text",
                      value: month,
                    }}
                  />
                </div>

                <div className={styles.AdminDateTime__dayInput}>
                  <InputCustomType
                    userInput={day}
                    onHandleUserInput={handleDay}
                    customType="date-time"
                    styles={customInputStyles}
                    maxLength={2}
                    maxValue={31}
                    autoZero={true}
                    inputFields={{
                      type: "text",
                      value: day,
                    }}
                  />
                </div>

                <div className={styles.AdminDateTime__yearInput}>
                  <InputCustomType
                    userInput={year}
                    onHandleUserInput={handleYear}
                    customType="date-time"
                    styles={customInputStyles}
                    maxLength={4}
                    maxValue={2050}
                    autoZero={false}
                    inputFields={{
                      type: "text",
                      value: year,
                    }}
                  />
                </div>
              </div>
            </section>

            <section className={styles.adminDateTime__timeInputsContainer}>
              <div className={styles.AdminDateTime__timeInputHeadings}>
                <p className={styles.AdminDateTime__hourHeading}>Hour</p>
                <p className={styles.AdminDateTime__minuteHeading}>Min.</p>
              </div>

              <div className={styles.AdminDateTime__timeInputSubHeadings}>
                <p className={styles.AdminDateTime__hourSubHeading}>(HH)</p>
                <p className={styles.AdminDateTime__minuteSubHeading}>(mm)</p>
              </div>

              <div className={styles.AdminDateTime__timeInputs}>
                <div className={styles.AdminDateTime__hourInput}>
                  <InputCustomType
                    userInput={hour}
                    onHandleUserInput={handleHour}
                    customType="date-time"
                    styles={customInputStyles}
                    maxLength={2}
                    maxValue={23}
                    autoZero={true}
                    inputFields={{
                      type: "text",
                      value: hour,
                    }}
                  />
                </div>

                <div className={styles.AdminDateTime__minuteInput}>
                  <InputCustomType
                    userInput={minute}
                    onHandleUserInput={handleMinute}
                    customType="date-time"
                    styles={customInputStyles}
                    maxLength={2}
                    maxValue={59}
                    autoZero={true}
                    inputFields={{
                      type: "text",
                      value: minute,
                    }}
                  />
                </div>
              </div>
            </section>
          </div>

          <section className={styles.AdminDateTime__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseAdminDateTimeModal(false)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdminDateTime;
