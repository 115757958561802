import ProductService from "../../../services/ProductService";
import StockLocationService from "../../../services/StockLocationService";
import StockedItemService from "../../../services/StockedItemService";

class InventoryTransferPlaceViewController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  getMyStockedItems = async (callback) => {
    StockedItemService.findByUser(this.user, this.site)
      .then((stockedItems) => {
        callback(null, stockedItems);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getProductByBarcode = async (barcode, callback) => {
    ProductService.getProductByBarcode(this.user, barcode)
      .then((product) => {
        callback(null, product);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getStockLocationByBarcode = async (barcode, callback) => {
    StockLocationService.getByBarcode(this.user, this.site, barcode)
      .then((stockLocation) => {
        callback(null, stockLocation);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getStockedItems = async (stockLocation, callback) => {
    StockLocationService.getStockedItems(this.user, this.site, stockLocation)
      .then((stockedItems) => {
        callback(null, stockedItems);
      })
      .catch((error) => {
        callback(error);
      });
  };

  async updateStockLocation(stockLocation, callback) {
    StockLocationService.update(this.user, this.site, stockLocation)
      .then((stockLocation) => {
        callback(null, stockLocation);
      })
      .catch((error) => {
        callback(error);
      });
  }

  processSmartCanisterPlace = async (stockLocation, callback) => {
    StockLocationService.update(this.user, this.site, stockLocation)
      .then((sl) => {
        callback(null, sl);
      })
      .catch((error) => {
        callback(error);
      });
  };

  processPlace = async (originalStockedItem, modifiedStockedItem, callback) => {
    try {
      if (modifiedStockedItem.user !== null) {
        throw new Error("A user association must not exist before placing");
      }

      if (modifiedStockedItem.stockLocation === null) {
        throw new Error("A stock location association must be made to place");
      }

      // manage a smart canister use case
      if (modifiedStockedItem.stockLocation.type.type.includes("CANISTER")) {
        modifiedStockedItem.packageDescription =
          modifiedStockedItem.stockLocation.type.type;
        modifiedStockedItem.deviceCompatability =
          modifiedStockedItem.stockLocation.type.deviceCompatability;
        modifiedStockedItem.barcode = modifiedStockedItem.stockLocation.barcode;
        if (modifiedStockedItem.stockLocation.productAssociations.length > 1) {
          throw new Error(
            "A smart canister can have only one product assocaited with it"
          );
        }
      }

      // modifiy original
      let stockedItem;

      if (
        originalStockedItem &&
        originalStockedItem.quantity === modifiedStockedItem.quantity
      ) {
        // all items are placed
        modifiedStockedItem._id = originalStockedItem._id;
        stockedItem = await StockedItemService.update(
          this.user,
          this.site,
          modifiedStockedItem
        );
      } else if (
        originalStockedItem &&
        originalStockedItem.quantity !== modifiedStockedItem.quantity
      ) {
        originalStockedItem.quantity -= modifiedStockedItem.quantity;
        await StockedItemService.update(
          this.user,
          this.site,
          originalStockedItem
        );
        stockedItem = await StockedItemService.create(
          this.user,
          this.site,
          modifiedStockedItem
        );
      }

      callback(null, stockedItem);
    } catch (error) {
      callback(error);
    }
  };
}

export default InventoryTransferPlaceViewController;
