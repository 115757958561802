import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import Button from "../../../components/Button";
import ErrorBanner from "../../../components/ErrorBanner";
import InputCustomType from "../../../components/InputCustomType/InputCustomType";
import Spacer from "../../../components/Spacer";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { deepCopy } from "../../../functions/utilities";
import styles from "./ChangePatientLocation.module.css";

const ChangePatientLocation = ({
  pendDisOrderControllerRef,
  selectedItem,
  onCloseChangePatientLocationModal,
}) => {
  const { t, i18n } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const originalLocation =
    selectedItem && selectedItem.patientInfo && selectedItem.patientInfo.room
      ? selectedItem.patientInfo.room
      : "";
  const [location, setLocation] = useState(originalLocation);

  const [userError, setUserError] = useState("");

  const handleLocation = (locationEntry) => {
    setLocation(locationEntry);
  };

  const handleSubmit = () => {
    // Verify correct data format
    if (!location) {
      setUserError("No location was entered.");
    } else {
      let modifiedDispensingOrderItem = deepCopy(selectedItem);
      modifiedDispensingOrderItem.patientInfo.room = location;

      pendDisOrderControllerRef.updatePatientLocation(
        selectedItem,
        modifiedDispensingOrderItem
      );
      onCloseChangePatientLocationModal(true);
    }
  };

  // en is English; es is Spanish; fr is French;
  const languageDetected = i18n.language;
  let packageIdClass;
  let defaultDrugNameClass;
  let patientLocationClass;

  let packageId;
  let productDefaultName;

  try {
    packageId = selectedItem.product.packageId;
  } catch (tryError) {
    packageId = "";
  }

  try {
    productDefaultName = selectedItem.product.defaultName;
  } catch (tryError) {
    productDefaultName = "";
  }

  switch (languageDetected) {
    case "en":
      packageIdClass = "ChangePatientLocation__basicInfo--packageIdEn";
      defaultDrugNameClass = "ChangePatientLocation__basicInfo--defaultNameEn";
      patientLocationClass =
        "ChangePatientLocation__basicInfo--patientLocationEn";
      break;
    case "es":
      packageIdClass = "ChangePatientLocation__basicInfo--packageIdEs";
      defaultDrugNameClass = "ChangePatientLocation__basicInfo--defaultNameEs";
      patientLocationClass =
        "ChangePatientLocation__basicInfo--patientLocationEs";
      break;
    case "fr":
      packageIdClass = "ChangePatientLocation__basicInfo--packageIdFr";
      defaultDrugNameClass = "ChangePatientLocation__basicInfo--defaultNameFr";
      patientLocationClass =
        "ChangePatientLocation__basicInfo--patientLocationFr";
      break;
    default:
      packageIdClass = "ChangePatientLocation__basicInfo--packageIdEn";
      defaultDrugNameClass = "ChangePatientLocation__basicInfo--defaultNameEn";
      patientLocationClass =
        "ChangePatientLocation__basicInfo--patientLocationEn";
  }

  const goBackHandler = () => {
    onCloseChangePatientLocationModal(false);
  };

  return (
    <div className={styles.ChangePatientLocation__overlay}>
      <div className={styles.ChangePatientLocation__view}>
        <div className={styles.ChangePatientLocation__container}>
          <div className={styles.ChangePatientLocation__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseChangePatientLocationModal(false)}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.ChangePatientLocation__header}>
              <div
                className={styles.ChangePatientLocation__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.ChangePatientLocation__backArrowIcon}
                />
                <p className={styles.ChangePatientLocation__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.ChangePatientLocation__title}>
                {t("changePatientLocationLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.ChangePatientLocation__header}>
              <p className={styles.ChangePatientLocation__title}>
                {t("changePatientLocationLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.ChangePatientLocation__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section
            className={styles.ChangePatientLocation__productSelectionContainer}
          >
            <p
              className={[
                styles["ChangePatientLocation__basicInfo"],
                styles["ChangePatientLocation__basicInfo--id"],
                styles[packageIdClass],
              ].join(" ")}
            >
              {`${t("originalPackageIdLabel")}: `}
              <span>{packageId}</span>
            </p>
            <p
              className={[
                styles["ChangePatientLocation__basicInfo"],
                styles["ChangePatientLocation__basicInfo--drugName"],
                styles[defaultDrugNameClass],
              ].join(" ")}
            >
              {smScreen ? "" : `${t("defaultDrugNameLabel")}: `}
              <span>{productDefaultName}</span>
            </p>
            <p
              className={[
                styles["ChangePatientLocation__basicInfo"],
                styles["ChangePatientLocation__basicInfo--patientLocation"],
                styles[patientLocationClass],
              ].join(" ")}
            >
              {`${t("originalPatientLocationLabel")}: `}
              <span>{selectedItem.patientInfo.room}</span>
            </p>
          </section>

          <section
            className={styles.ChangePatientLocation__quantityInputSection}
          >
            <p className={styles.ChangePatientLocation__quantityInputHeading}>
              {`${t("patientLocationInputLabel")}`}
            </p>

            <div
              className={styles.ChangePatientLocation__quantityInputContainer}
            >
              <InputCustomType
                userInput={location}
                onHandleUserInput={handleLocation}
                customType="strip-multiple-spaces"
                enforceFocus={true}
                inputFields={{
                  type: "text",
                  value: location,
                }}
              />
            </div>
          </section>

          <section className={styles.ChangePatientLocation__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseChangePatientLocationModal(false)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePatientLocation;
