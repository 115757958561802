import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Inventory = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/inventory") {
      navigate("items");
    }
  }, [location, navigate]);

  return <Outlet />;
};

export default Inventory;
