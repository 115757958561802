import React from "react";
import styles from "./TruPakInventoryCard.module.css";

const TruPakInventoryCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.TruPakInventoryCard__reportList}>
      <li className={styles.TruPakInventoryCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.TruPakInventoryCard__dataContainer}>
          <h1>{headingsObj.device}</h1>
          <p>{dataObj.device}</p>
        </div>
        <div className={styles.TruPakInventoryCard__dataContainer}>
          <h1>{headingsObj.canister}</h1>
          <p>{dataObj.canister}</p>
        </div>
      </li>
      <li className={styles.TruPakInventoryCard__reportListItem}>
        <div className={styles.TruPakInventoryCard__dataContainer}>
          <h1>{headingsObj.product}</h1>
          <p>{dataObj.product}</p>
        </div>
      </li>
      <li className={styles.TruPakInventoryCard__reportListItem}>
        <div
          className={`${styles.TruPakInventoryCard__dataContainer} ${styles["TruPakInventoryCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.min}</h1>
          <p>{dataObj.min}</p>
        </div>
        <div
          className={`${styles.TruPakInventoryCard__dataContainer} ${styles["TruPakInventoryCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.max}</h1>
          <p>{dataObj.max}</p>
        </div>
        <div
          className={`${styles.TruPakInventoryCard__dataContainer} ${styles["TruPakInventoryCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.currentQty}</h1>
          <p>{dataObj.currentQty}</p>
        </div>
      </li>
    </ul>
  );
};

export default TruPakInventoryCard;
