import React, { useEffect, useRef, useState, useContext } from "react";
import styles from './CreateProductAccordion.module.css';
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import Spacer from "../../components/Spacer";

const CreateProductPartial = ({
                                  isNew,
                                  p,
                                  packageId,
                                  errors,
                                  onPropertyChange,
                                  onHandleNDCLookup,
                                  onError,
                                  isCopy,
                                  onUpdatePObjPackageId,
                              }) => {
    const productController = new ProductController();

    useEffect(() => {
        if(p.partialSixthDescription === undefined){
            onPropertyChange({
                partialHalfDescription: "",
                partialThirdDescription: "",
                partialTwoThirdDescription: "",
                partialQuarterDescription: "",
                partialThreeQuarterDescription: "",
                partialSixthDescription: ""
            })
        }
    }, [p]);

    return (
        <Accordion title={"Partial Descriptions"}>
            <div className={styles.CreateProductAccordion__col}>
                <div className={styles.CreateProductAccordion__row}>
                    <TextInput
                        type={'text'}
                        labelName={'1/2 Tab Description'}
                        isEnabled={true}
                        value={p?.partialHalfDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialHalfDescription: val})
                        }}
                    />
                    <Spacer space={20} unit={'px'}/>
                    <TextInput
                        type={'text'}
                        labelName={'1/3 Tab Description'}
                        isEnabled={true}
                        value={p?.partialThirdDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialThirdDescription: val})
                        }}
                    />
                    <Spacer space={20} unit={'px'}/>
                    <TextInput
                        type={'text'}
                        labelName={'2/3 Tab Description'}
                        isEnabled={true}
                        value={p?.partialTwoThirdDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialTwoThirdDescription: val})
                        }}
                    />
                </div>
                <div className={styles.CreateProductAccordion__row}>
                    <TextInput
                        type={'text'}
                        labelName={'1/4 Tab Description'}
                        isEnabled={true}
                        value={p?.partialQuarterDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialQuarterDescription: val})
                        }}
                    />
                    <Spacer space={20} unit={'px'}/>
                    <TextInput
                        type={'text'}
                        labelName={'3/4 Tab Description'}
                        isEnabled={true}
                        value={p?.partialThreeQuarterDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialThreeQuarterDescription: val})
                        }}
                    />
                    <Spacer space={20} unit={'px'}/>
                    <TextInput
                        type={'text'}
                        labelName={'1/6 Tab Description'}
                        isEnabled={true}
                        value={p?.partialSixthDescription || ''}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({partialSixthDescription: val})
                        }}
                    />
                </div>
            </div>
        </Accordion>
    )
};

export default CreateProductPartial;
