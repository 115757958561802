import React, {useEffect, useState} from 'react';
import CreateButton from "./CreateButton";
import InputModal from "./InputModal";
import ContextMenu from "./ContextMenu";

const TreeView = ({root, onError}) => {

    const grey = '#D9E1EC';
    const green = '#089BAB';
    const black = '#121A2D';


    //region Styles
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto'
    }
    const headerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '20px 50px 0 50px'
    }
    const scrollableBodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px',
        marginRight: '20px',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    }
    const rowStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '100%',
        backGroundColor: 'white',
        overflow: 'hidden',
        flexWrap: 'wrap'
    }
    const colStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '100%',
        width: '100%',
        overflow: 'auto'
    }
    const nodeContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        minWidth: '50px',
        height: '40px',
        borderRadius: '8px',
        border: `1px solid ${grey}`,
        cursor: 'pointer',
        paddingLeft: '10px',
        paddingRight: '10px',
        margin: '5px'
    }
    const rootTextStyle = {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '27px',
        color: black,
        margin: 0,
        textAlign: 'center',
        userSelect: 'none'
    }
    const textStyle = {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '12px',
        color: black,
        margin: 0,
        textAlign: 'center',
        userSelect: 'none'
    }
    const overlayStyle = {
        position: 'fixed', /* Positioning and size */
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(128,128,128,0.5)', /* color */
        display: 'none'
    }
    //endregion

    const actionListItems = [
        {
            type: 'add',
            description: "Add"
        },
        {
            type: 'edit',
            description: "Edit"
        },
        {
            type: 'delete',
            description: "Delete"
        }
    ]

    const [selectedNode, setSelectedNode] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [showEditInput, setShowEditInput] = useState(false);
    const [trail, setTrail] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);

    const handleNodeSelected = (node) => {
        setContextMenu(null);
        if(selectedNode?.name === node.name) {
            setSelectedNode(null);
            setTrail([]);
        } else {
            setSelectedNode(prevState=>{
                return node;
            });
            let newTrail = getTrail(node);
            //console.log(newTrail);
            setTrail(newTrail);
        }
    }

    useEffect(()=>{
        handleNodeSelected(root);
    },[])

    const handleAdd = (name) => {
        setShowInput(false);
        if(nameExists(name)) {
            onError('Name already exists.')
        } else {
            selectedNode.children.push({
                name: name,
                parent: selectedNode,
                children: []
            })
        }
    }

    const handleEdit = (name) => {
        setShowEditInput(false);
        if(nameExists(name, selectedNode.name)) {
            onError('Name already exists.')
        } else {
            selectedNode.name = name;
        }
    }

    const nameExists = (name, oldName) => {
        if(root.name.toUpperCase() === name.toUpperCase() && (oldName ? oldName.toUpperCase() !== name.toUpperCase() : true)){return true}
        let found = false;
        function recurse(children) {
            for (let i = 0; i < children.length; i++) {
                // Found the category!
                if (children[i].name.toUpperCase() === name.toUpperCase() && (oldName ? oldName.toUpperCase() !== name.toUpperCase() : true)) {
                    found = true;
                    break;
                } else {
                    if (children[i].children.length > 0) {
                        recurse(children[i].children);
                        if(found){
                            break;
                        }
                    }
                }
            }
        }

        recurse(root.children);

        return found;
    }

    const handleRemove = (node) => {
        let parentNode = getNode(node.parent.name);
        parentNode.children = parentNode.children.filter(o=>o.name !== node.name);
    }

    const getNode = (name) => {
        if(root.name.toUpperCase() === name.toUpperCase()){return root;}
        let found = false;
        let node = null;
        function recurse(children) {
            for (let i = 0; i < children.length; i++) {
                // Found the category!
                if (children[i].name.toUpperCase() === name.toUpperCase() ) {
                    found = true;
                    node = children[i];
                    break;
                } else {
                    if (children[i].children.length > 0) {
                        recurse(children[i].children);
                        if(found){
                            break;
                        }
                    }
                }
            }
        }

        recurse(root.children);

        return node;
    }



    const getTrail = (node) => {
        let theTrail = [node.name];
        function recurse(parent) {
            if(parent){
                theTrail.push(parent.name);
                recurse(parent.parent);
            }
        }

        recurse(node.parent);
        return theTrail;
    }

    const renderChildren = (node) => {
        return (
            <div style={rowStyle}>
                {node.children && node.children.filter(o=>trail.includes(o.name) || trail.length < 2 || o.parent?.name === selectedNode.name).sort((a,b)=>{
                    if( a.name.toUpperCase() <  b.name.toUpperCase()){
                        return -1
                    }
                    if( a.name.toUpperCase() >  b.name.toUpperCase()){
                        return 1
                    }
                    return 0;
                }).map(childNode=> {
                    return(
                        <div key={childNode.name}>
                            <div  style={childNode.name === selectedNode.name ? {
                                ...nodeContainerStyle,
                                backgroundColor: green
                            } : nodeContainerStyle} onMouseDown={(e) => {
                                if(e.button) {
                                    e.preventDefault();

                                    let left = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth;
                                    let top = e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 2;
                                    setContextMenu({left: left, top: top, listItems: getActionMenu(childNode), isRightAligned: true, onSelect: (action)=>{
                                            handleAction(action, childNode);
                                            setContextMenu(null)
                                        }});
                                    e.stopPropagation();
                                } else {
                                    handleNodeSelected(childNode);
                                }

                            }} onContextMenu={(e)=>{
                                e.preventDefault();
                            }}>
                                <p style={childNode.name === selectedNode.name ? {
                                    ...textStyle,
                                    color: 'white'
                                } : textStyle}>{childNode.name}</p>
                            </div>
                            <div style={colStyle}>
                                {(childNode.children.some(o=>trail.includes(o.name)) || childNode.name === selectedNode.name) && renderChildren(childNode)}
                            </div>


                        </div>
                    )

                })}
                {contextMenu &&
                <ContextMenu onSelect={contextMenu.onSelect} listItems={contextMenu.listItems} top={contextMenu.top} left={contextMenu.left} isRightAligned={contextMenu.isRightAligned} maxHeight={contextMenu.maxHeight} onClose={()=>setContextMenu(null)}/>
                }
            </div>

        )

    }

    const getActionMenu = (node) => {
        if(node.parent){
            return actionListItems;
        } else {
            return [actionListItems[0]]
        }

    }

    const handleAction = (action, node) =>{
        switch (action.type) {
            case 'add':
                setSelectedNode(node);
                setShowInput(true);
                break;
            case "edit":
                setSelectedNode(node);
                setShowEditInput(true);
                break;
            case "delete":
                handleRemove(node);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div style={showInput ? {...overlayStyle, display: 'block'} : overlayStyle}>
                {showInput && <InputModal labelName={'Name'} title={'Add Sublocation'} buttonLabelName={'Add'} onClose={()=>setShowInput(false)} onEnter={handleAdd}/>}
            </div>

            <div style={showEditInput ? {...overlayStyle, display: 'block'} : overlayStyle}>
                {showEditInput && <InputModal labelName={'Name'} title={'Edit Sublocation'} buttonLabelName={'Update'} onClose={()=>setShowEditInput(false)} onEnter={handleEdit} defaultValue={selectedNode.name}/>}
            </div>

            <section style={containerStyle}>
                <header style={headerStyle}>
                    <CreateButton onClick={() => {
                        setShowInput(selectedNode !== null)}
                    } isEnabled={selectedNode}
                                  labelName={'Add Sublocation'}/>
                </header>
                {/*root*/}
                <div style={scrollableBodyStyle}>
                    <div style={colStyle}>
                        <div style={rowStyle}>
                            <div style={selectedNode?.name === root.name ? {...nodeContainerStyle, backgroundColor: green} : nodeContainerStyle}
                                 onMouseDown={(e) => {
                                     if(e.button) {
                                         let left = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth;
                                         let top = e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 2;
                                         setContextMenu({left: left, top: top, listItems: getActionMenu(root), isRightAligned: true, onSelect: (action)=>{
                                                 handleAction(action, root);
                                                 setContextMenu(null)
                                             }});
                                     } else {
                                         handleNodeSelected(root);
                                     }
                                 }}
                                 onContextMenu={(e)=>{
                                     e.preventDefault();
                                 }}>
                                <p style={selectedNode?.name === root.name ? {...rootTextStyle, color: 'white'} : rootTextStyle}>{root.name || ''}</p>
                            </div>
                        </div>
                    </div>
                    {/*Display the tree until we get to node*/}
                    {selectedNode &&
                        <div style={colStyle}>
                            {renderChildren(root)}
                        </div>

                    }
                </div>

            </section>
        </>
    )
}

export default TreeView;
