import React from "react";
import styles from "./SuctionCupLifeCard.module.css";

const SuctionCupLifeCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.SuctionCupLifeCard__reportList}>
      <li className={styles.SuctionCupLifeCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.SuctionCupLifeCard__dataContainer}>
          <h1>{headingsObj.task}</h1>
          <p>{dataObj.task}</p>
        </div>
        <div className={styles.SuctionCupLifeCard__dataContainer}>
          <h1>{headingsObj.every}</h1>
          <p>{dataObj.every}</p>
        </div>
      </li>
      <li className={styles.SuctionCupLifeCard__reportListItem}>
        <div className={styles.SuctionCupLifeCard__dataContainer}>
          <h1>{headingsObj.instructions}</h1>
          <p>{dataObj.instructions}</p>
        </div>
      </li>
      <li className={styles.SuctionCupLifeCard__reportListItem}>
        <div className={styles.SuctionCupLifeCard__dataContainer}>
          <h1>{headingsObj.last}</h1>
          <p>{dataObj.last}</p>
        </div>
        <div className={styles.SuctionCupLifeCard__dataContainer}>
          <h1>{headingsObj.by}</h1>
          <p>{dataObj.by}</p>
        </div>
      </li>
    </ul>
  );
};

export default SuctionCupLifeCard;
