import React, {useState} from 'react';
import style from './StockLocationAssociation.module.css';
import {faTrash, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StockLocationAssociation = ({stockLocation, product, onSave, onDelete}) => {

    const pa = stockLocation?.productAssociations.find(o => o.product._id === product._id);
    const [min, setMin] = useState(pa.min);
    const [max, setMax] = useState(pa.max);

    return (

        <tr key={stockLocation._id} >
            <td>{stockLocation.site.name} - {stockLocation.name}</td>
            <td>
                <input
                    type='number'
                    value={min}
                    pattern="[0-9]+"
                    onChange={e=>{
                        const val = e.currentTarget.value;
                        setMin(val);
                    }} />
            </td>
            <td>
                <input
                    type='number'
                    value={max}
                    pattern="[0-9]+"
                    onChange={e=>{
                        const val = e.currentTarget.value;
                        setMax(val);
                    }} />
            </td>
            <td>
                <FontAwesomeIcon icon={faSave} color={'#089BAB'} className={style.StockLocationAssociation__icon} onClick={()=> {
                    pa.min = min;
                    pa.max = max;
                    onSave(stockLocation)
                }}/>
                <FontAwesomeIcon icon={faTrash} color={'#089BAB'} onClick={()=>{
                    onDelete(stockLocation)
                }}/>
            </td>
        </tr>
    )
}

export default StockLocationAssociation;
