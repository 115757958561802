export const getNewProductName = (name) => {
  if (name.toUpperCase() === "EV54" || name.toUpperCase() === "TRUSCRIPT") {
    return "TruScript";
  } else if (
    name.toUpperCase() === "PILLSORT" ||
    name.toUpperCase() === "TRUSORT"
  ) {
    return "TruSort";
  } else if (name.toUpperCase() === "VBM" || name.toUpperCase() === "TRUCARD") {
    return "TruCard";
  } else if (
    name.toUpperCase() === "PROUD" ||
    name.toUpperCase() === "TRUPAK"
  ) {
    return "TruPak";
  } else if (
    name.toUpperCase() === "MDM" ||
    name.toUpperCase() === "TRUCHECK"
  ) {
    return "TruCheck";
  }
};
