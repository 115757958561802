import React, { useState, useEffect, useReducer, useContext } from "react";
import Spacer from "../../components/Spacer";
import TextInput from "../../components/TextInput";
import SwitchInput from "../../components/SwitchInput";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import DropdownMultiSelectInput from "../../components/DropdownMultiSelectInput";
import validate from "../../validation/UserValidationRules";
import ConfigService from "../../services/ConfigService";
import ErrorBanner from "../../components/ErrorBanner";
import UserService from "../../services/UserService";
import PhoneList from "../../components/PhoneList/PhoneList";
import Button from "../../components/Button";
import PharmacyService from "../../services/PharmacyService";
import { useTranslation } from "react-i18next";
import { translateError } from "../../utils/functions/translateError";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../contexts/ScreenContext";
import styles from "./CreateUser.module.css";
import RoleCheckService from "../../services/RoleCheckService";
import { toCamelCase } from "../../utils/functions/strings.js"

const CreateUser = ({ currentUser, user }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation("createUser");

  const accountSecurity = currentUser.account.accountSecurity;

  const isNew = user == null;

  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [roleListItems, setRoleListItems] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { type: { type: "mobile", description: "Mobile" }, number: "" },
  ]);

  const [defaultSite, setDefaultSite] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentUserSites, setCurrentUserSites] = useState([]);
  const [dropdownSiteListEnterprise, setDropdownSiteListEnterprise] = useState(
    []
  );

  const [selectedRoles, changeSelectedRoles] = useReducer(
    (array, { type, value }) => {
      switch (type) {
        case "add":
          if (!array.some((o) => o.type === value.type)) {
            return [...array, value];
          }
          return array;
        case "remove":
          return array.filter((o) => o.type !== value.type);
        case "update":
          return array.map((o) =>
            o.type === value.type ? { ...o, ...value } : o
          );

        default:
          return array;
      }
    },
    []
  );
  const [dropdownSiteListUser, changeDropdownSiteListUser] = useReducer(
    (array, { type, value }) => {
      switch (type) {
        case "add":
          if (!array.some((o) => o.type === value.type)) {
            return [...array, value];
          }
          return array;
        case "remove":
          return array.filter((o) => o.type !== value.type);
        default:
          return array;
      }
    },
    []
  );
  const [language, setLanguage] = useState(null);
  const [langList, setLangList] = useState([]);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    ConfigService.languages()
      .then((languages) => {
        setLangList(languages);
        if (isNew || !user.language) {
          setLanguage(languages.find((o) => o.type === "en"));
        }
      })
      .catch((error) => setError(error));

    return () => {};
  }, [user, isNew]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmailAddress(user.emailAddress);
      setDefaultSite(user.defaultSite);
      setUsername(user.username);
      setPassword(user.password);
      setIsEnterprise(user.isEnterprise);
      setPhoneNumbers(user.phoneNumbers);
      for (let i = 0; i < user.roles.length; i++) {
        user.roles[i].type = toCamelCase(user.roles[i].type); // For backwards compatibility
        user.roles[i].description = t(user.roles[i].type, { ns: "roles" });
        user.roles[i].additionalInfo = t(`${user.roles[i].type}Info`, {
          ns: "roles",
        });
        changeSelectedRoles({ type: "add", value: user.roles[i] });
      }
      for (let i = 0; i < user.sites.length; i++) {
        changeDropdownSiteListUser({
          type: "add",
          value: {
            type: user.sites[i].name,
            description: user.sites[i].name,
            additionalInfo: user.sites[i].name,
            ref: user.sites[i],
          },
        });
      }
      if (user.language) {
        setLanguage(user.language);
      }
    }

    return () => {};
  }, [user]);

  useEffect(() => {
    ConfigService.roles().then((roles) => {

      roles.forEach((role) => {
        role.description = t(role.type, { ns: "roles" });
        role.additionalInfo = t(`${role.type}Info`, { ns: "roles" });
      });
      setRoleListItems(roles);
    });

    for (var role of selectedRoles) {
      role.description = t(role.type, { ns: "roles" });
      role.additionalInfo = t(`${role.type}Info`, { ns: "roles" });
      changeSelectedRoles({ type: "update", value: role });
    }
  }, [i18n.language]);

  useEffect(() => {
    PharmacyService.list(currentUser)
      .then((sites) => {
        let listItems = [];
        sites.forEach((site) => {
          listItems.push({
            type: site.name,
            description: site.name,
            additionalInfo: site.name,
            ref: site,
          });
        });
        setDropdownSiteListEnterprise(listItems);

        sites.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
        setCurrentUserSites(sites);
      })
      .catch((error) => {
        setError(error);
      });
  }, [currentUser]);

  const handleAddRole = (role) => {
    changeSelectedRoles({ type: "add", value: role });
  };

  const handleRemoveRole = (role) => {
    if (RoleCheckService.modifySiteAccessAndRoles(currentUser)) {
      changeSelectedRoles({ type: "remove", value: role });
    }
  };

  const handleAddSite = (site) => {
    if (RoleCheckService.modifySiteAccessAndRoles(currentUser)) {
      changeDropdownSiteListUser({ type: "add", value: site });
    }
  };

  const handleRemoveSite = (site) => {
    if (RoleCheckService.modifySiteAccessAndRoles(currentUser)) {
      changeDropdownSiteListUser({ type: "remove", value: site });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      (async () => {
        try {
          if (isNew) {
            let exists = await UserService.exists(
              currentUser.account,
              emailAddress,
              username
            );
            if (exists) {
              setError(t("error1"));
              setIsSubmitting(false);
              return;
            }

            let userDoc = await UserService.create(currentUser, {
              firstName: firstName,
              lastName: lastName,
              defaultSite: defaultSite,
              emailAddress: emailAddress,
              username: username,
              password: password,
              isEnterprise: isEnterprise,
              roles: selectedRoles,
              phoneNumbers: phoneNumbers,
              sites: isEnterprise
                ? []
                : dropdownSiteListUser.map((site) => {
                    return site.ref;
                  }),
              language: language,
            });

            if (userDoc) {
              if (accountSecurity.type === "EMAIL") {
                await UserService.sendInvite(userDoc.account, userDoc);
              }
              navigate("/settings/users", {
                state: { refresh: true },
                replace: true,
              });
              //onCreated(userDoc);
              setIsSubmitting(false);
            } else {
              setError(t("error2"));
              setIsSubmitting(false);
            }
          } else {
            let modifiedUser = {
              ...user,
              ...{
                firstName: firstName,
                lastName: lastName,
                defaultSite: defaultSite,
                emailAddress: emailAddress,
                username: username,
                isEnterprise: isEnterprise,
                roles: selectedRoles,
                phoneNumbers: phoneNumbers,
                sites: isEnterprise
                  ? []
                  : dropdownSiteListUser.map((site) => {
                      return site.ref;
                    }),
                language: language,
              },
            };
            let userDoc = await UserService.update(currentUser, modifiedUser);
            if (userDoc) {
              //onUpdated(userDoc);
              navigate("/settings/users", {
                state: { refresh: true },
              });

              setIsSubmitting(false);
            } else {
              setError(t("error3"));
              setIsSubmitting(false);
            }
          }
        } catch (error) {
          setError(error);
        }
        setIsSubmitting(false);
      })();
    } else if (Object.keys(errors).length > 0) {
      setError(t("error4"));
      setIsSubmitting(false);
    }
  }, [
    isNew,
    user,
    errors,
    isSubmitting,
    accountSecurity,
    currentUser,
    firstName,
    lastName,
    defaultSite,
    emailAddress,
    username,
    password,
    phoneNumbers,
    selectedRoles,
    dropdownSiteListUser,
    isEnterprise,
    language,
    t,
  ]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let validationErrors = validate({
        accountSecurity: accountSecurity,
        firstName: firstName,
        lastName: lastName,
        defaultSite: defaultSite,
        emailAddress: emailAddress,
        username: username,
        password: password,
        roles: selectedRoles,
        isEnterprise: isEnterprise,
        sites: dropdownSiteListUser,
    }, isNew);
    // if(isNew){
    //   validationErrors.password = ""
    // }
    setErrors(validationErrors);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className={styles.createUser__overlay}>
      {smScreen && error && (
        <div className={styles.createUser__errorBannerContainer}>
          <ErrorBanner message={translateError(error)} onClose={() => setError(null)} />
        </div>
      )}
      <section className={styles.createUser__view}>
        {smScreen && (
          <section className={styles.createUser__header}>
            <div
              className={styles.createUser__backArrow}
              onClick={goBackHandler}
            >
              <BackArrowMobile className={styles.createUser__backArrowIcon} />
              <p className={styles.createUser__goBackTitle}>
                {t("goBackTitle")}
              </p>
            </div>
            <p className={styles.createUser__title}>
              {isNew ? t("addNewLabel") : t("updateLabel")}
            </p>
          </section>
        )}

        {!smScreen && (
          <section className={styles.createUser__header}>
            <p className={styles.createUser__title}>
              {isNew ? t("addNewLabel") : t("updateLabel")}
            </p>
            <div className={styles.createUser__formCloseContainer}>
              {!smScreen && (
                <CloseIcon
                  fill={"#121A2D"}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
              )}
            </div>
          </section>
        )}

        {!smScreen && error && (
          <div className={styles.createUser__errorBannerContainer}>
            <ErrorBanner message={translateError(error)} onClose={() => setError(null)} />
          </div>
        )}

        <div className={styles.createUser__scroll}>
          <div className={styles.createUser__accordions}>
            <div
              className={[
                styles["createUser__accordion"],
                styles["createUser__accordion--0"],
              ].join(" ")}
            >
              <Accordion title={t("tab0")} openByDefault={true}>
                <section className={styles.createUser__row}>
                  <section className={styles.createUser__col}>
                    <DropdownInput
                      value={language?.description || ""}
                      validationText={""}
                      isEnabled={true}
                      placeholder={""}
                      listElements={langList}
                      labelName={t("languageLabel")}
                      onSelection={(listItem) => {
                        setLanguage(listItem);
                        if (currentUser?.account?.accountSecurity?.type === "EMAIL" && currentUser.emailAddress === emailAddress) {
                          i18n.changeLanguage(listItem.type);
                        } else if (currentUser?.account?.accountSecurity?.type === "USERNAME" && currentUser.username === username) {
                          i18n.changeLanguage(listItem.type);
                        }
                      }}
                    />
                    {lgScreen && <Spacer space={10} unit={"px"} />}
                    <TextInput
                      value={firstName}
                      validationText={t(errors.firstName, {ns: "validation"})}
                      type={"text"}
                      placeholder={""}
                      labelName={t("label1")}
                      isEnabled={true}
                      focus={true}
                      onChange={(value) => {
                        setFirstName(value);
                      }}
                    />
                    {lgScreen && <Spacer space={10} unit={"px"} />}
                    <TextInput
                      value={lastName}
                      validationText={t(errors.lastName, {ns: "validation"})}
                      type={"text"}
                      placeholder={""}
                      labelName={t("label2")}
                      isEnabled={true}
                      focus={false}
                      onChange={(value) => {
                        setLastName(value);
                      }}
                    />
                    {lgScreen && <Spacer space={10} unit={"px"} />}
                    {!accountSecurity || accountSecurity.type === "EMAIL" ? (
                      <TextInput
                        value={emailAddress}
                        validationText={t(errors.emailAddress, {ns: "validation"})}
                        type={"email"}
                        placeholder={""}
                        labelName={t("label3")}
                        isEnabled={isNew}
                        focus={false}
                        onChange={(value) => {
                          setEmailAddress(value);
                        }}
                      />
                    ) : (
                      <>
                        <TextInput
                          value={username}
                          validationText={t(errors.username, {ns: "validation"})}
                          type={"text"}
                          placeholder={""}
                          labelName={t("label4")}
                          isEnabled={isNew}
                          focus={false}
                          onChange={(value) => {
                            setUsername(value);
                          }}
                        />
                        {isNew && lgScreen && <Spacer space={10} unit={"px"} />}
                        {isNew && <TextInput
                            value={password}
                          validationText={isNew? t(errors.password, {ns: "validation"}) : ""}
                            type={"text"}
                            placeholder={""}
                            labelName={t("label5")}
                            isEnabled={true}
                            focus={false}
                            onChange={(value) => {
                              setPassword(value);
                            }}
                        />}
                      </>
                    )}
                  </section>
                  {lgScreen && <Spacer space={40} unit={"px"} />}
                  {mdScreen && <Spacer space={30} unit={"px"} />}
                  <section className={styles.createUser__col}>
                    {lgScreen && <Spacer space={10} unit={"px"} />}
                    <PhoneList
                      phoneNumbers={phoneNumbers}
                      readonly={false}
                      errors={t(errors.phoneNumbers, {ns: "validation"})}
                      height={"310px"}
                      onAdd={(phoneNumber) => {
                        setPhoneNumbers((arr) => {
                          return [...arr, phoneNumber];
                        });
                      }}
                      onRemove={(index) => {
                        let newArray = [...phoneNumbers];
                        newArray.splice(index, 1);
                        setPhoneNumbers(newArray);
                      }}
                      onChange={(index, phoneNumber) => {
                        let newArray = [...phoneNumbers];
                        newArray[index] = phoneNumber;
                        setPhoneNumbers(newArray);
                      }}
                    />
                  </section>
                </section>
              </Accordion>
            </div>

            {RoleCheckService.viewSiteAccessAndRoles(currentUser) && (
              <div
                className={[
                  styles["createUser__accordion"],
                  styles["createUser__accordion--1"],
                ].join(" ")}
              >
                <Accordion title={t("tab1")}>
                  <section
                    className={
                      smScreen ? styles.createUser__col : styles.createUser__row
                    }
                  >
                    <section className={styles.createUser__col}>
                      <SwitchInput
                        labelName={t("label6")}
                        isOn={isEnterprise}
                        onToggle={(isOn) => {
                          if (
                            RoleCheckService.modifySiteAccessAndRoles(
                              currentUser
                            )
                          ) {
                            setIsEnterprise(isOn);
                          }
                        }}
                      />
                      <Spacer space={30} unit={"px"} />
                      {!isEnterprise && (
                        <DropdownMultiSelectInput
                          labelName={
                            dropdownSiteListUser.length > 0
                              ? t("label7")
                              : t("label8")
                          }
                          validationText={t(errors.sites, {ns: "validation"})}
                          listElements={dropdownSiteListEnterprise}
                          selectedItems={dropdownSiteListUser}
                          onAddItem={handleAddSite}
                          onRemoveItem={handleRemoveSite}
                          width={"200px"}
                        />
                      )}

                      {lgScreen && <Spacer space={10} unit={"px"} />}

                      <DropdownInput
                        value={user?.defaultSite?.name || ""}
                        validationText={t(errors.defaultSite, {ns: "validation"})}
                        isEnabled={true}
                        placeholder={""}
                        listElements={
                          isEnterprise
                            ? dropdownSiteListEnterprise
                            : dropdownSiteListUser
                        }
                        labelName={t("defaultSite")}
                        onSelection={(listItem) => {
                          let sitesIndex = currentUserSites?.findIndex(
                            (o) => o.name === listItem?.description
                          );

                          setDefaultSite(currentUserSites[sitesIndex]);
                        }}
                      />
                    </section>
                    {lgScreen && <Spacer space={40} unit={"px"} />}
                    {mdScreen && <Spacer space={30} unit={"px"} />}
                    <section className={styles.createUser__col}>
                      <DropdownMultiSelectInput
                        labelName={
                          selectedRoles.length > 0 ? t("label9") : t("label10")
                        }
                        validationText={t(errors.roles, {ns: "validation"})}
                        listElements={roleListItems}
                        selectedItems={selectedRoles}
                        onAddItem={handleAddRole}
                        onRemoveItem={handleRemoveRole}
                        width={"300px"}
                      />
                    </section>
                  </section>
                </Accordion>
              </div>
            )}
          </div>
        </div>

        <section className={styles.createUser__footer}>
          <Button
            labelName={t("cancelButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            onClick={() => navigate(-1)}
            minWidth={smScreen ? 122 : null}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={isNew ? t("addNewButtonLabel") : t("updateButtonLabel")}
            isPrimary={true}
            isDisabled={false}
            onClick={handleUpdate}
            minWidth={smScreen ? 122 : 213}
          />
        </section>
      </section>
    </div>
  );
};

export default CreateUser;
