const replenishment_reportObj = {
  title: "",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: false,
  collections: ["arbitrary1", "arbitrary2"],
  fields: [
    {
      collection: "arbitrary1",
      dataType: "String",
      name: "Data 1",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "arbitrary2",
      dataType: "String",
      name: "Data 2",
      query: [],
      sum: false,
      average: false,
    },
  ],
  functionFields: [],
  functionLookups: {},
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "",
};

export default replenishment_reportObj;
