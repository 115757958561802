import React, { useState, useContext } from "react";
import ProductDisplay from "./ProductDisplay";
import StockedItemsQty from "../stockedItemsQty/StockedItemsQty";
import { ScreenContext } from "../../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import classes from "./StockedItemsDisplayList.module.css";
import styles from "../StockBody.module.css";

const StockedItemsDisplayList = ({
  stockedItemObjs,
  handleStockedItemObjs,
  setInputFieldQty,
  setEnforceInputFocus,
  productAssociationNotFound,
}) => {
  const [activeStockedItemQty, setActiveStockedItemQty] = useState(-1);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  return (
    <div
      className={
        smScreen || stockedItemObjs.length > 3
          ? [classes["scrollArea"], classes["newStockedItems"]].join(" ")
          : classes.newStockedItems
      }
    >
      {stockedItemObjs.map((stockedItemObj, index) =>
        !productAssociationNotFound ? (
          <div
            key={index}
            className={
              stockedItemObjs.length > 3
                ? index === activeStockedItemQty
                  ? [
                      styles["stockBody__newStockedItem"],
                      styles["stockBody__newStockedItem--active"],
                      styles["stockBody__newStockedItem--withScroll"],
                    ].join(" ")
                  : [
                      styles["stockBody__newStockedItem"],
                      styles["stockBody__newStockedItem--withScroll"],
                    ].join(" ")
                : index === activeStockedItemQty
                ? [
                    styles["stockBody__newStockedItem"],
                    styles["stockBody__newStockedItem--active"],
                  ].join(" ")
                : styles.stockBody__newStockedItem
            }
          >
            <ProductDisplay stockedItemObj={stockedItemObj} mapIndex={index} />

            <StockedItemsQty
              setEnforceInputFocus={setEnforceInputFocus}
              mapIndex={index}
              stockedItemObj={stockedItemObj}
              stockedItemObjs={stockedItemObjs}
              handleStockedItemObjs={handleStockedItemObjs}
              setInputFieldQty={setInputFieldQty}
              setActiveStockedItemQty={setActiveStockedItemQty}
            />
          </div>
        ) : null
      )}
    </div>
  );

  //   );
};

export default StockedItemsDisplayList;
