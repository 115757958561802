import React, { Fragment, useState, useEffect, useRef } from "react";
import stylesAll from "../PullStockForInternalTransfer/view.module.css";
import styles from "./InventoryPlaceProductView.module.css";
import InventoryTransferPlaceViewController from "./InventoryTransferPlaceViewController";
import Header from "./InventoryPlaceHeader";
import ErrorBanner from "../../../components/ErrorBanner";
import { deepCopy } from "../../../functions/utilities.js";
import Modal from "../commonUI/ModalTransparent";
import Button from "../commonUI/Button";
// import Button2 from "../commonUI/Button2";
import { ReactComponent as Pills } from "../../../assets/pills.svg";
import ProductController from "../../../controllers/ProductController";

const InventoryPlaceSmartCanisterToParent = ({
  onStateBack,
  onStateBackDone,
  user,
  site,
  userSmartStockedItems,
  smartCanisterStockedItems,
  scannedSmartCanister,
  scannedParentStockLocation,
  onHideModal,
  resetLocationStates,
  smartProduct,
  smartCanister,
}) => {
  const [processingReady, setProcessingReady] = useState(false);
  const pcRef = useRef(null);
  const [smartProductInfo, setSmartProductInfo] = useState(null);
  const [useEffect6Processed, setUseEffect6Processed] = useState(false);
  const [userStockedItemsUpdated, setUserStockedItemsUpdated] = useState([]);
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [stockedItemsForPlacement, setStockedItemsForReplacement] = useState(
    []
  );
  const [activateErrorModal, setActivateErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const latestQuantities = useRef(null);
  const [activeStockedItemQty, setActiveStockedItemQty] = useState(-1);

  const [activateUserMsg, setActivateUserMsg] = useState("");
  const [stockLocationStockedItems, setStockLocationStockedItems] = useState(
    []
  );
  // const [inputMsg, setInputMsg] = useState("");
  // const [btn2Text, setbtn2Text] = useState("");
  const [stockedItemFound, setStockedItemFound] = useState(true);
  const [nonUserStockedItems, setNonUserStockedItems] = useState([]);

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [scannedBarcodePrev, setScannedBarcodePrev] = useState("");

  const [productPackageId, setProductPackageId] = useState("");
  const [productPackageIdPrev, setProductPackageIdPrev] = useState("");

  const _controller = useRef(null);

  const [productState, setProductState] = useState({});
  const [productStatePrev, setProductStatePrev] = useState({});

  const [stockedItemInUserListToAddState, setStockedItemInUserListToAddState] =
    useState({});

  const [updateSmartCanProdAssoc, setUpdateSmartCanProdAssoc] = useState(false);

  const [processDataToggle, setProcessDataToggle] = useState(false);

  // const restoreStateUponCancelClick = () => {
  //   setScannedBarcode(scannedBarcodePrev);
  //   setProductPackageId(productPackageIdPrev);
  //   setProductState({ ...productStatePrev });
  // };

  const resetProductStates = () => {
    setUseEffect6Processed(false);
    setUserStockedItemsUpdated([]);
    setProducts([]);
    setQuantities([]);
    setStockedItemsForReplacement([]);
    setActivateErrorModal(false);
    setErrorMsg("");
    setActiveStockedItemQty(-1);
    setActivateUserMsg("");
  };

  useEffect(() => {
    if (processingReady && useEffect6Processed) {
      collectDataHandler();
    }
  }, [processingReady, useEffect6Processed]);

  useEffect(() => {
    if (smartProduct && smartCanister && useEffect6Processed) {
      const simulatedEvent = {
        which: 13,
        target: {
          value: smartProduct.productId.toString(),
        },
      };

      inputKeyUpHandler(simulatedEvent);
    }
  }, [processingReady, useEffect6Processed]);

  useEffect(() => {
    pcRef.current = new ProductController(user);
  }, []);

  useEffect(() => {
    if (smartCanister && smartProduct && userSmartStockedItems) {
      let quantityArray = [];
      let quantityTotal = 0;
      for (let i = 0; i < userSmartStockedItems.length; i++) {
        quantityArray.push(userSmartStockedItems[i].quantity);
        quantityTotal += userSmartStockedItems[i].quantity;
      }
      setQuantities(quantityArray);
    }
  }, [smartCanister, smartProduct, userSmartStockedItems]);

  useEffect(() => {
    const processUserData = (product) => {
      if (
        !scannedSmartCanister.isControlled &&
        product.controlLevel.type !== 0
      ) {
        setErrorMsg(
          `You are not allowed to place a controlled product to the scanned location, ${scannedSmartCanister.barcode}.`
        );
        setActivateErrorModal(true);
        return false;
      }

      setStockedItemFound(false);
      setStockedItemInUserListToAddState(stockedItemInUserListToAddState);

      setProcessingReady(true);
    };

    const callback = (error, product) => {
      const failureCallback = (error) => {
        setErrorMsg("There was an error processing the product barcode.");
        setActivateErrorModal(true);
      };

      if (!error && product) {
        if (!productPackageId) {
          setProductPackageIdPrev(productPackageId);
          setProductPackageId(product.packageId);
        }
        if (processUserData(product)) {
          let tempArraya = [...products];
          tempArraya.unshift(product);
          setProducts(tempArraya);
        }
      } else {
        failureCallback();
      }
    };
    if (scannedBarcode) {
      _controller.current.getProductByBarcode(scannedBarcode, callback);
    }
  }, [processDataToggle]);

  useEffect(() => {
    _controller.current = new InventoryTransferPlaceViewController(user, site);
  }, []);

  useEffect(() => {
    const callback = (error, stockedItems) => {
      // console.log("stockedItems", stockedItems);
      if (!error && stockedItems) {
        setStockLocationStockedItems(stockedItems);
      } else {
        console.log("error", error);
      }
    };
    if (stockLocationStockedItems && stockLocationStockedItems.length === 0) {
      _controller.current.getStockedItems(scannedSmartCanister, callback);
    }
  }, [scannedSmartCanister]);

  latestQuantities.current = quantities;

  useEffect(() => {
    let imgSource = null;
    let srcDBImage =
      imgSource && imgSource.mimeType
        ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
        : imgSource && imgSource.base64EncodedString;

    let productDesc;

    if (
      smartProduct &&
      smartProduct.defaultName &&
      smartProduct.defaultName < 16
    ) {
      productDesc = smartProduct ? smartProduct.defaultName : "";
    } else {
      productDesc = smartProduct ? smartProduct.primaryName : "";
      if (smartProduct && smartProduct.strength) {
        productDesc += ` ${smartProduct.strength}`;
      }
    }

    let packageDesc = "";

    if (smartProduct && smartProduct.packageDescription) {
      packageDesc = `${smartProduct.packageDescription}`;
    }

    setSmartProductInfo(
      <div className={styles["listItem"]}>
        <div
          className={[
            styles["imageContainer"],
            styles["imageContainer--default"],
          ].join(" ")}
        >
          {imgSource ? (
            <img className={styles.product__image} src={srcDBImage} alt="" />
          ) : (
            <Pills stroke={"#A7BBCF"} />
          )}
        </div>
        <div className={styles.descriptionGroup}>
          <div className={styles.productDesc}>{productDesc}</div>
          <div className={styles.packageDesc}>{packageDesc}</div>
        </div>
        <div
          className={`${styles.stockedItemQtyGroup} ${styles["stockedItemQtyGroup--smart"]}`}
        >
          <p>Qty</p>
          <p>
            {smartCanisterStockedItems[0] &&
            smartCanisterStockedItems[0].quantity
              ? smartCanisterStockedItems[0].quantity
              : null}
          </p>
        </div>
      </div>
    );
    setUseEffect6Processed(true);
  }, [products, activeStockedItemQty, userSmartStockedItems]);

  const backArrowHandler = () => {
    onStateBack();
  };

  const ErrorModal = () => {
    return (
      <div className={stylesAll.errorBannerContainer}>
        <ErrorBanner
          message={errorMsg}
          onClose={() => {
            setActivateErrorModal(false);
            setErrorMsg("");
          }}
        />
      </div>
    );
  };

  const doneBtnHandler = () => {
    if (!errorMsg) {
      const callback = (error, sl) => {
        if (!error && sl) {
          // console.log("stock location placed", sl);
        } else {
          console.log("error", error);
        }
      };

      resetProductStates();
      resetLocationStates();
      let modifiedScannedStockLocation = deepCopy(scannedSmartCanister);
      modifiedScannedStockLocation.parent = scannedParentStockLocation;
      modifiedScannedStockLocation.user = null;

      // console.log("scannedSmartCanister", scannedSmartCanister);
      // console.log("modifiedScannedStockLocation", modifiedScannedStockLocation);
      // console.log("scannedParentStockLocation", scannedParentStockLocation);
      // console.log("userSmartStockedItems", userSmartStockedItems);
      _controller.current.processSmartCanisterPlace(
        modifiedScannedStockLocation,
        callback
      );

      onStateBackDone();
    }
  };

  const updateProductsAssociation = (updatedStockLocation) => {
    const callback = (error, stockLocation) => {
      if (!error && stockLocation) {
        // console.log("updatedStockLocation", stockLocation);
      } else {
        console.log(error);
      }
    };

    _controller.current.updateStockLocation(updatedStockLocation, callback);
  };

  const collectDataHandler = () => {
    let holdArrayProductsVar;
    let holdArrayItemsVar;

    if (updateSmartCanProdAssoc) {
      const newProductAssociation = {
        min: 0,
        max: 0,
        product: productState,
      };

      let updatedStockLocation = { ...scannedSmartCanister };

      updatedStockLocation.productAssociations[0] = newProductAssociation;
      updateProductsAssociation(updatedStockLocation);
    }

    const updateDataNew = () => {
      // Note that stockedItemInUserListToAdd is false if the
      // entered barcode is not a stocked item in userStockedItems.
      let stockedItemToAdd = null;

      if (stockedItemInUserListToAddState) {
        stockedItemToAdd = stockedItemInUserListToAddState.stockedItemToAdd;
      }

      const tempArrayProducts = [...products];
      tempArrayProducts.unshift(productState);

      const tempArrayItems = [...stockedItemsForPlacement];

      if (stockedItemToAdd) {
        tempArrayItems.unshift(stockedItemToAdd);
      }

      holdArrayProductsVar = tempArrayProducts;
      holdArrayItemsVar = tempArrayItems;
    };

    updateDataNew();

    const product = holdArrayProductsVar[0];

    setProductPackageIdPrev(productPackageId);
    setProductPackageId(product.packageId);
    const tempProductsArray = [...products];
    tempProductsArray.unshift(holdArrayProductsVar[0]);

    const tempItemsArray = [...stockedItemsForPlacement];

    let newStockedItem = {};

    if (stockedItemFound) {
      tempItemsArray.unshift(holdArrayItemsVar[0]);
    } else {
      newStockedItem = {
        account: user.account,
        site: site,
        product: product,
        stockLocation: null,
        user: user,
        packageDescription: "Smart Canister",
        quantity: product.packageQuantity,
        barcode: scannedBarcode,
      };
      let tempNonUserStockedItems = [...nonUserStockedItems];
      tempNonUserStockedItems.push(newStockedItem);
      setNonUserStockedItems(tempNonUserStockedItems);

      tempItemsArray.unshift(newStockedItem);
    }

    const tempQuantitiesArray = [...quantities];

    if (stockedItemFound) {
      tempQuantitiesArray.unshift(holdArrayItemsVar[0].quantity);
    } else {
      tempQuantitiesArray.unshift(newStockedItem.quantity);
    }

    setProducts(tempProductsArray);
    setStockedItemsForReplacement(tempItemsArray);
    setQuantities(tempQuantitiesArray);
    setActivateUserMsg(false);
  };

  // const cancelBtnHandler = () => {
  //   setActivateUserMsg(false);
  //   restoreStateUponCancelClick();
  //   setUpdateSmartCanProdAssoc(false);
  // };

  const inputKeyUpHandler = (e) => {
    if (e.which === 13) {
      setScannedBarcode(e.target.value);
      setProductStatePrev({ ...productState });
      let barcode = e.target.value;

      setScannedBarcodePrev(scannedBarcode);

      if (barcode) {
        const containsItem = (barcode, list) => {
          let i;
          for (i = 0; i < list.length; i++) {
            if (list[i].barcode.toLowerCase() === barcode.toLowerCase()) {
              return { index: i, stockedItemToAdd: list[i] };
            }
          }
          return null;
        };

        // Checks to see if the scanned barcode has a matching stocked item (same product) in userStockedItems.
        // If true, it returns a matching stocked item (the first it finds) in the form {index: num, stockedItemToAdd: {...}}.
        // If false, it returns null.
        const stockedItemInUserListToAdd = containsItem(
          barcode,
          userStockedItemsUpdated
        );

        setStockedItemInUserListToAddState(stockedItemInUserListToAdd);

        if (stockedItemInUserListToAdd) {
          let i;
          let tempArray = [...userStockedItemsUpdated];
          for (i = 0; i < tempArray.length; i++) {
            if (tempArray[i] === stockedItemInUserListToAdd.stockedItemToAdd) {
              tempArray.splice(i, 1);
              break;
            }
          }
          setUserStockedItemsUpdated(tempArray);
        }

        setProcessDataToggle(!processDataToggle);
      }
    }
  };

  // Not used, but available for future checks

  // const userMsg = (
  //   <div className={styles.messageContainer}>
  //     <p className={styles.message}>{inputMsg}</p>
  //     <div className={styles.btnGroup}>
  //       <Button2 invStyle onClickHandler={cancelBtnHandler}>
  //         Cancel
  //       </Button2>
  //       <Button2 className={styles.btn} onClickHandler={collectDataHandler}>
  //         {btn2Text}
  //       </Button2>
  //     </div>
  //   </div>
  // );

  return (
    <Fragment>
      <div className={stylesAll.container}>
        {activateErrorModal && <ErrorModal />}
        {activateErrorModal && <Modal />}
        {
          <Header
            headerTitle="Place"
            backArrowHandler={backArrowHandler}
            useInput={false}
            onHideModal={onHideModal}
            smartCanister={smartCanister ? true : false}
            smartProduct={smartProduct ? true : false}
          />
        }

        {/* {activateUserMsg && userMsg} */}

        {!activateUserMsg && (
          <Fragment>
            <div className={styles.listItemsContainer}>
              <p className={styles.stockLocationName}>
                {scannedSmartCanister.name}
              </p>
              <ul className={`${styles.listItems}`}>{smartProductInfo}</ul>
            </div>

            <div
              className={
                errorMsg
                  ? `${styles.doneButtonContainer} ${stylesAll["buttonNoHoverCursor"]}`
                  : `${styles.doneButtonContainer} ${stylesAll["buttonHoverCursor"]}`
              }
            >
              {processingReady && (
                <Button onClickHandler={doneBtnHandler}>Done</Button>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default InventoryPlaceSmartCanisterToParent;
