import ProductService from "../../../services/ProductService";
import StockedItemService from "../../../services/StockedItemService";
import StockLocationService from "../../../services/StockLocationService";

class InventoryTransferPullProductViewController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  getProductByBarcode = async (barcode, callback) => {
    ProductService.getProductByBarcode(this.user, barcode)
      .then((product) => {
        callback(null, product);
      })
      .catch((error) => {
        callback(error);
      });
  };

  processSmartCanisterPull = async (
    stockLocation,
    parentStockLocation,
    callback
  ) => {
    StockLocationService.update(
      this.user,
      this.site,
      stockLocation,
      parentStockLocation
    )
      .then((sl) => {
        callback(null, sl);
      })
      .catch((error) => {
        callback(error);
      });
  };

  processPull = async (originalStockedItem, modifiedStockedItem, callback) => {
    // if pulledQty = original qty just update original to associate the StockedItem to the User and
    // nullify the StockLocation
    if (modifiedStockedItem.quantity === originalStockedItem.quantity) {
      modifiedStockedItem.stockLocation = null;
      modifiedStockedItem.user = this.user;
      StockedItemService.update(this.user, this.site, modifiedStockedItem)
        .then((stockedItem) => {
          callback(null, stockedItem);
        })
        .catch((error) => {
          callback(error);
        });
    } else {
      // if pulled qty is not equal to original then split (1 remains at StockLocation and the rest on User)
      try {
        originalStockedItem.quantity -= modifiedStockedItem.quantity;
        const updatedStockedItem = await StockedItemService.update(
          this.user,
          this.site,
          originalStockedItem
        );
        modifiedStockedItem.stockLocation = null;
        modifiedStockedItem.user = this.user;
        modifiedStockedItem._id = null; // remove any relationship
        const newStockedItem = await StockedItemService.create(
          this.user,
          this.site,
          modifiedStockedItem
        );
        callback(null, newStockedItem, updatedStockedItem);
      } catch (error) {
        callback(error);
      }
    }
  };
}

export default InventoryTransferPullProductViewController;
