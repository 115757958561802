import React from "react";
import { useTranslation } from "react-i18next";
import Spacer from "../../components/Spacer";

export const InventoryAddEquivProductsHeadings = () => {
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  };

  const colStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const textStyle = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#121A2D",
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
  };
  const { t } = useTranslation("invEquivalency");

  return (
    <div
      style={{
        ...rowStyle,
        borderBottomColor: "#089BAB",
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
      }}
    >
      <div
        style={{
          ...colStyle,
          width: "121px",
          marginLeft: "20px",
          marginRight: "68px",
        }}
      >
        <p style={{ ...textStyle }}>{t("col0")}</p>
        <Spacer space={14} unit={"px"} />
      </div>
      <div style={{ ...colStyle, width: "256px", marginRight: "100px" }}>
        <p style={textStyle}>{t("col1")}</p>
        <Spacer space={14} unit={"px"} />
      </div>
      <div style={{ ...colStyle, width: "141px", marginRight: "71px" }}>
        <p style={textStyle}>{t("col2")}</p>
        <Spacer space={14} unit={"px"} />
      </div>
      <div style={{ ...colStyle, width: "61px" }}>
        <p style={textStyle}>{t("col3")}</p>
        <Spacer space={14} unit={"px"} />
      </div>
    </div>
  );
};
