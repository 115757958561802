import React, { useEffect, useRef, useState } from "react";
import styles from "./TrayPickList.module.css";
import { ReactComponent as BackButton } from "../../assets/back_icon.svg";
import ProductFieldController from "../../controllers/ProductFieldController";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";
import DataMatrix from "react-datamatrix-svg";
import DispensingOrderService from "../../services/DispensingOrderService";

const TrayPickList = ({ user, data, onClose }) => {
  const productFieldController = new ProductFieldController();
  const componentRef = useRef();

  const [trayNeeds, setTrayNeeds] = useState(null);

  useEffect(() => {
    DispensingOrderService.getTrayNeeds(user, data)
      .then((needs) => {
        setTrayNeeds(needs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getBarcode = (trayNeed) => {
    const bc = `NEWORDER${trayNeed.product.packageId}/${trayNeed.quantity}*TRAY*BATCH*ULTFill`;
    return <DataMatrix msg={bc} dim={50} />;
  };

  const print = useReactToPrint({
    pageStyle: "{padding: '20px'}",
    content: () => componentRef.current,
  });

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div ref={componentRef} className={styles.column}>
      <div className={styles.headerRow}>
        <div className={styles.row}>
          <BackButton className={styles.backButton} onClick={onClose} />
          <h1>Tray Pick List</h1>
        </div>
        <FontAwesomeIcon
          icon={faPrint}
          size={"2x"}
          color={"#089BAB"}
          onClick={() => print()}
          style={{ cursor: "pointer" }}
        />
      </div>
      <br />
      <div className={styles.headerRow}>
        <div className={styles.column}>
          <h2>
            <span>Batch ID:</span> {trayNeeds?.batchId}
          </h2>
          <h2>
            <span>Batch Name:</span> {trayNeeds?.batchName}
          </h2>
        </div>
      </div>
      <br />
      <table>
        <thead>
          <tr>
            <th align={"left"}>ID</th>
            <th align={"left"}>Name</th>
            <th align={"left"}>Manufacturer</th>
            <th align={"left"}>Control Level</th>
            <th align={"left"}>Quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {trayNeeds &&
            trayNeeds.products
              .sort((p1, p2) => {
                if (p1.product.primaryName < p2.product.primaryName) {
                  return -1;
                }
                if (p2.product.primaryName < p1.product.primaryName) {
                  return 1;
                }
                return 0;
              })
              .map((obj) => {
                return (
                  <tr key={obj.product._id}>
                    <td>{obj.product.packageId}</td>
                    <td>
                      <div>
                        <p>
                          {productFieldController.getDefaultName(obj.product)}
                        </p>
                        <p className={styles.DescriptionParagraph}>
                          {obj.product.comments}
                        </p>
                      </div>
                    </td>
                    <td>
                      {obj.product.manufacturer?.abbrName ||
                        obj.product.manufacturer?.fullName ||
                        ""}
                    </td>
                    <td>{obj.product.controlLevel?.description || ""}</td>
                    <td>
                      {obj.partialQty === 0
                        ? obj.quantity
                        : `${obj.quantity} (1/${obj.partialQty} tab(s))`}
                    </td>
                    <td>{getBarcode(obj)}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default TrayPickList;
