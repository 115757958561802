import React from "react";
import styles from "./HelpVideosCard.module.css";

const HelpVideosCard = ({ cardTitle, machineType, onCardClick }) => {
  return (
    <div onClick={onCardClick} className={styles.helpVideosCard__topicCard}>
      <div className={styles.helpVideosCard__cardImageContainer}></div>
      <h2>{cardTitle}</h2>
    </div>
  );
};

export default HelpVideosCard;
