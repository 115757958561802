import React, { useContext } from "react";
import ScanProduct from "./scanInput/ScanProduct";
import StockHeaderHeadingGroup from "./stockHeaderHeadingGroup/StockHeaderHeadingGroup";
import GetProductByBarCode from "./scanInput/GetProductByBarCode";
import SearchForProducts from "./scanInput/SearchForProducts";
import GetStockLocation from "./scanInput/GetStockLocation";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./StockHeader.module.css";

const StockHeader = ({
  setSecondProductScan,
  currentStatusObj,
  handleCurrentStatusObj,
  stockedItemObjs,
  handleStockedItemObjs,
  productRescannedEntryComplete,
  setProductRescannedEntryComplete,
  page,
  setPage,
  barcodesToBeAssociated,
  setBarcodesToBeAssociated,
  scannedBarcodes,
  setScannedBarcodes,
  lotNumbers,
  setLotNumbers,
  expirations,
  setExpirations,
  user,
  activeIcon,
  site,
  setSite,
  productScanned,
  setProductScanned,
  productScannedLot,
  setProductScannedLot,
  productScannedExpDate,
  setProductScannedExpDate,
  setStockedItems,
  stockedItemsQty,
  inputFieldQty,
  setInputFieldQty,
  stockLocations,
  setStockLocations,
  stockLocationScanned,
  setStockLocationScanned,
  productRescanned,
  setProductRescanned,
  productRescannedLot,
  setProductRescannedLot,
  productRescannedExpDate,
  setProductRescannedExpDate,
  scannedProducts,
  setScannedProducts,
  setNewStockedItems,
  pullStock,
  setPullStock,
  placeStock,
  setPlaceStock,
  enforceInputFocus,
  setEnforceInputFocus,
  scannedItemIndex,
  setScannedItemIndex,
  stockedItemsQuantities,
  scannedStockedItemsQuantities,
  setScannedStockedItemsQuantities,
  setProductAssociationFound,
  productAssociationNotFound,
  setProductAssociationNotFound,
  setGetStockedItemsComplete,
  productNotFound,
  setProductNotFound,
  productBarcode,
  setProductBarcode,
  associateBarcodeWithProduct,
  setAssociateBarcodeWithProduct,
  productsSearchText,
  setProductsSearchText,
  selectedProductForAssoc,
  setSelectedProductForAssoc,
  userCredentials,
  setUserCredentials,
  newAssociationComplete,
  setNewAssociationComplete,
  onHideModal,
  onActivateStockLocationDisabled,
  errorMsg,
  onSetErrorHandler,
  updatedStockLocations,
  handleUpdatedStockLocations,
  setPreviousProductScanned,
}) => {
  const { smallScreen } = useContext(ScreenContext);

  const smScreen = useMediaQuery(smallScreen);

  let tallHeader = false;

  if (site) {
    tallHeader = true;
  }

  let fullBottomBorderRadius = false;

  if (site && !productScanned) {
    fullBottomBorderRadius = true;
  }

  if (
    (productNotFound &&
      site &&
      productBarcode &&
      !associateBarcodeWithProduct) ||
    selectedProductForAssoc
  ) {
    tallHeader = false;
    fullBottomBorderRadius = false;
  }

  let headerStyles;

  if (!tallHeader && !fullBottomBorderRadius) {
    headerStyles = [styles["header"], styles["header--short"]].join(" ");
  } else if (!tallHeader && fullBottomBorderRadius) {
    headerStyles = [
      styles["header"],
      styles["header--short"],
      styles["header--fullBottomBorderRadius"],
    ].join(" ");
  } else if (tallHeader && fullBottomBorderRadius) {
    headerStyles = [
      styles["header"],
      styles["header--fullBottomBorderRadius"],
    ].join(" ");
  } else {
    headerStyles = styles.header;
  }

  return (
    <section className={smScreen ? "" : headerStyles}>
      <StockHeaderHeadingGroup
        setSecondProductScan={setSecondProductScan}
        currentStatusObj={currentStatusObj}
        handleCurrentStatusObj={handleCurrentStatusObj}
        stockedItemObjs={stockedItemObjs}
        handleStockedItemObjs={handleStockedItemObjs}
        productRescannedEntryComplete={productRescannedEntryComplete}
        setProductRescannedEntryComplete={setProductRescannedEntryComplete}
        page={page}
        scannedBarcodes={scannedBarcodes}
        setScannedBarcodes={setScannedBarcodes}
        setLotNumbers={setLotNumbers}
        setExpirations={setExpirations}
        barcodesToBeAssociated={barcodesToBeAssociated}
        setBarcodesToBeAssociated={setBarcodesToBeAssociated}
        setSite={setSite}
        activeIcon={activeIcon}
        pullStock={pullStock}
        setPullStock={setPullStock}
        placeStock={placeStock}
        setPlaceStock={setPlaceStock}
        setProductScanned={setProductScanned}
        setProductScannedLot={setProductScannedLot}
        setProductScannedExpDate={setProductScannedExpDate}
        stockLocationScanned={stockLocationScanned}
        setStockLocationScanned={setStockLocationScanned}
        setStockLocations={setStockLocations}
        setProductRescanned={setProductRescanned}
        productRescannedLot={productRescannedLot}
        setProductRescannedLot={setProductRescannedLot}
        productRescannedExpDate={productRescannedExpDate}
        setProductRescannedExpDate={setProductRescannedExpDate}
        setScannedProducts={setScannedProducts}
        setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
        setScannedItemIndex={setScannedItemIndex}
        setGetStockedItemsComplete={setGetStockedItemsComplete}
        setPreviousProductScanned={setPreviousProductScanned}
        setStockedItems={setStockedItems}
        setProductAssociationNotFound={setProductAssociationNotFound}
        setProductBarcode={setProductBarcode}
        productNotFound={productNotFound}
        setProductNotFound={setProductNotFound}
        associateBarcodeWithProduct={associateBarcodeWithProduct}
        setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
        productsSearchText={productsSearchText}
        setProductsSearchText={setProductsSearchText}
        selectedProductForAssoc={selectedProductForAssoc}
        setSelectedProductForAssoc={setSelectedProductForAssoc}
        userCredentials={userCredentials}
        setUserCredentials={setUserCredentials}
        newAssociationComplete={newAssociationComplete}
        setNewAssociationComplete={setNewAssociationComplete}
        onHideModal={onHideModal}
      />

      {activeIcon === "Receive" &&
        site &&
        !productScannedExpDate &&
        !productNotFound && (
          <GetProductByBarCode
            currentStatusObj={currentStatusObj}
            handleCurrentStatusObj={handleCurrentStatusObj}
            stockedItemObjs={stockedItemObjs}
            handleStockedItemObjs={handleStockedItemObjs}
            setPage={setPage}
            barcodesToBeAssociated={barcodesToBeAssociated}
            setBarcodesToBeAssociated={setBarcodesToBeAssociated}
            scannedBarcodes={scannedBarcodes}
            setScannedBarcodes={setScannedBarcodes}
            lotNumbers={lotNumbers}
            setLotNumbers={setLotNumbers}
            expirations={expirations}
            setExpirations={setExpirations}
            user={user}
            site={site}
            productScanned={productScanned}
            scannedProducts={scannedProducts}
            setScannedProducts={setScannedProducts}
            setProductScanned={setProductScanned}
            productScannedLot={productScannedLot}
            setProductScannedLot={setProductScannedLot}
            productScannedExpDate={productScannedExpDate}
            setProductScannedExpDate={setProductScannedExpDate}
            setStockedItems={setStockedItems}
            inputFieldQty={inputFieldQty}
            setInputFieldQty={setInputFieldQty}
            stockLocations={stockLocations}
            setStockLocations={setStockLocations}
            stockLocationScanned={stockLocationScanned}
            setStockLocationScanned={setStockLocationScanned}
            productRescanned={productRescanned}
            setProductRescanned={setProductRescanned}
            productRescannedLot={productRescannedLot}
            setProductRescannedLot={setProductRescannedLot}
            productRescannedExpDate={productRescannedExpDate}
            setProductRescannedExpDate={setProductRescannedExpDate}
            setNewStockedItems={setNewStockedItems}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            scannedItemIndex={scannedItemIndex}
            setScannedItemIndex={setScannedItemIndex}
            stockedItemsQty={stockedItemsQty}
            stockedItemsQuantities={stockedItemsQuantities}
            scannedStockedItemsQuantities={scannedStockedItemsQuantities}
            setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
            productAssociationNotFound={productAssociationNotFound}
            setProductAssociationNotFound={setProductAssociationNotFound}
            productNotFound={productNotFound}
            setProductNotFound={setProductNotFound}
            productBarcode={productBarcode}
            setProductBarcode={setProductBarcode}
            associateBarcodeWithProduct={associateBarcodeWithProduct}
            setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
            onSetErrorHandler={onSetErrorHandler}
            updatedStockLocations={updatedStockLocations}
            handleUpdatedStockLocations={handleUpdatedStockLocations}
            errorMsg={errorMsg}
          />
        )}

      {activeIcon === "Receive" &&
        associateBarcodeWithProduct &&
        !selectedProductForAssoc && (
          <SearchForProducts
            user={user}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            setProductNotFound={setProductNotFound}
            setProductsSearchText={setProductsSearchText}
            productsSearchText={productsSearchText}
            onSetErrorHandler={onSetErrorHandler}
            errorMsg={errorMsg}
          />
        )}

      {activeIcon === "Receive" &&
        productScanned &&
        productScannedLot &&
        productScannedExpDate &&
        !stockLocationScanned && (
          <GetStockLocation
            currentStatusObj={currentStatusObj}
            handleCurrentStatusObj={handleCurrentStatusObj}
            stockedItemObjs={stockedItemObjs}
            handleStockedItemObjs={handleStockedItemObjs}
            setPage={setPage}
            user={user}
            site={site}
            setStockLocationScanned={setStockLocationScanned}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            setProductAssociationFound={setProductAssociationFound}
            associateBarcodeWithProduct={associateBarcodeWithProduct}
            onSetErrorHandler={onSetErrorHandler}
            onActivateStockLocationDisabled={onActivateStockLocationDisabled}
            setScannedBarcodes={setScannedBarcodes}
            errorMsg={errorMsg}
          />
        )}

      {activeIcon === "Receive" && stockLocationScanned && !productNotFound && (
        <ScanProduct
          setSecondProductScan={setSecondProductScan}
          currentStatusObj={currentStatusObj}
          handleCurrentStatusObj={handleCurrentStatusObj}
          stockedItemObjs={stockedItemObjs}
          handleStockedItemObjs={handleStockedItemObjs}
          productRescannedEntryComplete={productRescannedEntryComplete}
          setProductRescannedEntryComplete={setProductRescannedEntryComplete}
          setPage={setPage}
          barcodesToBeAssociated={barcodesToBeAssociated}
          setBarcodesToBeAssociated={setBarcodesToBeAssociated}
          scannedBarcodes={scannedBarcodes}
          setScannedBarcodes={setScannedBarcodes}
          lotNumbers={lotNumbers}
          setLotNumbers={setLotNumbers}
          expirations={expirations}
          setExpirations={setExpirations}
          user={user}
          site={site}
          scannedProducts={scannedProducts}
          setScannedProducts={setScannedProducts}
          setStockedItems={setStockedItems}
          inputFieldQty={inputFieldQty}
          setInputFieldQty={setInputFieldQty}
          stockLocations={stockLocations}
          setStockLocations={setStockLocations}
          stockLocationScanned={stockLocationScanned}
          setStockLocationScanned={setStockLocationScanned}
          productRescanned={productRescanned}
          setProductRescanned={setProductRescanned}
          productRescannedLot={productRescannedLot}
          setProductRescannedLot={setProductRescannedLot}
          productRescannedExpDate={productRescannedExpDate}
          setProductRescannedExpDate={setProductRescannedExpDate}
          setNewStockedItems={setNewStockedItems}
          enforceInputFocus={enforceInputFocus}
          setEnforceInputFocus={setEnforceInputFocus}
          scannedItemIndex={scannedItemIndex}
          setScannedItemIndex={setScannedItemIndex}
          stockedItemsQty={stockedItemsQty}
          stockedItemsQuantities={stockedItemsQuantities}
          scannedStockedItemsQuantities={scannedStockedItemsQuantities}
          setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
          productAssociationNotFound={productAssociationNotFound}
          setProductAssociationNotFound={setProductAssociationNotFound}
          productNotFound={productNotFound}
          setProductNotFound={setProductNotFound}
          productBarcode={productBarcode}
          setProductBarcode={setProductBarcode}
          associateBarcodeWithProduct={associateBarcodeWithProduct}
          setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
          onSetErrorHandler={onSetErrorHandler}
          updatedStockLocations={updatedStockLocations}
          handleUpdatedStockLocations={handleUpdatedStockLocations}
          errorMsg={errorMsg}
        />
      )}
    </section>
  );
};

export default StockHeader;
