import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../../../assets/mobile-back-arrow.svg";
import { ReactComponent as BackArrowMobile } from "../../../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseModalIcon } from "../../../../../assets/x.svg";
import { ScreenContext } from "../../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./StockHeaderHeadingGroup.module.css";

const StockHeaderHeadingGroup = ({
  handleCurrentStatusObj,
  handleStockedItemObjs,
  page,
  setBarcodesToBeAssociated,
  scannedBarcodes,
  setScannedBarcodes,
  setLotNumbers,
  setExpirations,
  setProductScannedLot,
  setProductRescannedLot,
  setProductScannedExpDate,
  setProductRescannedExpDate,
  setSite,
  activeIcon,
  pullStock,
  placeStock,
  setProductScanned,
  stockLocationScanned,
  setStockLocationScanned,
  setStockLocations,
  setProductRescanned,
  setScannedProducts,
  setScannedStockedItemsQuantities,
  setScannedItemIndex,
  setGetStockedItemsComplete,
  setStockedItems,
  setProductAssociationNotFound,
  productNotFound,
  setProductNotFound,
  setProductBarcode,
  associateBarcodeWithProduct,
  setAssociateBarcodeWithProduct,
  productsSearchText,
  setProductsSearchText,
  selectedProductForAssoc,
  setSelectedProductForAssoc,
  userCredentials,
  setUserCredentials,
  newAssociationComplete,
  setNewAssociationComplete,
  onHideModal,
  setPreviousProductScanned,
}) => {
  const navigate = useNavigate();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const goBackHandler = () => {
    if (!stockLocationScanned && newAssociationComplete) {
      // console.log(2);
      setNewAssociationComplete(false);
      setProductNotFound(false);
      setScannedBarcodes([]);
      setProductAssociationNotFound(true);
      setProductBarcode("");
      setAssociateBarcodeWithProduct(false);
      setProductsSearchText("");
      setSelectedProductForAssoc("");
      setUserCredentials("");
      setProductScanned("");
      setPreviousProductScanned("");
    }

    // Before the location is scanned, and a barcode association process is in progress, but before the Associate button is clicked.
    else if (!stockLocationScanned && selectedProductForAssoc) {
      // console.log(3);
      setSelectedProductForAssoc("");
    } else if (
      !stockLocationScanned &&
      associateBarcodeWithProduct &&
      productsSearchText
    ) {
      // console.log(4);
      setProductsSearchText("");
    } else if (!stockLocationScanned && associateBarcodeWithProduct) {
      // console.log(5);
      setAssociateBarcodeWithProduct(false);
    } else if (!stockLocationScanned && productNotFound) {
      // console.log(6);
      setProductNotFound(false);
      setScannedBarcodes([]);
      setProductAssociationNotFound(true);
      setProductBarcode("");
    }
    // After the location is scanned, and a barcode association process is in progress, but before the Associate button is clicked.
    else if (stockLocationScanned && newAssociationComplete) {
      // console.log(7);
      // to be implemented
      if (newAssociationComplete) {
        setNewAssociationComplete(false);
        setProductNotFound(false);
        setProductBarcode(scannedBarcodes[0]);
        let tempArray = [scannedBarcodes[0]];
        setScannedBarcodes(tempArray);
        setAssociateBarcodeWithProduct(false);
        setProductsSearchText("");
        setSelectedProductForAssoc("");
        setUserCredentials("");
        setNewAssociationComplete(false);
      }
      // After the location is scanned, and a barcode association process is in progress, but before the Associate button is clicked.
    } else if (stockLocationScanned && productNotFound) {
      // console.log(8);
      if (userCredentials === "Authorized") {
        setUserCredentials("");
        setNewAssociationComplete(false);
      } else if (selectedProductForAssoc) {
        // console.log(9);
        setSelectedProductForAssoc("");
      } else if (productsSearchText) {
        // console.log(10);
        setProductsSearchText("");
      } else if (associateBarcodeWithProduct) {
        // console.log(11);
        setAssociateBarcodeWithProduct(false);
      } else if (productNotFound) {
        // console.log(12);
        setProductNotFound(false);
        setProductBarcode(scannedBarcodes[0]);
        let tempArray = [scannedBarcodes[0]];
        setScannedBarcodes(tempArray);
      }
    }

    // Outside of barcode association process
    else if (page === 3 || page === 4 || page === 5 || page === 6) {
      handleCurrentStatusObj({
        barcode: "",
        product: "",
        lotNumber: "",
        expiration: "",
      });
      handleStockedItemObjs([]);
      setScannedBarcodes([]);
      setBarcodesToBeAssociated([]);
      setScannedProducts([]);
      setStockLocationScanned("");
      setProductRescanned("");
      setScannedItemIndex(0);
      setScannedStockedItemsQuantities([]);
      setProductAssociationNotFound(true);
      setExpirations([]);
      setProductScannedExpDate("");
      setLotNumbers([]);
      setProductScannedLot("");
      setScannedBarcodes([]);
      setProductScanned("");
      setStockedItems("");
      setGetStockedItemsComplete(false);
      setStockLocations([]);
      setProductBarcode("");
      setProductRescannedLot("");
      setProductRescannedExpDate("");
    } else if (page === 2) {
      setSite("");
    } else if (page === 1) {
      navigate("/inventory/items");
    }
  };

  return smScreen ? (
    <section className={styles.stockHeader__headingGroup}>
      <div className={styles.stockHeader__backArrow} onClick={goBackHandler}>
        <BackArrowMobile className={styles.stockHeader__backArrow__icon} />
        <p className={styles.stockHeader__goBackTitle}>{"Back"}</p>
      </div>
      <p className={styles.stockHeader__title}>
        {!(pullStock || placeStock) ? activeIcon : pullStock ? "Pull" : "Place"}
      </p>
    </section>
  ) : (
    <section className={styles.stockHeader__headingGroup}>
      {activeIcon !== "Home" && (
        <BackArrow
          className={styles.stockHeader__backArrow}
          onClick={goBackHandler}
        />
      )}
      {!(pullStock || placeStock) && (
        <span className={styles.stockHeader__heading}>{activeIcon}</span>
      )}{" "}
      {(pullStock || placeStock) && (
        <span className={styles.stockHeader__heading}>
          {pullStock ? "Pull" : "Place"}
        </span>
      )}
      {!smScreen ? (
        <CloseModalIcon
          className={styles.stockHeader__closeModalIcon}
          onClick={() => onHideModal()}
        />
      ) : null}
    </section>
  );
};

export default StockHeaderHeadingGroup;
