import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getTimeRangeDay,
  getTimeRange12Hours,
  getTimeRangeWeek,
  getTimeRangeMonth,
} from "../../../../functions/dates";
import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";
import PouchesPerHourGraph from "../graphs/PouchesPerHourGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";

const GetPouchesPerHourGraph = ({
  user,
  dispensingDevices,
  selectedDevices,
  selectedSites,
  viewWidth,
  loader,
}) => {
  const [timeRange, setTimeRange] = useState("month");
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);
  const [Graph, setGraph] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (!dispensingDevices.length || selectedDevices.length === 0) {
      setGraph([]);
    }
  }, [dispensingDevices, selectedDevices]);

  useEffect(() => {
    if (selectedDevices.length) {
      setGraph(
        GraphContainer(
          "pouchesPerHourGraph", // id
          graphData // data for selected site and dispensing device
        )
      );
    }
  }, [graphData, fetchingData, selectedDevices]);

  useEffect(() => {
    let dateRangeObject;
    if (timeRange === "12 hours") {
      dateRangeObject = getTimeRange12Hours();
    } else if (timeRange === "day") {
      dateRangeObject = getTimeRangeDay();
    } else if (timeRange === "week") {
      dateRangeObject = getTimeRangeWeek();
    } else {
      dateRangeObject = getTimeRangeMonth();
    }
    setStartDate(dateRangeObject.startDate);
    setStopDate(dateRangeObject.stopDate);
  }, [timeRange]);

  // Pouches Per Hour graph-data fetching methods
  useEffect(() => {
    if (startDate && stopDate && selectedDevices.length) {
      // Get data for Pouches Per Hour graph
      const utcStartDate = startDate.toUTCString();
      const utcStopDate = stopDate.toUTCString();

      if (selectedSites.length && selectedDevices.length) {
        setGraph([GraphContainer(1, [], 0, null, true)]);
      }

      async function updateGraphs() {
        setFetchingData(true);
        let hourCountData = [];

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakPouchesPerHourData(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate
            )
            .then((data) => {
              const getScriptsForHour = (hour, results) => {
                return results.filter((o) => {
                  const dt = new Date(o.packagedOn); // UTC
                  return dt.getHours() === hour; // Local time
                });
              };

              let forIndex = 0;
              for (let hour = 0; hour < 24; hour++) {
                const temp = getScriptsForHour(
                  hour,
                  data.results.truPakPackagedPouchesData
                );

                if (hourCountData.length <= 23) {
                  hourCountData.push({
                    hour: hour,
                    count: temp.length,
                  });
                } else {
                  hourCountData[forIndex].count += temp.length;
                }
                forIndex++;
              }
              setGraphData(
                hourCountData.map((result, index) => ({
                  x:
                    index < 12
                      ? `${result.hour > 0 ? result.hour : 12} AM`
                      : `${result.hour > 12 ? result.hour - 12 : 12} PM`,
                  y: result.count,
                }))
              );
            });
        }

        setFetchingData(false);
      }
      if (selectedDevices.length) {
        updateGraphs();
      } else {
        setGraph([]);
      }
    } else {
      setGraph([]);
    }
  }, [selectedSites, startDate, stopDate, selectedDevices]);

  const handleTimeRangePouchesPerHour = (stringVal) => {
    setTimeRange(stringVal);
  };

  const GraphContainer = (id, data) => {
    let benchmarkValue = 0;
    selectedDevices.forEach((device) => {
      if (
        device?.device &&
        device.device?.settings &&
        device.device.settings?.pouchesPerHourBenchmark
      ) {
        benchmarkValue += Number(
          device.device.settings.pouchesPerHourBenchmark
        );
      }
    });

    return (
      <div
        key={id}
        className={
          smScreen
            ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
            : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
        }
        // onClick={!fetchingData ? () => handleGraphClick(1) : null}
      >
        {fetchingData && loader}
        <PouchesPerHourGraph
          graphData={data}
          benchmarkValue={benchmarkValue}
          graphId={`newpouchesPerHourGraph${id}`}
          graphTitle="Pouches per Hour"
          palette={["#318ce7", "#E68000"]}
          viewWidth={viewWidth}
          timeRange={timeRange}
          onTimeRange={handleTimeRangePouchesPerHour}
        />
      </div>
    );
  };

  return Graph;
};

export default GetPouchesPerHourGraph;
