import ApiService from "./ApiService";
import axios from "axios";

class EV54Transaction extends ApiService {

    static processingTransactionCount = async (user, device, canisterNo) => {
        try {
            const rc = {
                headers: { "x-access-token": user.token },
            };
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${device.site._id || device.site}/stock-locations/${device._id}/processingTransactionCount?canisterNo=${canisterNo}`;
            let response = await axios.get(url, rc);
            return response.data.count;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default EV54Transaction;
