import React from "react";
import styles from "./AssociateProductBarcode.module.css";

const AssociateProductBarcode = ({
  scannedBarcodes,
  setScannedBarcodes,
  productBarcode,
  setProductBarcode,
  setProductNotFound,
  setAssociateBarcodeWithProduct,
}) => {
  const cancelHandler = () => {
    setProductNotFound(false);
    setProductBarcode("");
    let tempArray = [...scannedBarcodes];
    tempArray.pop();
    setScannedBarcodes(tempArray);
  };

  const associateHandler = () => {
    setAssociateBarcodeWithProduct(true);
  };

  return (
    <div className={styles.associateProductBarcode}>
      <p className={styles.associateProductBarcode__message}>
        The barcode scanned is not recognized. Do you want to associate{" "}
        <span className={styles.associateProductBarcode__messageStyling}>
          {scannedBarcodes[scannedBarcodes.length - 1]}
        </span>{" "}
        with a product?
      </p>
      <div className={styles.associateProductBarcode__buttons}>
        <button
          className={[
            styles["associateProductBarcode__button"],
            styles["associateProductBarcode__button--create"],
          ].join(" ")}
          onClick={associateHandler}
        >
          Yes
        </button>
        <button
          className={[
            styles["associateProductBarcode__button"],
            styles["associateProductBarcode__button--cancel"],
          ].join(" ")}
          onClick={cancelHandler}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AssociateProductBarcode;
