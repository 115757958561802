import React from "react";
import { ReactComponent as ExpandIcon } from "../../../../assets/add-icon-small.svg";
import styles from "./LocationCard.module.css";

const LocationCard = ({ data, expandIcon }) => {
  return (
    <div className={styles.locationCard__container}>
      <div className={styles.locationCard__dataList}>
        <div className={styles.locationCard__rowGroup}>
          <div
            className={`${styles.locationCard__colGroup} ${styles["locationCard__colGroup--1"]}`}
          >
            <div className={`${styles["locationCard__heading"]}`}>
              {data.nameHeading}
            </div>
            <div className={`${styles["locationCard__value"]}`}>
              {data.name}
            </div>
          </div>

          {expandIcon && (
            <div className={styles.locationCard__expandIconContainer}>
              <ExpandIcon className={styles.locationCard__expandIcon} />
            </div>
          )}
        </div>

        <div className={styles.locationCard__rowGroup}>
          <div
            className={`${styles.locationCard__colGroup} ${styles["locationCard__colGroup--1"]}`}
          >
            <div className={`${styles["locationCard__heading"]}`}>
              {data.typeHeading}
            </div>
            <div className={`${styles["locationCard__value"]}`}>
              {data.type}
            </div>
          </div>
        </div>

        <div className={styles.locationCard__rowGroup}>
          <div
            className={`${styles.locationCard__colGroup} ${styles["locationCard__colGroup--1"]} `}
          >
            <div className={`${styles["locationCard__heading"]}`}>
              {data.barcodeHeading}
            </div>
            <div className={`${styles["locationCard__value"]}`}>
              {data.barcode}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
