import React from "react";
import styles from "./TruPakAlarmsCard.module.css";

const TruPakAlarmsCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.TruPakAlarmsCard__reportList}>
      <li className={styles.TruPakAlarmsCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.TruPakAlarmsCard__dataContainer}>
          <h1>{headingsObj.description}</h1>
          <p>{dataObj.description}</p>
        </div>
        <div className={styles.TruPakAlarmsCard__dataContainer}>
          <h1>{headingsObj.occurred}</h1>
          <p>{dataObj.occurred}</p>
        </div>
      </li>
    </ul>
  );
};

export default TruPakAlarmsCard;
