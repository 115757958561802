export const resetToHomeDefault = (
  setScannedBarcodes,
  setBarcodesToBeAssociated,
  setSite,
  setProductScanned,
  setScannedProducts,
  setStockedItems,
  setGetStockedItemsComplete,
  setStockedItemsQty,
  setInputFieldQty,
  setStockLocations,
  setSortedStockLocations,
  setStockLocationScanned,
  setProductRescanned,
  setQtyError,
  setQtyErrorArray,
  setPullStock,
  setPlaceStock,
  setScannedStockedItems,
  setScannedItemIndex,
  setScannedStockedItemsQuantities,
  setUpdatedScannedStockedItems,
  setNeededMeds,
  setEnforceInputFocus,
  setPreviousProductScanned,
  setProductAssociationFound,
  setProductAssociationNotFound,
  setProductNotFound,
  setProductBarcode,
  setAssociateBarcodeWithProduct,
  setProductsSearchText,
  setSelectedProductForAssoc,
  setUserCredentials,
  setNewAssociationComplete,
  setAuthorizationFailure
) => {
  setScannedBarcodes([]);
  setBarcodesToBeAssociated([]);
  setSite("");
  setProductScanned("");
  setScannedProducts([]);
  setStockedItems("");
  setGetStockedItemsComplete(false);
  setStockedItemsQty(0);
  setInputFieldQty(0);
  setStockLocations([]);
  setSortedStockLocations([]);
  setStockLocationScanned("");
  setProductRescanned("");
  setQtyError("");
  setQtyErrorArray([]);
  setPullStock(false);
  setPlaceStock(false);
  setScannedStockedItems([]);
  setScannedItemIndex(0);
  setScannedStockedItemsQuantities([]);
  setUpdatedScannedStockedItems([]);
  setNeededMeds([]);
  setEnforceInputFocus(true);
  setPreviousProductScanned("");
  setProductAssociationFound(false);
  setProductAssociationNotFound(true);
  setProductNotFound(false);
  setProductBarcode("");
  setAssociateBarcodeWithProduct(false);
  setProductsSearchText("");
  setSelectedProductForAssoc("");
  setUserCredentials("");
  setNewAssociationComplete(false);
  setAuthorizationFailure(false);
};

export const resetReceiveState = (
  setProductScanned,
  setScannedProducts,
  setStockedItems,
  setGetStockedItemsComplete,
  setStockedItemsQty,
  setInputFieldQty,
  setStockLocations,
  setSortedStockLocations,
  setStockLocationScanned,
  setProductRescanned,
  setQtyError,
  setQtyErrorArray,
  setPullStock,
  setPlaceStock,
  setScannedStockedItems,
  setScannedItemIndex,
  setScannedStockedItemsQuantities,
  setUpdatedScannedStockedItems,
  setNeededMeds,
  setEnforceInputFocus,
  setPreviousProductScanned,
  setProductAssociationFound,
  setProductAssociationNotFound,
  setProductNotFound,
  setProductBarcode,
  setAssociateBarcodeWithProduct,
  setProductsSearchText,
  setSelectedProductForAssoc,
  setUserCredentials,
  setNewAssociationComplete,
  setAuthorizationFailure
) => {
  setProductScanned("");
  setScannedProducts([]);
  setStockedItems("");
  setGetStockedItemsComplete(false);
  setStockedItemsQty(0);
  setInputFieldQty(0);
  setStockLocations([]);
  setSortedStockLocations([]);
  setStockLocationScanned("");
  setProductRescanned("");
  setQtyError("");
  setQtyErrorArray([]);
  setPullStock(false);
  setPlaceStock(false);
  setScannedStockedItems([]);
  setScannedItemIndex(0);
  setScannedStockedItemsQuantities([]);
  setUpdatedScannedStockedItems([]);
  setNeededMeds([]);
  setEnforceInputFocus(true);
  setPreviousProductScanned("");
  setProductAssociationFound(false);
  setProductAssociationNotFound(true);
  setProductNotFound(false);
  setProductBarcode("");
  setAssociateBarcodeWithProduct(false);
  setProductsSearchText("");
  setSelectedProductForAssoc("");
  setUserCredentials("");
  setNewAssociationComplete(false);
  setAuthorizationFailure(false);
};

export const getActionMenu = (actionListItems) => {
  let listItems = [...actionListItems];
  let index = listItems.findIndex((o) => o.type === "enable");
  listItems.splice(index, 1);
  return listItems;
};
