import StockLocationService from "../../../services/StockLocationService";

class InventoryTransferPullLocationController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  getStockLocationByBarcode = async (barcode, callback) => {
    StockLocationService.getByBarcode(this.user, this.site, barcode)
      .then((stockLocation) => {
        callback(null, stockLocation);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getStockedItems = async (stockLocation, callback) => {
    StockLocationService.getStockedItems(this.user, this.site, stockLocation)
      .then((stockedItems) => {
        callback(null, stockedItems);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export default InventoryTransferPullLocationController;
