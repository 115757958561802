import DashboardService from "../../../services/DashboardService";

class PillSortDashboardController {
  constructor() {}

  // this will return a count of products that do not have price information for
  // a given site.  If you want all sites, just leave site null
  getPillSortProductCountWithNoPrice = async (user, site, details) => {
    return await DashboardService.getPillSortProductCountWithNoPrice(
      user,
      site,
      details
    );
  };

  getProcessHistory = async (user, site, startDate, stopDate, details) => {
    return await DashboardService.getPillSortProcessHistory(
      user,
      site,
      startDate,
      stopDate,
      details
    );
  };

  // Returns the following JSON: [{errorCode:<>,description:<>,count:<>}]
  getAlarmHistory = async (user, site, startDate, stopDate, details) => {
    return await DashboardService.getPillSortAlarmHistory(
      user,
      site,
      startDate,
      stopDate,
        details
    );
  };

  getPillSortAlarmResolution = async (user, errorCode) => {
    return await DashboardService.getPillSortAlarmResolution(
        user,
        errorCode
    );
  }

  // Returns the following JSON: [{stockLocationName:<>,life:<>}]
  getSuctionCupLife = async (user, site) => {
    return await DashboardService.getSuctionCupLife(user, site);
  };

  getPillSortData = async (user, startDate, stopDate) => {
    return await DashboardService.getPillSortData(user, startDate, stopDate);
  };

  resetSuctionCupLife = async (user, stockLocation) => {
    await DashboardService.resetSuctionCupLife(user, stockLocation);
  }
}

const instance = new PillSortDashboardController();
export default instance;
