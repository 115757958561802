import React, { useEffect, useState, useContext, Fragment } from "react";
import Loader from "react-loader-spinner";
import EV54AlarmsCardList from "./MobileViews/EV54Alarms/EV54AlarmsCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./EV54AlarmsTable.module.css";

const EV54AlarmsTable = ({
  goBackHandler,
  _controllerRef,
  user,
  selectedSite,
  startDate,
  stopDate,
  windowHeight,
}) => {
  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [alarmsData, setAlarmsData] = useState([]);
  const [fetchingAlarmsData, setFetchingAlarmsData] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const loader = (
    <div className={styles.AlarmsTable__loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>Fetching data...</p>
    </div>
  );

  const actionMenu = [
    {
      type: "linkToWiki",
      description: "Link to Wiki",
    },
    {
      type: "logTicket",
      description: "Log Ticket",
    },
    {
      type: "chat",
      description: "Chat",
    },
  ];

  const handleAction = (action) => {
    switch (action.type) {
      case "linkToWiki":
        alert("Link to Wiki");
        break;
      case "logTicket":
        alert("Order");
        break;
      case "chat":
        alert("Chat");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let site;

    if (typeof selectedSite === "object") {
      site = selectedSite;
    } else {
      site = "";
    }
    setFetchingAlarmsData(true);

    _controllerRef.current
      .getEV54AlarmHistory(user, site, startDate, stopDate, true)
      .then((results) => {
        // console.log("results: ", results);
        setAlarmsData(results);
        setFetchingAlarmsData(false);
      })
      .catch((error) => {
        setFetchingAlarmsData(false);
        console.log(error);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    (async () => {
      const tempItems = [];

      try {
        alarmsData.forEach((tableRow, index) => {
          const dateString = tableRow.occurredAt;
          const month = dateString.substring(5, 7);
          const day = dateString.substring(8, 10);
          const year = dateString.substring(0, 4);
          const time = dateString.substring(11, 19);
          const actions = (
            <div
              className={styles.EV54AlarmsTable__actionsContainer}
              onClick={(e) => {
                if (!showPopup) {
                  setShowPopup(true);
                  setPopupIndex(index);
                }
              }}
            >
              {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
              {showPopup && popupIndex === index && (
                <Popup
                  menuItems={actionMenu}
                  onAction={(action) => {
                    setShowPopup(null);
                    setPopupIndex(-1);
                    handleAction(action);
                  }}
                  onClose={() => setShowPopup(null)}
                  rightJustify={true}
                />
              )}
            </div>
          );
          tempItems.push([
            tableRow.error,
            `${month}/${day}/${year}, ${time}`,
            actions,
          ]);
        });
        setItems(tempItems);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [showPopup, popupIndex, alarmsData]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [
    "Description",
    "Occurred",
    <div className={styles.EV54AlarmsTable__actionsHeadingContainer}>
      Actions
    </div>,
  ];

  const DesktopView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.EV54AlarmsTable__header}>
        <h1>EV-54 Alarms</h1>
        <p>Service: +1 262-729-9202</p>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.EV54AlarmsTable__mainContainer}
      >
        <div className={styles.EV54AlarmsTable__tableContainer}>
          <Table
            padScrollBar={true}
            headers={headers}
            records={items}
            showPopup={showPopup}
            popupIndex={popupIndex}
            tableRowHeight={37}
            scrollAdjustConstant={160}
          />
        </div>
        <div className={styles.EV54AlarmsTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName="Back"
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.EV54AlarmsTable__mainContainer}>
        <div className={styles.EV54AlarmsTable__header}>
          <div className={styles.EV54AlarmsTable__backButton}>
            <img src={backIcon} onClick={goBackHandler} />
          </div>
          <p className={styles.EV54AlarmsTable__reportTitle}>Alarms</p>
        </div>
        {
          <div className={styles.EV54AlarmsTable__cardListContainer}>
            <EV54AlarmsCardList
              headings={headers}
              data={items}
              fetchingAlarmsData={fetchingAlarmsData}
            />
          </div>
        }
      </div>
    </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default EV54AlarmsTable;
