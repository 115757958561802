import React, {useEffect, useState} from 'react';
import {Button, Flex, Space, Table, Tooltip} from 'antd';
import StockLocationService from "../../../services/StockLocationService";

const Alarms = ({user, stockLocation, onAlarms}) => {

    const [alarms, setAlarms] = useState([]);

    useEffect(() => {
        StockLocationService.getAlarms(user, stockLocation).then((alarms)=>{
            setAlarms(alarms);
            
        })
    }, [stockLocation]);

    const columns = [
        {
            title: "Alarm",
            dataIndex: "_id",
            render: (text, alarm) => (
                <p>{alarm.description}</p>
            ),
        },
        {
            title: "Date",
            dataIndex: "_id",
            render: (text, alarm) => {
                const dt = new Date(alarm.occurredAt);
                const dStr = `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')}`;
                return (
                    <p>{dStr}</p>
                )
            }
        },
        {
            title: "Time",
            dataIndex: "_id",
            render: (text, alarm) => {
                const dt = new Date(alarm.occurredAt);
                const tStr = `${dt.getHours().toString().padStart(2,'0')}:${dt.getMinutes().toString().padStart(2,'0')}`;
                return (
                    <p>{tStr}</p>
                )
            }
        }

        // {
        //     title: "",
        //     key: "action",
        //     render: (_, product) => (
        //         <Tooltip title={"Remove product"}>
        //             <Button danger size={"small"} onClick={() => removeProduct(product)}>
        //                 <DeleteOutlined />
        //             </Button>
        //         </Tooltip>
        //     ),
        // },
    ];

    return (
        <Space
            size="large"
            direction="vertical"
            style={{ width: "100%" }}
        >
            <Flex gap={"middle"}>
                <Table columns={columns} dataSource={alarms} />
            </Flex>
        </Space>
    )
}

export default Alarms;