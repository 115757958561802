import React, {useEffect, useRef, useState} from 'react';
import Table from "../../../../components/Table/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import style from './EV54Alarms.module.css';

const EV54Alarms = ({user, controllerRef}) => {

    const [activeAlarms, setActiveAlarms] = useState([]);
    const [alarmRecs, setAlarmRecs] = useState([]);
    const [selectedAlarms, setSelectedAlarms] = useState([]);
    const mounted = useRef(false);

    useEffect(()=>{
        mounted.current = true;
        controllerRef.current.addActiveAlarmsCallback(handleAlarm);

        // setIsFetching(true)
        // contRef.current.getInventory(device, handleInventoryRecords)
        // const timerInterval = setInterval(handleTick, 60000);
        return () => {
            mounted.current = false;
            controllerRef.current.removeActiveAlarmsCallback(handleAlarm);
        }
    }, [user, controllerRef])

    const handleAlarm = (_) => {
        if(mounted.current){
            const aa = controllerRef.current.getActiveAlarms();
            const tempArr = [];
            aa.forEach(alarm=>{
                const canisterNo = alarm.canisterNo ? alarm.canisterNo.toString() : "";
                const error = canisterNo ? `${alarm.error} - Canister ${canisterNo}` : alarm.error;
                const date = new Date(alarm.createdAt).toLocaleDateString();
                const time = new Date(alarm.createdAt).toLocaleTimeString();
                tempArr.push([alarm, error, date, time]);
            })
            setAlarmRecs(tempArr);
            setActiveAlarms(aa);
        }

    }

    const acknowledgeAlarms = () => {
        controllerRef.current.acknowledgeAlarms(selectedAlarms);
        setSelectedAlarms([]);
    }

    return(
        <div className={style.EV54Alarms_container}>
            {selectedAlarms.length > 0 &&
            <>
                <div className={style.EV54Alarms_row} onClick={acknowledgeAlarms}>
                    <FontAwesomeIcon icon={faTrashCan} color={"#089bab"}/>
                    <p>Acknowledge</p>
                </div>
                <br/>
            </>
            }
            <Table
                id='ev54AlarmsTable'
                headers={["_selectedObject","Alarm", "Date", "Time"]}
                records={ alarmRecs }
                onClickHandler={(alarms)=>{
                    setSelectedAlarms(alarms);
                }}

                // })} {[["System Offline", "4/12/2022", "3:00pm", "Critical", "Establish network connection"]]}
            />
        </div>
    )
}

export default EV54Alarms;
