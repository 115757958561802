import React, { useEffect, useRef } from "react";

const Input = ({
  onChangeHandler,
  onKeyUpHandler,
  value,
  placeHolder,
  enforceInputFocus,
  setEnforceInputFocus,
  className,
  errorMsg,
}) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (enforceInputFocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, [enforceInputFocus]);

  const onBlurHandler = () => {
    if (enforceInputFocus) {
      inputElement.current.focus();
    }
  };

  const onClickHandler = () => {
    setEnforceInputFocus(true);
    inputElement.current.focus();
  };

  return (
    <input
      autoComplete="off"
      ref={inputElement}
      className={className}
      onClick={onClickHandler}
      onChange={onChangeHandler}
      onKeyUp={onKeyUpHandler}
      onBlur={onBlurHandler}
      value={value}
      type="text"
      placeholder={placeHolder}
      disabled={errorMsg}
    />
  );
};

export default Input;
