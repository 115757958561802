import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import * as React from "react";
import "../SFDropDownList/SFDropDownList.css";
import "./MultiSelectCheckbox.css";

// EXAMPLE OF USE

// checkboxId = "checkbox1"

// dataSource = [{ id: "device_id1", device: "device1" }, { id: "device_id2", device: "device2" }]

// fields = { value: "id", text: "device" }

// placeHolder="Select device(s)"

// onSelections = (arr) => setSelectedDevices(arr);

/****************************
<MultiSelectCheckbox
  checkboxId="checkbox1"
  dataSource={devices}
  fields={{ text: "device", value: "id" }}
  onSelections={handleSelectedDevices}
/>;
****************************/

const MultiSelectCheckbox = ({
  checkboxId,
  dataSource,
  fields,
  placeHolder,
  value,
  onSelections,
}) => {
  const handleSelections = (args) => {
    onSelections(args.value);
  };
  return (
    // specifies the tag for render the MultiSelect component
    <MultiSelectComponent
      id={checkboxId}
      dataSource={dataSource}
      fields={fields}
      placeholder={placeHolder}
      value={value || []}
      mode="CheckBox"
      selectAllText="Select All"
      unSelectAllText="unSelect All"
      showSelectAll={true}
      change={handleSelections}
    >
      <Inject services={[CheckBoxSelection]} />
    </MultiSelectComponent>
  );
};
export default MultiSelectCheckbox;
