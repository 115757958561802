import {io} from "socket.io-client";

class EV54DeviceController {

    constructor(user, device) {
        this.user = user;
        this.device = device;
        this.inventory = [];
        this.callbacks = [];
        this.transactionCallbacks = [];
        this.activeAlarmsCallback = [];
        this.activeAlarms = [];
        this.replenishmentNeedsCallbacks = [];
        this.replenishmentNeeds = [];
        this.ordersCount = 0;
        this.packagedItemCallbacks = [];
        this.currentStockNeeds = [];
        this.currentStockNeedsCallbacks = [];
        //this.tabClickCallback = null;
        this._containerLockState = new Array(55).fill(0);
    }

    async getRecords(accountId, siteId, stockLocationId) {

    }

    initializeSocket(commandCompleteCallback) {
        this.callbacks.push(commandCompleteCallback);
        const BASE_URL = process.env.REACT_APP_HOST || "";
        this.socket = io(`${BASE_URL}/ev54-client`);
        this.socket.on("connect", () => {
            console.log(
                `EV54DeviceController Client Socket ${this.socket.id}  has connected`
            );

            // let the other end know what to query for
            this.socket.emit('routing-info', {
                account: this.user.account,
                site: this.device.site,
                stockLocation: this.device
            });

            this.socket.emit('get-active-alarms');
        });
        this.socket.on("error", (err) => {
            console.log(
                'EV54DeviceController Client Socket error',err
            );
        })
        this.socket.on("disconnect", () => {
            console.log(`EV54DeviceController Client Socket has disconnected`);
            this.socket = null;
        });
        this.socket.on('command-complete', this._handleCommandComplete);
        this.socket.on('transaction-update', this._handleTransactionUpdate);
        this.socket.on('active-alarm', this._handleActiveAlarm);
        this.socket.on('replenishment-needs', this._handleReplenishmentNeeds);
        this.socket.on('packaged-items', this._handlePackagedItems);
        this.socket.on('canister-for-product', this._handleCanisterForProduct);
        this.socket.on('current-stock-needs', this._handleCurrentStockNeeds);
        this.socket.on("manual-dispense-trx", this._handleManualDispenseTransaction);
    }

    async lockAll(){
        if(this.socket && this._containerLockState.some(o=>o === 1)){
            const containers = []
            for(let i = 0; i < this._containerLockState.length; i++){
                if(this._containerLockState[i] === 1){
                    containers.push(i);
                }
            }
            await this.sendCanisterAndChuteCommand(this.user, this.device, containers, true);
        }
    }

    async releaseSocket() {
        if (this.socket) {
            if(this._containerLockState.some(o=>o === 1)){
                const containers = []
                for(let i = 0; i < this._containerLockState.length; i++){
                    if(this._containerLockState[i] === 1){
                        containers.push(i);
                    }
                }
                await this.sendCanisterAndChuteCommand(this.user, this.device, containers, true);
            }
            await this.socket.disconnect();
        }
    }

    _handleReplenishmentNeeds = (needs) => {
        this.replenishmentNeeds = needs;
        this.replenishmentNeedsCallbacks.forEach(callback=>{
            callback(needs);
        });
    }

    _handleCurrentStockNeeds = (needs) => {
        this.currentStockNeeds = needs;
        this.currentStockNeedsCallbacks.forEach(callback=>{
            callback(needs);
        });
    }

    getCurrentStockFromServer = (device) => {
        const data = {
            account: this.user.account,
            site: this.device.site,
            stockLocation: device,
        }
        this.socket.emit('get-current-stock', data);
    }

    getReplenishmentNeeds = (cb) => {
        if(cb){
            cb(this.replenishmentNeeds);
        }
    }

    getCurrentStock = (cb) => {
        try {
            if (cb) {
                cb(this.currentStockNeeds);
            }
        } catch (e) {
        }
    }

    zeroInventory = (stockLocation) => {
        /*
        const canisterNeed = {
                stockLocation: canister,
                qtyStocked: totalQty,
                stockedItems: stockedItems,
                product: canister.productAssociations[0].product,
                totalQty: totalQty,
                qtyNeeded: n,
            }
         */
        const currentNeed = this.currentStockNeeds.find(o=>o.stockLocation._id === stockLocation._id);
        if(currentNeed) {
          currentNeed.stockItems = [];
          currentNeed.totalQty = 0;
            this.currentStockNeedsCallbacks.forEach(callback=>{
                callback(this.currentStockNeeds);
            });
        }
    }

    _handleActiveAlarm = (alarm) => {
        if(!this.activeAlarms.some(a=>a._id === alarm._id)){
            this.activeAlarms.push(alarm);
            this.activeAlarmsCallback.forEach(cb=>{
                cb(alarm);
            })
        }
    }



    setOrdersCount = (count) => {
        this.ordersCount = count;
    }

    getActiveAlarmCount = () => {
        return this.activeAlarms.length;
    }

    getOrdersCount = () => {
        return this.ordersCount;
    }

    getActiveAlarms = () => {
        return this.activeAlarms.sort((a,b)=>{
            if(a.createdAt < b.createdAt){return 1}
            if(b.createdAt < a.createdAt){return -1}
            return 0;
        });
    }

    clearReplenishmentNeed = (need) => {
        const index = this.replenishmentNeeds.findIndex(o=>o.stockLocation._id === need.stockLocation._id);
        this.replenishmentNeeds.splice(index, 1);
        for(let i = 0; i < this.replenishmentNeedsCallbacks.length; i++){
            this.replenishmentNeedsCallbacks[i](this.replenishmentNeeds);
        }
    }

    getReplenishmentNeedsCount = () => {
        return this.replenishmentNeeds.length;
    }

    addReplenishmentNeedsCallback = (callback, stockNeedsCallback) => {
        callback(this.replenishmentNeeds);
        this.replenishmentNeedsCallbacks.push(callback);

        if(stockNeedsCallback){
            stockNeedsCallback(this.currentStockNeeds);// init client
            this.currentStockNeedsCallbacks.push(stockNeedsCallback);
        }
    }

    removeReplenishmentNeedsCallback = (callback, stockNeedsCallback) => {
        let index = this.replenishmentNeedsCallbacks.findIndex(callback);
        this.replenishmentNeedsCallbacks.splice(index, 1);

        if(stockNeedsCallback){
            index = this.currentStockNeedsCallbacks.findIndex(stockNeedsCallback);
            this.currentStockNeedsCallbacks.splice(index, 1);
        }

    }

    addPackagedItemCallback = (callback) => {
        this.packagedItemCallbacks.push(callback);
    }

    removePackagedItemCallback = (callback) => {
        const index = this.packagedItemCallbacks.findIndex(callback);
        this.packagedItemCallbacks.splice(index, 1);
    }

    addActiveAlarmsCallback = (callback) => {
        callback(); // init client
        this.activeAlarmsCallback.push(callback);
    }

    removeActiveAlarmsCallback = (callback) => {
        const index = this.activeAlarmsCallback.findIndex(callback);
        this.activeAlarmsCallback.splice(index, 1);
    }

    addTransactionCallback = (callback) => {
        this.transactionCallbacks.push(callback);
    }

    removeTransactionCallback = (callback) => {
        const index = this.transactionCallbacks.findIndex(callback);
        this.transactionCallbacks.splice(index, 1);
    }

    addCommandCompleteCallback = (callback) => {
        this.callbacks.push(callback);
    }

    removeCommandCompleteCallback = (callback) => {
        const index = this.callbacks.findIndex(callback);
        this.callbacks.splice(index, 1);
    }

    _handleCommandComplete = (ev54Comand) => {
        this.callbacks.forEach(callback=>{
            callback(ev54Comand);
        });
    }

    _handleTransactionUpdate = (trx) => {
        this.transactionCallbacks.forEach(callback=>{
            callback(trx);
        });
    }

    sendInitializeCommand = (user, device) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'initialize'
        }
        this.socket.emit('initialize-command', data);
    }

    sendStartCommand = (user, device) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'start'
        }
        this.socket.emit('start-command', data);
    }

    sendResetCommand = (user, device) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'reset'
        }
        this.socket.emit('reset-command', data);
    }

    sendCanisterAndChuteCommand = (user, device, containers, lock, serialNo) => {
        if(lock){
            for(let i = 0; i < containers.length; i++){
                this._containerLockState[containers[i]] = 0; // locked
            }
        } else {
            for(let i = 0; i < containers.length; i++){
                this._containerLockState[containers[i]] = 1; // unlocked
            }
        }

        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: lock ? 'lock-canister-and-chute' : 'unlock-canister-and-chute',
            request: {
                serialNo: containers.length > 1 ? 0 : serialNo ? serialNo : 1,
                startCanisterNo: containers[0],
                endCanisterNo: containers.length > 1 ? containers[containers.length - 1] : containers[0]
            }
        }
        this.socket.emit('canister-and-chute-command', data);
    }

    sendReleaseCanisterWithTimerCommand = (containers) => {
        const data = {
            account: this.user.account,
            site: this.device.site,
            stockLocation: this.device,
            commandName: 'release-canister-with-timer',
            request: {
                startCanisterNo: containers[0],
                endCanisterNo: containers.length > 1 ? containers[containers.length - 1] : containers[0]
            }
        }
        this.socket.emit('release-canister-with-timer-command', data);
    }

    sendUnlockCanisterCommand = (containers, serialNo) => {
        const data = {
            account: this.user.account,
            site: this.device.site,
            stockLocation: this.device,
            commandName: 'unlock-canister',
            request: {
                startCanisterNo: containers[0],
                endCanisterNo: containers.length > 1 ? containers[containers.length - 1] : containers[0]
            }

        }

        for(let i = 0; i < containers.length; i++){
            this._containerLockState[containers[i]] = 1; // unlocked
        }

        if(serialNo){
            data.request.serialNo = serialNo;
        }
        this.socket.emit('unlock-canister-command', data);
    }

    sendLockCanisterCommand = (containers, serialNo) => {
        const data = {
            account: this.user.account,
            site: this.device.site,
            stockLocation: this.device,
            commandName: 'lock-canister',
            request: {
                startCanisterNo: containers[0],
                endCanisterNo: containers.length > 1 ? containers[containers.length - 1] : containers[0]
            }

        }

        for(let i = 0; i < containers.length; i++){
            this._containerLockState[containers[i]] = 0; // locked
        }

        if(serialNo){
            data.request.serialNo = serialNo;
        }

        this.socket.emit('lock-canister-command', data);
    }

    sendStatusCommand = (user, device, serialNo) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'status',
            request: {
                serialNo: serialNo
            }
        }
        this.socket.emit('status-command', data);
    }

    sendFillCommand = (user, device, rxNumber, canisterNo, quantity, isAudit, serialNo) => {
        const data = {
            isAudit: isAudit || false,
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'fill',
            request: {
                canisterNo: canisterNo,
                quantity: quantity,
                rxNumber: rxNumber
            }

        }
        if(serialNo){
            data.request.serialNo = serialNo;
        }
        this.socket.emit('fill-command', data);
    }

    sendCancelFillCommand = (user, device, rxNumber, serialNo, canisterNo) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'cancelFill',
            request: {
                rxNumber: rxNumber,
                serialNo: serialNo,
                canisterNo: canisterNo
            }
        }
        this.socket.emit('cancel-fill-command', data);
    }

    sendResumeFillCommand = (user, device, rxNumber, serialNo, canisterNo) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'resumeFill',
            request: {
                rxNumber: rxNumber,
                serialNo: serialNo,
                canisterNo: canisterNo
            }
        }
        this.socket.emit('resume-fill-command', data);
    }

    sendDispenseCommand = (user, device, rxNumber, serialNo, canisterNo) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'dispense',
            request: {
                rxNumber: rxNumber,
                serialNo: serialNo,
                canisterNo: canisterNo
            }
        }
        this.socket.emit('dispense-command', data);
    }

    sendCancelDispenseCommand = (user, device, rxNumber, serialNo, canisterNo) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            commandName: 'cancelDispense',
            request: {
                rxNumber: rxNumber,
                serialNo: serialNo,
                canisterNo: canisterNo
            }
        }
        this.socket.emit('cancel-dispense-command', data);
    }

    acknowledgeAlarms = (selectedAlarms) => {
        const data = {
            user: this.user,
            alarms: selectedAlarms
        }

        selectedAlarms.forEach(alarm=> {
            const index = this.activeAlarms.find(a => a._id === alarm._id);
            this.activeAlarms.splice(index, 1);
        })

        this.activeAlarmsCallback.forEach(cb=>{
            cb();
        })

        this.socket.emit('acknowledge-alarms', data);

    }

    updateProduct = (product) => {
        const data = {
            product: product
        }
        this.socket.emit('update-product', data);
    }

    getPackagedItemByRxNumber = (user, device, rxNumber) => {
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            rxNumber: rxNumber
        }
        this.socket.emit('get-packaged-item', data);
    }


    _handlePackagedItems = (data) => {
        this.packagedItemCallbacks.forEach(callback=>{
            callback(data.packagedItems);
        });
    }

    getCanisterForProduct = (user, device, product, cb) => {
        this.canisterForProductCallback = cb;
        const data = {
            account: user.account,
            site: device.site,
            stockLocation: device,
            product: product
        }
        this.socket.emit('get-canister-for-product', data);
    }

    _handleCanisterForProduct = (data) => {
        if(this.canisterForProductCallback){
            this.canisterForProductCallback(data.canister);
        }
    }

    returnToStock = (user, device, canister, packagedItem) => {
        const data = {
            user: user,
            account: user.account,
            site: device.site,
            stockLocation: device,
            canister: canister,
            packagedItem: packagedItem
        }
        this.socket.emit('return-to-stock', data);
    }

    sortTransactions = (account, site, device, rxNumbers) => {
        const data = {
            account: account,
            site: site,
            stockLocation: device,
            rxNumbers: rxNumbers
        }
        this.socket.emit('sort-transactions', data);
    }

    completeTransaction = (account, site, device, rxNumber, dispenseQty, canisterNo, user) => {
        const data = {
            account: account,
            site: site,
            stockLocation: device,
            rxNumber: rxNumber,
            dispenseQty: dispenseQty,
            canisterNo: canisterNo,
            user: user
        }

        this.socket.emit('complete-transaction', data);
    }

    getExistingManualTransaction = (user, device, cb) => {
        this.manualTransactionCallback = cb;
        const data = {
            account: user.account,
            stockLocation: device,
        }
        this.socket.emit("get-manual-dispense-trx", data);
    }

    _handleManualDispenseTransaction = (trx) => {
        this.manualTransactionCallback(trx);
    }
}

export default EV54DeviceController;
