import React, { useEffect, useRef, useContext } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import ListBox from "../../components/ListBox";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import ProductService from "../../services/ProductService";

const CreateProductBarcodesAndOtherIDs = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onError,
  onAddImage,
  onDeleteImage,
    user
}) => {
  const { t } = useTranslation("createProduct");
  const productControllerRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  const barcodeExists = (barcode) => {
    ProductService.getProductByBarcode(user, barcode, false).then(product => {
      if(product && product.packageId !== p?.packageId){
        onError(`${barcode} is already associated with ${productControllerRef.current.getDefaultName(product)}`)
      } else {
        let data = p?.barcodeList;
        if (!data) {
          data = [barcode];
        } else {
          data = [...p?.barcodeList, barcode];
        }
        onPropertyChange({ barcodeList: data });
      }
    })
  };

  return (
    <Accordion title={"Barcodes and Other IDs"}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <ListBox
            title={"Barcodes"}
            listItems={
              p?.barcodeList
                ? p?.barcodeList.sort((a, b) => {
                    if (a < b) return -1;
                    if (b < a) return 1;
                    return 0;
                  })
                : []
            }
            onAdd={(listItem) => {
              // let data = p?.barcodeList;
              // if (!data) {
              //   data = [listItem];
              // } else {
              //   data = [...p?.barcodeList, listItem];
              // }
              // onPropertyChange({ barcodeList: data });
              barcodeExists(listItem)
            }}
            onRemove={(listItem) => {
              const list = [...p?.barcodeList];
              const index = p?.barcodeList.findIndex((o) => o === listItem);
              list.splice(index, 1);
              onPropertyChange({ barcodeList: list });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <ListBox
            title={"Other IDs"}
            listItems={
              p?.otherIds
                ? p?.otherIds.sort((a, b) => {
                    if (a < b) return -1;
                    if (b < a) return 1;
                    return 0;
                  })
                : []
            }
            onAdd={(listItem) => {
              let data = p?.otherIds;
              if (!data) {
                data = [listItem];
              } else {
                data = [...p?.otherIds, listItem];
              }
              onPropertyChange({ otherIds: data });
            }}
            onEdit={(oldListItem, listItem) => {
              const list = [...p?.otherIds];
              const index = p?.otherIds.findIndex((o) => o === oldListItem);
              list[index] = listItem;
              onPropertyChange({ otherIds: list });
            }}
            onRemove={(listItem) => {
              const list = [...p?.otherIds];
              const index = p?.otherIds.findIndex((o) => o === listItem);
              list.splice(index, 1);
              onPropertyChange({ otherIds: list });
            }}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductBarcodesAndOtherIDs;
