import React from "react";
import styles from "./PickListMainHeadingByProduct.module.css";

const PickListMainHeadingByProduct = () => {
  return (
    <div className={styles.pickList__mainHeading}>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item1"],
        ].join(" ")}
      >
        Package ID
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item2"],
        ].join(" ")}
      >
        Description
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item3"],
        ].join(" ")}
      >
        Lot
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item4"],
        ].join(" ")}
      >
        Exp
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item5"],
        ].join(" ")}
      >
        Need
      </p>
    </div>
  );
};

export default PickListMainHeadingByProduct;
