import React, { useState } from "react";
import Loader from "react-loader-spinner";
import Button from "../../../../components/Button";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";
import styles from "./VideoView.module.css";

const VideoView = ({
  onPageTitle,
  onShowHeaderUtilities,
  videoUrl,
  width = "100%",
  onSelectedCardIndex,
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const loader = (
    <div className={styles.videoView__loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>Loading video...</p>
    </div>
  );

  const handleVideoReady = () => {
    console.log("video is loaded");
    setVideoLoaded(true);
  };

  const handleGoBackToVideoList = () => {
    onSelectedCardIndex(null);
    setVideoLoaded(false);
    onPageTitle("Help Videos");
    onShowHeaderUtilities(true);
  };

  return (
    <div className={styles.videoView__videoPage}>
      <div className={styles.videoView__videoPlayerContainer}>
        {!videoLoaded && loader}
        <VideoPlayer
          url={videoUrl}
          width={width}
          height="100%"
          playing
          onReady={handleVideoReady}
        />
      </div>
      <div className={styles.videoView__buttonGroup}>
        <Button
          onClick={handleGoBackToVideoList}
          labelName="Back"
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
      </div>
    </div>
  );
};

export default VideoView;
