import React, { useState, useEffect, useRef } from "react";
import backgroundView from "../../assets/background-log-in.jpg";
import heartIcon from "../../assets/heart-icon.svg";
import Spacer from "../../components/Spacer";
import TextInput from "../../components/TextInput";
import validate from "../../validation/LogInValidationRules";
import AuthService from "../../services/AuthService";
import ErrorBanner from "../../components/ErrorBanner";
import { useTranslation } from "react-i18next";
import { translateError } from "../../utils/functions/translateError";
import { useKeyPress } from "../../components/InputHooks";
import MediaQuery from "react-responsive";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import ConfigService from "../../services/ConfigService";
import DropdownInput from "../../components/DropDownInput/DropdownInput";

const LogInView = ({ onAuthenticated }) => {
  const navigate = useNavigate();
  // const _controllerRef = useRef(dashboardController);

  //region css
  const containerStyle = {
    backgroundImage: `url(${backgroundView})`,
    backgroundPosition: "center",
    backgroundRepeat: "norepeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: 0,
    width: "100vw",
    height: "100vh",
    minHeight: "1024px",
    overflow: "hidden",
  };
  const cardContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderBottomLeftRadius: "70px",
    borderTopLeftRadius: "70px",
    width: "610px",
    // minWidth: '610px',
    height: "100%",
  };
  const cardBodyStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "80%",
    height: "100%",
  };
  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "60px",
  };
  const logoTextStyle = {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "38px",
    lineHeight: "57px",
    letterSpacing: "0.5px",
    color: "#778CA2",
  };
  const logoTextStyleBold = {
    fontWeight: 600,
  };
  const titleStyle = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "42px",
    letterSpacing: "0.7px",
    color: "#089BAB",
  };
  const linkButtonStyle = {
    // display: "flex",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#089BAB",
    cursor: "pointer",
    background: "none",
    border: "none",
    outline: "none",
    margin: 0,
    textAlign: "left",
    // width: "100%",
  };
  const logInButtonStyle = {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    backgroundColor: "#089BAB",
    color: "white",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    width: "100%",
    height: "48px",
  };
  //endregion

  const { t, i18n } = useTranslation("logIn");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [language, setLanguage] = useState(null);
  const [langList, setLangList] = useState([]);

  const enterPressed = useKeyPress("Enter");

  useEffect(() => {
    sessionStorage.setItem("loginLanguage", "");
    ConfigService.languages()
      .then((languages) => {
        setLangList(languages);
        var storedLanguage = localStorage.getItem("loginLanguage") || i18n.language || "en";
        setLanguage(languages.find((lang) => lang.type === storedLanguage));
        i18n.changeLanguage(storedLanguage)
      })
      .catch((error) => setError(error));
  }, []); 

  useEffect(() => {
    if (enterPressed) {
      handleLogIn();
    }
  }, [enterPressed]);

  const handleLogIn = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmitting(true);
    let validationErrors = validate({
      username: username,
      password: password,
    });

    setErrors(validationErrors);
  };
  
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      // todo login

      AuthService.logIn({
        username: username,
        password: password,
      })
        .then((userDoc) => {
          if (!sessionStorage.getItem("loginLanguage") && userDoc.language) {
            i18n.changeLanguage(userDoc.language.type, () => {
              console.log(
                "The language has been changed to ",
                userDoc.language.description
              );
            });
          } else if (!sessionStorage.getItem("loginLanguage")) {
            i18n.changeLanguage("en", () => {
              console.log("The language is defaulting to English");
            });
          }
          // navigate("/",{state: {
          //     user: userDoc
          // }});
          onAuthenticated(userDoc);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [errors]);

  return (
    <>
      <MediaQuery minWidth={641}>
        <div style={containerStyle}>
          <aside style={cardContainerStyle}>
            <section style={cardBodyStyle}>
              <header style={headerStyle}>
                {/*<img src={heartIcon} alt={"logo"} />*/}
                {/*<Spacer space={14} unit={"px"} />*/}
                {/*<p style={logoTextStyle}>*/}
                {/*  <b style={logoTextStyleBold}>Intel</b>Rx*/}
                {/*</p>*/}
              </header>
              
              {error && (
                <>
                  <Spacer space={2} unit={"%"} />
                  <ErrorBanner message={translateError(error)} onClose={() => setError(null)} />
                  <Spacer space={2} unit={"%"} />
                </>
              )}
              {!error && <Spacer space={10} unit={"%"} />}
              
              
              <p style={titleStyle}>{t("title")}</p>


              <form className={styles.Login__form}>
                <TextInput
                  focus={true}
                  isEnabled={true}
                  labelName={t("usernameLabel")}
                  onChange={(value) => {
                    setUsername(value);
                  }}
                  placeholder={""}
                  type={"text"}
                  validationText={t(errors.username, {ns: "validation"})}
                  value={username}
                />
                <TextInput
                  autoComplete="off"
                  focus={false}
                  isEnabled={true}
                  labelName={t("passwordLabel")}
                  onChange={(value) => {
                    setPassword(value);
                  }}
                  placeholder={""}
                  type={"password"}
                  validationText={t(errors.password, {ns: "validation"})}
                  value={password}
                />
                <Spacer space={10} unit={"px"} />
                <a
                  style={linkButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/reset-psw");
                  }}
                >
                  {t("forgotPasswordText")}
                </a>
                <Spacer space={7} unit={"%"} />
                <button style={logInButtonStyle} onClick={handleLogIn}>
                  {t("buttonLabel")}
                </button>
                <Spacer space={7} unit={"%"} />
                
                <DropdownInput
                  value={language?.description || ""}
                  validationText={""}
                  isEnabled={true}
                  placeholder={""}
                  listElements={langList}
                  labelName={t("languageLabel")}
                  onSelection={(listItem) => {
                    localStorage.setItem("loginLanguage", listItem.type);
                    sessionStorage.setItem("loginLanguage", listItem.type);
                    i18n.changeLanguage(listItem.type);
                    setLanguage(listItem);
                  }}
                />
                <Spacer space={7} unit={"%"} />
                {/*<a*/}
                {/*  style={linkButtonStyle}*/}
                {/*  onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    navigate("/account-setup");*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {t("createAccountText")}*/}
                {/*</a>*/}
              </form>
            </section>
          </aside>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={640}>
        <div className={styles.Login__view}>
          <div className={styles.Login__header}>
            <img src={backgroundView} alt={"pharmacist log in"} />
            <div className={styles.Login__logo}>
              {/*<img src={heartIcon} alt={"logo"} />*/}
              {/*<Spacer space={14} unit={"px"} />*/}
              {/*<p>*/}
              {/*  <b>Intel</b>Rx*/}
              {/*</p>*/}
            </div>
          </div>
          <div className={styles.Login__body}>
            {error && (
              <>
                <ErrorBanner message={translateError(error)} onClose={() => setError(null)} />
                <Spacer space={2} unit={"%"} />
              </>
            )}
            <h1>{t("title")}</h1>
            <form className={styles.Login__form}>
              {" "}
              <TextInput
                focus={true}
                isEnabled={true}
                labelName={t("usernameLabel")}
                onChange={(value) => {
                  setUsername(value);
                }}
                placeholder={""}
                type={"text"}
                validationText={t(errors.username, {ns: "validation"})}
                value={username}
              />
              <TextInput
                autoComplete="off"
                focus={false}
                isEnabled={true}
                labelName={t("passwordLabel")}
                onChange={(value) => {
                  setPassword(value);
                }}
                placeholder={""}
                type={"password"}
                validationText={t(errors.password, {ns: "validation"})}
                value={password}
              />
            </form>
          </div>
          <div className={styles.Login__footer}>
            <button onClick={handleLogIn}>{t("buttonLabel")}</button>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default LogInView;
