import React from "react";

const TabularInfo = ({
  data,
  tableClassName,
  labelCellClassName,
  dataCellClassName,
}) => {
  return (
    <table className={tableClassName}>
      <tbody>
        {data.map((row) => (
          <tr key={row.key}>
            <td className={labelCellClassName}>{row.label}:</td>
            <td className={dataCellClassName}>{row.data}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TabularInfo;
