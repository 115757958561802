import React, { useEffect, useState } from "react";
import { notification } from "antd";
import styles from "./TrayItem.module.css";

const TrayItem = ({
  item,
  onClick,
  partialView,
  controlView,
  selectedDrug,
  totalQtyView,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [isCorrectedApproved, setIsCorrectedApproved] = useState(false);

  useEffect(() => {
    if (totalQtyView) {
      let newQty = 0;
      if (item.drugs) {
        item.drugs.forEach((drug) => (newQty += drug.quantity));
      }
      setQuantity(newQty);
      setIsCorrectedApproved(false);
    } else {
      if (item.drugs) {
        const foundItem = item.drugs.find(
          (drug) =>
            drug.product._id === selectedDrug.product._id &&
            drug.partial === selectedDrug.partial
        );
        if (foundItem) {
          setQuantity(
            partialView
              ? `${foundItem.quantity > 1 ? `${foundItem.quantity} ` : ""}${
                  foundItem.partial
                }`
              : foundItem.quantity
          );
          if (foundItem.approvalType === "corrected approved")
            setIsCorrectedApproved(true);
          else setIsCorrectedApproved(false);
        } else {
          setQuantity(0);
          setIsCorrectedApproved(false);
        }
      } else {
        setQuantity(0);
        setIsCorrectedApproved(false);
      }
    }
  }, [item.drugs, partialView, controlView, totalQtyView, selectedDrug]);

  const handleClick = () => {
    if (!item.isEmpty) onClick(item);
    else notification.info({ message: "Empty slot" });
  };

  return (
    <td
      rowSpan={1}
      onClick={handleClick}
      key={item.slotNumber}
      className={styles.TrayGridCell}
    >
      <div
        className={
          isCorrectedApproved ? styles.CorrectedTrayItem : styles.TrayItem
        }
      >
        <div className={styles.TrayItemNumber}>{item.slotNumber}</div>
        <div
          className={
            totalQtyView
              ? styles.TrayItemTotalQty
              : quantity === 0 || quantity === "0"
              ? styles.TrayItemQuantityZero
              : partialView
              ? styles.TrayItemPartialQuantity
              : controlView
              ? styles.TrayItemControlQuantity
              : quantity > 1
              ? styles.TrayItemTotalQty
              : styles.TrayItemQuantity
          }
        >
          {quantity}
        </div>
      </div>
    </td>
  );
};

export default TrayItem;

