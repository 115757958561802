import { Col, Row, Spin, Switch, message } from "antd";
import React, { useState } from "react";
import AccountService from "../../services/AccountService";
import "./AccountSettings.module.css";

const AccountSettings = ({ user, site }) => {
  const [productApprovalRequired, setProductApprovalRequired] = useState(
    user.account.productApprovalRequired
  );
  const [loading, setLoading] = useState(false);

  const onToggle = async (checked) => {
    setProductApprovalRequired(checked);
    setProductApprovalRequired(checked);
    try {
      user.account.productApprovalRequired = checked;
      setLoading(true);
      await AccountService.put(user, user.account);
      message.success("Settings updated successfully!");
    } catch (error) {
      console.error(error);
      message.error("Failed to update settings.");
      setProductApprovalRequired(!checked);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row style={{ height: "70px" }}>
        <Col span={24}>{loading && <Spin size="large" />}</Col>
      </Row>
      <Row gutter={5}>
        <Col>
          <span>Product Require Approval</span>
        </Col>
        <Col>
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={productApprovalRequired}
            onChange={onToggle}
            disabled={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountSettings;
