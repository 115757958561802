import React from "react";
import { Modal } from "antd";
import Button from "../../components/Button";

import styles from "./TrayCheckFinishedModal.module.css";

const TrayCheckFinishedModal = ({
  open,
  onCancel,
  onTotalCell,
  tray,
  trayRange,
}) => {
  return (
    <Modal
      open={open}
      width={450}
      onCancel={onCancel}
      classNames={{
        content: styles.TrayCheckFinishedModalBackground,
        header: styles.TrayCheckFinishedModalBackground,
      }}
      title="Tray Check Finished"
      footer={
        <div className={styles.TrayCheckFinishedModalFooter}>
          <Button labelName="Total Cell QTY" onClick={onTotalCell} />
          <Button labelName="OK" isPrimary onClick={onCancel} />
        </div>
      }
    >
      <div className={styles.TrayCheckFinishedModalContainer}>
        <div className={styles.TrayCheckFinishedRow}>
          <span>Order #:</span>
          <span className={styles.TrayCheckFinishedData}>{tray.rfid}</span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>Batch Name:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.account.companyName}
          </span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>Tray #:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.assignedTrayId}
          </span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>Tray Sequence:</span>
          <span
            className={styles.TrayCheckFinishedData}
          >{`${tray.rangeId} of ${trayRange}`}</span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>Tray Status:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.slots.some((slot) =>
              slot.drugs.some(
                (drug) => drug.approvalType === "corrected approved"
              )
            )
              ? "CORRECTED APPROVED"
              : "APPROVED"}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default TrayCheckFinishedModal;
