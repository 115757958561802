import React, {useEffect, useState} from 'react';
import styles from "./CreateProduct.module.css";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";

const CreateProductVBM = ({isNew, p, errors, onPropertyChange, onError}) => {

    const productController = new ProductController();

    useEffect(()=>{
        if(!p.allowVBM){
            onPropertyChange({vbmDrugName: productController.getDefaultName(p)})
        }
    }, [p])

    return (
        <Accordion title={"VBM Information"}>
            <div className={styles.CreateProduct__productCategory_row}>
                <div className={styles.CreateProduct__productCategory_col}>
                    <Checkbox
                        labelName={'Do not dispense'}
                        isOn={ p?.vbmDoNotDispense }
                        onCheck={isOn => onPropertyChange({vbmDoNotDispense: isOn})}
                    />
                </div>
                <div className={styles.CreateProduct__productCategory_col}>
                    <Checkbox
                        labelName={'BCO Only'}
                        isOn={ p?.vbmBCOOnly }
                        onCheck={isOn => onPropertyChange({vbmBCOOnly: isOn})}
                    />
                </div>
            </div>
            <br/>
            <div className={styles.CreateProduct__productCategory_row}>
                <div className={styles.CreateProduct__productCategory_col}>
                    <TextInput
                        type={'text'}
                        labelName={'Drug Name'}
                        isEnabled={true}
                        value={p?.vbmDrugName || productController.getDefaultName(p)}
                        validationText={''}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({vbmDrugName: val})
                        }}
                    />
                </div>
            </div>
        </Accordion>
    )
}

export default CreateProductVBM;
