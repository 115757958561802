import React from "react";
import styles from "./Button.module.css";

const Button = (props) => {
  return (
    <button
      className={
        props.invStyle
          ? [styles["button"], styles["button--inverse"]].join(" ")
          : styles.button
      }
      type={props.type || "button"}
      onClick={props.onClickHandler}
    >
      {props.children}
    </button>
  );
};

export default Button;
