import React, { useEffect, useRef, useContext } from "react";
import { ReactComponent as AddEquivIcon } from "../../assets/create-button-icon.svg";
import InventoryAddEquivDataCard from "./InventoryAddEquivDataCard";
import styles from "./InventoryProductsList.module.css";
import ProductController from "../../controllers/ProductController";
import { useTranslation } from "react-i18next";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const InventoryProductsList = ({
  product,
  toggle,
  setToggle,
  filteredProducts,
  equivProductIds,
  setEquivProductIds,
  setProductEquivalency,
  prodEquivCntrInstance,
}) => {
  const pcRef = useRef(null);

  useEffect(() => {
    pcRef.current = new ProductController();
  }, []);

  const maxNumRowsNoScroll = 4;

  const { t } = useTranslation("invEquivalency");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const handleAddEquivClick = (prod, id) => {
    const callback = (error, productEquivalency) => {
      if (!error) {
        setProductEquivalency(productEquivalency);
        setToggle(!toggle);
      } else {
        console.log("error", error);
        setProductEquivalency(productEquivalency);
        setToggle(!toggle);
      }
      let tempEquivProductIds = [...equivProductIds];
      if (prod.packageId) {
        tempEquivProductIds.push(prod.packageId);
      }
      setEquivProductIds(tempEquivProductIds);
    };

    prodEquivCntrInstance.addProductEquivalency(product, prod, callback);
  };

  return (
    <div className={styles.prodList__scrollArea}>
      {filteredProducts.map((prod) =>
        !smScreen ? (
          <React.Fragment key={prod.packageId}>
            {!equivProductIds.includes(prod.packageId) ? (
              <ul className={styles.prodList__listItem}>
                <li className={styles.prodList__id}>{prod.packageId}</li>
                <li className={styles.prodList__description}>
                  {pcRef.current.getDefaultName(prod)}
                </li>
                <li className={styles.prodList__manufacturer}>
                  {prod.manufacturer.fullName}
                </li>
                <AddEquivIcon
                  className={styles.prodList__AddEquivIcon}
                  onClick={() => {
                    handleAddEquivClick(prod, prod.packageId);
                  }}
                />
              </ul>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          <InventoryAddEquivDataCard
            key={prod.packageId}
            data={{
              idHeading: t("col0"),
              descriptionHeading: t("col1"),
              manufacturerHeading: t("col2"),
              actionsHeading: t("col3"),
              id: prod.packageId,
              description: pcRef.current.getDefaultName(prod),
              manufacturer: prod.manufacturer.fullName,
              actions: (
                <div
                  id={prod.packageId}
                  onClick={() => {
                    handleAddEquivClick(prod, prod.packageId);
                  }}
                >
                  <AddEquivIcon
                    className={styles.prodList__AddEquivIcon}
                    onClick={() => {
                      handleAddEquivClick(prod, prod.packageId);
                    }}
                  />
                </div>
              ),
            }}
          />
        )
      )}
    </div>
  );
};

export default InventoryProductsList;
