import React from "react";
import styles from "./TrayCheckTags.module.css";

export const ControlMedTag = () => (
  <div className={styles.ControlMedTag}>
    <h1 className={styles.ControlMedTagText}>Control Med</h1>
  </div>
);

export const PartialMedTag = ({ label }) => (
  <div className={styles.PartialMedTag}>
    <h1 className={styles.PartialMedTagText}>{label}</h1>
  </div>
);
