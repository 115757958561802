import React, { Fragment, useState, useEffect, useRef } from "react";
import stylesAll from "../PullStockForInternalTransfer/view.module.css";
import InventoryTransferPlaceViewController from "./InventoryTransferPlaceViewController";
import InventoryPlaceStockedItemToRegularStockLocation from "./InventoryPlaceStockedItemToRegularStockLocation";
import InventoryPlaceProductToSmartCanister from "./InventoryPlaceProductToSmartCanister";
import InventoryPlaceSmartCanisterToParent from "./InventoryPlaceSmartCanisterToParent";
import { isCanister } from "../../../functions/utilities.js";
import ErrorBanner from "../../../components/ErrorBanner";
import Header from "./InventoryPlaceHeader";
import Modal from "../commonUI/ModalTransparent";

const InventoryTransferPlaceLocationView = ({
  onStateBack,
  user,
  site,
  onHideModal,
}) => {
  const [destinationBarcode, setDestinationBarcode] = useState(null);
  const [destinationStockLocation, setDestinationStockLocation] =
    useState(null);
  const [
    destinationStockLocationSmartCanister,
    setDestinationStockLocationSmartCanister,
  ] = useState(false);

  const [
    destinationStockLocationScanComplete,
    setDestinationStockLocationScanComplete,
  ] = useState(false);
  const [smartProcessingComplete, setSmartProcessingComplete] = useState(false);
  const [regularProcessingComplete, setRegularProcessingComplete] =
    useState(false);
  const [smartProduct, setSmartProduct] = useState(null);
  const [scannedItemBarcode, setScannedItemBarcode] = useState(null);
  const [scannedItemSmartCanister, setScannedItemSmartCanister] =
    useState(null);
  const [userStockedItems, setUserStockedItems] = useState(null);
  const [smartCanisterStockedItems, setSmartCanisterStockedItems] =
    useState(null);
  const [userSmartStockedItems, setUserSmartStockedItems] = useState(null);
  const [activateErrorModal, setActivateErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [blankInput, setBlankInput] = useState();

  const _controller = useRef(null);

  _controller.current = new InventoryTransferPlaceViewController(user, site);

  useEffect(() => {
    const callback = (error, stockedItems) => {
      if (!error && stockedItems) {
        setUserStockedItems(stockedItems);
      } else {
        console.log("error:", error);
      }
    };

    if (!userStockedItems) {
      _controller.current.getMyStockedItems(callback);
    }
  }, []);

  useEffect(() => {
    const callback = (error, stockLocation) => {
      if (!error && stockLocation) {
        setDestinationStockLocation(stockLocation);
      } else {
        setErrorMsg("The scanned stock location does not exist.");
        setActivateErrorModal(true);
        console.log("error:", error);
      }
    };
    if (destinationBarcode && !destinationStockLocation) {
      _controller.current.getStockLocationByBarcode(
        destinationBarcode,
        callback
      );
    }
  }, [destinationBarcode]);

  useEffect(() => {
    if (destinationStockLocation && isCanister(destinationStockLocation)) {
      setDestinationStockLocationSmartCanister(true);
      setDestinationStockLocationScanComplete(true);
    } else {
      if (destinationStockLocation) {
        setDestinationStockLocationScanComplete(true);
      }
    }
  }, [destinationStockLocation]);

  useEffect(() => {
    if (destinationStockLocationScanComplete) {
      if (destinationStockLocationSmartCanister) {
        // The scanned destination corresponds to a smart canister.

        setSmartProcessingComplete(true);
      } else {
        // The scanned destination corresponds to a regular canister."

        const callback = (error, stockLocation) => {
          if (!error && stockLocation) {
            if (isCanister(stockLocation)) {
              // The scanned item to place corresponds to a smart canister.

              if (stockLocation.parent === null) {
                setScannedItemSmartCanister(stockLocation);
              } else {
                setErrorMsg("The scanned smart canister already has a parent.");
                setScannedItemBarcode(null);
                setActivateErrorModal(true);
              }
            }
          } else {
            // The scanned item to place is not a smart canister.
            // Check if to see if the scanned item barcode corresponds to a product.
            const productCallback = (error, product) => {
              if (!error && product) {
                setRegularProcessingComplete(true);
              } else {
                setErrorMsg(
                  "The scanned item barcode is not associated with a smart canister nor a product."
                );
                setActivateErrorModal(true);
              }
            };
            _controller.current.getProductByBarcode(
              scannedItemBarcode,
              productCallback
            );
          }
        };

        if (scannedItemBarcode) {
          _controller.current.getStockLocationByBarcode(
            scannedItemBarcode,
            callback
          );
        }
      }
    }
  }, [destinationStockLocationScanComplete, scannedItemBarcode]);

  useEffect(() => {
    if (scannedItemSmartCanister) {
      const callback = (error, stockedItems) => {
        if (!error && stockedItems) {
          setSmartCanisterStockedItems(stockedItems);
        } else {
          console.log("error:", error);
        }
      };

      const stockLocation = scannedItemSmartCanister;

      if (
        stockLocation.productAssociations &&
        stockLocation.productAssociations.length > 0
      ) {
        setSmartProduct(stockLocation.productAssociations[0].product);
      } else {
        setSmartProduct(null);
      }

      _controller.current.getStockedItems(stockLocation, callback);
    }
  }, [scannedItemSmartCanister]);

  useEffect(() => {
    if (smartCanisterStockedItems && userStockedItems) {
      let tempArray = userStockedItems.filter((stockedItem, i) => {
        if (
          scannedItemSmartCanister.productAssociations &&
          scannedItemSmartCanister.productAssociations[0]
        ) {
          return (
            stockedItem.product.packageId ===
            scannedItemSmartCanister.productAssociations[0].product.packageId
          );
        }
        return false;
      });
      setUserSmartStockedItems(tempArray);
      setSmartProcessingComplete(true);
    }
  }, [smartCanisterStockedItems, userStockedItems]);

  const stateBackHandler = () => {
    setDestinationStockLocationSmartCanister(false);
    setSmartProcessingComplete(false);
    setRegularProcessingComplete(false);
    setScannedItemBarcode(null);
  };

  const stateBackHandlerDone = () => {
    setDestinationBarcode(null);
    setDestinationStockLocation(null);
    setDestinationStockLocationSmartCanister(false);
    setDestinationStockLocationScanComplete(false);
    setSmartProcessingComplete(false);
    setRegularProcessingComplete(false);
    setSmartProduct(null);
    setScannedItemBarcode(null);
    setScannedItemSmartCanister(null);
    setSmartCanisterStockedItems(null);
    setUserSmartStockedItems(null);
    setActivateErrorModal(false);
    setErrorMsg("");
  };

  const backArrowHandler = () => {
    if (!destinationBarcode) {
      onStateBack("");
    } else {
      setDestinationBarcode(null);
      setDestinationStockLocation(null);
      setDestinationStockLocationScanComplete(false);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorMsg("");
    setActivateErrorModal(false);
  };

  const resetLocationStates = () => {};

  const handleSetBlankInput = () => {
    return setBlankInput();
  };

  const inputKeyUpHandler1 = (e) => {
    if (e.which === 13) {
      setDestinationBarcode(e.target.value);
    }
  };

  const inputKeyUpHandler2 = (e) => {
    if (e.which === 13) {
      setScannedItemBarcode(e.target.value);
    }
  };

  const ErrorModal = () => {
    return (
      <div className={stylesAll.errorBannerContainer}>
        <ErrorBanner message={errorMsg} onClose={handleCloseErrorModal} />
      </div>
    );
  };

  if (!smartProcessingComplete && !regularProcessingComplete) {
    return (
      <Fragment>
        <div className={stylesAll.container}>
          {activateErrorModal && <ErrorModal />}
          {activateErrorModal && <Modal />}
          <Header
            headerTitle="Place"
            backArrowHandler={backArrowHandler}
            useInput={true ? true : false}
            showFullBottomBorderRadius={true}
            onHideModal={onHideModal}
            inputFields={{
              onKeyUp: !destinationStockLocationScanComplete
                ? inputKeyUpHandler1
                : inputKeyUpHandler2,
              placeholder: !destinationStockLocationScanComplete
                ? "Scan destination barcode"
                : "Scan item to place",
              type: "text",
              disabled: `${errorMsg}`,
            }}
            inputExtras={{
              enforceFocus: true,
              blankInput: destinationStockLocationScanComplete ? true : false,
              onSetBlankInput: handleSetBlankInput,
            }}
          />
        </div>
      </Fragment>
    );
  } else if (smartProcessingComplete && !scannedItemSmartCanister) {
    return (
      <InventoryPlaceProductToSmartCanister
        onStateBackDone={stateBackHandlerDone}
        user={user}
        site={site}
        userStockedItems={userStockedItems}
        scannedStockLocation={destinationStockLocation}
        onHideModal={onHideModal}
        resetLocationStates={resetLocationStates}
        controllerCurrent={_controller.current}
      />
    );
  } else if (smartProcessingComplete && scannedItemSmartCanister) {
    return (
      <InventoryPlaceSmartCanisterToParent
        onStateBack={stateBackHandler}
        onStateBackDone={stateBackHandlerDone}
        user={user}
        site={site}
        userStockedItems={userStockedItems}
        smartCanisterStockedItems={smartCanisterStockedItems}
        userSmartStockedItems={userSmartStockedItems}
        scannedSmartCanister={scannedItemSmartCanister}
        scannedParentStockLocation={destinationStockLocation}
        onHideModal={onHideModal}
        resetLocationStates={resetLocationStates}
        smartProduct={smartProduct}
        smartCanister={true}
      />
    );
  } else if (regularProcessingComplete) {
    return (
      <InventoryPlaceStockedItemToRegularStockLocation
        receivedBarcode={scannedItemBarcode}
        onStateBack={stateBackHandler}
        onStateBackDone={stateBackHandlerDone}
        user={user}
        site={site}
        userStockedItems={userStockedItems}
        scannedStockLocation={destinationStockLocation}
        onHideModal={onHideModal}
        resetLocationStates={resetLocationStates}
      />
    );
  }
};

export default InventoryTransferPlaceLocationView;
