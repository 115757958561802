export const getResettedTrayCheck = (tray) => {
  const newSlots = [];
  tray.slots.forEach((slot) => {
    const newDrugs = [];
    if (slot.drugs) {
      slot.drugs.forEach((drug) => {
        newDrugs.push({
          ...drug,
          approved: false,
          approvedBy: null,
          approvedAt: null,
          approvalType: null,
          correctedReason: null,
          correctedComments: null,
        });
      });
    }
    newSlots.push({
      ...slot,
      drugs: newDrugs,
    });
  });
  return {
    ...tray,
    slots: newSlots,
    approved: false,
    approvedAt: null,
    approvedBy: null,
    isLocked: false,
    status: "Ready",
  };
};

export const getCorrectedApprovedDrugOnSlot = (
  userId,
  drug,
  slot,
  tray,
  reason,
  comments
) => {
  // Checks if all data exist
  if (
    !!!userId ||
    !!!drug ||
    !!!slot ||
    !!!slot.drugs ||
    !!!tray ||
    !!!tray.slots
  )
    return false;

  // Creates a slot copy
  const updatedSlot = { ...slot };

  // Finds the drug to update
  const drugIndexOnSlot = updatedSlot.drugs.findIndex(
    (d) => d.product._id === drug.product._id && d.partial === drug.partial
  );

  // Returns false if the drug is not found
  if (drugIndexOnSlot < 0) return false;

  const qty = slot.drugs[drugIndexOnSlot].quantity;

  // Removes the outdated drug from the slot
  updatedSlot.drugs.splice(drugIndexOnSlot, 1);

  // Adds the updated drug to the slot
  updatedSlot.drugs.push({
    ...drug,
    quantity: qty,
    approved: true,
    approvedBy: userId,
    approvedAt: new Date(),
    approvalType: "corrected approved",
    correctedReason: reason,
    correctedComments: comments,
  });

  // Creates a tray copy
  const updatedTray = { ...tray, status: "Corrected Approved" };

  // Finds the slot to update
  const slotIndexOnTray = tray.slots.findIndex(
    (s) => s.slotNumber === slot.slotNumber
  );

  // Returns false if the tray is not found
  if (slotIndexOnTray < 0) return false;

  // Removes the outdated slot from the tray
  updatedTray.slots.splice(slotIndexOnTray, 1);

  // Adds the updated slot to the tray
  updatedTray.slots.push(updatedSlot);

  // Returns the updated tray
  return updatedTray;
};

export const getApprovedDrugOnTray = (userId, drug, tray) => {
  const approvedDrug = {
    ...drug,
    approved: true,
    approvedBy: userId,
    approvedAt: new Date(),
    approvalType: "approved",
  };
  const updatedSlots = [];
  if (tray.slots) {
    tray.slots.forEach((slot) => {
      let updatedDrugs = [...slot.drugs];
      if (slot.drugs) {
        const foundAt = slot.drugs.findIndex(
          (item) =>
            item.product._id === approvedDrug.product._id &&
            item.partial === approvedDrug.partial &&
            !item.approved
        );
        if (foundAt >= 0) {
          const qty = slot.drugs[foundAt].quantity;
          updatedDrugs.splice(foundAt, 1);
          updatedDrugs.push({ ...approvedDrug, quantity: qty });
        }
        updatedSlots.push({
          ...slot,
          drugs: updatedDrugs,
        });
      }
    });
  }
  return {
    ...tray,
    slots: updatedSlots,
  };
};

export const getApprovedTray = (userId, tray) => {
  return {
    ...tray,
    approved: true,
    isLocked: false,
    approvedBy: userId,
    approvedAt: new Date(),
    status:
      !!tray.slots &&
      tray.slots.some(
        (s) =>
          !!s.drugs &&
          s.drugs.some(
            (d) =>
              d.approved &&
              !!d.approvalType &&
              d.approvalType.toUpperCase() === "CORRECTED APPROVED"
          )
      )
        ? "Corrected Approved"
        : "Approved",
  };
};

export const getLockedTray = (tray) => {
  return {
    ...tray,
    isLocked: true,
  };
};

export const getUnlockedTray = (tray) => {
  return {
    ...tray,
    isLocked: false,
  };
};

export const isPartial = (partialValue) =>
  (typeof partialValue === "string" || partialValue instanceof String) &&
  partialValue.includes("/");

export const getTrayDrugs = (trayCheck) => {
  const drugs = [];
  trayCheck.slots.forEach((slot) => {
    slot.drugs.forEach((drug) => {
      if (
        !drugs.some(
          (d) =>
            d.product._id === drug.product._id && d.partial === drug.partial
        )
      ) {
        drugs.push(drug);
      }
    });
  });
  drugs.sort((a, b) =>
    !a.approved && b.approved ? -1 : a.approved && !b.approved ? 1 : 0
  );
  return drugs;
};

export const getTrayCheckGrid = (trayCheck) => {
  const rows = 6;
  const columns = 10;
  const newGrid = [];
  for (let i = 0; i < rows; i++) {
    const line = [];
    const number = 60 - i;
    for (let j = 0; j < columns; j++) {
      let slotData = trayCheck.slots.find(
        (item) => item.slotNumber === number - j * rows
      );
      if (slotData && slotData.drugs && slotData.drugs.length) {
        line.push(slotData);
      } else {
        line.push({ isEmpty: true, slotNumber: number - j * rows });
      }
    }
    newGrid.push(line);
  }
  newGrid[0].unshift({ label: trayCheck.assignedTrayId });
  const slot63Data = trayCheck.slots.find((item) => item.slotNumber === 63);
  if (slot63Data && slot63Data.drugs && slot63Data.drugs.length) {
    newGrid[3].unshift(slot63Data);
  } else {
    newGrid[3].unshift({ isEmpty: true, slotNumber: 63 });
  }
  const slot62Data = trayCheck.slots.find((item) => item.slotNumber === 62);
  if (slot62Data && slot62Data.drugs && slot62Data.drugs.length) {
    newGrid[4].unshift(slot62Data);
  } else {
    newGrid[4].unshift({ isEmpty: true, slotNumber: 62 });
  }
  const slot61Data = trayCheck.slots.find((item) => item.slotNumber === 61);
  if (slot61Data && slot61Data.drugs && slot61Data.drugs.length) {
    newGrid[5].unshift(slot61Data);
  } else {
    newGrid[5].unshift({ isEmpty: true, slotNumber: 61 });
  }
  return newGrid;
};

export const isOrderListEqual = (currentList, newList) => {
  // If list sizes are different, the lists are different
  if (currentList.length !== newList.length) return false;

  // Walk through the current list to check the important attributes
  for (let i = 0; i < currentList.length; i++) {
    const currentListOrder = currentList[i];

    // Try to find the same order on the new list
    const newListOrder = newList.find(
      (o) => o.dispensingOrder._id === currentListOrder.dispensingOrder._id
    );

    // If the order is not found on the new list, the lists are different
    if (!newListOrder) return false;

    // If tray checks list sizes are different, the lists are different
    if (newListOrder.trayChecks.length !== currentListOrder.trayChecks.length)
      return false;

    // Walk through the current order tray checks list to check the important attributes
    for (let j = 0; j < currentListOrder.trayChecks.length; j++) {
      const currentListOrderTrayCheck = currentListOrder.trayChecks[j];

      // Try to find the same tray check on the new list order tray checks
      const newListOrderTrayCheck = newListOrder.trayChecks.find(
        (tChk) => tChk._id === currentListOrderTrayCheck._id
      );

      // If the tray check is not found on the new list order tray checks, the lists are different
      if (!newListOrderTrayCheck) return false;

      // Checks the important attributes
      if (
        currentListOrderTrayCheck.isLocked !== newListOrderTrayCheck.isLocked ||
        currentListOrderTrayCheck.approved !== newListOrderTrayCheck.approved ||
        currentListOrderTrayCheck.approvalType !==
          newListOrderTrayCheck.approvalType ||
        currentListOrderTrayCheck.approvedAt !==
          newListOrderTrayCheck.approvedAt ||
        currentListOrderTrayCheck.status !== newListOrderTrayCheck.status ||
        currentListOrderTrayCheck.correctedComments !==
          newListOrderTrayCheck.correctedComments ||
        currentListOrderTrayCheck.correctedReason !==
          newListOrderTrayCheck.correctedReason
      )
        return false;
    }
  }

  // If got here, the lists are equal
  return true;
};

export const getDrugQtyNeeded = (slots, drugId, partial) => {
  let qty = 0;
  if (slots && slots.length > 0 && drugId) {
    slots.forEach((slot) => {
      qty +=
        slot.drugs?.find(
          (drug) => partial === drug.partial && drug.product._id === drugId
        )?.quantity || 0;
    });
  }
  return qty;
};
