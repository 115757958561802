import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MagnifyingGlass } from "../../../assets/magnifying-glass.svg";
import styles from "./InventoryPlaceInput.module.css";

const InventoryPlaceInput = (props) => {
  const inputElement = useRef(null);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (props.enforceFocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (props.blankInput && props.onSetBlankInput) {
      setValue("");
    }
  }, [props.blankInput]);

  const onChangeHandler = (e) => {
    if (props.blankInput && props.onSetBlankInput) {
      props.onSetBlankInput(false);
    }
    setValue(e.target.value);
    if (props.onInputValue) {
      props.onInputValue(e.target.value);
    }
  };

  const blurHandler = () => {
    if (props.enforceFocus) {
      inputElement.current.focus();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.inputContainer}>
        <MagnifyingGlass className={styles.magnifyingGlassIcon} />
        <input
          className={styles.input}
          autoComplete="off"
          ref={inputElement}
          onChange={onChangeHandler}
          onBlur={blurHandler}
          value={value}
          {...props.inputFields}
        ></input>
      </div>
    </React.Fragment>
  );
};

export default InventoryPlaceInput;
