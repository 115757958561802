import ApiService from "./ApiService";
import axios from "axios";

class ProductEquivalencyService extends ApiService {
  static get = async (user, product) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/${product._id}/product-equivalencies`,
        rc
      );
      return response.data.productEquivalency;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static add = async (user, product, productToAdd) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/${product._id}/product-equivalencies`,
        productToAdd,
        rc
      );
      return response.data.productEquivalency;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static remove = async (
    user,
    productEquivalency,
    product,
    productToRemove
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.put(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/products/${product._id}/product-equivalencies/${productEquivalency._id}`,
        productToRemove,
        rc
      );
      return response.data.productEquivalency;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default ProductEquivalencyService;
