import React from "react";
import SuctionCupLifeCard from "./SuctionCupLifeCard";
import styles from "./SuctionCupLifeCardList.module.css";

const SuctionCupLifeCardList = ({ headings, data }) => {
  const headingsObj = {
    task: headings[0],
    every: headings[1],
    instructions: headings[2],
    last: headings[3],
    by: headings[4],
  };

  if (data.length) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.SuctionCupLifeCardList__container}>
        <SuctionCupLifeCard
          headingsObj={headingsObj}
          dataObj={{
            task: dataRow[0],
            every: dataRow[1],
            instructions: dataRow[2],
            last: dataRow[3],
            by: dataRow[4],
            actions: dataRow[5],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.SuctionCupLifeCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.SuctionCupLifeCardList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default SuctionCupLifeCardList;
