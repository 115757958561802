import React, { useEffect, useRef, useState } from "react";
import styles from "../Product/CreateProduct.module.css";
import antdTableStyles from "./CreateTherapeuticEquivalency.module.css";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorBanner from "../../components/ErrorBanner";
import WaitIndicator from "../../components/WaitIndicator";
import ProductService from "../../services/ProductService";
import TextInput from "../../components/TextInput";
import CreateButton from "../../components/CreateButton";
import { Modal, Table, Button, Tooltip, Input } from "antd";
import { DeleteOutlined, FileAddOutlined } from "@ant-design/icons";
import ProductFieldController from "../../controllers/ProductFieldController";
const { Search } = Input;

const CreateTherapeuticEquivalency = ({ user, teCode }) => {
  const searchRef = useRef();
  // Variables
  const navigate = useNavigate();
  const location = useLocation();
  const isNew = !teCode;
  const productFieldController = new ProductFieldController();

  // State
  const [currentTECode, setCurrentTECode] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equivalentProducts, setEquivalentProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);

  const columns = [
    {
      title: "Product",
      dataIndex: "_id",
      render: (text, product) => (
        <div>
          <p>{productFieldController.getDefaultName(product)}</p>
          <p>packageId: {product?.packageId}</p>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, product) => (
        <Tooltip title={"Remove product"}>
          <Button danger size={"small"} onClick={() => removeProduct(product)}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const addColumns = [
    {
      title: "Id",
      dataIndex: "packageId",
      key: "packageId",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Product",
      dataIndex: "_id",
      key: "_id",
      render: (text, product) => (
        <p>{productFieldController.getDefaultName(product)}</p>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, product) => (
        <Tooltip title={"Add product"}>
          <a onClick={() => addProduct(product)}>Add</a>
        </Tooltip>
      ),
    },
  ];

  const addProduct = (product) => {
    product.therapeuticEquivalencyCode = currentTECode;
    ProductService.update(user, product)
      .then((p) => {
        setEquivalentProducts((o) => {
          return [...equivalentProducts, product];
        });
        handleCancel();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const removeProduct = (product) => {
    product.therapeuticEquivalencyCode = "";
    ProductService.update(user, product)
      .then((p) => {
        const temp = [...equivalentProducts];
        const index = temp.findIndex((o) => o._id === p._id);
        if (index !== -1) {
          temp.splice(index, 1);
          setEquivalentProducts(temp);
        }
        if (temp.length === 0) {
          navigate("/settings/therapeuticEquivalencies", {
            state: { refresh: true },
          });
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  // Effects
  useEffect(() => {
    if (teCode) {
      setCurrentTECode(teCode);
      setIsLoading(true);
      ProductService.getTherapeuticEquivalentProducts(user, teCode)
        .then((list) => {
          setEquivalentProducts(list);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }, [user]);

  // Event handlers
  const handleClose = () => {
    navigate("/settings/therapeuticEquivalencies", {
      state: { refresh: true },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAvailableProducts([]);
  };

  const onSearch = (value, _e, info) => {
    setAvailableProducts([]);
    if (value.length > 3) {
      ProductService.getWithNoTECode(user, value)
        .then((list) => {
          setAvailableProducts(list);
          console.log(list.length);
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  if (isProcessing) {
    return (
      <WaitIndicator
        message={
          isNew
            ? "Creating Therapeutic Equivalency...."
            : "Updating Therapeutic Equivalency..."
        }
      />
    );
  } else if (isLoading) {
    return <WaitIndicator message={"Loading products..."} />;
  } else {
    return (
      <div className={styles.CreateProduct__overlay}>
        <div className={styles.CreateProduct__view}>
          <header className={styles.CreateProduct__header}>
            <h1 className={styles.CreateProduct__title}>
              {isNew
                ? "Add Therapeutic Equivalency"
                : "Update Therapeutic Equivalency"}
            </h1>
            <div className={styles.CreateProduct__close}>
              <CloseIcon fill={"rgba(18,26, 45, 0.5)"} onClick={handleClose} />
            </div>
          </header>

          {error && (
            <div className={styles.CreateProduct__errorBannerContainer}>
              <ErrorBanner message={error} onClose={() => setError(null)} />
            </div>
          )}

          <div
            className={styles.CreateProduct__productCategory_row}
            style={{ justifyContent: "space-between" }}
          >
            <div
              className={styles.CreateProduct__productCategory_col}
              style={{ width: "200px" }}
            >
              <TextInput
                type={"text"}
                labelName={"TE Code"}
                isEnabled={equivalentProducts.length === 0}
                value={currentTECode}
                validationText={""}
                focus={true}
                onChange={(val) => {
                  setCurrentTECode(val);
                }}
              />
            </div>
            {currentTECode.length > 0 && (
              <div className={styles.CreateProduct__productCategory_row_end}>
                <CreateButton
                  onClick={showModal}
                  isEnabled={true}
                  labelName={"Add product"}
                />
              </div>
            )}
          </div>
          <br />

          <Table
            columns={columns}
            dataSource={equivalentProducts}
            className={antdTableStyles.tableDataRow}
            rowKey="_id"
          />
        </div>
        <Modal
          title="Add Product"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          afterOpenChange={() => {
            const { input } = searchRef.current;
            input.focus();
          }}
        >
          <br />
          <Search
            ref={searchRef}
            enterButton
            placeholder="enter ndc or name (minimum 4 characters)"
            onSearch={onSearch}
          />
          <Table columns={addColumns} dataSource={availableProducts} />
        </Modal>
      </div>
    );
  }
};

export default CreateTherapeuticEquivalency;
