import { addZeros } from "../../functions/utilities";

export const getDefaultDatesFormatted = () => {
  // Sunday - Saturday : 0 - 6
  const workingDays = [false, true, true, true, true, true, false];

  const dateToday = new Date();

  // Sunday - Saturday : 0 - 6
  const dayOfWeek = dateToday.getDay();

  let endDate;

  if (workingDays[dayOfWeek] === false) {
    let daysCount = 1;
    for (let i = dayOfWeek - 1; i > -1; i--) {
      if (workingDays[i]) {
        daysCount++;
        let currentTime = new Date().getTime();
        endDate = new Date(currentTime - daysCount * 24 * 60 * 60 * 1000);
        break;
      }
    }

    if (!endDate) {
      for (let i = 6; i > dayOfWeek; i--) {
        if (workingDays[i]) {
          let currentTime = new Date().getTime();
          endDate = new Date(
            currentTime + (i - dayOfWeek) * 24 * 60 * 60 * 1000
          );
          break;
        }
      }
    }
  } else {
    endDate = dateToday;
  }

  const startDate = new Date(endDate - 4 * 24 * 60 * 60 * 1000);

  const startDateSplitArray = startDate.toLocaleDateString().split("/");
  const startMonth = addZeros(startDateSplitArray[0], 2);
  const startDay = addZeros(startDateSplitArray[1], 2);
  const startYear = startDateSplitArray[2];
  const startDateString = `${startYear}-${startMonth}-${startDay}T00:00`;

  const endDateSplitArray = endDate.toLocaleDateString().split("/");
  const endMonth = addZeros(endDateSplitArray[0], 2);
  const endDay = addZeros(endDateSplitArray[1], 2);
  const endYear = endDateSplitArray[2];
  const endDateString = `${endYear}-${endMonth}-${endDay}T23:59:59`;
  return {
    startDateString,
    endDateString,
  };
};

export const getDateLocaleDateString = (datesArray, format = 1) => {
  if (datesArray?.length) {
    if (format === 1) {
      const startDateFormatted = datesArray[0].startDate.toLocaleDateString();
      const endDateFormatted = datesArray[0].endDate.toLocaleDateString();
      return { startDateFormatted, endDateFormatted };
    } else if (format === 2) {
      const startDateSplitArray = datesArray[0].startDate
        .toLocaleDateString()
        .split("/");
      const startMonth = addZeros(startDateSplitArray[0], 2);
      const startDay = addZeros(startDateSplitArray[1], 2);
      const startYear = startDateSplitArray[2];
      const startDateString = `${startYear}-${startMonth}-${startDay}T00:00`;

      const endDateSplitArray = datesArray[0].endDate
        .toLocaleDateString()
        .split("/");
      const endMonth = addZeros(endDateSplitArray[0], 2);
      const endDay = addZeros(endDateSplitArray[1], 2);
      const endYear = endDateSplitArray[2];
      const endDateString = `${endYear}-${endMonth}-${endDay}T23:59:59`;

      return { startDateString, endDateString };
    } else if (format === 3) {
      const startDateSplitArray = datesArray[0].startDate
        .toLocaleDateString()
        .split("/");
      const startMonth = addZeros(startDateSplitArray[0], 2);
      const startDay = addZeros(startDateSplitArray[1], 2);
      const startYear = startDateSplitArray[2];
      const startDateString = `${startYear}-${startMonth}-${startDay}`;

      const endDateSplitArray = datesArray[0].endDate
        .toLocaleDateString()
        .split("/");
      const endMonth = addZeros(endDateSplitArray[0], 2);
      const endDay = addZeros(endDateSplitArray[1], 2);
      const endYear = endDateSplitArray[2];
      const endDateString = `${endYear}-${endMonth}-${endDay}`;

      return { startDateString, endDateString };
    }
  }
};
