export const getLastMonthName = (currentMonthValue) => {
  let lastMonthName;
  switch (currentMonthValue) {
    case 0:
      lastMonthName = "December";
      break;
    case 1:
      lastMonthName = "January";
      break;
    case 2:
      lastMonthName = "February";
      break;
    case 3:
      lastMonthName = "March";
    case 4:
      lastMonthName = "April";
      break;
    case 5:
      lastMonthName = "May";
      break;
    case 6:
      lastMonthName = "June";
      break;
    case 7:
      lastMonthName = "July";
      break;
    case 8:
      lastMonthName = "August";
      break;
    case 9:
      lastMonthName = "September";
      break;
    case 10:
      lastMonthName = "October";
      break;
    case 11:
      lastMonthName = "November";
    default:
    // code block
  }
  return lastMonthName;
};

export const getTimeRangeDay = () => {
  const today = new Date();
  const startDate = new Date(today.getTime() - 1000 * 60 * 60 * 24);
  const stopDate = new Date();
  return { startDate: startDate, stopDate: stopDate };
};

export const getTimeRangeWeek = () => {
  const startDate = new Date();
  const stopDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  startDate.setDate(startDate.getDate() - ((startDate.getDay() + 0) % 7) - 7); // start of Sunday

  stopDate.setDate(stopDate.getDate() - ((stopDate.getDay() + 1) % 7));
  stopDate.setHours(23, 59, 59, 59); // end of Saturday
  return { startDate: startDate, stopDate: stopDate };
};

export const getTimeRange12Hours = () => {
  const today = new Date();
  const startDate = new Date(today.getTime() - 1000 * 60 * 60 * 12);
  const stopDate = new Date();
  return { startDate: startDate, stopDate: stopDate };
};

export const getTimeRangeMonth = () => {
  const startDate = new Date();
  const currentYear = startDate.getFullYear();
  const currentMonthValue = startDate.getMonth(); // getMonth is 0 - 11 based

  let lastMonthName = getLastMonthName(currentMonthValue);

  let yearLastMonth = currentYear;
  if (currentMonthValue === 0) {
    yearLastMonth = currentYear - 1;
  }

  let lastMonthValue = currentMonthValue - 1;

  if (currentMonthValue === 0) {
    lastMonthValue = 11; // Jan -> Dec
  }

  const lastMonthStart = new Date(
    `${lastMonthName} 1, ${yearLastMonth}, 00:00:00`
  );

  const numDaysLastMonth = (year, month) =>
    // getDate is 1-12 based
    new Date(year, month, 0).getDate();

  const stopDate = new Date(
    `${lastMonthName} ${numDaysLastMonth(
      yearLastMonth,
      currentMonthValue
    )}, ${yearLastMonth} 23:59:59`
  );

  return { startDate: lastMonthStart, stopDate: stopDate };
};

export const dateSelectionRangeTimeAdjustment = (startDate, endDate) => {
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  return {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
};
