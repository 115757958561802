import React, { useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import InventoryListController from "./InventoryListController";
import InventoryListCard from "./InventoryListCard";
import Table from "../../../components/TableExpandable/TableExpandable";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./InventoryList.module.css";

const InventoryList = ({
  user,
  selectedSites,
  selectedDevices,
  automationFilter,
  searchText,
  onError,
}) => {
  const controllerRef = useRef(null);
  const [items, setItems] = useState([]);
  const { t } = useTranslation("inventory");

  useEffect(() => {
    controllerRef.current = new InventoryListController(user);
    return () => {
      controllerRef.current = null;
    };
  }, [user]);

  useEffect(() => {
    (async () => {
      if (selectedSites.length) {
        try {
          let temp = [];
          for (let i = 0; i < selectedSites.length; i++) {
            const selectedSite = selectedSites[i];
            let forExpandableTable;
            if (smScreen) {
              forExpandableTable = false;
            } else {
              forExpandableTable = true;
            }
            let items = await controllerRef.current.getStockedItems(
              selectedSite,
              selectedDevices,
              automationFilter,
              forExpandableTable
            );
            temp = temp.concat(items);
          }
          setItems(temp);
        } catch (e) {
          onError(e.message);
        }
      } else {
        setItems([]);
      }
    })();
  }, [selectedSites, selectedDevices, automationFilter]);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  if (!smScreen) {
    return (
      <div className={styles.inventoryList__container}>
        <Table
          padScrollBar={true}
          searchText={searchText}
          headers={[
            "Site",
            "Location",
            "Containers",
            "Package Id",
            "Description",
            "Form",
            "Manufacturer",
            "Total Qty",
            "Total Cost",
          ]}
          records={items}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.inventoryList__container}>
        {items
          // filter by id, description, or manufacturer
          .filter((o) =>
            searchText
              ? o[1].toUpperCase().includes(searchText) ||
                o[2].toUpperCase().includes(searchText) ||
                o[4].toUpperCase().includes(searchText)
              : true
          )
          .map((item, index) => {
            return (
              <InventoryListCard
                key={index}
                index={index}
                data={{
                  siteHeading: t("siteHeading"),
                  idHeading: t("idHeading"),
                  descriptionHeading: t("descriptionHeading"),
                  formHeading: t("formHeading"),
                  manufacturerHeading: t("manufacturerHeading"),
                  locationHeading: t("locationHeading"),
                  parentHeading: t("parentLocationHeading"),
                  lotHeading: t("lotHeading"),
                  expHeading: t("expHeading"),
                  minHeading: t("minHeading"),
                  maxHeading: t("maxHeading"),
                  qohHeading: t("qohHeading"),
                  costHeading: t("costHeading"),
                  site: item[0],
                  id: item[1],
                  description: item[2],
                  form: item[3],
                  manufacturer: item[4],
                  location: item[5],
                  parent: item[6],
                  lot: item[7],
                  exp: item[8],
                  min: item[9],
                  max: item[10],
                  qoh: item[11],
                  cost: item[12],
                }}
              />
            );
          })}
      </div>
    );
  }
};

export default InventoryList;
