import ApiService from "./ApiService";
import axios from 'axios';

class TrayService extends ApiService {

    static deleteTray = async (user, site, stockLocation, tray) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/trays/${tray._id}`;

            const rc = {
                headers:{'x-access-token': user.token},
            }

            return await axios.delete(url, rc);

        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static getUnapprovedTraysForDeviceByDispensingOrder = async (user, site, stockLocation) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/trays`;

            const rc = {
                headers:{'x-access-token': user.token},
            }

            let response = await axios.get(url, rc);
            return response.data;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static updateTray = async (user, site, stockLocation, tray) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/trays/${tray._id}`;
            const rc = {
                headers:{'x-access-token': user.token},
            }
            const response = await axios.put(url, tray, rc);
            return response.data;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static createTray = async (user, site, stockLocation, tray) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/trays`;
            const rc = {
                headers:{'x-access-token': user.token},
            }
            const response = await axios.post(url, tray, rc);
            return response.data;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static getTray = async (user, site, stockLocation, trayId) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/trays/${trayId}`;
            const rc = {
                headers:{'x-access-token': user.token},
            }
            const response = await axios.get(url, rc);
            return response.data;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default TrayService;
