import React from "react";
import styles from "./ProductLocationCard.module.css";

const ProductLocationCard = ({ data, onProductLocationCardClick = false }) => {
  return (
    <div
      className={styles.productLocationCard__container}
      onClick={(e) => onProductLocationCardClick(e)}
    >
      <div className={styles.productLocationCard__dataList}>
        <div className={styles.productLocationCard__rowGroup}>
          <div
            className={`${styles.productLocationCard__colGroup} ${styles["productLocationCard__colGroup--1"]}`}
          >
            <div className={`${styles["productLocationCard__heading"]}`}>
              {data.heading1}
            </div>
            <div className={`${styles["productLocationCard__value"]}`}>
              {data.item1}
            </div>
          </div>

          <div
            className={`${styles.productLocationCard__colGroup} ${styles["productLocationCard__colGroup--2"]}`}
          >
            <div className={`${styles["productLocationCard__heading"]}`}>
              {data.heading2}
            </div>
            <div className={`${styles["productLocationCard__value"]}`}>
              {data.item2}
            </div>
          </div>
        </div>

        <div className={styles.productLocationCard__rowGroup}>
          <div
            className={`${styles.productLocationCard__colGroup} ${styles["productLocationCard__colGroup--1"]}`}
          >
            <div className={`${styles["productLocationCard__heading"]}`}>
              {data.heading3}
            </div>
            <div className={`${styles["productLocationCard__value"]}`}>
              {data.item3}
            </div>
          </div>
        </div>

        <div className={styles.productLocationCard__rowGroup}>
          <div
            className={`${styles.productLocationCard__colGroup} ${styles["productLocationCard__colGroup--1"]} ${styles["productLocationCard__emailGroup"]} `}
          >
            <div className={`${styles["productLocationCard__heading"]}`}>
              {data.heading4}
            </div>
            <div className={`${styles["productLocationCard__value"]}`}>
              {data.item4}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLocationCard;
