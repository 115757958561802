import React, { useEffect, useRef, useContext, useState } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import CreateButton from "../../components/CreateButton";
import ProductStockLocationController from "./ProductStockLocationController";
import AddStockLocation from "./AddStockLocation/AddStockLocation";
import StockLocationAssociation from "./StockLocationAssociation/StockLocationAssociation";

const CreateStockLocationAsociations = ({ p, errors, user, onError }) => {
  const { t } = useTranslation("createProduct");
  const controller = new ProductStockLocationController();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [stockLocations, setStockLocations] = useState([]);
  const [sites, setSites] = useState([]);
  const [showAddStockLocation, setShowAddStockLocation] = useState(false);

  // get user's accessible sites
  useEffect(() => {
    (async () => {
      await controller.getAccessibleStockLocationsForUser(
        user,
        p,
        (sites, stockLocations) => {
          setSites(sites);
          setStockLocations(stockLocations);
        }
      );
    })();
  }, [user, p]);

  const onSave = async (stockLocation) => {
    await controller.update(user, stockLocation);
  };

  const onDelete = async (stockLocation) => {
    let index = stockLocation.productAssociations.findIndex(
      (o) => o.product._id === p._id
    );
    stockLocation.productAssociations.splice(index, 1);
    await controller.update(user, stockLocation);
    const arr = [...stockLocations];
    index = arr.findIndex((sl) => sl._id === stockLocation._id);
    arr.splice(index, 1);
    setStockLocations(arr);
  };

  return (
    <Accordion title={"Stock Locations"}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        {showAddStockLocation && (
          <AddStockLocation
            user={user}
            sites={sites}
            product={p}
            onClose={() => setShowAddStockLocation(false)}
            onAdd={(stockLocation) => {
              const arr = [...stockLocations];
              arr.push(stockLocation);
              arr.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (b.name < a.name) {
                  return 1;
                }
                return 0;
              });
              setStockLocations(arr);
              setShowAddStockLocation(false);
            }}
          />
        )}
        {!showAddStockLocation && (
          <div className={styles.CreateProductAccordion__col_left}>
            <div className={styles.CreateProductAccordion__row}>
              <CreateButton
                onClick={() => setShowAddStockLocation(true)}
                isEnabled={true}
                labelName="Add Stock Location"
              />
            </div>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Min</th>
                  <th>Max</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {stockLocations.map((stockLocation) => {
                  return (
                    <StockLocationAssociation
                      key={stockLocation._id}
                      stockLocation={stockLocation}
                      user={user}
                      product={p}
                      onSave={onSave}
                      onDelete={onDelete}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Accordion>
  );
};

export default CreateStockLocationAsociations;
