import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
} from "react";
import { NavbarContext } from "../../contexts/NavbarContext";
import BarcodeComponent from "./LabelComponents/BarcodeComponent";
import Rectangle from "./LabelComponents/Rectangle";
import Text from "./LabelComponents/Text";
import Repeater from "./LabelComponents/Repeater";
import Image from "./LabelComponents/Image";
import { placeHolderImage } from "./LabelComponents/placeHolderImage.js";
import RectangleProperties from "./PropertyComponents/RectangleProperties/RectangleProperties";
import BarcodeProperties from "./PropertyComponents/BarcodeProperties/BarcodeProperties";
import TextProperties from "./PropertyComponents/TextProperties/TextProperties";
import RepeaterProperties from "./PropertyComponents/RepeaterProperties/RepeaterProperties";
import ImageProperties from "./PropertyComponents/ImageProperties/ImageProperties";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import ModalBackdrop from "../../components/Modal";
import { ReactComponent as BarcodeSVG } from "./svgs/barcode2.svg";
import { ReactComponent as ImageSVG } from "./svgs/image.svg";
import { ReactComponent as RectangleSVG } from "./svgs/rectangle2.svg";
import { ReactComponent as RepeaterSVG } from "./svgs/repeater.svg";
import { ReactComponent as TextSVG } from "./svgs/text.svg";
import CindyCanisterLabelTemplate from "./labelTemplates/CindyCanisterLabelTemplate";
import styles from "./LabelEditor.module.css";

const LabelEditor = ({
  user,
  onResetCreateLabelStatesToDefaults,
  labelName,
  labelType,
  machineType,
  customizedLabel,
  onHideLabelCanvas,
  _controllerRef,
  selectedLabel,
  nodesArrayForUpdate,
  handleNodesArrayForUpdate,
  labelModificationInProgress,
  onLabelModificationInProgress,
}) => {
  const selectedObjectBorderColor = "#1F51FF";

  const [error, setError] = useState("");
  const [barcodeValueError, setBarcodeValueError] = useState(""); // 1

  const [initialRelativeMousePosition, setInitialRelativeMousePosition] =
    useState(null); // 2
  const [newNode, setNewNode] = useState(null); // 3

  const [canvasPositionData, setCanvasPositionData] = useState(null); // 4
  const canvasPositionDataRef = useRef(null); // 5
  canvasPositionDataRef.current = canvasPositionData;

  const [
    draggedExplorerObjectXPosmmAdjustment,
    setDraggedExplorerObjectXPosmmAdjustment,
  ] = useState(0); //6

  const [
    draggedExplorerObjectYPosmmAdjustment,
    setDraggedExplorerObjectYPosmmAdjustment,
  ] = useState(0); // 7

  const [dragPosition, setDragPosition] = useState(null); // 8
  const dragPositionRef = useRef(null); // 9
  dragPositionRef.current = dragPosition;

  const [nodes, setNodes] = useState([]); // 10
  const nodesRef = useRef(null); // 11
  nodesRef.current = nodes;

  const [componentDragged, setComponentDragged] = useState(null); // 12
  const componentDraggedRef = useRef(null); // 13
  componentDraggedRef.current = componentDragged;

  const [nodeDragged, setNodeDragged] = useState(null); // 14
  const nodeDraggedRef = useRef(null); // 15
  nodeDraggedRef.current = nodeDragged;

  const [nodeDraggedOver, setNodeDraggedOver] = useState(null); // 16
  const nodeDraggedOverRef = useRef(null); // 17
  nodeDraggedOverRef.current = nodeDraggedOver;

  const [nodeClicked, setNodeClicked] = useState(null); // 18
  const nodeClickedRef = useRef(null); // 19
  nodeClickedRef.current = nodeClicked;

  const [inputFocused, setInputFocused] = useState(false); //20
  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState(false); //21

  const [showModal, setShowModal] = useState(false); //22

  const canvasComponent = useRef(null);

  const RectangleObject = useRef(null);
  const BarcodeObject = useRef(null);
  const TextObject = useRef(null);
  const RepeaterObject = useRef(null);
  const ImageObject = useRef(null);

  const {
    labelModificationInProgressViaContext,
    handleLabelModificationInProgressViaContext,
  } = useContext(NavbarContext);

  const resetStatesToDefault = () => {
    setComponentDragged(null);
    setNodeDragged(null);
    setNodeClicked(null);
  };

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  let nodesArrayTemp = [];

  const buildNodesArray = (node, parent) => {
    // RECURSIVE CASE
    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node); // Traverse child nodes
    }
    if (!arrayContainsObject(node, nodesArrayTemp)) {
      if (parent) {
        node.parent = parent;
      }
      nodesArrayTemp.push(node);

      // sort nodesArrayTemp by nodeId, ascending order
      nodesArrayTemp.sort((a, b) => {
        return a.nodeId - b.nodeId;
      });
    }
    // BASE CASE
    return;
  };

  const handleSaveLabel = () => {
    nodes[0].xPosmm = 0;
    nodes[0].xPosmm = 0;
    _controllerRef.current
      .createLabel(labelName, labelType, machineType, customizedLabel, nodes[0])
      .then((result) => {
        if (result) {
          // after processing, reset label state and return to "label name" page
          resetStatesToDefault();
          setNodes([]);
          setNewNode(null);
          setDragPosition(null);
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
    handleLabelModificationInProgressViaContext(false);
  };

  const handleUpdateLabel = () => {
    nodes[0].xPosmm = 0;
    nodes[0].yPosmm = 0;
    _controllerRef.current
      .updateLabel(
        selectedLabel,
        labelName,
        labelType,
        machineType,
        customizedLabel,
        nodes[0]
      )
      .then((result) => {
        if (result) {
          // after processing, reset label state and return to "label name" page
          resetStatesToDefault();
          setNodes([]);
          setNewNode(null);
          setDragPosition(null);
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
      });
    handleLabelModificationInProgressViaContext(false);
  };

  useEffect(() => {
    // take out false to create Cindy label automatically
    if (
      false &&
      labelType === "Canister" &&
      machineType === "TruScript" &&
      !selectedLabel
    ) {
      const arrayContainsObject = (obj, array) => {
        return array.some((elem) => elem === obj);
      };

      let nodesArrayTemp = [];

      const buildNodesArray = (node, parent) => {
        // RECURSIVE CASE
        for (let i = 0; i < node["childNodes"]?.length; i++) {
          buildNodesArray(node["childNodes"][i], node); // Traverse child nodes
        }
        if (!arrayContainsObject(node, nodesArrayTemp)) {
          if (parent) {
            node.parent = parent;
          }
          nodesArrayTemp.push(node);

          // sort nodesArrayTemp by nodeId, ascending order
          nodesArrayTemp.sort((a, b) => {
            return a.nodeId - b.nodeId;
          });
        }
        // BASE CASE
        return;
      };

      buildNodesArray(CindyCanisterLabelTemplate, null);
      setNodes(nodesArrayTemp);
      setNodeClicked(nodesArrayTemp[1]);
      setComponentDragged("childRectangle");
      nodesArrayTemp = [];
    } else if (!selectedLabel) {
      // Automatically place root rectangle
      const rootNode = {
        nodeId: 0,
        parent: null,
        type: "rectangle",
        widthmm: "215.9",
        heightmm: "279.4",
        xPosmm: "0",
        yPosmm: "0",
        borderOn: true,
        borderColor: "#121a2d",
        padding: { bottom: 0, left: 0, right: 0, top: 0 },
        childNodes: [],
      };
      setNodes([rootNode]);
      setNodeClicked(rootNode);
      setComponentDragged("rectangle");
    }
  }, [selectedLabel, labelType]);

  useEffect(() => {
    // upon loading of label for edit, automatically select the first node
    if (selectedLabel && nodes.length && !nodeClicked) {
      setNodeClicked(nodes[0]);
    }
  }, [selectedLabel, nodes, nodes[0]]);

  useEffect(() => {
    if (nodesArrayForUpdate?.length) {
      setNodes(nodesArrayForUpdate);
    }
  }, [nodesArrayForUpdate]);

  useEffect(() => {
    // Get canvas position data and update if window size changes
    setCanvasPositionData(canvasComponent.current.getBoundingClientRect());

    const getCanvasPositionData = () => {
      setCanvasPositionData(canvasComponent.current.getBoundingClientRect());
    };

    window.addEventListener("resize", getCanvasPositionData);

    return () => window.removeEventListener("resize", getCanvasPositionData);
  }, []);

  useEffect(() => {
    // Set label node
    if (newNode?.type === "rectangle" && newNode?.parent === null) {
      setNodes((prevState) => {
        return [...prevState, newNode];
      });
    } else if (nodes.length && newNode) {
      // set label children nodes
      let tempNodes = [...nodes, newNode];
      tempNodes[newNode.parent.nodeId].childNodes.push(newNode);
      setNodes(tempNodes);
      setNewNode(null);
    }
  }, [newNode]);

  useEffect(() => {
    if (newNode) {
      setNodeClicked(newNode);
    }
  }, [newNode]);

  useEffect(() => {
    if (nodes.length) {
      // setNodeClicked(nodes[0]);
    }
  }, [nodes]);

  useEffect(() => {
    if (nodeDragged) {
      setNodeClicked(nodeDragged);
    }
  }, [nodeDragged]);

  const handleError = (msg) => {
    setError(msg);
  };

  const handleBarcodeValueError = (msg) => {
    setBarcodeValueError(msg);
  };

  const handleDragExplorerObject = (e) => {
    // console.log("handleDragExplorerObject");
    setDragPosition({
      xPos: e.clientX, // px
      xPosmm: (e.clientX * 0.2646) / 1, // mm
      yPos: e.clientY, // px
      yPosmm: (e.clientY * 0.2646) / 1, // mm
    });
  };

  const handleDragLabelObject = (e) => {
    // console.log("handleDragLabelObject");
    let xPos = e.clientX - initialRelativeMousePosition.x;
    let yPos = e.clientY - initialRelativeMousePosition.y;
    let xPosmm = xPos * 0.2646;
    let yPosmm = yPos * 0.2646;
    setDragPosition({
      xPos: xPos,
      xPosmm: xPosmm,
      yPos: yPos,
      yPosmm: yPosmm,
    });
    handleLabelModificationInProgressViaContext(true);
  };

  const handleDragOverLabelObject = (e) => {
    // console.log("handleDragOverLabelObject");
    e.preventDefault();
    e.stopPropagation();
    if (
      nodesRef.current[Number(e.currentTarget.getAttribute("position"))] !==
        nodeDraggedOver &&
      Number(e.currentTarget.getAttribute("position")) >= 1
    ) {
      setNodeDraggedOver(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    }
  };

  const handleDragStartLabelObject = (e) => {
    // console.log("handleDragStartLabelObject");
    e.stopPropagation();
    const initialRepeaterPositionObj = e.currentTarget.getBoundingClientRect();

    const initialMouseXPosition = e.clientX;
    const initialMouseYPosition = e.clientY;

    const relativeXMousePosition =
      initialMouseXPosition - initialRepeaterPositionObj.x;

    const relativeYMousePosition =
      initialMouseYPosition - initialRepeaterPositionObj.y;

    setInitialRelativeMousePosition({
      x: relativeXMousePosition,
      y: relativeYMousePosition,
    });
    if (Number(e.currentTarget.getAttribute("position")) >= 1) {
      setNodeDragged(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    }
  };

  const handleDropLabelObject = (e) => {
    // console.log("handleDropLabelObject");
    e.preventDefault();
    e.stopPropagation();
    setNodeDraggedOver(null);
    handleLabelModificationInProgressViaContext(true);
  };

  const handleNodes = (arrayOfNodes) => {
    setNodes(arrayOfNodes);
  };

  const handleUpdateNode = (nodeId, updatedNode) => {
    // console.log("handleUpdateNode");
    let tempNodes = [...nodes];
    tempNodes[nodeId] = updatedNode;
    setNodes(tempNodes);

    if (
      updatedNode &&
      updatedNode?.xPosmm &&
      updatedNode?.yPosmm &&
      nodesArrayForUpdate &&
      nodesArrayForUpdate.length
    ) {
      let tempNodesArrayForUpdate = [...nodesArrayForUpdate];
      tempNodesArrayForUpdate[nodeId].xPosmm = updatedNode.xPosmm;
      tempNodesArrayForUpdate[nodeId].yPosmm = updatedNode.yPosmm;
      handleNodesArrayForUpdate(tempNodesArrayForUpdate);
    }
  };

  const handleRectangleNodeClicked = (e) => {
    if (
      !(
        nodeClicked &&
        (nodeClicked.type === "rectangle" ||
          nodeClicked.type === "childRectangle")
      ) ||
      (nodeClicked &&
        Number(nodeClicked?.nodeId) !==
          Number(e.currentTarget.getAttribute("position")))
    ) {
      setNodeClicked(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    } else {
      setNodeClicked(null);
    }
  };

  const handleBarcodeNodeClicked = (e) => {
    if (
      !(nodeClicked && nodeClicked.type === "barcode") ||
      (nodeClicked &&
        Number(nodeClicked?.nodeId) !==
          Number(e.currentTarget.getAttribute("position")))
    ) {
      setNodeClicked(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    } else {
      setNodeClicked(null);
    }
  };

  const handleTextNodeClicked = (e) => {
    // console.log("handleTextNodeClicked");
    if (
      !(nodeClicked && nodeClicked.type === "text") ||
      (nodeClicked &&
        Number(nodeClicked?.nodeId) !==
          Number(e.currentTarget.getAttribute("position")))
    ) {
      setNodeClicked(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    } else {
      setNodeClicked(null);
    }
  };

  const handleRepeaterNodeClicked = (e) => {
    // console.log("handleRepeaterNodeClicked");
    if (
      !(nodeClicked && nodeClicked.type === "repeater") ||
      (nodeClicked &&
        Number(nodeClicked?.nodeId) !==
          Number(e.currentTarget.getAttribute("position")))
    ) {
      setNodeClicked(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    } else {
      setNodeClicked(null);
    }
  };

  const handleImageNodeClicked = (e) => {
    // console.log("handleImageNodeClicked");
    if (
      !(nodeClicked && nodeClicked.type === "image") ||
      (nodeClicked &&
        Number(nodeClicked?.nodeId) !==
          Number(e.currentTarget.getAttribute("position")))
    ) {
      setNodeClicked(
        nodesRef.current[Number(e.currentTarget.getAttribute("position"))]
      );
    } else {
      setNodeClicked(null);
    }
  };

  /*****************************************/
  // place child node into parent
  /*****************************************/
  const handleDropIntoLabel = (e, parentId) => {
    // The parentId is also the index position of the parent in the nodes array

    // node is the parent object (the object getting dropped into)
    const node = nodesRef.current[parentId];
    if (
      (nodeDragged === null ||
        nodeDragged.parent === nodesRef.current[parentId]) &&
      !(componentDragged === "image" && node.type === "rectangle")
      // Commented out on 02/10/2024 to allow an image to be dropped into a child rectangle
      // && !(componentDragged === "image" && node.type === "childRectangle")
    ) {
      if (nodesRef.current.length && !nodeDraggedRef.current) {
        const canvasXPosmm = (Number(canvasPositionData.x) * 0.2646) / 1; // convert px to mm
        const canvasYPosmm = (Number(canvasPositionData.y) * 0.2646) / 1; // convert px to mm

        // The parentId is also the index position of the parent in the nodes array

        // initialize
        let xPosmm = 0;
        let yPosmm = 0;
        let widthmm;
        let heightmm;
        let parentparentPaddingLeft = 0;
        let parentparentPaddingTop = 0;
        let parentXPosmm = 0;
        let parentYPosmm = 0;
        let referencedNode = { ...node };

        // Set dropped rectangle to one half size of its parent
        const widthRectangle = Number(nodesRef.current[parentId].widthmm * 0.5); // mm
        const heightRectangle = Number(
          nodesRef.current[parentId].heightmm * 0.5
        ); //mm

        const widthBarcode = 32; // mm
        const heightBarcode = 24; // mm

        const widthText = 20; // mm
        const heightText = 8; // mm

        const widthRepeater = 60; // mm
        const heightRepeater = 26; // mm

        const widthImage = 30; // px
        const heightImage = 30; // px

        let i = 0;
        while (true) {
          parentXPosmm += Number(referencedNode.xPosmm);

          if (referencedNode.parent) {
            parentparentPaddingLeft += Number(
              referencedNode.padding.left * 0.2646
            );
          }

          parentYPosmm += Number(referencedNode.yPosmm);

          if (referencedNode.parent) {
            parentparentPaddingTop += Number(
              referencedNode.padding.top * 0.2646
            );
          }
          if (referencedNode.parent !== null) {
            referencedNode = referencedNode.parent;
          } else {
            break;
          }
        }

        xPosmm = (
          Number(dragPositionRef.current.xPosmm) -
          Number(draggedExplorerObjectXPosmmAdjustment) -
          Number(canvasXPosmm) -
          Number(parentXPosmm) -
          Number(parentparentPaddingTop)
        ).toFixed(1);

        yPosmm = (
          Number(dragPositionRef.current.yPosmm) -
          Number(draggedExplorerObjectYPosmmAdjustment) -
          Number(canvasYPosmm) -
          Number(parentYPosmm) -
          Number(parentparentPaddingTop)
        ).toFixed(1);

        if (Number(xPosmm) < 0) {
          xPosmm = 0;
        }

        if (Number(yPosmm) < 0) {
          yPosmm = 0;
        }

        if (componentDraggedRef.current === "rectangle") {
          widthmm = widthRectangle;
          heightmm = heightRectangle;
          if (Number(xPosmm) + Number(widthmm) > Number(node.widthmm)) {
            xPosmm = node.widthmm - node.widthmm / 2;
          }
          if (Number(yPosmm) + Number(heightmm) > Number(node.heightmm)) {
            yPosmm = node.heightmm - node.heightmm / 2;
          }
        } else if (componentDraggedRef.current === "barcode") {
          widthmm = widthBarcode;
          heightmm = heightBarcode;
          if (Number(xPosmm) + Number(widthmm) > Number(node.widthmm)) {
            xPosmm = node.widthmm - widthmm;
          }
          if (Number(yPosmm) + Number(heightmm) > Number(node.heightmm)) {
            yPosmm = node.heightmm - heightmm;
          }
        } else if (componentDraggedRef.current === "text") {
          widthmm = widthText;
          heightmm = heightText;
          if (Number(xPosmm) + Number(widthmm) > Number(node.widthmm)) {
            xPosmm = node.widthmm - widthmm;
          }
          if (Number(yPosmm) + Number(heightmm) > Number(node.heightmm)) {
            yPosmm = node.heightmm - heightmm;
          }
        } else if (componentDraggedRef.current === "repeater") {
          widthmm = widthRepeater;
          heightmm = heightRepeater;
          if (Number(xPosmm) + Number(widthmm) > Number(node.widthmm)) {
            xPosmm = node.widthmm - widthmm;
          }
          if (Number(yPosmm) + Number(heightmm) > Number(node.heightmm)) {
            yPosmm = node.heightmm - heightmm;
          }
        } else if (componentDraggedRef.current === "image") {
          widthmm = widthImage * 0.2646; // convert px to mm
          heightmm = heightImage * 0.2646; // convert px to mm
          if (Number(xPosmm) + Number(widthmm) > Number(node.widthmm)) {
            xPosmm = node.widthmm - widthmm;
          }
          if (Number(yPosmm) + Number(heightmm) > Number(node.heightmm)) {
            yPosmm = node.heightmm - heightmm;
          }
        }

        let nodeToAdd;

        if (componentDraggedRef.current === "rectangle") {
          nodeToAdd = {
            nodeId: nodesRef.current.length,
            parent: nodesRef.current[parentId],
            type: "childRectangle",
            widthmm: widthRectangle.toString(),
            heightmm: heightRectangle.toString(),
            xPosmm: xPosmm.toString(),
            yPosmm: yPosmm.toString(),
            borderOn: true,
            borderColor: "#121a2d",
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
            childNodes: [],
          };
        }

        if (componentDraggedRef.current === "barcode") {
          nodeToAdd = {
            nodeId: nodesRef.current.length,
            parent: nodesRef.current[parentId],
            type: componentDraggedRef.current,
            xPosmm: xPosmm.toString(),
            yPosmm: yPosmm.toString(),
            widthmm: widthBarcode.toString(),
            heightmm: heightBarcode.toString(),
            symbology: "Code128A",
            propertyName: "bagBarcode",
            defaultValueCode128A: "BAG 001",
            defaultValueCode128B: "Bag 001",
            defaultValueCode39: "BAG 001",
            defaultValueQRBarcode: "123~Happy Pill~ghtrs",
            defaultValueDataMatrix: "BAG 001",
            value: "",
            showValue: true,
            mapping: {
              friendlyName: "Default Value",
              path: "",
              value: "BAG 001",
            },
            childNodes: [],
          };
        } else if (componentDraggedRef.current === "text") {
          nodeToAdd = {
            nodeId: nodesRef.current.length,
            parent: nodesRef.current[parentId],
            type: componentDraggedRef.current,
            xPosmm: xPosmm.toString(),
            yPosmm: yPosmm.toString(),
            widthmm: widthText.toString(),
            heightmm: heightText.toString(),
            textColor: "#000000",
            propertyName: "bagNumber",
            defaultValue: "Bag 001",
            value: "",
            font: "Poppins",
            bold: false,
            italic: false,
            underline: false,
            textAlignment: "left",
            fontSize: "14",
            wordWrap: false,
            textLines: 1,
            mapping: {
              friendlyName: "Default Value",
              path: "",
              value: "Bag 001",
            },
            childNodes: [],
          };
        } else if (componentDraggedRef.current === "repeater") {
          nodeToAdd = {
            defaultValue: "repeater",
            nodeId: nodesRef.current.length,
            parent: nodesRef.current[parentId],
            type: componentDraggedRef.current,
            xPosmm: xPosmm.toString(),
            yPosmm: yPosmm.toString(),
            widthmm: widthRepeater.toFixed(1).toString(),
            heightmm: heightRepeater.toFixed(1).toString(),
            gapmm: "3",
            propertyName: "drugRepeater",
            orientation: "Vertical",
            repeatCount: 1,
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
            childNodes: [],
          };
        } else if (componentDraggedRef.current === "image") {
          nodeToAdd = {
            nodeId: nodesRef.current.length,
            parent: nodesRef.current[parentId],
            type: componentDraggedRef.current,
            xPosmm: xPosmm.toString(),
            yPosmm: yPosmm.toString(),
            width: widthImage.toString(),
            height: heightImage.toString(),
            base64EncodedString: placeHolderImage,
            mimeType: "image/jpeg",
            childNodes: [],
            getImagesFromDatabase: true,
            ignoreImage: false,
          };
        }

        setNewNode(nodeToAdd);
        onLabelModificationInProgress(true);
      } else if (
        nodeDraggedRef.current &&
        (nodeDraggedRef.current.parent.type !== "repeater" ||
          nodeDraggedOverRef.current.type === "repeater")
      ) {
        /*****************************************/
        // update node if dragged
        /*****************************************/
        const canvasXPosmm = canvasPositionData.x * 0.2646; // convert px to mm
        const canvasYPosmm = canvasPositionData.y * 0.2646; // convert px to mm

        // initialize
        let parentparentPaddingLeft = 0;
        let parentparentPaddingTop = 0;
        let parentXPosition = 0;
        let parentYPosition = 0;

        let referencedNode = { ...node };

        while (true) {
          parentXPosition += Number(referencedNode.xPosmm);
          if (referencedNode.parent) {
            parentparentPaddingLeft += Number(
              referencedNode.parent.padding.left * 0.2646
            );
          }
          parentYPosition += Number(referencedNode.yPosmm);
          if (referencedNode.parent) {
            parentparentPaddingTop += Number(
              referencedNode.parent.padding.top * 0.2646
            );
          }
          if (referencedNode.parent !== null) {
            referencedNode = referencedNode.parent;
          } else {
            break;
          }
        }

        parentXPosition = parentXPosition + canvasXPosmm;
        parentYPosition = parentYPosition + canvasYPosmm;

        const draggedObjectXPosition =
          (e.clientX - initialRelativeMousePosition.x) * 0.2646;

        const draggedObjectYPosition =
          (e.clientY - initialRelativeMousePosition.y) * 0.2646;

        let xPosmm = (
          draggedObjectXPosition -
          parentXPosition -
          Number(parentparentPaddingLeft)
        ).toFixed(1);
        let yPosmm = (
          draggedObjectYPosition -
          parentYPosition -
          Number(parentparentPaddingTop)
        ).toFixed(1);

        let draggedNodeWidthmm;
        let draggedNodeHeightmm;

        if (nodeDraggedRef.current.type === "image") {
          draggedNodeWidthmm = Number(nodeDraggedRef.current.width) * 0.2646;
        } else {
          draggedNodeWidthmm = Number(nodeDraggedRef.current.widthmm);
        }

        if (nodeDraggedRef.current.type === "image") {
          draggedNodeHeightmm = Number(nodeDraggedRef.current.height) * 0.2646;
        } else {
          draggedNodeHeightmm = Number(nodeDraggedRef.current.heightmm);
        }

        if (Number(xPosmm) < 0) {
          xPosmm = 0;
        } else if (Number(xPosmm) + draggedNodeWidthmm > Number(node.widthmm)) {
          xPosmm = Number(node.widthmm) - draggedNodeWidthmm;
        } else if (
          nodeDraggedRef.current.type.type === "rectangle" &&
          Number(xPosmm) + Number(node.widthmm) / 2 > Number(node.widthmm)
        ) {
          xPosmm = node.widthmm - node.widthmm / 2;
          if (
            Number(xPosmm) + Number(draggedNodeWidthmm) >
            Number(node.widthmm)
          ) {
            xPosmm = node.widthmm - node.widthmm / 2;
          }
          if (
            Number(yPosmm) + Number(draggedNodeHeightmm) >
            Number(node.heightmm)
          ) {
            yPosmm = node.heightmm - node.heightmm / 2;
          }
        }

        if (Number(yPosmm) < 0) {
          yPosmm = 0;
        } else if (
          Number(yPosmm) + draggedNodeHeightmm >
          Number(node.heightmm)
        ) {
          yPosmm = Number(node.heightmm) - draggedNodeHeightmm;
        }

        xPosmm = xPosmm.toString();
        yPosmm = yPosmm.toString();

        let tempNodes = [...nodesRef.current];

        tempNodes[nodeDraggedRef.current.nodeId].xPosmm = xPosmm;

        tempNodes[nodeDraggedRef.current.nodeId].yPosmm = yPosmm;

        onLabelModificationInProgress(true);
        setNodes([...tempNodes]);
      }
    }
    setNodeDragged(null);
    setComponentDragged(null);
  };

  // Create label node (only one allowed)
  const handleDropIntoCanvas = (e) => {
    if (!nodes.length) {
      const nodeToAdd = {
        nodeId: 0,
        parent: null,
        type: "rectangle",
        widthmm: "215.9",
        heightmm: "279.4",
        xPosmm: "0",
        yPosmm: "0",
        borderOn: true,
        borderColor: "#121a2d",
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        childNodes: [],
      };
      setNewNode(nodeToAdd);
    }
  };

  const ObjectExplorer = (
    <div className={styles.LabelEditor__objectExplorer} ref={RectangleObject}>
      <header>
        <h2>Object Explorer</h2>
        <div className={styles.LabelEditor__objectExplorerGroup}>
          <div
            id="rectangle"
            className={styles.LabelEditor__svgContainer}
            draggable={true}
            onDragStart={(e) => {
              let RectangleObjectPositionData =
                RectangleObject.current.getBoundingClientRect();
              setDraggedExplorerObjectXPosmmAdjustment(
                (e.clientX - RectangleObjectPositionData.x) * 0.2646
              ); // convert pixels to mm
              setDraggedExplorerObjectYPosmmAdjustment(
                (e.clientY - RectangleObjectPositionData.y) * 0.2646 - 14
              ); // convert pixels to mm

              setComponentDragged("rectangle");
            }}
            onDrag={
              nodes.length ? (e) => handleDragExplorerObject(e) : () => {}
            }
          >
            <div className={styles.LabelEditor__objectExplorerSVGContainer}>
              <div>
                <RectangleSVG />
              </div>
            </div>
          </div>
          <p className={styles.LabelEditor__objectExplorerTypeName}>
            Rectangle
          </p>
        </div>
        <div
          className={styles.LabelEditor__objectExplorerGroup}
          ref={BarcodeObject}
        >
          <div
            id="barcode"
            className={styles.LabelEditor__svgContainer}
            draggable={nodes.length ? true : false}
            onDragStart={(e) => {
              let BarcodeObjectPositionData =
                BarcodeObject.current.getBoundingClientRect();
              setDraggedExplorerObjectXPosmmAdjustment(
                (e.clientX - BarcodeObjectPositionData.x) * 0.2646
              ); // convert pixels to mm
              setDraggedExplorerObjectYPosmmAdjustment(
                (e.clientY - BarcodeObjectPositionData.y) * 0.2646
              ); // convert pixels to mm

              setComponentDragged("barcode");
            }}
            onDrag={(e) => handleDragExplorerObject(e)}
          >
            <div
              className={
                nodes.length
                  ? styles.LabelEditor__objectExplorerSVGContainer
                  : `${styles.LabelEditor__objectExplorerSVGContainer} ${styles["LabelEditor__objectExplorerSVGContainer--inactive"]}`
              }
            >
              <div>
                <BarcodeSVG />
              </div>
            </div>
          </div>
          <p
            className={
              nodes.length
                ? styles.LabelEditor__objectExplorerTypeName
                : `${styles.LabelEditor__objectExplorerTypeName} ${styles["LabelEditor__objectExplorerTypeName--inactive"]}`
            }
          >
            Barcode
          </p>
        </div>
        <div
          className={styles.LabelEditor__objectExplorerGroup}
          ref={TextObject}
        >
          <div
            id="text"
            className={styles.LabelEditor__svgContainer}
            draggable={nodes.length ? true : false}
            onDragStart={(e) => {
              let TextObjectPositionData =
                TextObject.current.getBoundingClientRect();
              setDraggedExplorerObjectXPosmmAdjustment(
                (e.clientX - TextObjectPositionData.x) * 0.2646
              ); // convert pixels to mm
              setDraggedExplorerObjectYPosmmAdjustment(
                (e.clientY - TextObjectPositionData.y) * 0.2646
              ); // convert pixels to mm

              setComponentDragged("text");
            }}
            onDrag={(e) => handleDragExplorerObject(e)}
          >
            <div
              className={
                nodes.length
                  ? styles.LabelEditor__objectExplorerTextSVGContainer
                  : `${styles.LabelEditor__objectExplorerTextSVGContainer} ${styles["LabelEditor__objectExplorerTextSVGContainer--inactive"]}`
              }
            >
              <div
                className={
                  nodes.length
                    ? styles.LabelEditor__objectExplorerSVGContainer
                    : `LabelEditor__objectExplorerTextSVGContainer ${styles["LabelEditor__objectExplorerTextSVGContainer--inactive"]}`
                }
              >
                <TextSVG />
              </div>
            </div>
          </div>
          <p
            className={
              nodes.length
                ? styles.LabelEditor__objectExplorerTypeName
                : `${styles.LabelEditor__objectExplorerTypeName} ${styles["LabelEditor__objectExplorerTypeName--inactive"]}`
            }
          >
            Text
          </p>
        </div>

        <div
          className={styles.LabelEditor__objectExplorerGroup}
          ref={RepeaterObject}
        >
          <div
            className={styles.LabelEditor__svgContainer}
            draggable={nodes.length ? true : false}
            onDragStart={(e) => {
              let RepeaterObjectPositionData =
                RepeaterObject.current.getBoundingClientRect();
              setDraggedExplorerObjectXPosmmAdjustment(
                (e.clientX - RepeaterObjectPositionData.x) * 0.2646
              ); // convert pixels to mm
              setDraggedExplorerObjectYPosmmAdjustment(
                (e.clientY - RepeaterObjectPositionData.y) * 0.2646
              ); // convert pixels to mm

              setComponentDragged("repeater");
            }}
            onDrag={(e) => handleDragExplorerObject(e)}
          >
            <div
              className={
                nodes.length
                  ? styles.LabelEditor__objectExplorerSVGContainer
                  : `${styles.LabelEditor__objectExplorerSVGContainer} ${styles["LabelEditor__objectExplorerSVGContainer--inactive"]}`
              }
            >
              <div
                className={
                  nodes.length
                    ? styles.LabelEditor__objectExplorerSVGContainer
                    : `LabelEditor__objectExplorerSVGContainer ${styles["LabelEditor__objectExplorerSVGContainer--inactive"]}`
                }
              >
                <RepeaterSVG />
              </div>
            </div>
          </div>
          <p
            className={
              nodes.length
                ? styles.LabelEditor__objectExplorerTypeName
                : `${styles.LabelEditor__objectExplorerTypeName} ${styles["LabelEditor__objectExplorerTypeName--inactive"]}`
            }
          >
            Repeater
          </p>
        </div>

        <div
          className={styles.LabelEditor__objectExplorerGroup}
          ref={ImageObject}
        >
          <div
            id="image"
            className={styles.LabelEditor__svgContainer}
            draggable={nodes.length ? true : false}
            onDragStart={(e) => {
              let ImageObjectPositionData =
                ImageObject.current.getBoundingClientRect();
              setDraggedExplorerObjectXPosmmAdjustment(
                (e.clientX - ImageObjectPositionData.x) * 0.2646
              ); // convert pixels to mm
              setDraggedExplorerObjectYPosmmAdjustment(
                (e.clientY - ImageObjectPositionData.y) * 0.2646
              ); // convert pixels to mm

              setComponentDragged("image");
            }}
            onDrag={(e) => handleDragExplorerObject(e)}
          >
            <div
              className={
                nodes.length
                  ? styles.LabelEditor__objectExplorerImageSVGContainer
                  : `${styles.LabelEditor__objectExplorerTextSVGContainer} ${styles["LabelEditor__objectExplorerImageSVGContainer--inactive"]}`
              }
            >
              <div
                className={
                  nodes.length
                    ? styles.LabelEditor__objectExplorerSVGContainer
                    : `LabelEditor__objectExplorerImageSVGContainer ${styles["LabelEditor__objectExplorerImageSVGContainer--inactive"]}`
                }
              >
                <ImageSVG />
              </div>
            </div>
          </div>
          <p
            className={
              nodes.length
                ? styles.LabelEditor__objectExplorerTypeName
                : `${styles.LabelEditor__objectExplorerTypeName} ${styles["LabelEditor__objectExplorerTypeName--inactive"]}`
            }
          >
            Image
          </p>
        </div>
      </header>
    </div>
  );

  const handleShowModal = (boolVal) => {
    setShowModal(boolVal);
  };

  const handleInputFocused = (boolVal) => {
    setInputFocused(boolVal);
  };

  const handleShowDeleteNodeWarning = (boolVal) => {
    setShowDeleteNodeWarning(boolVal);
  };

  const handleDeleteNode = (selectedNode) => {
    let updatedNodes = [];
    if (selectedNode.parent === null) {
      updatedNodes = [];
    } else {
      let nodesToDelete = [];

      const pushChildNodes = (node) => {
        for (let i = 0; i < node.childNodes.length; i++) {
          pushChildNodes(node.childNodes[i]);
        }
        nodesToDelete.push(node);
      };

      pushChildNodes(selectedNode);

      // update parent node
      const parentNode = selectedNode.parent;
      const updatedChildNodes = parentNode.childNodes.filter((childNode) => {
        return childNode !== selectedNode;
      });

      parentNode.childNodes = updatedChildNodes;

      updatedNodes = nodesRef.current.filter((nodeObj) => {
        return !nodesToDelete.includes(nodeObj);
      });

      // update ids to match position in the nodes array
      updatedNodes.forEach((node, index) => {
        node.nodeId = index;
      });
    }
    handleNodes(updatedNodes);
    resetStatesToDefault();
  };

  let propertiesHeading = "Properties";

  if (
    nodeClicked &&
    (nodeClicked.type === "rectangle" || nodeClicked.type === "childRectangle")
  ) {
    propertiesHeading += " - Rectangle";
  } else if (nodeClicked && nodeClicked.type === "text") {
    propertiesHeading += " - Text";
  } else if (nodeClicked && nodeClicked.type === "barcode") {
    propertiesHeading += " - Barcode";
  } else if (nodeClicked && nodeClicked.type === "repeater") {
    propertiesHeading += " - Repeater";
  } else if (nodeClicked && nodeClicked.type === "image") {
    propertiesHeading += " - Image";
  }

  let props;

  if (nodeClicked) {
    props = {
      activeNode: nodeClickedRef.current,
      nodes: nodes,
      nodesRef: nodesRef,
      onNodes: handleNodes,
      onShowDeleteNodeWarning: handleShowDeleteNodeWarning,
      onInputFocused: handleInputFocused,
      labelType: labelType,
      error: error,
      onError: handleError,
      barcodeValueError: barcodeValueError,
      onBarcodeValueError: handleBarcodeValueError,
      labelModificationInProgress: labelModificationInProgress,
      onLabelModificationInProgress: onLabelModificationInProgress,
      onShowModal: handleShowModal,
    };
  }

  const Properties = (
    <div className={styles.LabelEditor__properties}>
      <header>
        <h2>{propertiesHeading}</h2>
      </header>

      {nodeClicked &&
        (nodeClicked.type === "rectangle" ||
          nodeClicked.type === "childRectangle") && (
          <RectangleProperties {...props} />
        )}
      {nodeClicked && nodeClicked.type === "barcode" && (
        <BarcodeProperties {...props} />
      )}
      {nodeClicked && nodeClicked.type === "text" && (
        <TextProperties {...props} />
      )}
      {nodeClicked && nodeClicked.type === "repeater" && (
        <RepeaterProperties {...props} />
      )}
      {nodeClicked && nodeClicked.type === "image" && (
        <ImageProperties {...props} />
      )}
    </div>
  );

  const GetCanvasComponent = (children) => {
    return (
      <div
        onClick={() => handleInputFocused(false)}
        ref={canvasComponent}
        className={styles.LabelEditor__canvas}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => handleDropIntoCanvas(e)}
        onDragEnter={(e) => {}}
        onDragLeave={(e) => {}}
        onDragStart={(e) => {
          setComponentDragged("Canvas");
        }}
      >
        {children}
      </div>
    );
  };

  const getRectangleComponent = (node, children) => {
    return (
      <Rectangle
        key={node.nodeId}
        nodesRef={nodesRef}
        node={node}
        nodeClicked={nodeClickedRef.current}
        handleUpdateNode={handleUpdateNode}
        canvasPositionData={canvasPositionData}
        onClick={(e) => {
          handleRectangleNodeClicked(e);
          handleInputFocused(false);
          nodesRef.current.length && e.stopPropagation();
        }}
        onDragOver={(e) => handleDragOverLabelObject(e)}
        onDrop={(e) => {
          handleDropLabelObject(e);
          if (node.nodeId === 0) {
            handleDropIntoLabel(e, 0);
          } else if (
            // Commented out on 02/10/2024 to allow an image to be dropped into a child rectangle
            // componentDraggedRef.current !== "image" &&
            (nodeDraggedRef.current === null &&
              componentDraggedRef.current !== "childRectangle") ||
            nodeDraggedRef.current?.parent?.type === "childRectangle"
            // Commented out on 02/10/2024 to allow an image to be dropped into a child rectangle
            // && componentDraggedRef.current !== "image"
          ) {
            handleDropIntoLabel(e, node.nodeId);
          } else {
            setNodeDragged(null);
            setNodeDraggedOver(null);
            setComponentDragged(null);
          }
        }}
        nodeDragged={nodeDraggedRef.current}
        onDragStart={
          node.id === 0 ? () => {} : (e) => handleDragStartLabelObject(e)
        }
        onDrag={node.id === 0 ? () => {} : (e) => handleDragLabelObject(e)}
        selectedObjectBorderColor={selectedObjectBorderColor}
        onInputFocused={handleInputFocused}
      >
        {children}
      </Rectangle>
    );
  };

  const getBarcodeComponent = (node, children) => {
    return (
      <BarcodeComponent
        key={node.nodeId}
        node={node}
        nodeClicked={nodeClickedRef.current}
        onClick={(e) => {
          handleBarcodeNodeClicked(e);
          handleInputFocused(false);
          e.stopPropagation();
        }}
        nodeDragged={nodeDraggedRef.current}
        onDrop={(e) => handleDropLabelObject(e)}
        onDragOver={(e) => handleDragOverLabelObject(e)}
        onDragStart={(e) => handleDragStartLabelObject(e)}
        onDrag={(e) => handleDragLabelObject(e)}
        selectedObjectBorderColor={selectedObjectBorderColor}
        onInputFocused={handleInputFocused}
      >
        {children}
      </BarcodeComponent>
    );
  };

  const getTextComponent = (node, children) => {
    return (
      <Text
        key={node.nodeId}
        node={node}
        nodeClicked={nodeClickedRef.current}
        onClick={(e) => {
          handleTextNodeClicked(e);
          e.stopPropagation();
        }}
        nodeDragged={nodeDraggedRef.current}
        onDrop={(e) => handleDropLabelObject(e)}
        onDragOver={(e) => handleDragOverLabelObject(e)}
        onDragStart={(e) => handleDragStartLabelObject(e)}
        onDrag={(e) => handleDragLabelObject(e)}
        selectedObjectBorderColor={selectedObjectBorderColor}
        onInputFocused={handleInputFocused}
      >
        {children}
      </Text>
    );
  };

  const getRepeaterComponent = (
    node,
    children,
    ghost = false,
    ghostIndex = -1,
    copy = false
  ) => {
    let key;

    if (!copy) {
      key = `${node.nodeId}`;
    } else {
      key = `${node.nodeId}-${copy}-${ghostIndex}`;
    }

    return (
      <Repeater
        key={key}
        node={node}
        nodeClicked={nodeClickedRef.current}
        nodeDragged={nodeDraggedRef.current}
        onClick={(e) => {
          ghostIndex === -1 && handleRepeaterNodeClicked(e);
          handleInputFocused(false);
          e.stopPropagation();
          ghostIndex !== -1 && e.preventDefault(e);
        }}
        // prevent event bubbling on ghost
        onClickCapture={!ghost ? () => {} : (e) => e.stopPropagation()}
        onDragCapture={
          !ghost
            ? () => {}
            : (e) => {
                e.stopPropagation();
              }
        }
        onDropCapture={!ghost ? () => {} : (e) => e.stopPropagation()}
        onDrop={(e) => {
          handleDropLabelObject(e);
          if (
            true
            // componentDraggedRef.current !== "barcode" &&
            // componentDraggedRef.current !== "repeater"
          ) {
            handleDropIntoLabel(e, node.nodeId);
          }
        }}
        onDragOver={
          ghostIndex === -1 ? (e) => handleDragOverLabelObject(e) : () => {}
        }
        onDragStart={
          ghostIndex === -1 ? (e) => handleDragStartLabelObject(e) : () => {}
        }
        onDrag={ghostIndex === -1 ? (e) => handleDragLabelObject(e) : () => {}}
        ghost={ghost}
        ghostIndex={ghostIndex}
        selectedObjectBorderColor={selectedObjectBorderColor}
        onInputFocused={handleInputFocused}
        copy={copy}
      >
        {children}
      </Repeater>
    );
  };

  const getImageComponent = (node, children) => {
    return (
      <Image
        key={node.nodeId}
        node={node}
        nodeClicked={nodeClickedRef.current}
        onClick={(e) => {
          handleImageNodeClicked(e);
          handleInputFocused(false);
          e.stopPropagation();
        }}
        onDrop={(e) => handleDropLabelObject(e)}
        nodeDragged={nodeDraggedRef.current}
        onDragOver={(e) => handleDragOverLabelObject(e)}
        onDragStart={(e) => handleDragStartLabelObject(e)}
        onDrag={(e) => handleDragLabelObject(e)}
        selectedObjectBorderColor={selectedObjectBorderColor}
        onInputFocused={handleInputFocused}
      >
        {children}
      </Image>
    );
  };

  // Traverse node tree recursively, starting with label child nodes

  let nodeIds = [];

  const traverseLabelChildren = (node) => {
    if (node.childNodes.length) {
      const NodeComponents = node.childNodes.map((childNode) => {
        if (childNode.type === "childRectangle") {
          return getRectangleComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        }
        if (childNode.type === "barcode") {
          return getBarcodeComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        } else if (childNode.type === "text") {
          return getTextComponent(
            childNode,
            traverseLabelChildren(childNode),
            false,
            -1,
            true
          );
        } else if (childNode.type === "repeater") {
          let nodeFound = false;

          if (nodeIds.indexOf(childNode.nodeId.toString()) !== -1) {
            nodeFound = true;
          }

          let content = [
            getRepeaterComponent(
              childNode,
              traverseLabelChildren(childNode),
              false,
              -1,
              nodeFound
            ),
          ];

          if (nodeIds.indexOf(childNode.nodeId.toString()) === -1) {
            nodeIds.push(childNode.nodeId.toString());
          }

          for (let i = 0; i < childNode.repeatCount; i++) {
            content.push(
              getRepeaterComponent(
                childNode,
                traverseLabelChildren(childNode),
                true,
                i,
                true
              )
            );
          }
          return content;
        } else if (childNode.type === "image") {
          return getImageComponent(childNode, traverseLabelChildren(childNode));
        }
      });
      return NodeComponents;
    } else {
      return;
    }
  };

  const handleDeleteKey = (e) => {
    if (nodeClicked) {
      if (!inputFocused && (e.keyCode === 46 || e.keyCode === 8)) {
        setShowDeleteNodeWarning(true);
      }
    }
  };
  const DeleteWarning = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.LabelEditor__deleteWarningModal}>
        <p>Do you really want to delete this node and its children?</p>
        <div className={styles.LabelEditor__ModalButtonsGroup}>
          <Button
            labelName="Cancel"
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => setShowDeleteNodeWarning(false)}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName="Delete"
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              setShowDeleteNodeWarning(false);
              handleDeleteNode(nodeClicked);
            }}
            isDisabled={error ? true : false}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  const handlePrevious = (overrideLabelModificationInProgress = false) => {
    if (
      !selectedLabel &&
      !overrideLabelModificationInProgress &&
      labelModificationInProgressViaContext
    ) {
      setShowModal(true);
    } else if (
      selectedLabel ||
      !labelModificationInProgressViaContext ||
      overrideLabelModificationInProgress
    ) {
      onHideLabelCanvas();
    }
  };

  const Modal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.LabelEditor__deleteWarningModal}>
        <p className={styles.LabelEditor__deleteWarningModalMessage}>
          Do you really want to continue to the previous screen? Your changes
          are not saved and would be lost.
        </p>
        <div className={styles.LabelEditor__ModalButtonsGroup}>
          <Button
            labelName="Cancel"
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName="Continue"
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              handleLabelModificationInProgressViaContext(false);
              setShowModal(false);
              handlePrevious(true);
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  return (
    <Fragment>
      {showModal && Modal}
      <div className={styles.LabelEditor__labelAndButtonsContainer}>
        <header>
          <h2 className={styles.LabelEditor__labelEditorHeading}>
            Label Editor
          </h2>
        </header>
        <div className={styles.LabelEditor__groupContainer}>
          {showDeleteNodeWarning && DeleteWarning}

          <div
            className={styles.LabelEditor__labelEditorContainer}
            tabIndex="0"
            onKeyDown={(e) => handleDeleteKey(e)}
          >
            {ObjectExplorer}
            {GetCanvasComponent(
              // create label and start recursive traversal across all child nodes
              nodes.length
                ? [
                    getRectangleComponent(
                      nodes[0],
                      traverseLabelChildren(nodes[0])
                    ),
                  ]
                : null
            )}
            {Properties}
          </div>
        </div>
        <div className={styles.LabelEditor__buttonsContainer}>
          <Button
            labelName={"Previous"}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => handlePrevious()}
            isDisabled={!(error || barcodeValueError) ? false : true}
          />
          <Spacer space={20} unit={"px"} />
          <div
            onClick={
              nodesArrayForUpdate?.length ? handleUpdateLabel : handleSaveLabel
            }
          >
            <Button
              labelName={
                nodesArrayForUpdate?.length ? "Update Label" : "Save Label"
              }
              isPrimary={true}
              minWidth={"213px"}
              isDisabled={
                nodes.length && !(error || barcodeValueError) ? false : true
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LabelEditor;
