import { useEffect, useContext, useState, useMemo, useCallback } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import ButtonGen from "../../commonUI/ButtonGen";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./PrintMedList.module.css";
import PatientList from "./PatientList";
import PDFDocumentViewer from "./PDFDocumentViewer";
import MedListsDocument from "./MedListsDocument";
import FacilitiesRedirect from "./FacilitiesRedirect";

const PrintMedList = ({
  patients,
  medLists,
  selectedSiteIndex,
  dispensingOrder,
  onHandleClosePrintMedListModal,
  onHandleModal,
}) => {
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedMedLists, setSelectedMedLists] = useState([]);
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const [invalidFacilities, setInvalidFacilities] = useState([])  
  const [showFacilitiesRedirect, setShowFacilitiesRedirect] = useState(false)

  const [areaWidth, setAreaWidth] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    smScreen && handleShowSmScreenHeader(false);
  }, [smScreen, handleShowSmScreenHeader]);

  useEffect(() => {
    const calculateArea = () => {
      const availableWidth = window.innerWidth * 0.6;
      const availableHeight = window.innerHeight * 0.9;

      setAreaWidth(availableWidth);
      setAreaHeight(availableHeight);
    }

    if (!smScreen) {
      calculateArea();
      window.addEventListener("resize", calculateArea);      
    };
    
    return () => window.removeEventListener("resize", calculateArea);
  }, [smScreen]);

  useEffect(() => {
    setSelectedPatients(patients.map((p) => p._id));
  }, [patients]);

  const memoizedPatients = useMemo(
    () => patients.filter((p) => selectedPatients.includes(p._id)),
    [patients, selectedPatients]
  );

  useEffect(() => {
    setSelectedMedLists(
      medLists.filter((ml) => selectedPatients.includes(ml.patientId))
    );
  }, [selectedPatients, medLists]);

  const MemoizedDocument = useMemo(() => {
    return () => (
      <MedListsDocument patients={memoizedPatients} meds={selectedMedLists} />
    );
  }, [memoizedPatients, selectedMedLists]);

  const validateFacilities = useCallback(() => {
    const facilities = []
    for (const patient of patients) {
      if (
        !patient.facility?.timeCodes.length &&
        !facilities.some((f) => f._id === patient.facility._id)
      ) {
        facilities.push(patient.facility);
      }
    }
    return facilities;
  }, [patients])

  useEffect(() => {
    setInvalidFacilities(validateFacilities())
  }, [validateFacilities])

  return (
    <>
      <div
        className={styles.PrintMedList__container}
        style={
          showPDFViewer && !smScreen
            ? { width: areaWidth, height: areaHeight, minWidth: 900 }
            : {}
        }
      >
        {!smScreen ? (
          <section className={styles.PrintMedList__headingGroup}>
            {showFacilitiesRedirect ? (
              <p className={styles.PrintMedList__title}>Missing Time Codes</p>
            ) : <p className={styles.PrintMedList__title}>Print Medication Lists</p>}
            <CloseModalIcon
              className={styles.PrintMedList__closeModalIcon}
              onClick={onHandleClosePrintMedListModal}
            />
          </section>
        ) : (
          <section className={styles.PrintMedList__headingGroup}>
            <div
              className={styles.PrintMedList__backArrow}
              onClick={() => {
                if (showFacilitiesRedirect) {
                  setShowFacilitiesRedirect(false);
                } else {
                  onHandleModal(false);
                  handleShowSmScreenHeader(true);
                }
              }}
            >
              <BackArrowMobile
                className={styles.PrintMedList__backArrow__icon}
              />
              <p className={styles.PrintMedList__goBackTitle}>{"Back"}</p>
            </div>
            {showFacilitiesRedirect ? (
              <p className={styles.PrintMedList__title}>Missing Time Codes</p>
            ) : <p className={styles.PrintMedList__title}>Print Medication Lists</p>}
          </section>
        )}

        {showFacilitiesRedirect ? (
          <FacilitiesRedirect 
            facilities={invalidFacilities}
            selectedSiteIndex={selectedSiteIndex}
            dispensingOrder={dispensingOrder}
          />
        ) : showPDFViewer ? (
          <PDFDocumentViewer Document={MemoizedDocument} />
        ) : (
          <PatientList
            patients={patients}
            selectedPatients={selectedPatients}
            setSelectedPatients={setSelectedPatients}
          />
        )}

        <div className={styles.PrintMedList__buttonContainer}>
          {smScreen && !invalidFacilities.length && !showFacilitiesRedirect ? (
            <PDFDownloadLink
              document={<MemoizedDocument />}
              fileName="Medication Lists.pdf"
            >
              {({ blob, url, loading, error }) => (
                <ButtonGen
                  buttonClass={
                    !selectedPatients.length || loading
                      ? `${styles["button"]} ${styles["button--disabled"]}`
                      : styles.button
                  }
                  btnDisabled={selectedPatients.length === 0 || loading}                  
                >
                  {"Download medication lists"}
                </ButtonGen>
              )}
            </PDFDownloadLink>
          ) : (
            <ButtonGen
              buttonClass={
                !selectedPatients.length
                  ? `${styles["button"]} ${styles["button--disabled"]}`
                  : styles.button
              }
              btnDisabled={selectedPatients.length === 0}
              onClickHandler={() => {
                if (showPDFViewer) {
                  setShowPDFViewer(false);
                } else if (showFacilitiesRedirect) {
                  setShowFacilitiesRedirect(false);
                } else if (selectedPatients.length) {
                  const facilities = validateFacilities();
                  setInvalidFacilities(facilities);
                  if (facilities.length) {
                    setShowFacilitiesRedirect(true);
                  } else {
                    setShowPDFViewer(true);
                  }
                }
              }}
            >
              {showPDFViewer ||showFacilitiesRedirect
                ? "Back"
                : smScreen
                ? "Download medication lists" // For facility modal
                : "Preview medication lists"}
            </ButtonGen>
          )}
        </div>
      </div>
    </>
  );
};

export default PrintMedList;
