import React from "react";
import styles from "./LocationProductCard.module.css";

const LocationProductCard = ({ data, onLocationProductCardClick = false }) => {
  return (
    <div
      className={styles.locationProductCard__container}
      onClick={(e) => onLocationProductCardClick(e)}
    >
      <div className={styles.locationProductCard__dataList}>
        <div className={styles.locationProductCard__rowGroup}>
          <div
            className={`${styles.locationProductCard__colGroup} ${styles["locationProductCard__colGroup--1"]}`}
          >
            <div className={`${styles["locationProductCard__heading"]}`}>
              {data.heading1}
            </div>
            <div className={`${styles["locationProductCard__value"]}`}>
              {data.item1}
            </div>
          </div>
        </div>

        <div className={styles.locationProductCard__rowGroup}>
          <div
            className={`${styles.locationProductCard__colGroup} ${styles["locationProductCard__colGroup--1"]}`}
          >
            <div className={`${styles["locationProductCard__heading"]}`}>
              {data.heading2}
            </div>
            <div className={`${styles["locationProductCard__value"]}`}>
              {data.item2}
            </div>
          </div>
        </div>

        <div className={styles.locationProductCard__rowGroup}>
          <div
            className={`${styles.locationProductCard__colGroup} ${styles["locationProductCard__colGroup--1"]} `}
          >
            <div className={`${styles["locationProductCard__heading"]}`}>
              {data.heading3}
            </div>
            <div className={`${styles["locationProductCard__value"]}`}>
              {data.item3}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationProductCard;
