import PharmacyService from "../../services/PharmacyService";

class GetSiteController {
  constructor(user) {
    this.user = user;
  }

  async getSites(callback) {
    PharmacyService.list(this.user)
      .then((sites) => {
        if (this.user.isEnterprise) {
          callback(null, sites);
        } else {
          let siteArr = [];
          for (let i = 0; i < sites.length; i++) {
            const s = sites[i];
            if (this.user.sites.includes(s._id)) {
              siteArr.push(s);
            }
          }
          callback(null, siteArr);
        }
      })
      .catch((error) => {
        callback(error);
      });
  }
}

export default GetSiteController;
