import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./FacilitiesDataCard.module.css";

const FacilitiesDataCard = ({ data }) => {
  const { t, i18n } = useTranslation("pharmacySites");
  return (
    <div className={styles.facilitiesDataCard__container}>
      <div className={styles.facilitiesDataCard__dataList}>
        <div className={styles.facilitiesDataCard__rowGroup}>
          <div
            className={`${styles.facilitiesDataCard__colGroup} ${styles["facilitiesDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["facilitiesDataCard__heading"]}`}>
              {data.facilityNameHeading}
            </div>
            <div className={`${styles["facilitiesDataCard__value"]}`}>
              {data.facilityName}
            </div>
          </div>

          <div
            className={`${styles.facilitiesDataCard__colGroup} ${styles["facilitiesDataCard__colGroup--2"]}`}
          >
            <div className={`${styles["facilitiesDataCard__heading"]}`}>
              {data.typeHeading}
            </div>
            <div className={`${styles["facilitiesDataCard__value"]}`}>
              {data.type}
            </div>
          </div>
          {data.actions}
        </div>

        <div className={styles.facilitiesDataCard__rowGroup}>
          <div
            className={`${styles.facilitiesDataCard__colGroup} ${styles["facilitiesDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["facilitiesDataCard__heading"]}`}>
              {data.contactNameHeading}
            </div>
            <div className={`${styles["facilitiesDataCard__value"]}`}>
              {data.contactName}
            </div>
          </div>
        </div>

        <div className={styles.facilitiesDataCard__rowGroup}>
          <div
            className={`${styles.facilitiesDataCard__colGroup} ${styles["facilitiesDataCard__colGroup--1"]} ${styles["facilitiesDataCard__emailGroup"]} `}
          >
            <div className={`${styles["facilitiesDataCard__heading"]}`}>
              {data.emailHeading}
            </div>
            <div className={`${styles["facilitiesDataCard__value"]}`}>
              {data.email}
            </div>
          </div>

          <div
            className={`${styles.facilitiesDataCard__colGroup} ${styles["facilitiesDataCard__colGroup--2"]}`}
          >
            <div className={`${styles["facilitiesDataCard__heading"]}`}>
              {data.phoneNumberHeading}
            </div>
            <div className={`${styles["facilitiesDataCard__value"]}`}>
              {data.phoneNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesDataCard;
