import ApiService from "./ApiService";
import axios from "axios";

class StockedItemService extends ApiService {

  static getCanistersForSite = async (user, site, deviceCompatability) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/canisters?deviceCompatability=${deviceCompatability}`;

      let response = await axios.get(url, rc);
      return response.data.canisters;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  // Get all StockedItems for a site
  static list = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items`;

      let response = await axios.get(url, rc);
      return response.data.stockedItems;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static findByUser = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items?user=${user._id}`;

      let response = await axios.get(url, rc);
      return response.data.stockedItems;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static create = async (user, site, stockedItem) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items`;

      let response = await axios.post(url, { stockedItem: stockedItem }, rc);
      return response.data.stockedItem;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static update = async (user, site, stockedItem) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items/${stockedItem._id}`;

      let response = await axios.put(url, { stockedItem: stockedItem }, rc);
      return response.data.stockedItem;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static delete = async (user, site, stockedItem) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items/${stockedItem._id}`;

      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // Find all the stocked items associated with this barcode
  static findByBarcode = async (user, site, barcode) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stocked-items?barcode=${barcode}`;

      let response = await axios.get(url, rc);
      return response.data.stockedItems;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default StockedItemService;
