import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { useTranslation } from "react-i18next";
import SearchBar from "../../components/SearchBar";
import { InventoryAddEquivProductsHeadings } from "./InventoryAddEquivProductsHeadings.js";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import InventoryProductsList from "./InventoryProductsList";
import styles from "./InventoryAddEquiv.module.css";
import ProductController from "../../controllers/ProductController";
import { useNavigate } from "react-router-dom";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const InventoryAddEquiv = ({
  product,
  toggle,
  setToggle,
  setAddEquivModalActive,
  setProductEquivalency,
  equivProducts,
  setEquivProducts,
  prodEquivCntrInstance,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("invEquivalency");
  const pcRef = useRef(null);

  let filteredProducts = [];
  const [products, setProducts] = useState([]);
  const [equivProductIds, setEquivProductIds] = useState([]);
  const [filterText, setFilterText] = useState("");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const callback = (error, products) => {
    if (products) {
      setProducts(products);
    } else {
      console.log("error", error);
    }
  };

  useEffect(() => {
    pcRef.current = new ProductController();
  }, []);

  useEffect(() => {
    prodEquivCntrInstance.getProducts(product, callback);
    let tempEquivProductIds = [];
    equivProducts.forEach((obj) => {
      if (product.packageId !== obj.packageId) {
        tempEquivProductIds.push(obj.packageId);
      }
    });
    setEquivProductIds(tempEquivProductIds);
  }, [equivProducts]);

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  products.forEach((product) => {
    filteredProducts = products.filter((prod) => {
      if (
        filterText !== "" &&
        prod.packageId !== product.packageId &&
        !equivProductIds.includes(prod.packageId) &&
        (prod.packageId
          .toString()
          .toUpperCase()
          .includes(filterText.toUpperCase()) ||
          pcRef.current
            .getDefaultName(prod)
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          (prod.manufacturer.fullName &&
            prod.manufacturer.fullName
              .toUpperCase()
              .includes(filterText.toUpperCase())))
      ) {
        return true;
      } else {
        return false;
      }
    });
  });

  const inventoryProductsList = (
    <InventoryProductsList
      product={product}
      toggle={toggle}
      setToggle={setToggle}
      filteredProducts={filteredProducts}
      equivProducts={equivProducts}
      setEquivProducts={setEquivProducts}
      equivProductIds={equivProductIds}
      setEquivProductIds={setEquivProductIds}
      setProductEquivalency={setProductEquivalency}
      prodEquivCntrInstance={prodEquivCntrInstance}
    />
  );

  const searchBar = (
    <div className={styles.addEquiv__searchBarContainer}>
      <SearchBar
        placeholder={
          !smScreen ? t("searchPlaceholder") : t("searchPlaceholderSmall")
        }
        onSearch={handleSearch}
        smFullWidth={smScreen ? true : false}
      />
    </div>
  );

  const smallScreenHeader = (
    <section className={styles.addEquiv__header}>
      <div className={styles.addEquiv__backArrow} onClick={goBackHandler}>
        <BackArrowMobile className={styles.addEquiv__backArrowIcon} />
        <p className={styles.addEquiv__goBackTitle}>{`Back`}</p>
      </div>
      {searchBar}

      <div className={styles.addEquiv__breakToNewRow}></div>
      <p className={styles.addEquiv__title}>{`Equivalency`}</p>
    </section>
  );

  return (
    <>
      {smScreen && (
        <section className={styles.addEquiv__view}>
          {/* {searchBar} */}
          {smallScreenHeader}
          {inventoryProductsList}
        </section>
      )}

      {!smScreen && (
        <div className={styles.addEquiv__overlay}>
          <section className={styles.addEquiv__view}>
            <section className={styles.addEquiv__header}>
              <p className={styles.addEquiv__title}>
                {t("addEquivalencyLabel")}
              </p>
              <div
                className={styles.addEquiv__closeIconContainer}
                onClick={() => {
                  setAddEquivModalActive(false);
                }}
              >
                <CloseIcon className={styles.addEquiv__closeIcon} />
              </div>
            </section>
            {searchBar}

            <div className={styles.addEquiv__productsHeadingsContainer}>
              <InventoryAddEquivProductsHeadings />
            </div>

            {inventoryProductsList}
          </section>
        </div>
      )}
    </>
  );
};

export default InventoryAddEquiv;
