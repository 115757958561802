import React from "react";
import styles from "./QuickGuidesCard.module.css";

const QuickGuidesCard = ({ cardTitle, machineType, pdfUrl }) => {
  const handleCardClick = () => {
    window.open(pdfUrl, "_blank", "noreferrer");
  };

  return (
    <div
      onClick={handleCardClick}
      className={styles.quickGuidesCard__topicCard}
    >
      <div className={styles.quickGuidesCard__cardImageContainer}></div>
      <h2>{cardTitle}</h2>
    </div>
  );
};

export default QuickGuidesCard;
