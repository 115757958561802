import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/magnifying-glass.svg";
import DatePickerComponent from "../../../../../components/DatePickerComponent/DatePickerComponent";
import Button from "../../../../../components/Button";
import ProductController from "../../../../../controllers/ProductController";
import Input from "./Input";
import styles from "./ScanInput.module.css";

const GetProductByBarCode = ({
  /*** Added */
  currentStatusObj,
  handleCurrentStatusObj,
  stockedItemObjs,
  handleStockedItemObjs,
  /*** end */
  setPage,
  productScanned,
  setProductScanned,
  productScannedLot,
  setProductScannedLot,
  setProductScannedExpDate,
  productBarcode,
  setProductBarcode,
  setScannedBarcodes,
  setLotNumbers,
  setExpirations,
  user,
  productRescanned,
  enforceInputFocus,
  setEnforceInputFocus,
  stockLocationScanned,
  productAssociationNotFound,
  productNotFound,
  setProductNotFound,
  onSetErrorHandler,
  errorMsg,
}) => {
  const [value, setValue] = useState("");
  const placeHolder = "Scan product";
  const placeHolderLot = "Enter lot number";
  const _productController = useRef(null);
  const inputElement = useRef(null);

  const dataRef = useRef({});
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [expirationDateTime, setExpirationDateTime] = useState(tomorrow);
  const [tomorrowDateTime, setTomorrowDateTime] = useState("");
  const [disableContinueButton, setDisableContinueButton] = useState(false);

  const EPSILON = 10000; // milliseconds constant for time comparison

  /*** Added */
  useEffect(() => {
    let tempCurrentStatusObj = { ...currentStatusObj };
    tempCurrentStatusObj.barcode = productBarcode;
    tempCurrentStatusObj.product = productScanned;
    handleCurrentStatusObj(tempCurrentStatusObj);
  }, [productBarcode, productScanned]);
  /*** end */

  useEffect(() => {
    _productController.current = new ProductController(user);
    if (inputElement.current) {
      inputElement.current.focus();
    }
    setValue("");
  }, []);

  useEffect(() => {
    if (expirationDateTime && !tomorrowDateTime) {
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setTomorrowDateTime(tomorrow);
    }
  }, [expirationDateTime]);

  useEffect(() => {
    if (!tomorrowDateTime) {
      setTomorrowDateTime(expirationDateTime);
    }
  }, [expirationDateTime]);

  useEffect(() => {
    try {
      // This is float comparison, thus the need for the EPSILON constant.
      if (
        expirationDateTime.getTime() + EPSILON >=
        tomorrowDateTime.getTime()
      ) {
        setDisableContinueButton(false);
      } else {
        setDisableContinueButton(true);
      }
    } catch {
      // do nothing
    }
  }, [expirationDateTime, tomorrowDateTime]);

  useEffect(() => {
    if (!productNotFound && !productScanned) {
      setPage(2);
    } else if (!productScannedLot) {
      setPage(3);
    } else {
      setPage(4);
    }
  }, [productNotFound, productScanned, productScannedLot]);

  // getProductByBarCode
  let callbackCalled = false;
  const callback = (error, product) => {
    if (!callbackCalled) {
      callbackCalled = true;
      if (product) {
        if (!product.packageQuantity) {
          onSetErrorHandler(
            "The product associated with this barcode does not have a package quantity."
          );
        } else {
          setProductScanned(product);
        }
      } else {
        setProductNotFound(true);
        // console.log("error:", error);
      }
      setValue("");
    }
  };

  const changeScanInputHandler = (e) => {
    setValue(e.target.value);
  };

  const onBlurHandler = () => {
    inputElement.current.focus();
  };

  const onKeyUpHandler = (e) => {
    if (e.which === 13 && e.target.value) {
      const response = _productController.current.getProductByBarcode(
        e.target.value,
        callback
      );

      if (response) {
        setScannedBarcodes((prevState) => {
          return [...prevState, e.target.value];
        });
        setProductBarcode(e.target.value);
      } else {
        onSetErrorHandler("Unable to retrieve product.");
      }
    }
  };

  const onKeyUpHandlerLot = (e) => {
    if (e.which === 13 && e.target.value) {
      setLotNumbers((prevState) => {
        return [...prevState, e.target.value];
      });

      setProductScannedLot(e.target.value);
      setValue("");

      /*** Added */
      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.lotNumber = e.target.value;
      handleCurrentStatusObj(tempCurrentStatusObj);
      /*** end */
    }
  };

  const onKeyUpHandlerExpDate = () => {
    if (expirationDateTime.getTime() + EPSILON >= tomorrowDateTime.getTime()) {
      let fullYear = expirationDateTime.getFullYear().toString();
      let month = parseInt(expirationDateTime.getMonth());
      let day = expirationDateTime.getDate().toString();

      month = month + 1;

      if (month.length === 1) {
        month = month + 1;
      }

      month = month.toString();

      if (month.length === 1) {
        month = "0" + month;
      }

      if (day.length === 1) {
        day = "0" + day;
      }

      setExpirations((prevState) => {
        return [...prevState, `${fullYear}-${month}-${day}`];
      });

      setProductScannedExpDate(`${fullYear}-${month}-${day}`);

      /*** Added */
      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.expiration = `${fullYear}-${month}-${day}`;
      handleCurrentStatusObj(tempCurrentStatusObj);
      /*** end */
    }
  };

  if (
    !(stockLocationScanned && productRescanned && productAssociationNotFound)
  ) {
    if (!productNotFound && !productScanned) {
      return (
        <section className={styles.scanInput__container}>
          <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
          <Input
            className={styles.scanInput__field}
            onChangeHandler={changeScanInputHandler}
            onKeyUpHandler={onKeyUpHandler}
            onBlurHandler={onBlurHandler}
            value={value}
            placeHolder={placeHolder}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            errorMsg={errorMsg}
          />
        </section>
      );
    } else if (!productScannedLot) {
      return (
        <section className={styles.scanInput__container}>
          <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
          <Input
            className={styles.scanInput__field}
            onChangeHandler={changeScanInputHandler}
            onKeyUpHandler={onKeyUpHandlerLot}
            onBlurHandler={onBlurHandler}
            value={value}
            placeHolder={placeHolderLot}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            errorMsg={errorMsg}
          />
        </section>
      );
    } else {
      return (
        <section
          className={`${styles.scanInput__container} ${styles["scanInput__container--white"]}`}
        >
          <DatePickerComponent
            label={"Lot Expiration Date"}
            startDate={expirationDateTime}
            onChange={(date) => {
              setExpirationDateTime(date);
              dataRef.current.expirationDateTime = date;
            }}
          />
          <div className={styles.scanInput__datePickerButton}>
            <Button
              labelName={"Continue"}
              isPrimary={true}
              isDisabled={disableContinueButton}
              onClick={onKeyUpHandlerExpDate}
              minWidth={150}
            />
          </div>
        </section>
      );
    }
  }
};

export default GetProductByBarCode;
