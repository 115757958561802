import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import backgroundView from "../../assets/Background.jpg";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import Accordion from "../../components/Accordion/Accordion";
import Address from "../../components/Address";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import ErrorBanner from "../../components/ErrorBanner";
import PhoneList from "../../components/PhoneList/PhoneList";
import Spacer from "../../components/Spacer";
import TextInput from "../../components/TextInput";
import WaitIndicator from "../../components/WaitIndicator";
import { ScreenContext } from "../../contexts/ScreenContext";
import AccountService from "../../services/AccountService";
import ConfigService from "../../services/ConfigService";
import UserService from "../../services/UserService";
import validate from "../../validation/AccountSetupValidationRules";
import styles from "./AccountSetupView.module.css";

function AccountSetupView({ user }) {
  const { t } = useTranslation("createAccount");

  const listElements = [
    { type: "EMAIL", description: "Email-based" },
    { type: "USERNAME", description: "Username-based" },
  ];

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [title, setTitle] = useState("");
  const [accountSecurityType, setAccountSecurityType] = useState(
    listElements[0]
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([
    { type: { type: "mobile", description: "Mobile" }, number: "" },
  ]);
  const [shippingAddress, setShippingAddress] = useState({
    sameAsShipping: false,
    // companyName: '',
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [billingAddress, setBillingAddress] = useState({
    sameAsShipping: false,
    // companyName: '',
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [mailingAddress, setMailingAddress] = useState({
    sameAsShipping: false,
    // companyName: '',
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);
  const [copyShippingAddressToBilling, setCopyShippingAddressToBilling] =
    useState(false);
  const [copyShippingAddressToMailing, setCopyShippingAddressToMailing] =
    useState(false);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const account = {
    companyName: companyName,
    accountSecurity: accountSecurityType,
    shippingAddress: shippingAddress,
    billingAddress: billingAddress,
    mailingAddress: mailingAddress,
  };

  const administrator = {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    title: title,
    phoneNumbers: phoneNumbers,
    username: username,
    password: password,
    isEnterprise: true,
    roles: roles,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let validationErrors = validate({
      account: account,
      administrator: administrator,
    });

    setErrors(validationErrors);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveData();
    } else if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
      setError(t("requiredFieldsMissing"));
    }
  }, [errors]);

  useEffect(() => {
    if (copyShippingAddressToBilling) {
      let clonedObj = { ...shippingAddress };
      setBillingAddress(clonedObj);
    } else {
      setBillingAddress({
        sameAsShipping: false,
        // companyName: '',
        street1: "",
        street2: "",
        city: "",
        state: null,
        zipcode: "",
        country: null,
      });
    }
  }, [copyShippingAddressToBilling]);

  useEffect(() => {
    if (copyShippingAddressToMailing) {
      let clonedObj = { ...shippingAddress };
      setMailingAddress(clonedObj);
    } else {
      setMailingAddress({
        sameAsShipping: false,
        // companyName: '',
        street1: "",
        street2: "",
        city: "",
        state: null,
        zipcode: "",
        country: null,
      });
    }
  }, [copyShippingAddressToMailing]);

  useEffect(() => {
    ConfigService.roles().then((roles) => {
      let adminRole = roles.find(
        (o) => o.type.toUpperCase() === "ADMINISTRATOR"
      );
      setRoles((prevState) => {
        return [...prevState, adminRole];
      });
    });
  }, []);

  const handleCancel = () => {
    navigate("/");
    //history.push('/');
  };

  const saveData = async () => {
    try {
      let exists = await AccountService.exists(user, account.companyName);
      if (exists) {
        setError(t("companyExistsMsg"));
        setIsSubmitting(false);
        return;
      }

      let accountDoc = await AccountService.create(user, { account: account });
      let userDoc = await UserService.createAccountAdmin(
        accountDoc,
        administrator
      );
      if (accountDoc.accountSecurity.type === "EMAIL") {
        await UserService.sendInvite(accountDoc, userDoc);
      }
      setIsSubmitting(false);
      navigate("/account-setup/success", {
        state: {
          account: accountDoc,
          administrator: userDoc,
        },
      });
    } catch (error) {
      setIsSubmitting(false);
      setError(error);
    }
  };

  const handleBillingCheckboxClick = () => {
    setCopyShippingAddressToBilling(!copyShippingAddressToBilling);
  };

  const handleMailingCheckboxClick = () => {
    setCopyShippingAddressToMailing(!copyShippingAddressToMailing);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  if (isSubmitting) {
    return (
      <WaitIndicator
        message={"Configuring account for use (this may take a minute)..."}
      />
    );
  } else {
    return (
      <div
        className={styles.accountSetup__container}
        style={
          !smScreen
            ? {
                backgroundImage: `url(${backgroundView})`,
                backgroundPosition: "center",
                backgroundRepeat: "norepeat",
                backgroundSize: "cover",
              }
            : null
        }
      >
        <section className={styles.accountSetup__formView}>
          {smScreen && (
            <section className={styles.accountSetup__header}>
              <div
                className={styles.accountSetup__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.accountSetup__backArrowIcon}
                />
                <p className={styles.accountSetup__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <p className={styles.accountSetup__title}>{t("title")}</p>
            </section>
          )}

          {!smScreen && (
            <header className={styles.accountSetup__formHeader}>
              <h1>{t("title")}</h1>
              <div
                className={styles.accountSetup__formCloseContainer}
                onClick={handleCancel}
              >
                {/*<img src={closeIcon} alt={'close'}/>*/}
                <CloseIcon fill={"#121A2D"} />
              </div>
            </header>
          )}
          <section className={styles.accountSetup__formBody}>
            {error && (
              <>
                <ErrorBanner message={error} onClose={() => setError(null)} />
                <Spacer space={10} unit={"px"} />
              </>
            )}
            <div className={styles.accountSetup__scrollArea}>
              <div className={styles.accountSetup__accordions}>
                <div
                  className={[
                    styles["accountSetup__accordion"],
                    styles["accountSetup__accordion--0"],
                  ].join(" ")}
                >
                  <Accordion title={t("tab0")} openByDefault={true}>
                    <section className={"formRow"}>
                      <section className={"formColumn"}>
                        <TextInput
                          labelName={t("companyNameLabel")}
                          isEnabled={true}
                          value={companyName}
                          placeholder={""}
                          validationText={errors.companyName}
                          type={"text"}
                          onChange={(value) => {
                            setCompanyName(value);
                          }}
                          focus={true}
                        />
                        <TextInput
                          labelName={t("firstNameLabel")}
                          isEnabled={true}
                          value={firstName}
                          placeholder={""}
                          validationText={errors.firstName}
                          type={"text"}
                          onChange={(value) => {
                            setFirstName(value);
                          }}
                          focus={false}
                        />
                        <TextInput
                          labelName={t("lastNameLabel")}
                          isEnabled={true}
                          value={lastName}
                          placeholder={""}
                          validationText={errors.lastName}
                          type={"text"}
                          onChange={(value) => {
                            setLastName(value);
                          }}
                        />
                        <TextInput
                          labelName={t("emailAddressLabel")}
                          isEnabled={true}
                          value={emailAddress}
                          placeholder={""}
                          validationText={errors.emailAddress}
                          type={"email"}
                          onChange={(value) => {
                            setEmailAddress(value);
                          }}
                        />
                        <TextInput
                          labelName={t("titleLabel")}
                          isEnabled={true}
                          value={title}
                          placeholder={""}
                          validationText={""}
                          type={"text"}
                          onChange={(value) => {
                            setTitle(value);
                          }}
                        />
                      </section>

                      <Spacer space={10} unit={"%"} />

                      <section className={"formColumn"}>
                        <PhoneList
                          phoneNumbers={phoneNumbers}
                          readonly={false}
                          errors={errors.phoneNumbers}
                          onAdd={(phoneNumber) => {
                            setPhoneNumbers((arr) => {
                              return [...arr, phoneNumber];
                            });
                          }}
                          onRemove={(index) => {
                            let newArray = [...phoneNumbers];
                            newArray.splice(index, 1);
                            setPhoneNumbers(newArray);
                          }}
                          onChange={(index, phoneNumber) => {
                            let newArray = [...phoneNumbers];
                            newArray[index] = phoneNumber;
                            setPhoneNumbers(newArray);
                          }}
                        />

                        <Spacer space={10} unit={"%"} />

                        <DropdownInput
                          listElements={listElements}
                          labelName={t("accountSecurityTypeLabel")}
                          isEnabled={true}
                          value={accountSecurityType.description}
                          placeholder={""}
                          validationText={""}
                          onSelection={(value) => {
                            setAccountSecurityType(value);
                          }}
                        />
                        {accountSecurityType.type === "USERNAME" && (
                          <section className={"formRow"}>
                            <section className={"formColumn w-50"}>
                              <TextInput
                                labelName={t("usernameLabel")}
                                isEnabled={true}
                                value={username}
                                placeholder={""}
                                validationText={errors.username}
                                type={"text"}
                                onChange={(value) => {
                                  setUsername(value);
                                }}
                              />
                            </section>
                            <Spacer space={10} unit={"%"} />
                            <section className={"formColumn w-50"}>
                              <TextInput
                                labelName={t("passwordLabel")}
                                isEnabled={true}
                                value={password}
                                placeholder={""}
                                validationText={errors.password}
                                type={"password"}
                                onChange={(value) => {
                                  setPassword(value);
                                }}
                              />
                            </section>
                          </section>
                        )}
                      </section>
                    </section>
                  </Accordion>
                </div>

                <div
                  className={[
                    styles["accountSetup__accordion"],
                    styles["accountSetup__accordion--1"],
                  ].join(" ")}
                >
                  <Accordion title={t("tab1")}>
                    <Address
                      errors={errors.shippingAddress}
                      address={shippingAddress}
                      onChange={(address) => {
                        setShippingAddress((prevState) => {
                          let mergedObj = { ...prevState, ...address };
                          return mergedObj;
                        });
                      }}
                    />
                  </Accordion>
                </div>

                <div className={styles.accountSetup__groupedAccordion}>
                  <div
                    className={[
                      styles["accountSetup__accordion"],
                      styles["accountSetup__accordion--2"],
                    ].join(" ")}
                  >
                    <Accordion title={t("tab2")}>
                      <Address
                        errors={errors.billingAddress}
                        address={billingAddress}
                        onChange={(address) => {
                          setBillingAddress((prevState) => {
                            return { ...prevState, ...address };
                          });
                        }}
                      />
                    </Accordion>
                  </div>

                  <div
                    className={[
                      styles["accountSetup__checkboxContainer"],
                      styles["accountSetup__checkboxContainer--2"],
                    ].join(" ")}
                  >
                    <Checkbox
                      labelName={
                        smScreen
                          ? t("sameAsShippingLabelMobile")
                          : t("sameAsShippingLabel")
                      }
                      onCheck={handleBillingCheckboxClick}
                      isOn={copyShippingAddressToBilling}
                      labelColor="#089bab"
                    />
                  </div>
                </div>

                <div
                  className={[
                    styles["accountSetup__groupedAccordion"],
                    styles["accountSetup__groupedAccordion--noBottomMargin"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      styles["accountSetup__accordion"],
                      styles["accountSetup__accordion--3"],
                    ].join(" ")}
                  >
                    <Accordion title={t("tab3")}>
                      <Address
                        address={mailingAddress}
                        onChange={(address) => {
                          setMailingAddress((prevState) => {
                            return { ...prevState, ...address };
                          });
                        }}
                      />
                    </Accordion>
                  </div>

                  <div
                    className={[
                      styles["accountSetup__checkboxContainer"],
                      styles["accountSetup__checkboxContainer--3"],
                    ].join(" ")}
                  >
                    <Checkbox
                      labelName={
                        smScreen
                          ? t("sameAsShippingLabelMobile")
                          : t("sameAsShippingLabel")
                      }
                      onCheck={handleMailingCheckboxClick}
                      isOn={copyShippingAddressToMailing}
                      labelColor="#089bab"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className={styles.accountSetup__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isDisabled={false}
              isPrimary={false}
              onClick={handleCancel}
            />
            <Spacer space={2} unit={"%"} />
            <Button
              labelName={t("createAccountButtonLabel")}
              minWidth={"213px"}
              isDisabled={false}
              isPrimary={true}
              onClick={handleSubmit}
            />
          </footer>
        </section>
      </div>
    );
  }
}

export default AccountSetupView;
