import React from "react";
import styles from "./OptiSortDailyReportCard.module.css";

const OptiSortDailyReportCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.OptiSortDailyReportCard__reportList}>
      <li className={styles.OptiSortDailyReportCard__reportListItem}>
        <div className={styles.OptiSortDailyReportCard__dataContainer}>
          <h1>{headingsObj.siteName}</h1>
          <p>{dataObj.siteName}</p>
        </div>
        <div className={styles.OptiSortDailyReportCard__dataContainer}>
          <h1>{headingsObj.deviceName}</h1>
          <p>{dataObj.deviceName}</p>
        </div>
      </li>

      <li className={styles.OptiSortDailyReportCard__reportListItem}>
        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.processedOn}</h1>
          <p>{dataObj.processedOn}</p>
        </div>
        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.packageId}</h1>
          <p>{dataObj.packageId}</p>
        </div>

        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.controlLevel}</h1>
          <p>{dataObj.controlLevel}</p>
        </div>
      </li>

      <li className={styles.OptiSortDailyReportCard__reportListItem}>
        <div className={styles.OptiSortDailyReportCard__dataContainer}>
          <h1>{headingsObj.product}</h1>
          <p>{dataObj.product}</p>
        </div>
        <div className={styles.OptiSortDailyReportCard__dataContainer}>
          <h1>{headingsObj.manufacturer}</h1>
          <p>{dataObj.manufacturer}</p>
        </div>
      </li>

      <li className={styles.OptiSortDailyReportCard__reportListItem}>
        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.cost}</h1>
          <p>{dataObj.cost}</p>
        </div>
        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.quantity}</h1>
          <p>{dataObj.quantity}</p>
        </div>
        <div
          className={`${styles.OptiSortDailyReportCard__dataContainer} ${styles["OptiSortDailyReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.totalCost}</h1>
          <p>{dataObj.totalCost}</p>
        </div>
      </li>
    </ul>
  );
};

export default OptiSortDailyReportCard;
