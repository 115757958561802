import React from "react";
import PillsortAlarmsCard from "./PillsortAlarmsCard";
import styles from "./PillsortAlarmsCardList.module.css";

const PillsortAlarmsCardList = ({ headings, data, fetchingAlarmsData }) => {
  const headingsObj = {
    alarm: headings[0],
    description: headings[1],
    occurred: headings[2],
    resolution: headings[3],
    by: headings[4],
  };

  if (data.length || fetchingAlarmsData) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.PillsortAlarmsCardList__container}>
        <PillsortAlarmsCard
          headingsObj={headingsObj}
          dataObj={{
            alarm: dataRow[0],
            description: dataRow[1],
            occurred: dataRow[2],
            resolution: dataRow[3],
            actions: dataRow[4],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.PillsortAlarmsCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.PillsortAlarmsCardList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default PillsortAlarmsCardList;
