import ApiService from "../../../../services/ApiService";
import axios from "axios";

class RefillReportService extends ApiService {
  static getCanisterReplenishmentData = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/fillList?}`,
        rc
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static getBatches = async (
    user,
    siteId,
    stockLocationId,
    stockLocation,
    startDate,
    endDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      const url = `${ApiService.BASE_URL}/accounts/${
        user.account._id
      }/sites/${siteId}/stock-locations/${stockLocationId}/reports/refill-report-batch-list?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;

      let response = await axios.get(url, rc);

      return response.data;

      // For testing
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // return [Math.random(), Math.random()];
    } catch (error) {}
  };

  static getNeeds = async (
    user,
    siteId,
    stockLocationId,
    stockLocation,
    startDate,
    endDate,
    batches
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      const url = `${ApiService.BASE_URL}/accounts/${
        user.account._id
      }/sites/${siteId}/stock-locations/${stockLocationId}/reports/refill-needs?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&batches=${batches}`;

      let response = await axios.get(url, rc);

      return response.data;
    } catch (error) {}
  };
}

export default RefillReportService;
