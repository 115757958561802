import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CreateProductCategoryDataCard.module.css";

const CreateProductCategoryDataCard = ({ data }) => {
  const { t, i18n } = useTranslation("products");
  return (
    <div className={`${styles.createProductCategoryDataCard__container}`}>
      <div className={styles.createProductCategoryDataCard__dataList}>
        <div className={styles.createProductCategoryDataCard__rowGroup}>
          <div
            className={`${styles.createProductCategoryDataCard__colGroup} ${styles[".createProductCategoryDataCard__colGroup--1"]}`}
          >
            <div
              className={`${styles["createProductCategoryDataCard__heading"]}`}
            >
              {data.categoryNameHeading}
            </div>
            <div
              className={`${styles["createProductCategoryDataCard__value"]}`}
            >
              {data.categoryName}
            </div>
          </div>
          <div className={styles.createProductCategoryDataCard__actions}>
            <div
              className={`${styles[".createProductCategoryDataCard__value"]}`}
            >
              {data.actions}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProductCategoryDataCard;
