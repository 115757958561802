import React from "react";
import styled from "styled-components";
import {ReactComponent as Pills} from "../assets/pills.svg";
import {ReactComponent as Trash} from "../assets/delete-circle-bg.svg";
import {ReactComponent as Add} from "../assets/add-icon-small.svg";
import Dropzone from "react-dropzone";

//region STYLES
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
`
const LabelText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089BAB;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 9px;
`
const Carousel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
`
const AddContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  outline: none;
  cursor: pointer;
`
const DragAndDropContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 134px;
  min-width: 134px;
  height: 120px;
  border: 1px dashed #A7BBCF;
  box-sizing: border-box;
  border-radius: 4px;
`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 134px;
  width: 134px;
  height: 120px;
  border: 1px solid #A7BBCF;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 16px;
  object-fit: contain;
  overflow: hidden;
`
const Image = styled.img`
  object-fit: contain;
  width: 100%;
`
const ImageDeleteContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  z-index: 50;
  cursor: pointer;
`
//endregion

const ImageCarousel = ({labelName, images, onAdd, onRemove, onDoubleClick, defaultImage}) => {

    //region FUNCTIONS
    const onDrop = async (files) => {
        let file = files[0];
        const reader = new FileReader();
        reader.onloadend = (e) => {
            if(! images.some( o => o.base64EncodedString === reader.result )){
                onAdd({
                    base64EncodedString: reader.result,
                    file: file
                });
            }
        }
        reader.readAsDataURL(file);
    }

    const handleDelete = (index) => {
        onRemove(images[index]);
    }

    const handleDoubleClick = (index) => {
        onDoubleClick(images[index]);
    }
    //endregion

    return (
        <Container>
            <Header>
                <LabelText>{labelName}</LabelText>
            </Header>
            <Body>
                <Carousel>
                    {images.length > 0 && images.map((source, index)=>{
                        return (
                            <ImageContainer style={ defaultImage && defaultImage._id === source._id ? {border: '2px solid #089BAB'} : {border: '1px solid #A7BBCF'}} key={index} onDoubleClick={() => handleDoubleClick(index)}>
                                <Image
                                     src={source.mimeType ? `data:${source.mimeType};base64,${source.base64EncodedString}` : source.base64EncodedString}
                                     alt={'logo'}
                                />
                                <ImageDeleteContainer onClick={ () => handleDelete(index) }>
                                    <Trash />
                                </ImageDeleteContainer>
                            </ImageContainer>
                        )
                    })}

                    <Dropzone accept={'image/jpeg, image/png, image/svg+xml'}
                              onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <DragAndDropContainer {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Pills stroke={'#A7BBCF'}/>
                            </DragAndDropContainer>
                        )}
                    </Dropzone>
                </Carousel>
                <Dropzone
                    accept={'image/jpeg, image/png, image/svg+xml'}
                    onDrop={acceptedFiles => onDrop(acceptedFiles)}
                >
                    {({getRootProps, getInputProps}) => (
                        // <section>
                            <AddContainer {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Add width={'30px'} height={'30px'}/>
                            </AddContainer>
                        // </section>
                    )}
                </Dropzone>
                {/*<AddContainer>*/}
                {/*    <Add width={'30px'} height={'30px'}/>*/}
                {/*</AddContainer>*/}
            </Body>
        </Container>
    )
}

export default ImageCarousel;
