import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getTimeRangeDay,
  getTimeRange12Hours,
  getTimeRangeWeek,
  getTimeRangeMonth,
} from "../../../../functions/dates";
import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";

import CanisterTrayRatioGraph from "../graphs/CanisterTrayRatioGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";

const GetCanisterTrayRatioGraph = ({
  user,
  selectedDevices,
  viewWidth,
  loader,
}) => {
  const [Graph, setGraph] = useState([]);
  const [timeRange, setTimeRange] = useState("month");
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    let dateRangeObject;
    if (timeRange === "12 hours") {
      dateRangeObject = getTimeRange12Hours();
    } else if (timeRange === "day") {
      dateRangeObject = getTimeRangeDay();
    } else if (timeRange === "week") {
      dateRangeObject = getTimeRangeWeek();
    } else {
      dateRangeObject = getTimeRangeMonth();
    }

    setStartDate(dateRangeObject.startDate);
    setStopDate(dateRangeObject.stopDate);
  }, [timeRange]);

  // graph-data fetching

  useEffect(() => {
    if (startDate && stopDate && selectedDevices.length) {
      const utcStartDate = startDate.toUTCString();
      const utcStopDate = stopDate.toUTCString();

      const handleTimeRangeCanisterTray = (stringVal) => {
        setTimeRange(stringVal);
      };

      async function updateCanisterTrayRatioGraph() {
        setGraph(
          <div>
            {loader}
            <CanisterTrayRatioGraph
              viewWidth={viewWidth}
              data={[]}
              palette={["#318ce7", "#FC8A17", "#A9A9A9"]}
              timeRange={timeRange}
              onTimeRange={handleTimeRangeCanisterTray}
              className={
                smScreen
                  ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
                  : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
              }
            />
          </div>
        );

        // initializations
        let canisterPillCount = 0;
        let fullPillCount = 0;
        let partialPillCount = 0;

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakCanisterUtilization(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate
            )
            .then((DTO) => {
              const batches = DTO.results.batches;
              for (let batch of batches) {
                const processedItems = batch.processedItems;
                for (let processedItem of processedItems) {
                  if (processedItem.isTrayMed) {
                    if (processedItem.isPartial) {
                      partialPillCount += processedItem.totalQty;
                    } else {
                      fullPillCount += processedItem.totalQty;
                    }
                  } else {
                    canisterPillCount += processedItem.totalQty;
                  }
                }
              }
            });
        }

        const canisterTrayGraphData = [
          { x: "Canister", y: canisterPillCount, text: "Canister" },
          { x: "Tray", y: fullPillCount, text: "Tray" },
          { x: "Tray 1/2 Tab", y: partialPillCount, text: "Tray 1/2 Tab" },
        ];

        setGraph(
          <div>
            <CanisterTrayRatioGraph
              viewWidth={viewWidth}
              data={canisterTrayGraphData}
              palette={["#318ce7", "#FC8A17", "#A9A9A9"]}
              timeRange={timeRange}
              onTimeRange={handleTimeRangeCanisterTray}
              className={
                smScreen
                  ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
                  : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
              }
            />
          </div>
        );
      }
      updateCanisterTrayRatioGraph();
    } else {
      setGraph([]);
    }
    // placing timeRange into the dependency array caused 'double' data fetch
    // also deliberately did not include viewWidth in the dependency array
  }, [startDate, stopDate, selectedDevices, smScreen, user]);

  return Graph;
};

export default GetCanisterTrayRatioGraph;
