import React, { Fragment, useContext } from "react";
import styles from "./ScannedProductDisplay.module.css";
import { ReactComponent as Pills } from "../../../../../assets/pills.svg";
import ProductController from "../../../../../controllers/ProductController";
import { ScreenContext } from "../../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const ScannedProductDisplay = ({ productScanned }) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const pc = new ProductController();

  if (productScanned) {
    let productDescription;

    if (pc.getDefaultName(productScanned).length > 55) {
      productDescription = (
        productScanned.primaryName +
        " " +
        productScanned.strength
      ).substring(0, 56);
    } else {
      productDescription = pc.getDefaultName(productScanned);
    }

    let packageDescription =
      productScanned.packageDescription?.toLowerCase() || "";

    // Use productScanned.defaultImg
    let imgSource = null;

    let srcDBImage =
      imgSource && imgSource.mimeType
        ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
        : imgSource && imgSource.base64EncodedString;

    return (
      <section className={styles.scannedProductDisplay}>
        {smScreen ? (
          <Fragment>
            <div className={styles.scannedProductDisplay__descriptionGroup}>
              <div
                className={[
                  styles["scannedProductDisplay__imageContainer"],
                  styles["scannedProductDisplay__imageContainer--default"],
                ].join(" ")}
              >
                {imgSource ? (
                  <img
                    className={styles.scannedProductDisplay__image}
                    src={srcDBImage}
                    alt=""
                  />
                ) : (
                  <Pills stroke={"#A7BBCF"} />
                )}
              </div>

              <div className={styles.scannedProductDisplay__productInfoGroup}>
                <div
                  className={styles.scannedProductDisplay__descriptionContainer}
                >
                  <span className={styles.scannedProductDisplay__description}>
                    {productDescription}
                  </span>
                </div>

                <div
                  className={
                    styles.scannedProductDisplay__packageDescriptionContainer
                  }
                >
                  <span
                    className={styles.scannedProductDisplay__packageDescription}
                  >
                    {packageDescription}
                  </span>
                </div>
              </div>
            </div>
          </Fragment>
        ) : null}

        {!smScreen ? (
          <Fragment>
            <div className={styles.scannedProductDisplay__descriptionGroup}>
              <div
                className={[
                  styles["scannedProductDisplay__imageContainer"],
                  styles["scannedProductDisplay__imageContainer--default"],
                ].join(" ")}
              >
                {imgSource ? (
                  <img
                    className={styles.scannedProductDisplay__image}
                    src={srcDBImage}
                    alt=""
                  />
                ) : (
                  <Pills stroke={"#A7BBCF"} />
                )}
              </div>
              <div
                className={styles.scannedProductDisplay__descriptionContainer}
              >
                <span className={styles.scannedProductDisplay__description}>
                  {productDescription}
                </span>

                <span
                  className={styles.scannedProductDisplay__packageDescription}
                >
                  {packageDescription}
                </span>
              </div>
            </div>
          </Fragment>
        ) : null}
      </section>
    );
  }
  return null;
};

export default ScannedProductDisplay;
