import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!EmailValidator.validate(values.emailAddress)) {
        errors.emailAddress = 'invalidEmail'
    }

    return errors;
}
