import React, {useState} from 'react'
import {Space, Button} from 'antd'
import Search from "antd/es/input/Search";
import ClientFDBService from "../../../services/ClientFDBService";
import ImageService from "../../../services/ImageService";
import ProductService from "../../../services/ProductService";

const FdbLookup = ({user, onAdd}) => {

    const [error, setError] = useState('');
    const [fdbPackagedDrug, setFdbPackagedDrug] = useState(null);

    const onSearch = (value, _e, info) => {
        setError('');
        ClientFDBService.getFdbPackagedDrugByNdc(user, value).then(fdbPackagedDrug=>{
            if(fdbPackagedDrug){
                ProductService.getProductByPackageId(user, fdbPackagedDrug.packagedDrugID).then(product=>{
                    if(product){
                        setError('This product already exists');
                    } else {
                        setFdbPackagedDrug(fdbPackagedDrug);
                    }
                })
            } else {
                setError('No matching product')
            }


        }).catch(error=>{
            setError(error);
        })
    };

    const convert = () => {
        const o = {...fdbPackagedDrug};
        const product = {
            images: [],
            isEnabled: true,
            isPending: false,
            productId: o.packagedDrugID.substring(0, 9),
            packageId: o.packagedDrugID,
            humanReadableId: o.ndcFormatted,
            primaryName: o.packagedDrugDesc,
            tallManName: o.dispensableDrugTallManDesc !== o.dispensableDrugDesc ? o.dispensableDrugTallManDesc : '',
            manufacturer: {
                fullName: o.labelerName,
                abbrName: o.labelerName
            },
            therapeuticEquivalencyCode: o.dispensableGenericID,
            packageDescription: o.packageDesc,
            packageQuantity: o.packageSize,
        }
        onAdd(product)
    }

    return (
        <Space direction={"vertical"}>
            <Search
                enterButton
                placeholder="Enter NDC"
                onSearch={onSearch}

            />
            {fdbPackagedDrug &&
                <>
                    <p><b>Package Id:</b> {fdbPackagedDrug.ndcFormatted}</p>
                    <p><b>Description:</b> {fdbPackagedDrug.packagedDrugDesc}</p>
                    <p><b>Labeler:</b> {fdbPackagedDrug.labelerName}</p>
                    <p><b>Substitution Id (GCN):</b> {fdbPackagedDrug.dispensableGenericID}</p>
                    <p><b>Package:</b> {fdbPackagedDrug.packageSize} {fdbPackagedDrug.packageDesc}</p>
                    <Button onClick={convert} type={'primary'}>Add Product</Button>
                </>

            }
            {error &&
                <p>{error}</p>
            }
        </Space>
    )
}

export default FdbLookup;