import ProductEquivalencyService from "../services/ProductEquivalencyService";
import ProductService from "../services/ProductService";

/*
    The ProductEquivalency model:
    {
        _id: ...,
        account: ...,
        site: ...,
        products: [product1, product2, ...]
    }
*/

class ProductEquivalencyController {
  constructor(user) {
    this.user = user;
  }
  /*
        Fetch all the products that are equivalent to specified product for a specific site.
        Can return null if no product equivalency exists for specified product.
        The callback signature is (error, productEquivalency).
     */
  async getProductEquivalency(product, callback) {
    try {
      const productEquivalency = await ProductEquivalencyService.get(
        this.user,
        product
      );
      if (productEquivalency) {
        productEquivalency.products.sort((a, b) => {
          if (a.primaryName < b.primaryName) {
            return -1;
          }
          if (b.primaryName < a.primaryName) {
            return 1;
          }
          return 0;
        });
      }
      callback(null, productEquivalency); // might be null
    } catch (error) {
      callback(error, null);
    }
  }

  /*
        Add an equivalent product to the specified product for the given site.
        The callback signature is (error, productEquivalency).
        Returns the updated (or created) product equivalency.
     */
  async addProductEquivalency(product, productToAdd, callback) {
    try {
      const productEquivalency = await ProductEquivalencyService.add(
        this.user,
        product,
        productToAdd
      );
      if (productEquivalency) {
        productEquivalency.products.sort((a, b) => {
          if (a.primaryName < b.primaryName) {
            return -1;
          }
          if (b.primaryName < a.primaryName) {
            return 1;
          }
          return 0;
        });
      }
      callback(null, productEquivalency);
    } catch (error) {
      callback(error, null);
    }
  }

  /*
        Remove a product equivalency.
        The callback signature is (error, productEquivalency).
        Returns the updated product equivalency
     */
  async removeProductEquivalency(
    productEquivalency,
    product,
    productToRemove,
    callback
  ) {
    try {
      const pe = await ProductEquivalencyService.remove(
        this.user,
        productEquivalency,
        product,
        productToRemove
      );
      if (pe) {
        pe.products.sort((a, b) => {
          if (a.primaryName < b.primaryName) {
            return -1;
          }
          if (b.primaryName < a.primaryName) {
            return 1;
          }
          return 0;
        });
      }
      callback(null, pe);
    } catch (error) {
      callback(error, null);
    }
  }

  /*
        Get all products for a site.
        Returns a sorted array of Product (by description)

        Note: this array will include the primary product and already equivalent
        products as well.
     */
  async getProducts(product, callback) {
    try {
      let products = await ProductService.list(this.user);
      products = products.filter(p=>p.packageId !== product.packageId)
      callback(
        null,
        products.sort((a, b) => {
          if (a.primaryName < b.primaryName) {
            return -1;
          }
          if (b.primaryName < a.primaryName) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      callback(error, null);
    }
  }
}

export default ProductEquivalencyController;
