import React, {useState} from 'react';
import styled from "styled-components";

//<editor-fold desc="STYLES">
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const LabelText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089BAB;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
`
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 31px;
  height: 20px;
`
const Option = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #089BAB;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
`
const OptionText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #778CA2;
  margin: 0;
`
//</editor-fold>

const OptionGroup = ({ optionList, onSelect, labelName, selection }) => {

    //<editor-fold desc="HTML">
    return (
        <Container>
            <Header>
                <LabelText>{labelName}</LabelText>
            </Header>
            <Body>
                {optionList.map( option => {
                    return (
                        <OptionContainer key={option.type}>
                            <Option style={ selection?.type === option.type ? {backgroundColor: '#089BAB'} : {backgroundColor: '#FFFFFF'}} onClick={ () => {
                                onSelect( option );
                            }}/>
                            <OptionText>{option.description}</OptionText>
                        </OptionContainer>
                    )
                })}
            </Body>
        </Container>
    )
    //</editor-fold>
}

export default OptionGroup;
