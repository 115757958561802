import React, { Fragment, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import WaitIndicator from "../../components/WaitIndicator";
import styles from "../Product/Products.module.css";
import ErrorBanner from "../../components/ErrorBanner";
import SearchBar from "../../components/SearchBar";
import RoleCheckService from "../../services/RoleCheckService";
import CreateButton from "../../components/CreateButton";
import Spacer from "../../components/Spacer";
import ProductService from "../../services/ProductService";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import Popup from "../../components/PopupMenu/Popup";
import ProductsDataCard from "../Product/ProductsDataCard";

const TherapeuticEquivalencies = ({ user, refresh }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const actionListItems = [
        {
            type: "edit",
            description: 'Edit',
        },
        {
            type: "remove",
            description: 'Remove',
        },
    ];

    const [filterText, setFilterText] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [products, setProducts] = useState([]);
    const [locationPathname, setLocationPathname] = useState("");
    const [data, setData] = useState([]);
    const [showPopup, setShowPopup] = useState(null);

    useEffect(() => {
        setLocationPathname(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        setLoading(true);
        ProductService.getTherapeuticEquivalencies(user).then(list=>{
            list = list.sort((a,b)=>{
                if(a.teCode < b.teCode){return -1}
                if(b.teCode < a.teCode){return 1}
                return 0
            })
            setData(list);
            setLoading(false);
        }).catch(error=>{
            setError(error);
            setLoading(false);
        })

    }, [user, refresh]);

    const handleSearch = (searchText) => {
        searchText = searchText.trim();
        setFilterText(searchText);
    };

    const handleCreate = () => {
        navigate("create");
    };

    const handleAction = (action, row) => {
        switch (action.type) {
            case "edit":
                navigate("create", { state: { teCode: row.teCode } });
                break;
            case "remove":
              // TBD
              break;
            default:
                break;
        }
    };

    if (loading) {
        return <WaitIndicator message={"Fetching therapeutic equivalencies..."} />;
    } else {
        return (
            <>
                <Outlet/>
                {error && (
                    <div className={styles.Products__errorBannerContainer}>
                        <ErrorBanner message={error} onClose={() => setError(null)} />
                    </div>
                )}
                {locationPathname === "/settings/therapeuticEquivalencies" && (
                    <>
                        {/* TITLE, SEARCH and CREATE */}
                        <Spacer space={70} unit={"px"}/>
                        <div className={styles.Products__UtilitiesGroup}>
                            <div className={styles.Products__searchBarContainer}>
                                <SearchBar
                                    placeholder={
                                        'enter a TE code'
                                    }
                                    onSearch={handleSearch}
                                    parentFilterText={filterText}
                                    focus={true}
                                />
                            </div>
                            {RoleCheckService.canCreateProduct(user) && (
                                <div className={styles.Products__createButtonContainer}>
                                    <CreateButton
                                        onClick={handleCreate}
                                        isEnabled={true}
                                        labelName={'Create New TE'}
                                    />
                                </div>
                            )}
                        </div>
                        <Spacer space={50} unit={"px"}/>

                        {/*    TABLE */}
                        <ul
                            className={`${styles.Products__header} ${styles.Products__list}`}
                        >
                            <li>TE Code</li>
                            <li>Product Count</li>
                            <li className={styles.Products__actionsHeading}>
                                Actions
                            </li>
                        </ul>
                        <Spacer space={20} unit={"px"}/>

                        {/*    TABLE DATA */}
                        <div className={styles.Products__scroll}>
                            {data.length > 0 &&
                                data.filter(o=>filterText ? o.teCode.toUpperCase().includes(filterText.toUpperCase()) : true).map((row, index) => {
                                    return (
                                        <ul
                                            className={`${styles.Products__listitem} ${styles.Products__list}` }
                                            key={row._id}
                                            style={{zIndex: data.length - index}}
                                        >
                                            <li>{row.teCode}</li>

                                            <li>
                                                {row.productCount}
                                            </li>
                                            {RoleCheckService.canCreateProduct(user) &&
                                                <li className={styles.Products__actionsContainer}>
                                                    <div
                                                        className={styles.Products__actionsIconContainer}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setShowPopup(row);
                                                        }}
                                                    >
                                                        <MoreActionsIcon/>
                                                        {showPopup === row && (
                                                            <div className={styles.Products__popupContainer}>
                                                                <Popup
                                                                    menuItems={actionListItems}
                                                                    rightJustify={true}
                                                                    onClose={() => setShowPopup(null)}
                                                                    onAction={(action) => {
                                                                        handleAction(action, row);
                                                                        setShowPopup(null);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    )
                                })}
                        </div>
                    </>
                )}
            </>
        )
    }
};

export default TherapeuticEquivalencies;
