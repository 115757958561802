import React, { useContext } from "react";
import { ReactComponent as ExpandIcon } from "../../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/subtract-circle-icon.svg";
import styles from "./ListDisplayByProduct.module.css";
import PickListMainHeadingByProduct from "./PickListMainHeadingByProduct";
import PickListSubHeadingByProduct from "./PickListSubHeadingByProduct";
import ProductController from "../../../controllers/ProductController";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import ProductCard from "./Cards/ProductCard";
import ProductLocationCard from "./Cards/ProductLocationCard";
import ProductBulkCard from "./Cards/ProductBulkCard";

const ListDisplayByProduct = ({
  demands,
  filterText,
  handleExpansionClick,
  expandableContainers,
  cardClickIndex,
  onHandleCardClickIndex,
  locationClickObject,
  onHandleLocationClick,
}) => {
  const pcRef = new ProductController();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const handleParentCardClick = (cardIndex) => {
    if (cardClickIndex === -1) {
      handleExpansionClick(cardIndex);
      onHandleCardClickIndex(cardIndex);
    }
  };

  const handleProductLocationCardClick = (e) => {
    e.stopPropagation();
    onHandleLocationClick({
      type: null,
      index: -1,
    });
  };

  const stopPropagation = (e) => {
    e.stopPropogation();
  };

  const handleLocationClick = (e, locationObject) => {
    e.stopPropagation();
    onHandleLocationClick(locationObject);
  };

  const handleScrollAreaClick = (e) => {
    e.stopPropagation();
  };

  if (!smScreen) {
    return (
      <div className={styles.pickList__view}>
        <section className={styles.pickList__container}>
          <PickListMainHeadingByProduct />
          <div className={styles.pickList__scrollArea}>
            <div id="this"></div>

            {demands.map((demand, index) => {
              let externalStockedItemsTotalQty = 0;
              return (!filterText ||
                demand.product.packageId.toUpperCase().includes(filterText) ||
                pcRef
                  .getDefaultName(demand.product)
                  .toUpperCase()
                  .includes(filterText)) &&
                !(
                  demand.outstandingNeed === 0 &&
                  demand.externalCanisterNeeds.length === 0 &&
                  demand.externalStockedItems.length === 0 &&
                  demand.refillQty === 0
                ) ? (
                <section
                  key={demand.product.packageId}
                  onClick={
                    demand.externalCanisterNeeds.length > 0 ||
                    demand.refillQty > 0
                      ? () => handleExpansionClick(index)
                      : null
                  }
                  className={
                    expandableContainers[index] === "collapsed"
                      ? styles.pickList__mainContainer
                      : [
                          styles["pickList__mainContainer"],
                          styles["pickList__mainContainer--expanded"],
                        ].join(" ")
                  }
                >
                  <div
                    className={
                      expandableContainers[index] === "collapsed"
                        ? styles.pickList__productInfo
                        : [
                            styles["pickList__productInfo"],
                            styles["pickList__productInfo--expanded"],
                          ].join(" ")
                    }
                  >
                    {(demand.externalCanisterNeeds.length > 0 ||
                      demand.refillQty > 0) && (
                      <div className={styles.pickList__boxIconContainer}>
                        {expandableContainers[index] === "expanded" ? (
                          <CollapseIcon className={styles.pickList__boxIcon} />
                        ) : (
                          <ExpandIcon className={styles.pickList__boxIcon} />
                        )}
                      </div>
                    )}
                    <p className={styles["pickList__mainColumnVal--item1"]}>
                      {demand.product.packageId || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item2"]}>
                      {pcRef.getDefaultName(demand.product) || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item3"]}>
                      {demand.product.lot || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item4"]}>
                      {demand.product.exp || ""}
                    </p>
                    <p className={styles["pickList__mainColumnVal--item5"]}>
                      {demand.totalQty}
                    </p>
                  </div>

                  {expandableContainers[index] === "expanded" && (
                    <div className={styles.pickList__subContainer}>
                      {((demand.externalCanisterNeeds &&
                        demand.externalCanisterNeeds.length > 0) ||
                        (demand.externalStockedItems &&
                          demand.externalStockedItems.length > 0)) && (
                        <PickListSubHeadingByProduct />
                      )}

                      {demand.externalCanisterNeeds.map((need) => {
                        return (
                          <div
                            key={
                              demand.product.packageId + "externalCanisterNeeds"
                            }
                            className={styles.pickList__stockedItemContainer}
                          >
                            <p
                              className={
                                styles["pickList__subColumnVal--item1"]
                              }
                            >
                              {need.stockLocation.type.description || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item2"]
                              }
                            >
                              {need.stockLocation.name || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item3"]
                              }
                            >
                              {need.stockLocation.barcode || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item4"]
                              }
                            >
                              {need.quantity}
                            </p>
                          </div>
                        );
                      })}

                      {demand.externalStockedItems.map((item) => {
                        externalStockedItemsTotalQty += item.quantity;

                        return (
                          <div
                            key={
                              demand.product.packageId + "externalCanisterNeeds"
                            }
                            className={styles.pickList__stockedItemContainer}
                          >
                            <p
                              className={
                                styles["pickList__subColumnVal--item1"]
                              }
                            >
                              {item.stockLocation.type.description || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item2"]
                              }
                            >
                              {item.stockLocation.name || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item3"]
                              }
                            >
                              {item.stockLocation.barcode || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item4"]
                              }
                            >
                              {item.quantity}
                            </p>
                          </div>
                        );
                      })}

                      {demand.refillQty > 0 &&
                        externalStockedItemsTotalQty < demand.refillQty && (
                          <div
                            className={styles.pickList__stockedItemContainer}
                          >
                            <p
                              className={
                                styles["pickList__subColumnVal--item1"]
                              }
                            >
                              Bulk
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item2"]
                              }
                            >
                              {`${pcRef.getDefaultName(demand.product)} (${
                                demand.product.packageId
                              })` || ""}
                            </p>
                            <p
                              className={
                                styles["pickList__subColumnVal--item4"]
                              }
                            >
                              {demand.refillQty - externalStockedItemsTotalQty}
                            </p>
                          </div>
                        )}
                    </div>
                  )}
                </section>
              ) : null;
            })}
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div
        className={styles.pickList__scrollArea}
        onClick={(e) => handleScrollAreaClick(e)}
      >
        {demands.map((demand, index) => {
          let externalStockedItemsTotalQty = 0;
          return (cardClickIndex === -1 ||
            expandableContainers[index] === "expanded") &&
            (!filterText ||
              demand.product.packageId.toUpperCase().includes(filterText) ||
              pcRef
                .getDefaultName(demand.product)
                .toUpperCase()
                .includes(filterText)) &&
            !(
              demand.outstandingNeed === 0 &&
              demand.externalCanisterNeeds.length === 0 &&
              demand.externalStockedItems.length === 0 &&
              demand.refillQty === 0
            ) ? (
            <section
              key={index}
              onClick={
                demand.externalCanisterNeeds.length > 0 || demand.refillQty
                  ? () => handleParentCardClick(index)
                  : null
              }
            >
              {cardClickIndex === -1 && (
                <ProductCard
                  expandIcon={
                    demand.externalCanisterNeeds.length > 0 ||
                    demand.refillQty > 0
                      ? true
                      : false
                  }
                  data={{
                    packageIDHeading: "Package ID",
                    descriptionHeading: "Description",
                    lotHeading: "Lot",
                    expirationHeading: "Exp",
                    needHeading: "Need",
                    packageID: demand.product.packageId || "",
                    description: pcRef.getDefaultName(demand.product) || "",
                    lot: demand.product.lot || "",
                    expiration: demand.product.exp || "",
                    need: demand.totalQty,
                  }}
                />
              )}
              {expandableContainers[index] === "expanded" && (
                <div className={styles.pickList__subContainer}>
                  <h2 className={styles.pickList__locationsTitle}>
                    {!locationClickObject.type &&
                    locationClickObject.index === -1
                      ? "Locations"
                      : "Selected Location"}
                  </h2>

                  {!locationClickObject.type &&
                    locationClickObject.index === -1 &&
                    demand.externalCanisterNeeds.map((need, childIndex) => {
                      return (
                        <div
                          key={childIndex + "externalCanisterNeeds"}
                          className={styles.pickList__stockedItemContainer}
                          onClick={(e) => {
                            handleLocationClick(e, {
                              type: "externalCanisterNeed",
                              index: childIndex,
                            });
                          }}
                        >
                          <p
                            className={styles["pickList__subColumnVal--item2"]}
                          >
                            {need.stockLocation.name || ""}
                          </p>
                        </div>
                      );
                    })}

                  {locationClickObject.type === "externalCanisterNeed" && (
                    <ProductLocationCard
                      onProductLocationCardClick={
                        handleProductLocationCardClick
                      }
                      data={{
                        heading1: "Type",
                        heading2: "Qty",
                        heading3: "Name",
                        heading4: "Barcode",
                        item1:
                          demand.externalCanisterNeeds[
                            locationClickObject.index
                          ].stockLocation.type.description || "",
                        item2:
                          demand.externalCanisterNeeds[
                            locationClickObject.index
                          ].quantity || "",
                        item3:
                          demand.externalCanisterNeeds[
                            locationClickObject.index
                          ].stockLocation.name || "",
                        item4:
                          demand.externalCanisterNeeds[
                            locationClickObject.index
                          ].stockLocation.barcode || "",
                      }}
                    />
                  )}

                  {!locationClickObject.type &&
                    locationClickObject.index === -1 &&
                    demand.externalStockedItems.map((item, childIndex) => {
                      externalStockedItemsTotalQty += item.quantity;

                      return (
                        <div
                          key={
                            demand.product.packageId + "externalCanisterNeeds"
                          }
                          className={styles.pickList__stockedItemContainer}
                          onClick={(e) => {
                            handleLocationClick(e, {
                              type: "externalStockedItem",
                              index: childIndex,
                            });
                          }}
                        >
                          <p
                            className={styles["pickList__subColumnVal--item2"]}
                          >
                            {item.stockLocation.name || ""}
                          </p>
                        </div>
                      );
                    })}

                  {locationClickObject.type === "externalStockedItem" && (
                    <ProductLocationCard
                      onProductLocationCardClick={
                        handleProductLocationCardClick
                      }
                      data={{
                        heading1: "Type",
                        heading2: "Qty",
                        heading3: "Name",
                        heading4: "Barcode",
                        item1:
                          demand.externalStockedItems[locationClickObject.index]
                            .stockLocation.type.description || "",
                        item2:
                          demand.externalStockedItems[locationClickObject.index]
                            .quantity || "",
                        item3:
                          demand.externalStockedItems[locationClickObject.index]
                            .stockLocation.name || "",
                        item4:
                          demand.externalStockedItems[locationClickObject.index]
                            .stockLocation.barcode || "",
                      }}
                    />
                  )}

                  {demand.refillQty > 0 &&
                    externalStockedItemsTotalQty < demand.refillQty &&
                    !locationClickObject.type &&
                    locationClickObject.index === -1 && (
                      <div
                        className={styles.pickList__stockedItemContainer}
                        onClick={(e) => {
                          handleLocationClick(e, {
                            type: "bulk",
                            index: 0,
                          });
                        }}
                      >
                        <p className={styles["pickList__subColumnVal--item2"]}>
                          Bulk
                        </p>
                      </div>
                    )}

                  {locationClickObject.type === "bulk" && (
                    <ProductBulkCard
                      onProductLocationCardClick={
                        handleProductLocationCardClick
                      }
                      data={{
                        heading3: "Name",
                        heading4: "Package ID",
                        heading1: "Type",
                        heading2: "Qty",
                        item3: pcRef.getDefaultName(demand.product) || "",
                        item4: demand.product.packageId || "",
                        item1: "Bulk",
                        item2: demand.refillQty - externalStockedItemsTotalQty,
                      }}
                    />
                  )}
                </div>
              )}
            </section>
          ) : null;
        })}
      </div>
    );
  }
};

export default ListDisplayByProduct;
