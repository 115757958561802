import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import StockLocationController from "../../../controllers/StockLocationController";
import CardFlexCol from "../../commonUI/CardFlexCol";
import StockHeader from "./stockHeader/StockHeader";
import StockBody from "./stockBody/StockBody";
import StockFooter from "./stockFooter/StockFooter";
import { resetToHomeDefault } from "../../../functions/resetStockStates";
import styles from "./StockWithoutReceiving.module.css";

const StockWithoutReceiving = ({
  user,
  site,
  setSite,
  onHideModal,
  activeIcon,
  setActiveIcon,
  menuChoice,
  errorMsg,
  onSetErrorHandler,
}) => {
  const [currentStatusObj, setCurrentStatusObj] = useState({
    barcode: "",
    product: "",
    lotNumber: "",
    expiration: "",
  });
  const [stockedItemObjs, setStockedItemObjs] = useState([]);
  const [productRescannedEntryComplete, setProductRescannedEntryComplete] =
    useState(false);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);
  const [lotNumbers, setLotNumbers] = useState([]);
  const [expirations, setExpirations] = useState([]);
  const [barcodesToBeAssociated, setBarcodesToBeAssociated] = useState([]);
  const [productScanned, setProductScanned] = useState("");
  const [productScannedLot, setProductScannedLot] = useState("");
  const [productScannedExpDate, setProductScannedExpDate] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);
  const [stockedItems, setStockedItems] = useState("");
  const [getStockedItemsComplete, setGetStockedItemsComplete] = useState(false);
  const [stockedItemsQty, setStockedItemsQty] = useState(0);
  const [inputFieldQty, setInputFieldQty] = useState(0);
  const [stockLocations, setStockLocations] = useState([]);
  const [sortedStockLocations, setSortedStockLocations] = useState([]);
  const [stockLocationScanned, setStockLocationScanned] = useState("");
  const [productRescanned, setProductRescanned] = useState("");
  const [productRescannedLot, setProductRescannedLot] = useState("");
  const [productRescannedExpDate, setProductRescannedExpDate] = useState("");
  const [qtyError, setQtyError] = useState("");
  const [qtyErrorArray, setQtyErrorArray] = useState([]);
  const [pullStock, setPullStock] = useState(false);
  const [placeStock, setPlaceStock] = useState(false);
  const [scannedStockedItems, setScannedStockedItems] = useState([]);
  const [scannedItemIndex, setScannedItemIndex] = useState(0);
  const [scannedStockedItemsQuantities, setScannedStockedItemsQuantities] =
    useState([]);

  const [updatedScannedStockedItems, setUpdatedScannedStockedItems] = useState(
    []
  );
  const [neededMeds, setNeededMeds] = useState([]);
  const [enforceInputFocus, setEnforceInputFocus] = useState(true);
  const _stockLocationController = useRef(null);
  const [previousProductScanned, setPreviousProductScanned] = useState("");
  const [productAssociationFound, setProductAssociationFound] = useState(false);
  const [productAssociationNotFound, setProductAssociationNotFound] =
    useState(true);

  const [productNotFound, setProductNotFound] = useState(false);
  const [productBarcode, setProductBarcode] = useState("");
  const [associateBarcodeWithProduct, setAssociateBarcodeWithProduct] =
    useState(false);

  const [productsSearchText, setProductsSearchText] = useState("");
  const [selectedProductForAssoc, setSelectedProductForAssoc] = useState("");
  const [userCredentials, setUserCredentials] = useState("");
  const [newAssociationComplete, setNewAssociationComplete] = useState(false);
  const [authorizationFailure, setAuthorizationFailure] = useState(false);

  const [allowProductAssocWithLocation, setAllowProductAssocWithLocation] =
    useState(true);

  const [stockLocationDisabled, setStockLocationDisabled] = useState(false);

  const [updatedStockLocations, setUpdatedStockLocations] = useState([]);
  const [page, setPage] = useState(0);

  const [secondProductScan, setSecondProductScan] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const handleStockedItemObjs = (arr) => {
    setStockedItemObjs(arr);
  };

  const handleCurrentStatusObj = (obj) => {
    setCurrentStatusObj(obj);
  };

  useEffect(() => {
    if (!smScreen) {
      setActiveIcon(menuChoice);
    }
  }, [smScreen]);

  useEffect(() => {
    if (activeIcon === "Home" || "Receive" || "Transfer") {
      resetToHomeDefault(
        setScannedBarcodes,
        setBarcodesToBeAssociated,
        setSite,
        setProductScanned,
        setScannedProducts,
        setStockedItems,
        setGetStockedItemsComplete,
        setStockedItemsQty,
        setInputFieldQty,
        setStockLocations,
        setSortedStockLocations,
        setStockLocationScanned,
        setProductRescanned,
        setQtyError,
        setQtyErrorArray,
        setPullStock,
        setPlaceStock,
        setScannedStockedItems,
        setScannedItemIndex,
        setScannedStockedItemsQuantities,
        setUpdatedScannedStockedItems,
        setNeededMeds,
        setEnforceInputFocus,
        setPreviousProductScanned,
        setProductAssociationFound,
        setProductAssociationNotFound,
        setProductNotFound,
        setProductBarcode,
        setAssociateBarcodeWithProduct,
        setProductsSearchText,
        setSelectedProductForAssoc,
        setUserCredentials,
        setNewAssociationComplete,
        setAuthorizationFailure
      );
    }
  }, [activeIcon]);

  useEffect(() => {
    if (
      activeIcon === "Receive" &&
      user &&
      site &&
      productScanned &&
      stockLocations.length === 0
    ) {
      _stockLocationController.current = new StockLocationController(
        user,
        site
      );
      _stockLocationController.current.getStockLocationsByProduct(
        productScanned,
        callback1
      );
    }
  }, [productScanned]);

  useEffect(() => {
    if (productScanned && !stockedItems) {
      stockLocations.map(async (location) => {
        await _stockLocationController.current.getStockedItems(
          location,
          callback2
        );
      });

      if (stockLocations.length > 0) {
        setGetStockedItemsComplete(true);
      }
    }
  }, [stockLocations]);

  // getStockLocationsByProduct
  const callback1 = (error, locations) => {
    if (!error) {
      setStockLocations(locations);
    } else {
      console.log("error:", error);
    }
  };

  // getStockedItems
  const callback2 = (error, stockedItems) => {
    if (stockedItems && stockedItems.length > 0) {
      const barcode = stockedItems[0].stockLocation.barcode;
      const stockLocIndex = stockLocations.findIndex(
        (x) => x.barcode === barcode
      );

      if (!error) {
        if (stockedItems.length > 0) {
          // Filter by Product
          const filteredStockedItems = stockedItems.filter(
            (filteredStockedItem) => {
              return (
                filteredStockedItem.product.packageId ===
                productScanned.packageId
              );
            }
          );

          let totalQty = 0;
          filteredStockedItems.forEach((stockedItem) => {
            totalQty += stockedItem.quantity;
          });

          setStockedItems((prevState) => {
            return [
              ...prevState,
              {
                stockLocIndex: stockLocIndex,
                stockedItemsList: filteredStockedItems,
                totalQty: totalQty,
              },
            ];
          });
        }
      } else {
        console.log("error:", error);
      }
    }
  };

  const handleActivateStockLocationDisabled = () => {
    return setStockLocationDisabled(true);
  };

  const handleDeactivateStockLocationDisabled = () => {
    setStockLocationDisabled(false);
  };

  const handleDenySetProductWithLocation = () => {
    setAllowProductAssocWithLocation(false);
  };

  const handleUpdatedStockLocations = (arr) => {
    setUpdatedStockLocations(arr);
  };

  return (
    <Fragment>
      {errorMsg && (
        <div className={styles.stock__modalTransparentBackdrop}></div>
      )}
      <CardFlexCol className={styles.stock__card}>
        {stockLocationDisabled &&
          onSetErrorHandler("The scanned stock location is disabled.")}

        {activeIcon !== "Transfer" && (
          <StockHeader
            setSecondProductScan={setSecondProductScan}
            currentStatusObj={currentStatusObj}
            handleCurrentStatusObj={handleCurrentStatusObj}
            stockedItemObjs={stockedItemObjs}
            handleStockedItemObjs={handleStockedItemObjs}
            productRescannedEntryComplete={productRescannedEntryComplete}
            setProductRescannedEntryComplete={setProductRescannedEntryComplete}
            page={page}
            setPage={setPage}
            barcodesToBeAssociated={barcodesToBeAssociated}
            setBarcodesToBeAssociated={setBarcodesToBeAssociated}
            scannedBarcodes={scannedBarcodes}
            setScannedBarcodes={setScannedBarcodes}
            lotNumbers={lotNumbers}
            setLotNumbers={setLotNumbers}
            expirations={expirations}
            setExpirations={setExpirations}
            site={site}
            setSite={setSite}
            user={user}
            activeIcon={activeIcon}
            setActiveIcon={setActiveIcon}
            productScanned={productScanned}
            setProductScanned={setProductScanned}
            productScannedLot={productScannedLot}
            setProductScannedLot={setProductScannedLot}
            productScannedExpDate={productScannedExpDate}
            setProductScannedExpDate={setProductScannedExpDate}
            scannedProducts={scannedProducts}
            setScannedProducts={setScannedProducts}
            setStockedItems={setStockedItems}
            stockedItemsQty={stockedItemsQty}
            setStockedItemsQty={setStockedItemsQty}
            inputFieldQty={inputFieldQty}
            setInputFieldQty={setInputFieldQty}
            stockLocations={stockLocations}
            setStockLocations={setStockLocations}
            stockLocationScanned={stockLocationScanned}
            setStockLocationScanned={setStockLocationScanned}
            productRescanned={productRescanned}
            setProductRescanned={setProductRescanned}
            productRescannedLot={productRescannedLot}
            setProductRescannedLot={setProductRescannedLot}
            productRescannedExpDate={productRescannedExpDate}
            setProductRescannedExpDate={setProductRescannedExpDate}
            qtyError={qtyError}
            setQtyError={setQtyError}
            pullStock={pullStock}
            placeStock={placeStock}
            scannedStockedItems={scannedStockedItems}
            setScannedStockedItems={setScannedStockedItems}
            scannedItemIndex={scannedItemIndex}
            setScannedItemIndex={setScannedItemIndex}
            scannedStockedItemsQuantities={scannedStockedItemsQuantities}
            setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            previousProductScanned={previousProductScanned}
            setPreviousProductScanned={setPreviousProductScanned}
            productAssociationFound={productAssociationFound}
            setProductAssociationFound={setProductAssociationFound}
            setProductAssociationNotFound={setProductAssociationNotFound}
            setGetStockedItemsComplete={setGetStockedItemsComplete}
            setSortedStockLocations={setSortedStockLocations}
            productAssociationNotFound={productAssociationNotFound}
            productNotFound={productNotFound}
            setProductNotFound={setProductNotFound}
            productBarcode={productBarcode}
            setProductBarcode={setProductBarcode}
            associateBarcodeWithProduct={associateBarcodeWithProduct}
            setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
            productsSearchText={productsSearchText}
            setProductsSearchText={setProductsSearchText}
            selectedProductForAssoc={selectedProductForAssoc}
            setSelectedProductForAssoc={setSelectedProductForAssoc}
            userCredentials={userCredentials}
            setUserCredentials={setUserCredentials}
            newAssociationComplete={newAssociationComplete}
            setNewAssociationComplete={setNewAssociationComplete}
            authorizationFailure={authorizationFailure}
            setAuthorizationFailure={setAuthorizationFailure}
            onHideModal={onHideModal}
            onActivateStockLocationDisabled={
              handleActivateStockLocationDisabled
            }
            onDenySetProductWithLocation={handleDenySetProductWithLocation}
            errorMsg={errorMsg}
            onSetErrorHandler={onSetErrorHandler}
            updatedStockLocations={updatedStockLocations}
            handleUpdatedStockLocations={handleUpdatedStockLocations}
          />
        )}

        {authorizationFailure &&
          onSetErrorHandler(
            "The entered credentials could not be authenticated."
          )}

        <StockBody
          secondProductScan={secondProductScan}
          currentStatusObj={currentStatusObj}
          handleCurrentStatusObj={handleCurrentStatusObj}
          stockedItemObjs={stockedItemObjs}
          handleStockedItemObjs={handleStockedItemObjs}
          productRescannedEntryComplete={productRescannedEntryComplete}
          setProductRescannedEntryComplete={setProductRescannedEntryComplete}
          page={page}
          setPage={setPage}
          barcodesToBeAssociated={barcodesToBeAssociated}
          setBarcodesToBeAssociated={setBarcodesToBeAssociated}
          scannedBarcodes={scannedBarcodes}
          setScannedBarcodes={setScannedBarcodes}
          lotNumbers={lotNumbers}
          setLotNumbers={setLotNumbers}
          expirations={expirations}
          setExpirations={setExpirations}
          activeIcon={activeIcon}
          setActiveIcon={setActiveIcon}
          site={site}
          setSite={setSite}
          user={user}
          productScanned={productScanned}
          setProductScanned={setProductScanned}
          productScannedLot={productScannedLot}
          setProductScannedLot={setProductScannedLot}
          productScannedExpDate={productScannedExpDate}
          setProductScannedExpDate={setProductScannedExpDate}
          previousProductScanned={previousProductScanned}
          setPreviousProductScanned={setPreviousProductScanned}
          scannedProducts={scannedProducts}
          setScannedProducts={setScannedProducts}
          stockedItems={stockedItems}
          setStockedItems={setStockedItems}
          getStockedItemsComplete={getStockedItemsComplete}
          setGetStockedItemsComplete={setGetStockedItemsComplete}
          stockedItemsQty={stockedItemsQty}
          setStockedItemsQty={setStockedItemsQty}
          inputFieldQty={inputFieldQty}
          setInputFieldQty={setInputFieldQty}
          stockLocations={stockLocations}
          setStockLocations={setStockLocations}
          sortedStockLocations={sortedStockLocations}
          setSortedStockLocations={setSortedStockLocations}
          stockLocationScanned={stockLocationScanned}
          setStockLocationScanned={setStockLocationScanned}
          productRescanned={productRescanned}
          setProductRescanned={setProductRescanned}
          productRescannedLot={productRescannedLot}
          setProductRescannedLot={setProductRescannedLot}
          productRescannedExpDate={productRescannedExpDate}
          setProductRescannedExpDate={setProductRescannedExpDate}
          qtyError={qtyError}
          setQtyError={setQtyError}
          qtyErrorArray={qtyErrorArray}
          setQtyErrorArray={setQtyErrorArray}
          pullStock={pullStock}
          setPullStock={setPullStock}
          placeStock={placeStock}
          setPlaceStock={setPlaceStock}
          scannedStockedItems={scannedStockedItems}
          setScannedStockedItems={setScannedStockedItems}
          scannedItemIndex={scannedItemIndex}
          setScannedItemIndex={setScannedItemIndex}
          updatedScannedStockedItems={updatedScannedStockedItems}
          setUpdatedScannedStockedItems={setUpdatedScannedStockedItems}
          scannedStockedItemsQuantities={scannedStockedItemsQuantities}
          setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
          neededMeds={neededMeds}
          setNeededMed={setNeededMeds}
          enforceInputFocus={enforceInputFocus}
          setEnforceInputFocus={setEnforceInputFocus}
          productAssociationFound={productAssociationFound}
          setProductAssociationFound={setProductAssociationFound}
          productAssociationNotFound={productAssociationNotFound}
          setProductAssociationNotFound={setProductAssociationNotFound}
          productNotFound={productNotFound}
          setProductNotFound={setProductNotFound}
          productBarcode={productBarcode}
          setProductBarcode={setProductBarcode}
          associateBarcodeWithProduct={associateBarcodeWithProduct}
          setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
          productsSearchText={productsSearchText}
          setProductsSearchText={setProductsSearchText}
          selectedProductForAssoc={selectedProductForAssoc}
          setSelectedProductForAssoc={setSelectedProductForAssoc}
          userCredentials={userCredentials}
          setUserCredentials={setUserCredentials}
          newAssociationComplete={newAssociationComplete}
          setNewAssociationComplete={setNewAssociationComplete}
          authorizationFailure={authorizationFailure}
          setAuthorizationFailure={setAuthorizationFailure}
          updatedStockLocations={updatedStockLocations}
          handleUpdatedStockLocations={handleUpdatedStockLocations}
          onSetErrorHandler={onSetErrorHandler}
        />

        {smScreen ? (
          <StockFooter activeIcon={activeIcon} setActiveIcon={setActiveIcon} />
        ) : null}
      </CardFlexCol>
    </Fragment>
  );
};

export default StockWithoutReceiving;
