import AuthService from "../services/AuthService";

class AuthController {
  constructor(user) {
    this.user = user;
  }

  verifyUserToken = async () => {
    let results = false;
    try {
      results = await AuthService.verifyUserToken(this.user);
    } catch (err) {
      console.log(err);
    }
    return results;
  };
}

export default AuthController;
