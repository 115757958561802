import {
  BlobServiceClient,
  generateAccountSASQueryParameters,
  AccountSASPermissions,
  AccountSASServices,
  AccountSASResourceTypes,
  StorageSharedKeyCredential,
  SASProtocol,
} from "@azure/storage-blob";
import help from "./Help";

class HelpController {
  static BLOB_SAS_URL = "https://cs21003200275c61485.blob.core.windows.net";
  static BLOB_SAS_TOKEN =
    "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2033-07-24T22:38:33Z&st=2023-07-24T14:38:33Z&spr=https,http&sig=abw5RZkFUc1LpNqSdcHLvIDCdbPMdDpwTao2FbuIOXM%3D";

  constructor() {
    this.blobServiceClient = new BlobServiceClient(
      `${HelpController.BLOB_SAS_URL}?${HelpController.BLOB_SAS_TOKEN}`,
      null
    );
  }

  getHelpGuidePdf = async (filename) => {
    try {
      // const temp = filename;
      const blobName = filename;
      const containerName = "help-guides";
      const containerClient = await this.blobServiceClient.getContainerClient(
        containerName
      );
      const blobClient = await containerClient.getBlockBlobClient(blobName);
      // const pdf = await blobClient.downloadToFile(temp);
      return blobClient.url;
    } catch (e) {
      console.log(e.message, e);
      return null;
    }
  };

  getHelpGuideList = async () => {
    const helpGuides = [];
    try {
      const containerName = "help-guides";
      const containerClient = this.blobServiceClient.getContainerClient(containerName);
      let iter = containerClient.listBlobsFlat();
      let blobItem = await iter.next();
      const blobPromises = [];
  
      while (!blobItem.done) {
        const blobName = blobItem.value.name;
        const blobClient = containerClient.getBlockBlobClient(blobName);
        
        // Push the promise to the array
        blobPromises.push(
          blobClient.getProperties().then(properties => {
            const obj = properties.metadata;
            obj["filename"] = blobName;
            return obj;
          })
        );
  
        blobItem = await iter.next();
      }
  
      // Wait for all promises to resolve
      const results = await Promise.all(blobPromises);
      helpGuides.push(...results);
  
      return helpGuides;
    } catch (e) {
      console.log(e.message);
      return helpGuides;
    }
  };

  getHelpVideoList = async (filename) => {
    const helpVideos = [];
    try {
      const containerName = "videos";
      const containerClient = await this.blobServiceClient.getContainerClient(
        containerName
      );
      let i = 1;
      let iter = containerClient.listBlobsFlat();
      let blobItem = await iter.next();
      while (!blobItem.done) {
        const blobName = blobItem.value.name;
        const blobClient = await containerClient.getBlockBlobClient(blobName);
        const properties = await blobClient.getProperties();
        const obj = properties.metadata;
        obj["filename"] = blobName;
        helpVideos.push(obj);
        blobItem = await iter.next();
      }
      return helpVideos;
    } catch (e) {
      console.log(e.message);
      return helpVideos;
    }
  };

  getHelpVideo = async (filename) => {
    try {
      // const temp = filename;
      const blobName = filename;
      const containerName = "videos";
      const containerClient = await this.blobServiceClient.getContainerClient(
        containerName
      );
      const blobClient = await containerClient.getBlockBlobClient(blobName);
      return blobClient.url;
    } catch (e) {
      console.log(e.message, e);
      return null;
    }
  };
}

export default HelpController;
