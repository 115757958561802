import { notification } from "antd";

import TrayService from "../services/TrayService";
import ImageService from "../services/ImageService";
import PharmacyService from "../services/PharmacyService";
import StockLocationService from "../services/StockLocationService";
import DispensingOrderService from "../services/DispensingOrderService";

import {
  getLockedTray,
  getUnlockedTray,
  getApprovedTray,
  getResettedTrayCheck,
  getApprovedDrugOnTray,
  getCorrectedApprovedDrugOnSlot,
} from "../utils/functions/trayChecks";

const useTrayChecksController = () => {
  const getSite = async (user, siteId) => {
    try {
      const site = await PharmacyService.getById(user, siteId);
      return site;
    } catch (error) {
      console.error("Error getting site ", error);
      notification.error({
        message: "Error getting site:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const getDevice = async (user, site, deviceId) => {
    try {
      const device = await StockLocationService.get(user, site, deviceId);
      return device;
    } catch (error) {
      console.error("Error getting device ", error);
      notification.error({
        message: "Error getting device:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const getOrder = async (user, site, orderId) => {
    try {
      const list = await DispensingOrderService.getBySite(user, site);
      const order = list.find((item) => item.dispensingOrder._id === orderId);
      return order;
    } catch (error) {
      console.error("Error getting order ", error);
      notification.error({
        message: "Error getting order:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const getTray = async (user, site, device, trayId) => {
    try {
      const tray = await TrayService.getTray(user, site, device, trayId);
      return tray;
    } catch (error) {
      console.error("Error loading tray:", error);
      notification.error({
        message: "Error loading tray:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const getOrdersList = async (user, site, device) => {
    try {
      const list = await DispensingOrderService.getBySite(user, site);
      const filteredList = list.filter(
        (item) =>
          item?.trayChecks?.length > 0 &&
          item?.dispensingOrder.state === "routed" &&
          device.name === item.dispensingOrder?.routedToName
      );
      return filteredList;
    } catch (error) {
      console.error("Error fetching  order list", error);
      notification.error({
        message: "Error fetching the order list:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const resetTray = async (user, site, device, tray, notify = true) => {
    try {
      const newTray = getResettedTrayCheck(tray);
      const updated = await TrayService.updateTray(user, site, device, newTray);
      if (notify)
        notification.success({ message: "Tray resetted successfully" });
      return updated;
    } catch (error) {
      console.error("Error resetting tray:", error);
      notification.error({
        message: "Error resetting tray:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const deleteTray = async (user, site, device, tray) => {
    try {
      const deleted = await TrayService.deleteTray(user, site, device, tray);
      notification.success({ message: "Tray deleted successfully" });
      return deleted;
    } catch (error) {
      console.error("Error deleting tray", error);
      notification.error({
        message: "Error deleting tray:",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const correctedApproveDrug = async (
    user,
    site,
    device,
    tray,
    slot,
    drug,
    reason,
    comments
  ) => {
    try {
      const userId = user._id;
      const newTray = getCorrectedApprovedDrugOnSlot(
        userId,
        drug,
        slot,
        tray,
        reason,
        comments
      );
      const updated = await TrayService.updateTray(user, site, device, newTray);
      notification.success({
        message: "Drug corrected approved successfully",
      });
      return updated;
    } catch (error) {
      console.error("Error corrected approving drug", error);
      notification.error({
        message: "Error corrected approving drug",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const approveDrug = async (user, site, device, tray, drug) => {
    try {
      const newTray = getApprovedDrugOnTray(user._id, drug, tray);
      const updated = await TrayService.updateTray(user, site, device, newTray);
      notification.success({
        message: "Drug approved successfully",
      });
      return updated;
    } catch (error) {
      console.error("Error approving drug", error);
      notification.error({
        message: "Error approving drug",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const approveTray = async (user, site, device, tray) => {
    try {
      const newTray = getApprovedTray(user._id, tray);
      const updated = await TrayService.updateTray(user, site, device, newTray);
      notification.success({ message: "Tray approved successfully" });
      return updated;
    } catch (error) {
      console.error("Error approving tray", error);
      notification.error({
        message: "Error approving tray",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const lockTray = async (user, site, device, tray) => {
    try {
      const newTray = getLockedTray(tray);
      const updated = await TrayService.updateTray(user, site, device, newTray);
      return updated;
    } catch (error) {
      console.error("Error locking tray", error);
      notification.error({
        message: "Error locking tray",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const unlockTray = async (user, site, device, tray) => {
    try {
      const newTray = getUnlockedTray(tray);
      const updated = await TrayService.updateTray(user, site, device, newTray);
      return updated;
    } catch (error) {
      console.error("Error unlocking tray", error);
      notification.error({
        message: "Error unlocking tray",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  const getDrugImage = async (user, imageId) => {
    try {
      const image = await ImageService.read(user, imageId);
      return image;
    } catch (error) {
      console.error("Error getting drug image", error);
      notification.error({
        message: "Error getting drug image",
        description:
          typeof error === "string" ? error : "Please contact the support",
      });
      return false;
    }
  };

  return {
    getSite,
    getTray,
    lockTray,
    getOrder,
    resetTray,
    getDevice,
    unlockTray,
    deleteTray,
    approveDrug,
    approveTray,
    getDrugImage,
    getOrdersList,
    correctedApproveDrug,
  };
};

export default useTrayChecksController;
