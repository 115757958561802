import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "./CreateProduct.module.css";
import validate from "../../validation/ProductValidationRules";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spacer from "../../components/Spacer";
import ErrorBanner from "../../components/ErrorBanner";
import Button from "../../components/Button";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import ProductService from "../../services/ProductService";
import CreateButton from "../../components/CreateButton";
import FdaNdcProductSearch from "./FdaNdcProductSearch";
import ProductCategories from "./CreateProductCategory";
import CreateProductGeneral from "./CreateProductGeneral";
import CreateProductPhysicalAttributes from "./CreateProductPhysicalAttributes";
import ImageService from "../../services/ImageService";
import CreateProductLTCCCosts from "./CreateProductLTCCCosts";
import CreateProductRetailCosts from "./CreateProductRetailCosts";
import CreateProductBarcodesAndOtherIDs from "./CreateProductBarcodesAndOtherIDs";
import WaitIndicator from "../../components/WaitIndicator";
import CreateProductEV54Naming from "./CreateProductEV54Naming";
import CreateProductVBM from "./CreateProductVBM";
import CreateProductProud from "./CreateProductProud";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import CreateStockLocationAssociations from "./CreateStockLocationAssociations";
import CreateProductPartial from "./CreateProductPartial";
import ClientFDBService from "../../services/ClientFDBService";
import FdbLookup from "./FdbLookup/FdbLookup";
import { Modal } from "antd";

const CreateProduct2 = ({
  user,
  product,
  dispensingOrderItem = null,
  onCloseAddProductModal = null,
  isNew = false
}) => {
  // Variables
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("createProduct");
  const isCopy = location.state?.isCopy || false;

  // State
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [p, setP] = useState({});
  const [categoryListItems, setCategoryListItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editProductCategories, setEditProductCategories] = useState(false);
  const [ndcLookup, setNDCLookup] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fdbImage, setFdbImage] = useState(null);
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (product) {
      if (product.images.length > 0) {
        ImageService.getAllProductWithImages(user, product).then((p) => {
          setP({ ...p });
        });
      } else {
        setP({ ...product });
      }
    }
  }, [product]);

  useEffect(() => {
    ProductService.getProductCategories(user)
      .then((results) => {
        setCategories(results);
        let mappedProductCategories = results.map((result) => {
          return {
            type: result.name,
            description: result.name,
          };
        });
        setCategoryListItems(mappedProductCategories);
        if (isNew) {
          const cat = results.find((o) => o.name.toUpperCase() === "DRUG");
          setP((prev) => {
            return { ...prev, productCategory: cat };
          });
        }
      })
      .catch((error) => setError(error));
  }, [isNew, user]);

  // Functions
  const setProperty = (obj) => {
    // let objToAdd = obj;
    // if (
    //   objToAdd?.dosageForm?.description === " " &&
    //   objToAdd?.route?.description !== " "
    // ) {
    //   objToAdd = {
    //     ...obj,
    //     dosageForm: {
    //       description: "",
    //       type: "",
    //       id: -1,
    //     },
    //   };
    // } else if (
    //   objToAdd?.dosageForm?.description !== " " &&
    //   objToAdd?.route?.type === " "
    // ) {
    //   objToAdd = {
    //     ...obj,
    //     route: {
    //       fullName: "",
    //       abbrName: "",
    //     },
    //   };
    // }
    setP((prev) => {
      return { ...prev, ...obj };
    });
  };

  // Event handlers
  const handleClose = () => {
    dispensingOrderItem ? onCloseAddProductModal() : navigate(-1);
  };

  const handleSubmit = () => {
    if (isDataValid()) {
      setIsProcessing(true);
      if (isNew) {
        createProduct()
          .then((product) => {
            if (dispensingOrderItem) {
              onCloseAddProductModal(true);
            }
            navigate("/settings/products", {
              state: { product: product, isNew: true },
              replace: true,
            });
          })
          .catch((error) => {
            console.log("error: ", error);
            setIsProcessing(false);
            setError(error);
          });
      } else {
        updateProduct()
          .then((product) => {
            navigate("/settings/products", {
              state: { product: product, isUpdate: true },
              replace: true,
            });
          })
          .catch((error) => {
            setIsProcessing(false);
            setError(error);
          });
      }
    }
  };

  const createProduct = () => {
    return new Promise(async (resolve, reject) => {
      let exists = await ProductService.productExists(user, p.packageId);
      if (exists) {
        reject(t("nameExistsMsg"));
      } else {
        const product = await ProductService.createProduct(user, {
          product: p,
        });
        resolve(product);
      }
    });
  };

  const updateProduct = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const product = await ProductService.update(user, p);
        resolve(product);
      } catch (error) {
        reject(error.message);
      }
    });
  };

  const isDataValid = () => {
    let validationErrors = validate({
      packageId: p?.packageId,
      productCategory: p?.productCategory,
      primaryName: p?.primaryName,
      // ev54DrugName: p?.ev54DrugName,
      // ev54DrugId: p?.ev54DrugId,
      product: p,
    });

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setError("One or more validation errors exist");
      return false;
    }
    return true;
  };

  const handleCategorySelection = (listItem) => {
    const pc = categories.find((o) => o.name === listItem.type);
    setProperty({ productCategory: pc });
  };

  const handleNDCLookup = () => {
    setNDCLookup(true);
  };

  const addImage = (image) => {
    ImageService.create(user, image.file, () => {})
      .then((imageDoc) => {
        let length = p.images ? p.images.length : 0;
        if (length === 0) {
          setProperty({ defaultImage: imageDoc._id });
          p.images = [];
        }
        setProperty({ images: [...p.images, imageDoc] });
      })
      .catch((error) => {
        setError(error);
      });
  };

  const deleteImage = (image) => {
    if (image._id === p.defaultImage) {
      setProperty({ defaultImage: null });
    }
    let imageCopies = [...p.images];
    const index = p.images.findIndex(
      (o) => o.base64EncodedString === image.base64EncodedString
    );
    imageCopies.splice(index, 1);
    setProperty({ images: imageCopies });

    if (imageCopies.length > 0) {
      setProperty({ defaultImage: imageCopies[0]._id });
    } else {
      setProperty({ defaultImage: null });
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const searchFDBImages = () => {
    ClientFDBService.getImagesByNdc(user, p.packageId)
      .then(async (images) => {
        if (!p.images || p.images.length === 0) {
          p.images = [];
          for (let i = 0; i < images.length; i++) {
            const imageDoc = await ImageService.createFromFdbImage(
              user,
              images[i]
            );
            if (i === 0) {
              setProperty({ defaultImage: imageDoc._id });
            }
            setProperty({ images: [...p.images, imageDoc] });
          }
        } else {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (!p.images.some((i) => i.name === image.name)) {
              const imageDoc = await ImageService.createFromFdbImage(
                user,
                image
              );
              setProperty({ images: [...p.images, imageDoc] });
            }
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const convertFdbPackagedDrug = (product) => {
    setP((prev) => {
      const r = { ...prev, ...product };
      return r;
    });
    setNDCLookup(false);
  };

  if (isProcessing) {
    return (
      <WaitIndicator
        message={isNew ? "Creating product...." : "Updating product..."}
      />
    );
  } else if (ndcLookup) {
    return (
      <Modal
        title="Search FDB"
        open={ndcLookup}
        footer={null}
        onCancel={() => setNDCLookup(false)}
      >
        <FdbLookup
          user={user}
          onAdd={(fdbPackagedDrug) => {
            convertFdbPackagedDrug(fdbPackagedDrug);
          }}
        />
      </Modal>

      // <FdaNdcProductSearch
      //   user={user}
      //   onClose={() => setNDCLookup(false)}
      //   onAdd={(product) => handleFdaNdcProduct(product)}
      // />
    );
  } else if (editProductCategories) {
    return (
      <ProductCategories
        onClose={(productCategories) => {
          setCategories(productCategories);
          let mappedProductCategories = productCategories.map((result) => {
            return {
              type: result.name,
              description: result.name,
            };
          });

          setCategoryListItems(mappedProductCategories);
          setEditProductCategories(false);
        }}
        user={user}
      />
    );
  } else {
    return (
      <div className={styles.CreateProduct__overlay}>
        {smScreen && error && (
          <div className={styles.CreateProduct__errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
          </div>
        )}
        <div className={styles.CreateProduct__view}>
          {smScreen && (
            <section className={styles.CreateProduct__header}>
              <div
                className={styles.CreateProduct__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.CreateProduct__backArrowIcon}
                />
                <p className={styles.CreateProduct__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.CreateProduct__title}>
                {isNew ? t("newTitle") : t("updateTitle")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <header className={styles.CreateProduct__header}>
              <h1 className={styles.CreateProduct__title}>
                {isNew ? t("newTitle") : t("updateTitle")}
              </h1>
              <div className={styles.CreateProduct__close}>
                <CloseIcon
                  fill={"rgba(18,26, 45, 0.5)"}
                  onClick={handleClose}
                />
              </div>
            </header>
          )}

          {!smScreen && error && (
            <div className={styles.CreateProduct__errorBannerContainer}>
              <ErrorBanner message={error} onClose={() => setError(null)} />
            </div>
          )}

          <div
            className={
              smScreen
                ? styles.CreateProduct__utilities
                : styles.CreateProduct__productCategory_row
            }
          >
            <div className={styles.CreateProduct__productCategory_col}>
              <DropdownInput
                labelName={"Select Product Category"}
                validationText={errors.category}
                value={p.category ? p.category.name : "Drug"}
                placeholder={""}
                isEnabled={isNew}
                onSelection={handleCategorySelection}
                listElements={categoryListItems}
              />
            </div>
            <div
              className={`${styles.CreateProduct__productCategory_row} ${styles["CreateProduct__productCategory_row--center"]}`}
            >
              <CreateButton
                onClick={() => setEditProductCategories(true)}
                isEnabled={true}
                labelName={t("buttonLabel0")}
              />
            </div>
          </div>
          <br />

          <div className={styles.CreateProduct__scroll}>
            {
              <CreateProductGeneral
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
                onHandleNDCLookup={handleNDCLookup}
                onError={(error) => setError(error)}
                isCopy={isCopy}
                user={user}
              />
            }

            {
              <CreateProductPhysicalAttributes
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
                onAddImage={addImage}
                onDeleteImage={deleteImage}
                onSearchFDBImages={searchFDBImages}
              />
            }

            {
              <CreateProductPartial
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
                user={user}
              />
            }

            <CreateProductBarcodesAndOtherIDs
              p={p}
              isNew={isNew}
              errors={errors}
              onPropertyChange={(obj) => setProperty(obj)}
              user={user}
              onError={(error) => setError(error)}
            />

            {/* Stock Location Associations*/}
            {!isNew && (
              <CreateStockLocationAssociations
                p={p}
                isNew={isNew}
                errors={errors}
                user={user}
                onError={(error) => setError(error)}
              />
            )}

            <CreateProductLTCCCosts
              p={p}
              isNew={isNew}
              errors={errors}
              onPropertyChange={(obj) => setProperty(obj)}
            />

            <CreateProductRetailCosts
              p={p}
              isNew={isNew}
              errors={errors}
              onPropertyChange={(obj) => setProperty(obj)}
            />

            {p && p.allowEV54 && (
              <CreateProductEV54Naming
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
              />
            )}

            {p && p.allowProud && (
              <CreateProductProud
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
              />
            )}

            {p && p.allowVBM && (
              <CreateProductVBM
                p={p}
                isNew={isNew}
                errors={errors}
                onPropertyChange={(obj) => setProperty(obj)}
              />
            )}
          </div>

          <footer>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={handleClose}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={smScreen ? 20 : 50} unit={"px"} />
            <Button
              style={{ opacity: 1 }}
              labelName={
                isNew ? t("addNewButtonLabel") : t("updateButtonLabel")
              }
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </footer>
        </div>
      </div>
    );
  }
};

export default CreateProduct2;
