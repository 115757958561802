import React, { useState, useEffect, useRef, useContext } from "react";
import AssociateProductBarcodeController from "./AssociateProductBarcodeController";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import NDCProduct from "./NDCProduct";
import styles from "./NDCProductList.module.css";

const NDCProductList = ({
  user,
  productsSearchText,
  setSelectedProductForAssoc,
}) => {
  const _controller = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [productsReturned, setProductsReturned] = useState([]);

  useEffect(() => {
    _controller.current = new AssociateProductBarcodeController(user);
  }, []);

  useEffect(() => {
    _controller.current.searchForProducts(productsSearchText, callback);
  }, [productsSearchText]);

  // searchForProducts
  const callback = (error, products) => {
    if (!error && products && products.length > 0) {
      setProductsReturned(products);
    } else if (!error && products && products.length === 0) {
      console.log("No products were found for this search");
      setProductsReturned([]);
    } else {
      console.log("error:", error);
      setProductsReturned([]);
    }
  };

  return (
    <div className={styles.NDCProductList}>
      <p className={styles.NDCProductList__clickMessage}>Select product</p>
      <div
        className={
          smScreen || (productsReturned && productsReturned.length > 3)
            ? [styles["scrollArea"], styles["NDCProductList__body"]].join(" ")
            : [styles["NDCProductList"], styles["NDCProductList__body"]].join(
                " "
              )
        }
      >
        {productsReturned.map((product) => {
          return (
            <NDCProduct
              key={product.packageId}
              product={product}
              setSelectedProductForAssoc={setSelectedProductForAssoc}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NDCProductList;
