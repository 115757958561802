import React, { useState, useEffect, useRef, useContext } from "react";
import UserController from "../../../../../controllers/UserController";
import styles from "./GetSite.module.css";

const GetSite = ({ setPage, setSite, user }) => {
  const [sites, setSites] = useState([]);
  const _controller = useRef(null);

  useEffect(() => {
    setPage(1);
    _controller.current = new UserController(user);
    _controller.current.getSites(callback);
  }, []);

  // getSites
  const callback = (error, sites) => {
    if (!error) {
      setSites(sites);
      if (sites.length === 1) {
        setSite(sites[0]);
      }
    } else {
      console.log("error:", error);
    }
  };

  return (
    <div className={styles.getSite}>
      <h2 className={styles.getSite__heading}>
        {sites && sites.length > 1 ? "Select Site" : null}
      </h2>
      <div className={styles.scrollArea}>
        <ul className={styles.getSite__list}>
          {sites.map((site, index) => {
            return (
              <li
                key={sites[index].id}
                className={styles.getSite__item}
                onClick={() => {
                  setSite(site);
                }}
              >
                {sites[index].name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GetSite;
