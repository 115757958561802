import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import Loader from "react-loader-spinner";
import PillSortDashboardController from "./PillSortDashboardController";
import StockLocationService from "../../../services/StockLocationService";
import BarLineGraph from "./graphs/BarLineGraph";
import BarGraph from "../sharedGraphs/BarGraph";
import AlarmsTable from "./SubViews/AlarmsTable";
import NDCTable from "./SubViews/NDCTable";
import OptiSortDailyReport from "./SubViews/OptiSortDailyReport";
import SuctionCupTable from "./SubViews/SuctionCupTable";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./PillSortDashboard.module.css";
import stylesMobile from "./MobileViews/PillSortDashboardMobileView.module.css";

const PillSortDashboard = ({ props }) => {
  const {
    viewWidth,
    user,
    selectedSite,
    handleCollectingDataFromServer,
    windowHeight,
    onShowDropdowns,
  } = props;

  const stopDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 4); // 5 days total

  const [fetchingQtyAndValueSortedData, setFetchingQtyAndValueSortedData] =
    useState(false);
  const [fetchingProductWOPriceData, setFetchingProductWOPriceData] =
    useState(false);
  const [fetchingSuctionCupLifeData, setFetchingSuctionCupLifeData] =
    useState(false);
  const [fetchingAlarmsData, setFetcingAlarmsData] = useState(false);

  const [dispensingDevices, setDispensingDevices] = useState([]);
  const [selectedGraph, setSelectedGraph] = useState(null); // -1 indicates no graph selected for full view
  const _controllerRef = useRef(PillSortDashboardController);
  const [siteDeviceNames, setSiteDeviceNames] = useState([]);
  const [qtyAndValueRawData, setQtyAndValueRawData] = useState([]);
  const [qtyAndValueGraphData, setQtyAndValueGraphData] = useState([]);
  const [productsCountWithoutPrice, setProductsCountWithoutPrice] =
    useState(null);

  const [suctionCupData, setSuctionCupData] = useState([]);
  const [selectedSuctionCupData, setSelectedSuctionCupData] = useState(null);
  const [dispensingDevicesDropdownList, setDispensingDevicesDropdownList] =
    useState([]);
  const [alarmsGraphData, setAlarmsGraphData] = useState([]);
  const [graphsDisplay, setGraphsDisplay] = useState([]);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    selectedGraph ? onShowDropdowns(false) : onShowDropdowns(true);
  }, [selectedGraph]);

  const [updateSuctionCupLifeGraphToggle, setUpdateSuctionCupLifeGraphToggle] =
    useState(false);

  const viewHeight = (+windowHeight - 200).toString() + "px";

  const dateObjectToFormattedString = (dateObject) => {
    // convert dateObject to locale string
    // 4/19/2023
    const unformattedDateString = dateObject.toLocaleString().split(",")[0];

    // ['4', '19', '2023']
    const dateSplit = unformattedDateString.split("/");

    const startMonth = dateSplit[0]; // 4
    const startDay = dateSplit[1]; // 19
    const startYear = dateSplit[2].slice(-2); //23

    // 4/19/23
    const formattedStringDate = `${startMonth}/${startDay}/${startYear}`; //

    return formattedStringDate;
  };

  const loader = (
    <div
      className={
        smScreen
          ? stylesMobile.PillSortDashboardMobile__loader
          : styles.PillSortDashboard__loader
      }
    >
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>Fetching data...</p>
    </div>
  );

  // This useEffect is for getting the pill count with no price
  useEffect(() => {
    setFetchingProductWOPriceData(true);
    // Will get total count for all sites if selectedSite is null; if selectedSite is defined, will get total count back only for that site.
    _controllerRef.current
      .getPillSortProductCountWithNoPrice(user, selectedSite)
      .then((count) => {
        // console.log(`The total count of products without pricing is ${count}`);
        setProductsCountWithoutPrice(count);
        setFetchingProductWOPriceData(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingProductWOPriceData(false);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    setFetchingQtyAndValueSortedData(true);
    // Will get total data for all sites if selectedSite is null; if selectedSite is defined, will get data back only for that site.
    // startDate and stopDate are defined as constants in the constant section above.
    _controllerRef.current
      .getProcessHistory(user, selectedSite, startDate, stopDate)
      .then((results) => {
        results.forEach((result) => {
          const dateObj = new Date(result.date);
          // convert Mongo date string to JS date object
          result.date = dateObj;
        });
        setQtyAndValueRawData(results);
        setFetchingQtyAndValueSortedData(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingQtyAndValueSortedData(false);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    if (user && selectedSite) {
      let tempDispensingDevices = [];
      async function getDispensingDevices() {
        (async () => {
          await StockLocationService.getDevices(user, selectedSite)
            .then((devices) => {
              devices.forEach((device) => {
                if (
                  device?.site?.name === selectedSite?.name &&
                  device?.type?.type === "RETURN_SYSTEM"
                ) {
                  tempDispensingDevices.push(device);
                }
              });

              setDispensingDevices(tempDispensingDevices);
            })
            .catch((error) => {
              console.log("error: ", error);
            });
        })();
      }
      getDispensingDevices();
    } else {
      setDispensingDevices([]);
    }
  }, [user, selectedSite]);

  /************ Set dispensing device names for the selected site ***********/
  useEffect(() => {
    setSiteDeviceNames([
      {
        description: "all",
        deviceName: "all",
      },
    ]);
  }, [dispensingDevices]);

  /************ Get data for Suction cup life graph ***********/
  useEffect(() => {
    setFetchingSuctionCupLifeData(true);
    let tempSuctionCupData = [];
    _controllerRef.current
      .getSuctionCupLife(user, selectedSite)
      .then((results) => {
        let worstLife = 110;
        let machineName = "";
        results.forEach((result) => {
          if (result.life < 0) {
            result.life = 0; // assign negative life to 0
          }

          if (result.life <= worstLife) {
            worstLife = result.life;
            machineName = result.stockLocationName;
          }
          tempSuctionCupData.push(result);
        });
        setSelectedSuctionCupData({
          stockLocationName: machineName,
          life: worstLife < 110 ? +worstLife.toFixed(0) : 0,
        });

        // sort table from worst to best
        tempSuctionCupData.sort((a, b) => {
          const lifeA = a.life;
          const lifeB = b.life;
          if (lifeA < lifeB) {
            return -1;
          } else if (lifeB < lifeA) {
            return 1;
          }
          return 0;
        });
        setSuctionCupData(tempSuctionCupData);
        setFetchingSuctionCupLifeData(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingSuctionCupLifeData(false);
      });
  }, [user, selectedSite, updateSuctionCupLifeGraphToggle]);

  /************ Get data for Alarms graph ***********/
  useEffect(() => {
    setFetcingAlarmsData(true);
    _controllerRef.current
      .getAlarmHistory(user, selectedSite, startDate, stopDate)
      .then((results) => {
        const alarmsData = results.map((result) => {
          return { x: result.description, y: result.count };
        });
        setAlarmsGraphData(alarmsData);
        setFetcingAlarmsData(false);
      })
      .catch((error) => {
        console.log(error);
        setFetcingAlarmsData(false);
      });
  }, [user, selectedSite]);

  /********************************************************************
   * function called in useEffect below for Qty & Value Sorted graph
   * returns an array of graph data, one element for each device name
   * *****************************************************************/
  const getGraphDataForSiteNames = async (
    // allDatesArray,
    datesWithGraphDataArray,
    siteDeviceNamesIndex
  ) => {
    let graphDataForSelectedSite;
    let graphData;

    graphData = qtyAndValueRawData;

    graphDataForSelectedSite = graphData.filter((obj) => {
      return true;
    });
    let graphDataForSiteNames;
    let graphDataSelectedSite;
    graphDataForSiteNames = [];
    if (siteDeviceNames?.length) {
      graphDataForSiteNames = [graphDataForSelectedSite];
    }

    const differenceInTime = stopDate.getTime() - startDate.getTime(); // milliseconds between dates

    const differenceInDays = Math.round(
      differenceInTime / (1000 * 3600 * 24) // converts millseconds to days
    );

    const beginDate = new Date();

    beginDate.setDate(beginDate.getDate() - 4); // 5 days total

    for (let j = 0; j <= differenceInDays; j++) {
      const formattedDate = dateObjectToFormattedString(beginDate);
      beginDate.setDate(beginDate.getDate() + 1);

      graphDataForSelectedSite.forEach((graphDataItem) => {
        if (
          graphDataItem.deviceName ===
            siteDeviceNames[siteDeviceNamesIndex].deviceName &&
          dateObjectToFormattedString(graphDataItem.date).includes(
            formattedDate
          )
        ) {
          if (!datesWithGraphDataArray.includes(formattedDate)) {
            datesWithGraphDataArray.push(formattedDate);
          }
        }
      });
    }
    return graphDataForSiteNames;
  };

  /************ Process data for Qty & Value Sorted graph ***********/
  useEffect(() => {
    if (siteDeviceNames?.length) {
      let plotDataForFields;
      const tempGraphObjs = [];

      async function getGraphData() {
        for (let i = 0; i < siteDeviceNames?.length; i++) {
          if (i === 1) {
            break;
          }

          // let allDatesArray = [];
          let datesWithGraphDataArray = [];

          let graphDataForSiteNames = await getGraphDataForSiteNames(
            // allDatesArray,
            datesWithGraphDataArray,
            i
          );

          const getTotals = async (fieldName1, fieldName2) => {
            // used to generate graph dates

            let field1Values = [];
            let field2Values = [];

            datesWithGraphDataArray.forEach((date, dateIndex) => {
              field1Values[dateIndex] = 0;
              field2Values[dateIndex] = 0;
              graphDataForSiteNames[i].forEach((graphDataItem) => {
                if (
                  dateObjectToFormattedString(graphDataItem.date).includes(date)
                ) {
                  field1Values[dateIndex] += graphDataItem[fieldName1];
                  field2Values[dateIndex] += graphDataItem[fieldName2];
                }
              });
            });

            if (
              datesWithGraphDataArray &&
              datesWithGraphDataArray?.length &&
              graphDataForSiteNames[i]?.length
            ) {
              plotDataForFields = field1Values.map((value, index) => {
                const dateSplitArray =
                  datesWithGraphDataArray[index].split("/");
                const month = dateSplitArray[0];
                const day = dateSplitArray[1];
                const year = dateSplitArray[2];
                const dateFormattedForGraph = `${month}/${day}/${year.slice(
                  -2
                )}`;
                return {
                  x: dateFormattedForGraph,
                  y: field2Values[index],
                  y1: value,
                };
              });
            }
          }; // end GetTotals

          // OUTSIDE OF if statements, inside of FOR LOOP

          await getTotals("price", "quantity");

          tempGraphObjs.push({
            deviceDescription: siteDeviceNames[i]?.description,
            deviceName: siteDeviceNames[i]?.deviceName,
            data: plotDataForFields,
            color: "#4874c4",
            id: i,
          });

          setQtyAndValueGraphData(tempGraphObjs);
        } // END INNER FOR LOOP

        handleCollectingDataFromServer(false);
      }
      getGraphData();
    }
  }, [siteDeviceNames, qtyAndValueRawData]);

  /************ Graphs Display ***********/
  useEffect(() => {
    let tempGraphsDisplay = [];

    if (siteDeviceNames?.length) {
      let graphId = siteDeviceNames[0].deviceName;

      let SuctionCupsList = [];
      let indicatorColorClass = "";

      suctionCupData.forEach((cup, index) => {
        if (cup?.life <= 15) {
          indicatorColorClass = "PillSortDashboard__cupLifeIndicator--red";
        } else if (cup?.life <= 35) {
          indicatorColorClass = "PillSortDashboard__cupLifeIndicator--yellow";
        } else if (cup?.life > 35) {
          indicatorColorClass = "PillSortDashboard__cupLifeIndicator--green";
        }

        let suctionCupRow = (
          <div
            key={cup?.stockLocationName}
            className={styles.PillSortDashboard__suctionCupListItem}
            onClick={() => handleGraphClick(graphId, 2, index)}
          >
            <p>{cup?.stockLocationName}</p>
            <div
              className={`${styles.PillSortDashboard__cupLifeIndicator} ${styles[indicatorColorClass]}`}
            ></div>
          </div>
        );
        SuctionCupsList.push(suctionCupRow);
      });

      tempGraphsDisplay.push(
        <Fragment key={graphId}>
          {/* Qty & Value Sorted graph view */}
          <div
            onClick={() => handleGraphClick(graphId, 0)}
            className={
              smScreen
                ? stylesMobile.PillSortDashboardMobile__graphContainer
                : styles.PillSortDashboard__graphContainer
            }
          >
            {fetchingQtyAndValueSortedData && loader}
            <BarLineGraph
              graphData={qtyAndValueGraphData[0]?.data || []}
              graphId={"barLineGraph-" + graphId}
              viewWidth={viewWidth}
            />
          </div>

          {/* Products Without Price Information graph view */}
          <div
            className={
              smScreen
                ? `${stylesMobile.PillSortDashboardMobile__graphContainer} ${stylesMobile["PillSortDashboardMobile__graphContainer--paddingTop"]}`
                : `${styles.PillSortDashboard__graphContainer} ${styles["PillSortDashboard__graphContainer--paddingTop"]}`
            }
            onClick={() => handleGraphClick(graphId, 1)}
          >
            <h2 className={styles.PillSortDashboard__graphHeading}>
              Products Without Price Information
            </h2>
            {fetchingProductWOPriceData && loader}
            <div className={styles.PillSortDashboard__NDCInfo}>
              {productsCountWithoutPrice}
            </div>
          </div>

          {/* Suction Cup Life graph view */}
          <div
            className={
              smScreen
                ? `${stylesMobile.PillSortDashboardMobile__graphContainer} ${stylesMobile["PillSortDashboardMobile__graphContainer--paddingTop"]}`
                : `${styles.PillSortDashboard__graphContainer} ${styles["PillSortDashboard__graphContainer--paddingTop"]}`
            }
          >
            {fetchingSuctionCupLifeData && loader}
            <h2
              className={`${styles.PillSortDashboard__graphHeading} ${styles["PillSortDashboard__graphHeading--suctionCup"]}`}
            >
              Suction Cup Life
            </h2>

            <div className={styles.PillSortDashboard__suctionCupsListContainer}>
              <div className={styles.PillSortDashboard__suctionCupsListScroll}>
                {SuctionCupsList}
              </div>
            </div>
          </div>

          {/* Alarms graph view */}

          <div
            className={
              smScreen
                ? `${stylesMobile.PillSortDashboardMobile__graphContainer}`
                : `${styles.PillSortDashboard__graphContainer}}`
            }
            onClick={() => handleGraphClick(graphId, 3)}
          >
            {fetchingAlarmsData && loader}
            <BarGraph
              graphData={alarmsGraphData}
              graphId={"barGraph-" + graphId}
              graphTitle="Alarms"
              palette={["#318ce7"]}
              columnsName=""
              viewWidth={viewWidth}
            />
          </div>
        </Fragment>
      );
    }

    setGraphsDisplay(tempGraphsDisplay);
  }, [
    qtyAndValueGraphData,
    productsCountWithoutPrice,
    selectedSuctionCupData,
    alarmsGraphData,
    dispensingDevicesDropdownList,
    fetchingQtyAndValueSortedData,
    fetchingProductWOPriceData,
    fetchingSuctionCupLifeData,
    fetchingAlarmsData,
    viewWidth,
  ]);

  useEffect(() => {
    let tempDispensingDevicesDropdownList = [];
    if (suctionCupData) {
      tempDispensingDevicesDropdownList = suctionCupData.map(
        (dataItem, index) => {
          return {
            Item: dataItem.stockLocationName,
            Value: index + 1,
          };
        }
      );
      setDispensingDevicesDropdownList(tempDispensingDevicesDropdownList);
    }
  }, [suctionCupData]);

  const handleGraphClick = (graphId, graphNumber, rowIndex = null) => {
    setSelectedGraph({ graphNumber, deviceName: graphId, rowIndex });
  };

  const goBackHandler = () => {
    setSelectedGraph(null);
  };

  const handleUpdateSuctionCupLifeGraphToggle = () => {
    setUpdateSuctionCupLifeGraphToggle((prev) => !prev);
  };

  let view = [];
  for (let i = 0; i < siteDeviceNames?.length; i++) {
    if (i === 1) {
      break;
    }
    view.push(
      <div
        key={siteDeviceNames[i]?.deviceName}
        className={
          smScreen
            ? stylesMobile.PillSortDashboardMobile__graphsGroup
            : styles.PillSortDashboard__graphsGroup
        }
      >
        {graphsDisplay[i]}
      </div>
    );
  }

  if (!selectedGraph && !smScreen) {
    return (
      <div
        style={{ height: viewHeight }}
        className={styles.PillSortDashboard__viewContainer}
      >
        {view}
      </div>
    );
  } else if (!selectedGraph && smScreen) {
    return view;
  } else {
    const graphNumber = selectedGraph?.graphNumber;

    switch (graphNumber) {
      case 0:
        return (
          <OptiSortDailyReport
            goBackHandler={goBackHandler}
            deviceName={selectedGraph?.deviceName}
            _controllerRef={_controllerRef}
            user={user}
            selectedSite={selectedSite}
            windowHeight={windowHeight}
          />
        );
      case 1:
        return (
          <NDCTable
            goBackHandler={goBackHandler}
            deviceName={selectedGraph?.deviceName}
            _controllerRef={_controllerRef}
            user={user}
            selectedSite={selectedSite}
            windowHeight={windowHeight}
          />
        );
      case 2:
        return (
          <SuctionCupTable
            user={user}
            suctionCupData={suctionCupData[selectedGraph.rowIndex]}
            goBackHandler={goBackHandler}
            siteName={!selectedSite ? "All Sites" : user?.defaultSite?.name}
            deviceName={selectedGraph?.deviceName}
            windowHeight={windowHeight}
            _controllerRef={_controllerRef}
            onUpdateSuctionCupLifeGraphToggle={
              handleUpdateSuctionCupLifeGraphToggle
            }
          />
        );
      case 3:
        return (
          <AlarmsTable
            goBackHandler={goBackHandler}
            selectedSite={!selectedSite ? "All Sites" : selectedSite}
            deviceName={selectedGraph?.deviceName}
            _controllerRef={_controllerRef}
            user={user}
            startDate={startDate}
            stopDate={stopDate}
            windowHeight={windowHeight}
          />
        );
      default:
        return null;
    }
  }
};

export default PillSortDashboard;
