import React, {useEffect, useState} from 'react';
import styles from "./CreateProduct.module.css";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";

const CreateProductProud = ({isNew, p, errors, onPropertyChange, onError}) => {

    const productController = new ProductController();
    useEffect(()=>{
        if(!p.allowProud){
            onPropertyChange({proudDrugName: productController.getDefaultName(p)})
        }

        if(p.proudMaximumPerBag === 0 || !p.proudMaximumPerBag){
            onPropertyChange({proudMaximumPerBag: 4})
        }

    }, [p])

    return (
        <Accordion title={"Proud Information"}>
            <div className={styles.CreateProduct__productCategory_row}>
                <div className={styles.CreateProduct__productCategory_col}>
                    <Checkbox
                        labelName={'Do not dispense'}
                        isOn={ p?.proudDoNotDispense }
                        onCheck={isOn => onPropertyChange({proudDoNotDispense: isOn})}
                    />
                </div>
            </div>
            <br/>
            <div className={styles.CreateProduct__productCategory_row}>
                <div className={styles.CreateProduct__productCategory_col}>
                    <TextInput
                        type={'number'}
                        labelName={'Maximum per Bag'}
                        isEnabled={true}
                        value={p?.proudMaximumPerBag}
                        validationText={''}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({proudMaximumPerBag: val})
                        }}
                    />
                </div>
            </div>
        </Accordion>
    )
}

export default CreateProductProud;
