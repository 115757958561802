import React, {useState, useEffect} from 'react';
import Button from "./Button";
import {ReactComponent as CloseIcon} from "../assets/x.svg";
import Spacer from "./Spacer";
import TextInput from "./TextInput";
import {useKeyPress} from "./InputHooks";

const InputModal = ({title, labelName, buttonLabelName, onClose, width, height, onEnter, defaultValue}) => {

    const left = (window.innerWidth - (width || 586)) / 2;
    const top = (window.innerHeight - (height || 200)) / 2;


    //region Styles
    const containerStyle = {
        position: 'fixed',
        zIndex: 99,
        display: 'block',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '20px',
        left: left,
        top: top,
        width: width || 586,
        height: 'auto', //height || 200,
        padding: '35px',
        boxShadow: '0px 5px 14px rgba(120, 146, 141, 0.05)',
    }
    const headerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        width: '100%',
        height: '50px',
    }
    const bodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
    }
    const footerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
    const titleStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '27px',
        color: '#121A2D'
    }
    //endregion

    const [val, setVal] = useState(defaultValue || '');

    let enterKey = useKeyPress('Enter');

    useEffect(()=>{
        if(enterKey){
            onEnter(val);

        }
    }, [val, enterKey, onEnter])

    return (
        <div style={containerStyle}>
            <header style={headerStyle}>
                <CloseIcon fill={'#121A2D'} onClick={onClose} style={{cursor: 'pointer'}}/>
            </header>
            <main style={bodyStyle}>
                <h1 style={titleStyle}>{title}</h1>
                <Spacer space={30} unit={'px'}/>
                <TextInput
                    type={'text'}
                    placeholder={''}
                    labelName={labelName || 'Enter a value'}
                    isEnabled={true}
                    validationText={''}
                    value={val}
                    focus={true}
                    onChange={(val) => setVal(val)}
                />
            </main>
            <footer style={footerStyle}>
                <Button isPrimary={true} isDisabled={false} onClick={() => onEnter(val)} labelName={buttonLabelName}
                        isInverse={false}/>
            </footer>
        </div>
    )
}

export default InputModal;
