import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/magnifying-glass.svg";
import StockLocationController from "../../../../../controllers/StockLocationController";
import Input from "./Input";
import styles from "./ScanInput.module.css";

const GetStockLocation = ({
  currentStatusObj,
  handleCurrentStatusObj,
  stockedItemObjs,
  handleStockedItemObjs,
  setPage,
  setStockLocationScanned,
  enforceInputFocus,
  setEnforceInputFocus,
  setProductAssociationFound,
  user,
  site,
  associateBarcodeWithProduct,
  onSetErrorHandler,
  onActivateStockLocationDisabled,
  setScannedBarcodes,
  errorMsg,
}) => {
  const _controller = useRef(null);
  const [value, setValue] = useState("");
  const placeHolder = "Scan location";

  useEffect(() => {
    _controller.current = new StockLocationController(user, site);
    setScannedBarcodes([]);
    setProductAssociationFound(false);
    setPage(5);
    handleStockedItemObjs([
      {
        ...currentStatusObj,
        totalQty: currentStatusObj.product.packageQuantity,
      },
    ]);
    handleCurrentStatusObj({
      barcode: "",
      product: "",
      lotNumber: "",
      expiration: "",
    });
  }, []);

  const changeScanInputHandler = (e) => {
    setValue(e.target.value);
  };

  const callback = (error, stockLocation) => {
    if (!error) {
      if (!stockLocation.isEnabled) {
        onActivateStockLocationDisabled();
      } else if (!stockLocation.type.type.toLowerCase().includes("canister")) {
        setStockLocationScanned(stockLocation);
      } else {
        onSetErrorHandler(
          "Only a stock location of non-canister type can receive a stocked item."
        );
      }
    } else {
      console.log("error:", error);
      onSetErrorHandler("The scanned stock location does not exist.");
    }
  };

  const onKeyUpHandler = (e) => {
    if (e.which === 13 && e.target.value) {
      _controller.current.getStockLocationByBarcode(e.target.value, callback);
    }
  };

  if (!associateBarcodeWithProduct) {
    return (
      <section className={styles.scanInput__container}>
        <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
        <Input
          className={styles.scanInput__field}
          onChangeHandler={changeScanInputHandler}
          onKeyUpHandler={onKeyUpHandler}
          value={value}
          placeHolder={placeHolder}
          enforceInputFocus={enforceInputFocus}
          setEnforceInputFocus={setEnforceInputFocus}
          errorMsg={errorMsg}
        />
      </section>
    );
  } else {
    return null;
  }
};

export default GetStockLocation;
