import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({
  url,
  width = "100%",
  height = "auto",
  playing = false,
  onReady = false,
  light = null,
}) => {
  return (
    <ReactPlayer
      url={url}
      width={width}
      height={height}
      playing={playing}
      onReady={onReady}
      light={light}
      controls
    />
  );
};

export default VideoPlayer;
