import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import backIcon from "../../assets/back_icon.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

//region CSS
const MyView = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const PDFReportViewer = ({
  title,
  headers,
  data,
  onBack,
  columnSums,
  selectionRange,
}) => {
  const pageWidth = 792;
  const columnWidth = pageWidth / headers.length - 1;

  const getDateString = (month, day, fullYear) => {
    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${month}/${day}/${fullYear}`;
  };

  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    const fullYearStart = selectionRange.startDate.getFullYear().toString();
    const monthStart = (selectionRange.startDate.getMonth() + 1).toString();
    const dayStart = selectionRange.startDate.getDate().toString();

    const fullYearEnd = selectionRange.endDate.getFullYear().toString();
    const monthEnd = (selectionRange.endDate.getMonth() + 1).toString();
    const dayEnd = selectionRange.endDate.getDate().toString();

    startDate = getDateString(monthStart, dayStart, fullYearStart);
    endDate = getDateString(monthEnd, dayEnd, fullYearEnd);
  }

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          padding: 20,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 12,
              color: "#121A2D",
              margin: 0,
              padding: 0,
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 12,
              color: "#121A2D",
              margin: 0,
              padding: 0,
            }}
          >
            {startDate && endDate && `${startDate} - ${endDate}`}
          </Text>
        </View>

        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            height: 50,
            borderBottom: "2px solid #089BAB",
          }}
        >
          {headers.map((header, index) => {
            return (
              <Text
                key={index}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 10,
                  color: "#121A2D",
                  width: columnWidth,
                  margin: 0,
                  padding: 0,
                }}
              >
                {header}
              </Text>
            );
          })}
        </View>

        {data.map((row, r) => {
          return (
            <View
              key={r}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                // height: 25,
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
              }}
            >
              {row.map((text, t) => {
                return (
                  <Text
                    key={t}
                    style={{
                      //   display: "flex",
                      //   flexDirection: "row",
                      //   flexWrap: "wrap",
                      //   flex: 1,
                      //   wordWrap: "break-word !important",
                      //   overflow: "hidden",
                      //   overflowWrap: "break-word !important;",
                      margin: 0,
                      padding: 0,
                      paddingRight: "2px",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: 10,
                      color: "#121A2D",
                      width: columnWidth,
                    }}
                  >
                    {text}
                  </Text>
                );
              })}
            </View>
          );
        })}

        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            height: 50,
            borderBottom: "2px solid #089BAB",
          }}
        >
          {columnSums && columnSums.map((total, index) => {
            return (
              <View
                key={index}
                fixed
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 50,
                  borderBottom: "2px solid #089BAB",
                }}
                render={({ pageNumber, totalPages }) =>
                  total &&
                  pageNumber === totalPages && (
                    <View>
                      <Text
                        style={{
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: 12,
                          color: "#121A2D",
                          width: columnWidth,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {total
                          .toFixed(2)
                          .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")}
                      </Text>
                    </View>
                  )
                }
              />
            );
          })}
        </View>

        <Text
          style={{
            margin: 0,
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 10,
            color: "#121A2D",
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>PDF Viewer</ReportTitle>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </MyView>
  );
};

export default PDFReportViewer;
