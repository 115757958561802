import React, {useEffect, useRef, useState} from "react";
import style from "./EV54ReplishCanister.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../../components/TextInput";
import Spacer from "../../../../components/Spacer";
import ProductFieldController from "../../../../controllers/ProductFieldController";
import StockedItemService from "../../../../services/StockedItemService";
import ErrorBanner from "../../../../components/ErrorBanner";
import RoleCheckService from "../../../../services/RoleCheckService";
import DatePickerComponent from '../../../../components/DatePickerComponent/DatePickerComponent';
import ProductService from "../../../../services/ProductService";
import StockLocationService from "../../../../services/StockLocationService";

const EV54ReplenishCanister = ({
  user,
  parentDevice,
  controllerRef,
  need,
  onClose,
}) => {
  const productFieldController = new ProductFieldController();

  const unlockingRef = useRef(-1);
  const dataRef = useRef({});

  const [scannedId, setScannedId] = useState("");
  const [error, setError] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [stockItemSaved, setStockedItemSaved] = useState(null);
  const [ev54ReadyForCommand, setEV54ReadyForCommand] = useState(true);
  const [isLocked, setIsLocked] = useState(true);
  const [bulkStockedItem, setBulkStockedItem] = useState(null);
  const [expirationDateTime, setExpirationDateTime] = useState(null);
  const [hide, setHide] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false)

  useEffect(() => {
    if (need.product?.isHazardous) {
      setError("Hazardous medication, handle with appropriate precautions.");
    }
  }, [need]);

  useEffect(()=>{
    return ()=>{
      controllerRef.current.lockAll().then();
    }
  }, [user])

  useEffect(() => {
    controllerRef.current.addCommandCompleteCallback(handleCommandSent);

    return () => {
      controllerRef.current.removeCommandCompleteCallback(handleCommandSent);
    };
  }, [user, need]);

  const handleCommandSent = async (ev54Command) => {
    console.log('replenishment handleCommandSent');
    console.log(ev54Command);
    if(ev54Command && ev54Command.responseSuccess === 1){
      setEV54ReadyForCommand(true);
      if(unlockingRef.current === 1){
        unlockingRef.current = 0;
      } else if(unlockingRef.current === 0){
        unlockingRef.current = -1;
        await onSave();

      }
    } else if(ev54Command && ev54Command.responseSuccess === 0 && unlockingRef.current === 1){
      setError('Could not unlock the canister.  Please check communications and re-initialize the device.');
      setIsLocked(true);
      unlockingRef.current = -1;
    }
  }

  const handleScannedBarcode = async () => {

    // double check that this barcode is not associated with anything else
    const product = await ProductService.getProductByBarcode(user, scannedId);
    if(product && need.product?._id !== product._id){
      setError(
          `${scannedId} is associated with ${productFieldController.getDefaultName(
              product
          )}`
      );
      setScannedId('');
      setShowProductDetails(false);
      return;
    } else if(!product) {
      setError(
          `${scannedId} is not associated with ${productFieldController.getDefaultName(
              product
          )}.  Please add this barcode to the Product if you would like to use.`
      );
      setScannedId('');
      setShowProductDetails(false);
      return;
    } else  {
      if(need.stockedItems.length > 0){
        // prefill lot and exp
        setLotNumber(need.stockedItems[need.stockedItems.length - 1].lotNumber);
        dataRef.current.lotNumber = need.stockedItems[need.stockedItems.length - 1].lotNumber;
        const exp = new Date(need.stockedItems[need.stockedItems.length - 1].expiration);
        if (exp instanceof Date && !isNaN(exp.getTime())){
          setExpirationDateTime(exp);
          dataRef.current.expirationDateTime = exp;
        }

      }
      setShowProductDetails(true);
      let element = document.getElementById('lotNumber');
      if(need.stockedItems.length > 0){
        element = document.getElementById('quantity');
      }
      element.focus();
    }
  }

  const isLotTheSame = (lotNumber) => {
    let isSame = true; // default to true to handle no current stockedItems
    for (let i = 0; i < need.stockedItems.length; i++) {
      if (need.stockedItems[i].lotNumber !== lotNumber) {
        isSame = false;
      }
    }
    return isSame;
  };

  const getProductId = () => {
    return `${productFieldController.getPackageId(need.product)}`;
  };

  const getProductName = () => {
    return `${productFieldController.getDefaultName(need.product)}`;
  };

  const getProductManufacturer = () => {
    return `${
      need.product.manufacturer?.abbrName ||
      need.product.manufacturer?.fullName ||
      ""
    }`;
  };

  const dataIsValid = () => {
    return scannedId && lotNumber && expirationDateTime > new Date() && quantity > 0;
  };

  const onSave = async () => {
    try {
      // if (!parentDevice.device.allowLotMixing && !isLotTheSame(lotNumber)) {
      //   setError(
      //     `Lot ${lotNumber} does not match and Lot Mixing is not allowed`
      //   );
      //   return;
      // }

      let stockedItem = need.stockedItems.find(
        (si) => si.lotNumber === dataRef.current.lotNumber
      );
      if (!stockedItem) {
        stockedItem = {
          account: user.account,
          site: parentDevice.site,
          product: need.product,
          stockLocation: need.stockLocation,
          packageDescription: "EV54_CANISTER",
          deviceCompatability: "VIAL_FILLER",
          lotNumber: dataRef.current.lotNumber,
          expiration: getExpiration(),
          quantity: dataRef.current.quantity,
          barcode: dataRef.current.scannedId,
        };
        stockedItem = await StockedItemService.create(
          user,
            parentDevice.site,
          stockedItem
        );
      } else {
        // update the stocked item
        stockedItem.quantity += dataRef.current.quantity;
        stockedItem = await StockedItemService.update(
          user,
          need.stockLocation.site,
          stockedItem
        );
      }

      if (!barcodeExists(need.product, dataRef.current.scannedId)) {
        // update product
        const p = need.product;
        p.barcodeList.push(dataRef.current.scannedId);
        controllerRef.current.updateProduct(p);
      }
      setStockedItemSaved(stockedItem);
      if (bulkStockedItem && stockedItem.quantity >= bulkStockedItem.quantity) {
        const t = { ...bulkStockedItem };
        await StockedItemService.delete(user, need.stockLocation.site, t);
      } else if (
        bulkStockedItem &&
        stockedItem.quantity < bulkStockedItem.quantity
      ) {
        const t = { ...bulkStockedItem };
        t.quantity = t.quantity - stockedItem.quantity;
        await StockedItemService.update(user, need.stockLocation.site, t);
      }

      // clear the replenishment alarm if the need has been met
      if(stockedItem.quantity >= need.qtyNeeded){
        controllerRef.current.clearReplenishmentNeed(need);
      }

      //onUnlockCanister();
      setBulkStockedItem(null);
      onClose();
    } catch (e) {
      setError(e);
    }
  };

  const getExpiration = () => {
    const oneYearMilliseconds = 31556926000;
    const nowUnixTime = Date.now();
    const expUnixTime = dataRef.current.expirationDateTime.getTime();
    let calculatedUnixTime = expUnixTime;
    if(need.stockedItems.length > 0 && expUnixTime - nowUnixTime > oneYearMilliseconds){
      calculatedUnixTime = nowUnixTime + oneYearMilliseconds;
    } else if(need.stockedItems.length > 0) {
      // set to 25% of remaining time
      const ms = (expUnixTime - nowUnixTime) * 0.25;
      calculatedUnixTime = nowUnixTime + ms;
    } else {
      calculatedUnixTime = expUnixTime;
    }
    const calculatedDt = new Date(calculatedUnixTime);

    let month = pad(calculatedDt.getMonth() + 1, 2);
    let day = pad(calculatedDt.getDate(), 2);
    let year = calculatedDt.getFullYear().toString();

    return `${year}-${month}-${day}`;
  }

  const barcodeExists = (product, barcode) => {
    return productFieldController.barcodeExists(product, barcode);
  };

  const onDelete = async () => {
    try {
      await StockLocationService.zeroInventory(
        user,
        need.stockLocation.site,
        need.stockLocation
      );
      need.stockedItems = [];
      need.totalQty = 0;
      setShowProductDetails(true);
      setLotNumber("");
      setQuantity(0);
      controllerRef.current.zeroInventory(need.stockLocation);
      dataRef.current.expirationDateTime = new Date();
      setExpirationDateTime(new Date());
      dataRef.current.lotNumber = '';
    } catch (e) {
      setError(e);
    }
  };

  const onUnlockCanister = () => {
    if (!parentDevice.device.allowLotMixing && !isLotTheSame(lotNumber)) {
      setError(
          `Lot ${lotNumber} does not match and Lot Mixing is not allowed`
      );
      return;
    }

    if (
      need.product.controlLevel?.type > 0 &&
      !RoleCheckService.canHandleControlledSubstances(user)
    ) {
      setError(
        "You are not authorized to handle Controlled Substances.  See your Administrator to have this role added."
      );
    } else {
      if (ev54ReadyForCommand) {
        unlockingRef.current = 1;
        setEV54ReadyForCommand(false);
        controllerRef.current.sendUnlockCanisterCommand([
          need.stockLocation.devicePosition,
        ]);
        setIsLocked(!isLocked);
      }
    }
  };

  const onLockCanister = () => {
    if (ev54ReadyForCommand) {
      setEV54ReadyForCommand(false);
      controllerRef.current.sendLockCanisterCommand([
        need.stockLocation.devicePosition,
      ]);
      setIsLocked(!isLocked);
    }
  };

  const pad = (str, max) =>{
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

  const zeroInventory = async () => {
    setHide(true);
    if(window.confirm('Are you sure you want to remove all inventory from this canister? This cannot be undone.')){
      await onDelete();
    }
    setHide(false);
  }

  if(hide){
    return <></>;
  }

  return (
    <div
      className={[
        style.EV54ReplenishCanister_column,
        style.EV54ReplenishCanister_column__main,
      ].join(" ")}
    >
      <div
        className={[
          style.EV54ReplenishCanister_row,
          style.EV54ReplenishCanister_header,
        ].join(" ")}
      >
        <h1>
          Replenish{" "}
          <span>Canister No. {need.stockLocation.devicePosition}</span>
        </h1>
        {ev54ReadyForCommand && <FontAwesomeIcon
          className={style.EV54ReplenishCanister_icon}
          icon={faClose}
          size={"2x"}
          onClick={onClose}
        />}
      </div>
      {error && (
        <>
          <br />
          <ErrorBanner
            message={error}
            onClose={() => {
              setError("");
              setScannedId("");
              dataRef.current.scannedId = '';
            }}
          />
          <br />
        </>
      )}
      <div
        className={[
          style.EV54ReplenishCanister_row,
          style.EV54ReplenishCanister_divider,
        ].join(" ")}
      >
        <div className={style.EV54ReplenishCanister_column}>
          <h3>Current Assignment</h3>
          <h2>
            {getProductId()} - {getProductName()} - {getProductManufacturer()}
          </h2>
        </div>
      </div>
      <div className={style.EV54ReplenishCanister_row}>
        <div className={style.EV54ReplenishCanister_flex1}>
          <TextInput
            labelName="NDC/UPC"
            type="text"
            placeholder="Scan or enter id"
            isEnabled={!stockItemSaved}
            value={scannedId}
            onChange={(val) => {
              setScannedId(val);
              dataRef.current.scannedId = val;
            }}
            onEnter={async () => {
              await handleScannedBarcode()
            }}
            focus={true}
          />
        </div>
        {showProductDetails && scannedId &&
            <>
          <Spacer space={20} unit={"px"}/>
          <div className={style.EV54ReplenishCanister_flex3}>
            <TextInput
            labelName="Name"
            type="text"
            isEnabled={!stockItemSaved}
            value={getProductName()}
            onChange={(e) => {}}
            />
          </div>
          <Spacer space={20} unit={"px"} />
          <div className={style.EV54ReplenishCanister_flex2}>
            <TextInput
            labelName="Manufacturer"
            type="text"
            isEnabled={!stockItemSaved}
            value={getProductManufacturer()}
            onChange={(e) => {}}
            />
          </div>
          </>
        }
      </div>
      {showProductDetails && scannedId && (
        <div className={style.EV54ReplenishCanister_row}>
          <TextInput
              id='lotNumber'
            labelName="Lot#"
            type="text"
            isEnabled={!stockItemSaved}
            value={lotNumber}
            onChange={(e) => {
              setLotNumber(e);
              dataRef.current.lotNumber = e;
            }}
            focus={false}
          />
          <Spacer space={20} unit={"px"} />
          <DatePickerComponent label={'Expiration Date'} startDate={expirationDateTime} showMonthYearPicker={true} onChange={date => {
            let d = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() - 1);
            setExpirationDateTime(d);
            dataRef.current.expirationDateTime = d;
          }}/>
          {/*<DateMask label={'Expiration Date'} startDate={expirationDateTime} onValidDate={(date)=>{*/}
          {/*  setExpirationDateTime(date);*/}
          {/*  dataRef.current.expirationDateTime = date;*/}
          {/*}}/>*/}
          <Spacer space={20} unit={"px"} />
          <TextInput
              id='quantity'
            labelName="Quantity"
            type="number"
            isEnabled={!stockItemSaved}
            value={quantity.toString()}
            onChange={(e) => {
              setQuantity(Number.parseInt(e));
              dataRef.current.quantity = Number.parseInt(e);
            }}
            focus={false}
          />
        </div>
      )}

      <div className={style.EV54ReplenishCanister_row} style={{justifyContent: 'space-between'}}>
        {/*<div className={style.EV54ReplenishCanister_row}>*/}
        {/*  <button*/}
        {/*    className={style.EV54ReplenishCanister_button__primary}*/}
        {/*    disabled={!dataIsValid() || stockItemSaved}*/}
        {/*    onClick={async () => {*/}
        {/*      await onSave();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Save*/}
        {/*  </button>*/}
        {/*  <Spacer space={20} unit={"px"} />*/}
        {/*  <button*/}
        {/*    className={style.EV54ReplenishCanister_button__secondary}*/}
        {/*    disabled={!stockItemSaved}*/}
        {/*    onClick={async () => {*/}
        {/*      await onDelete();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Delete*/}
        {/*  </button>*/}
        {/*</div>*/}

        <button
          className={style.EV54ReplenishCanister_button__secondary}
          disabled={!dataIsValid() || !ev54ReadyForCommand}
          onClick={() => {
            if (isLocked) {
              onUnlockCanister();
            } else {
              onLockCanister();
            }
          }}
        >
          {isLocked ? "Unlock Canister" : "Lock Canister"}
        </button>
        <button
            className={style.EV54ReplenishCanister_button__secondary}
            onClick={async () => {
              await zeroInventory();
            }}
        >
          Zero Inventory
        </button>
      </div>
    </div>
  );
};

export default EV54ReplenishCanister;
