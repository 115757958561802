import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./ProductDisplay.module.css";
import { ReactComponent as Pills } from "../../../../../assets/pills.svg";
import ProductController from "../../../../../controllers/ProductController";

const ProductDisplay = ({ stockedItemObj, mapIndex }) => {
  const pc = new ProductController();
  const productDescription = pc
    .getDefaultName(stockedItemObj.product)
    .split(" ");

  let qtyIndex = -1;

  function isNumeric(str) {
    if (typeof str != "string") return false; // only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }
  for (let i = 0; i < productDescription.length; i++) {
    if (isNumeric(productDescription[i])) {
      qtyIndex = i;
      break;
    }
  }

  // Get product name
  let productName = "";
  for (let i = 0; i < qtyIndex; i++) {
    if (productName === "") {
      productName = productDescription[i];
    } else {
      productName = productName + " " + productDescription[i];
    }
  }

  // Get package info
  let packageInfo = "";
  for (let i = qtyIndex; i < productDescription.length; i++) {
    if (packageInfo === "") {
      packageInfo = productDescription[i];
    } else {
      packageInfo = packageInfo + " " + productDescription[i];
    }
  }

  const isBigScreen = useMediaQuery({ query: "(min-width: 376px)" });
  let productDesc;

  if (isBigScreen) {
    if (pc.getDefaultName(stockedItemObj.product).length < 36) {
      productDesc = pc.getDefaultName(stockedItemObj.product);
    } else {
      productDesc =
        stockedItemObj.product.primaryName +
        " " +
        stockedItemObj.product.strength;
    }
    // small screen
  } else {
    if (pc.getDefaultName(stockedItemObj.product).length < 16) {
      productDesc = pc.getDefaultName(stockedItemObj.product);
    } else {
      productDesc =
        stockedItemObj.product.primaryName +
        " " +
        stockedItemObj.product.strength;
    }
  }

  let packageDesc = `${stockedItemObj.product?.packageDescription}`;

  if (packageDesc == "undefined") {
    packageDesc = "";
  }

  let imgSource = null;

  let srcDBImage =
    imgSource && imgSource.mimeType
      ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
      : imgSource && imgSource.base64EncodedString;

  return (
    <React.Fragment>
      <div className={styles.product}>
        <div
          className={[
            styles["product__imageContainer"],
            styles["product__imageContainer--default"],
          ].join(" ")}
          id={mapIndex}
        >
          {imgSource ? (
            <img className={styles.product__image} src={srcDBImage} alt="" />
          ) : (
            <Pills stroke={"#A7BBCF"} />
          )}
        </div>

        <div className={styles.product__productInfo}>
          <span className={styles.product__description}>{productDesc}</span>
          <div className={styles.product__lotNumberAndExpirationGroup}>
            <span className={styles.product__lotNumberDesc}>
              <span className={styles["product__lotNumberDesc--heading"]}>
                lot #:
              </span>
              {stockedItemObj.lotNumber}
            </span>
            <span className={styles.product__expirationDesc}>
              <span className={styles["product__expirationDesc--heading"]}>
                exp. date:
              </span>
              {stockedItemObj.expiration}
            </span>
          </div>
          <span className={styles.product__packageDescription}>
            {packageDesc}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductDisplay;
