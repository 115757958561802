import React, {useEffect, useState} from 'react';
import styles from './DeviceRouteSelector.module.css';
import {ReactComponent as CloseButton} from "../../assets/x.svg";
import StockLocationService from "../../services/StockLocationService";
import DropdownInput from "../DropDownInput/DropdownInput";

const DeviceRouteSelector =  ({user, site, dispensingOrder, onClose, onRoute}) => {

    const [error, setError] = useState(null);
    const [deviceList, setDeviceList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(()=>{
        StockLocationService.getDispensableLocations(user, site)
            .then(devices=>{
                let arr = []
                for(let i = 0; i < devices.length; i++){
                    const device = devices[i];

                    // make sure this facility uses applicable type
                    if( (device.type.type === "BLISTER_PACKAGER" && dispensingOrder.facility.packagingMethod?.type === "BLISTER_CARD") ||
                        (device.type.type === "POUCH_PACKAGER" && dispensingOrder.facility.packagingMethod?.type === "POUCH") ||
                        (device.type.type === "VIAL_FILLER" && dispensingOrder.facility.packagingMethod?.type === "VIAL") ) {

                        arr.push({
                            description: device.name,
                            device: device
                        });
                    }
                }
                setDeviceList(arr);
                if(arr.length){
                    setSelectedItem(arr[0])
                }
            })
            .catch(error=>{
                setError(error);
            })

    },[user, site])

    return (
        <div className={styles.DeviceRouteSelector__overlay}>
            <div className={styles.DeviceRouteSelector__modal}>

                <header>
                    <CloseButton fill={'rgba(0,0,0,0.5)'} onClick={onClose} style={{cursor: 'pointer'}}/>
                </header>
                <div className={styles.DeviceRouteSelector__modal_body}>
                    <h1>{dispensingOrder.facility.name}</h1>
                    <DropdownInput
                        placeholder={'Select a dispensing device'}
                        value={selectedItem ? selectedItem.description : ''}
                        labelName={''}
                        isEnabled={true}
                        onSelection={listItem=>{setSelectedItem(listItem)}}
                        listElements={deviceList || []}
                        maxWidth={'100%'}
                        display={''}
                    />
                </div>
                <footer>
                    <button disabled={!selectedItem} onClick={()=>onRoute(dispensingOrder, selectedItem.device)}>Route Order</button>
                </footer>
            </div>
        </div>
    )

}

export default DeviceRouteSelector;
