import React from "react";
import styles from "./StockFooter.module.css";
import StockIconBar from "./stockIconBar/StockIconBar";

const StockFooter = ({ activeIcon, setActiveIcon }) => {
  return (
    <section className={styles.stockFooter}>
      <StockIconBar activeIcon={activeIcon} setActiveIcon={setActiveIcon} />
    </section>
  );
};

export default StockFooter;
