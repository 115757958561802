import React, { useEffect, useState } from "react";

const Button = ({
  labelName,
  isPrimary,
  isDisabled,
  isOrange,
  isGray,
  onClick,
  isInverse,
  icon,
  minWidth,
  width,
  minHeight = "48px",
}) => {
  const button = {
    cursor: "pointer",
    minHeight: minHeight,
    minWidth: minWidth || "135px",
    width: width || "max-content",
    padding: "0 20px",
    outline: "none",
    border: "none",
    borderRadius: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    userSelect: "none",
    margin: 0,
  };
  const buttonTextStyle = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    userSelect: "none",
    marginRight: 0,
    marginBottom: 0,
    marginTop: 0,
    marginLeft: "8px",
  };

  const [buttonStyle, setButtonStyle] = useState(button);

  // Updates the button styling if the button minWidth changes due to sreen-size change (large screen to small screen, for example)
  useEffect(() => {
    setButtonStyle(button);
  }, [minWidth]);

  useEffect(() => {
    const primaryButton = {
      backgroundColor: !isDisabled ? "var(--green)" : "var(--greenDisabled)",
      color: "white",
    };

    const primaryButtonInverse = {
      backgroundColor: "white",
      color: "var(--green)",
      border: "1px solid #089BAB",
    };

    const secondaryButton = {
      backgroundColor: "var(--gray)",
      color: "black",
    };

    const orangeButton = {
      backgroundColor: "var(--orange)",
      color: "white",
    };

    const grayButton = {
      backgroundColor: "white",
      color: "var(--blueGray)",
      border: "1.5px solid var(--blueGray)",
      borderRadius: "25px",
    };

    if (isPrimary && isInverse) {
      setButtonStyle((prevState) => {
        return { ...prevState, ...primaryButtonInverse };
      });
    } else if (isPrimary && !isInverse) {
      setButtonStyle((prevState) => {
        return { ...prevState, ...primaryButton };
      });
    } else if (isOrange) {
      setButtonStyle((prevState) => {
        return { ...prevState, ...orangeButton };
      });
    } else if (isGray) {
      setButtonStyle((prevState) => {
        return { ...prevState, ...grayButton };
      });
    } else {
      setButtonStyle((prevState) => {
        return { ...prevState, ...secondaryButton };
      });
    }
  }, [labelName, isPrimary, isDisabled, onClick, isInverse, isGray]);

  if (!icon) {
    return (
      <button style={buttonStyle} onClick={onClick} disabled={isDisabled}>
        {labelName}
      </button>
    );
  } else {
    return (
      <button
        style={{ ...buttonStyle, display: "flex", alignItems: "center" }}
        onClick={onClick}
        disabled={isDisabled}
      >
        {icon}
        <p style={buttonTextStyle}>{labelName}</p>
      </button>
    );
  }
};

export default Button;
