import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { NavbarContext } from "../../contexts/NavbarContext";
import LabelEditor from "./LabelEditor";
import PrintLabels from "./PrintLabels";
import TextInput from "../../components/TextInput";
import SFDropDownList from "../../components/SFDropDownList/SFDropDownList";
import CreateButton from "../../components/CreateButton";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import SearchBar from "../../components/SearchBar";
import LabelController from "./LabelController";
import LabelTile from "../../components/LabelTile";
import ModalBackdrop from "../../components/Modal";
import styles from "./CreateLabel.module.css";

const CreateLabel = ({ user, onSelectedTab, onShowTabBar }) => {
  const [createLabel, setCreateLabel] = useState(false);
  const [showLabelCanvas, setShowLabelCanvas] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelType, setLabelType] = useState("Canister");
  const [machineType, setMachineType] = useState("TruCard");
  const [customizedLabel, setCustomizedLabel] = useState("");
  const [labels, setLabels] = useState([]);
  const [labelsNameAndAccount, setLabelsNameAndAccount] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [clickedLabel, setClickedLabel] = useState(null);
  const [nodesArray, setNodesArray] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nameError, setNameError] = useState("");
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [labelModificationInProgress, setLabelModificationInProgress] =
    useState(false);
  const [showModal, setShowModal] = useState(false);

  const _controllerRef = useRef(null);
  const { t } = useTranslation("labels");

  const {
    labelModificationInProgressViaContext,
    handleLabelModificationInProgressViaContext,
  } = useContext(NavbarContext);

  useEffect(() => {
    handleLabelModificationInProgressViaContext(labelModificationInProgress);
  }, [labelModificationInProgress]);

  useEffect(() => {
    if (selectedLabel) {
      setLabelName(selectedLabel?.name || "");
      setLabelType(selectedLabel?.labelType || "Canister");
      setMachineType(selectedLabel?.machineType || "TruCard");
      setCustomizedLabel(selectedLabel?.customizedLabel || "");
    }
  }, [selectedLabel]);

  useEffect(() => {
    if (createLabel) {
      onShowTabBar(false);
    } else {
      onShowTabBar(true);
    }
  }, [createLabel]);

  useEffect(() => {
    getLabels();
  }, [refreshToggle]);

  useEffect(() => {
    if (selectedLabel && nodesArray?.length) {
      setCreateLabel(true);
    }
  }, [selectedLabel, nodesArray]);

  const handleLabelModificationInProgress = (boolVal) => {
    setLabelModificationInProgress(boolVal);
  };

  const handleRefreshToggle = (prevState) => setRefreshToggle(!prevState);

  const actionListItems = [
    {
      type: "modify",
      description: t("action0"),
    },
    {
      type: "copy",
      description: t("action2"),
    },
    {
      type: "delete",
      description: t("action1"),
    },
    // {
    //   type: "standardize",
    //   description: "standardize",
    // },
  ];

  const getActionMenu = () => {
    let listItems = [...actionListItems];
    return listItems;
  };

  // const getStandardActionMenu = (reportObj) => {
  //   let listItems = [];

  //   if (RoleCheckService.canCreateReports(user)) {
  //     listItems = [...actionListItems];
  //     // remove modify & delete
  //     let index = listItems.findIndex((o) => o.type === "modify");
  //     listItems.splice(index, 1);
  //     index = listItems.findIndex((o) => o.type === "delete");
  //     listItems.splice(index, 1);
  //   }
  //   return listItems;
  // };

  const handleAction = async (action, label) => {
    switch (action.type) {
      case "modify":
        handleSelectedLabel(label);
        handleSetNodesArray(label);
        break;
      case "delete":
        handleDeleteLabel(label);
        break;
      case "copy":
        handleCopyLabel(label, nodesArray);
        break;
      default:
        break;
    }
  };

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  let nodesArrayTemp = [];

  const buildNodesArray = (node, parent) => {
    // RECURSIVE CASE
    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node); // Traverse child nodes
    }
    if (!arrayContainsObject(node, nodesArrayTemp)) {
      if (parent) {
        node.parent = parent;
      }
      nodesArrayTemp.push(node);

      // sort nodesArrayTemp by nodeId, ascending order
      nodesArrayTemp.sort((a, b) => {
        return a.nodeId - b.nodeId;
      });
    }
    // BASE CASE
    return;
  };

  const handleShowModal = (boolVal) => {
    setShowModal(boolVal);
  };

  const Modal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.CreateLabel__deleteWarningModal}>
        <p className={styles.CreateLabel__deleteWarningModalMessage}>
          Do you really want to continue to the previous screen? Your changes
          are not saved and would be lost.
        </p>
        <div className={styles.CreateLabel__ModalButtonsGroup}>
          <Button
            labelName="Cancel"
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName="Continue"
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              handleLabelModificationInProgressViaContext(false);
              setShowModal(false);
              handlePrevious(true);
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  const getLabels = () => {
    _controllerRef.current = new LabelController(user);
    _controllerRef.current.getLabels().then((results) => {
      if (results) {
        let tempLabels = [];
        results.labels.forEach((label) => {
          if (label?.template && label?.name) {
            tempLabels.push(label);
          }
        });
        setLabels(tempLabels);

        const tempLabelsNameAndAccount = tempLabels.map(
          (label) => `${label.name}/${label.account}`
        );

        setLabelsNameAndAccount(tempLabelsNameAndAccount);
      } else {
        console.log("error: ", results);
      }
    });
  };

  const handleCopyLabel = (label) => {
    buildNodesArray(label.template, null);
    const node0 = { ...nodesArrayTemp[0] };

    const newLabelName = label.name + "-copy";
    const machineType = label?.machineType || "TruCard";
    const customizedLabel = label?.customizedLabel || "";

    _controllerRef.current
      .createLabel(newLabelName, labelType, machineType, customizedLabel, node0)
      .then((result) => {
        if (result) {
          getLabels();
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleDeleteLabel = (label) => {
    _controllerRef.current.deleteLabel(label).then((result) => {
      if (result) {
        getLabels();
      } else {
        console.log("error: ", result);
      }
    });
  };

  const handleNodesArray = (nodes) => {
    setNodesArray(nodes);
  };

  const handleSetNodesArray = (label) => {
    buildNodesArray(label.template, null);
    setNodesArray(nodesArrayTemp);
    nodesArrayTemp = [];
  };

  const handleSelectedLabel = (label) => {
    setSelectedLabel(label);
    handleSetNodesArray(label);
  };

  const handleResetCreateLabelStatesToDefaults = () => {
    setCreateLabel(false);
    setShowLabelCanvas(false);
    setLabelName("");
    setLabelType("Canister");
    setMachineType("TruCard");
    setCustomizedLabel("");
    setLabels([]);
    setSelectedLabel(null);
    setNodesArray([]);
    getLabels();
  };

  const handleLabelName = (name) => {
    const labelNameAccountString = `${name}/${user.account._id}`;
    if (labelsNameAndAccount.includes(labelNameAccountString)) {
      setNameError("A label with this name already exists");
    } else if (nameError) {
      setNameError("");
    }
    setLabelName(name);
  };

  // for SyncFusion labelType dropdown
  const dropdownListLabelType = [
    { Item: "Canister", Value: 1 },
    { Item: "Other", Value: 2 },
  ];

  // for SyncFusion labelType dropdown
  const dropdownListCanisterMachineType = [
    { Item: "TruCard", Value: 1 },
    { Item: "TruPak", Value: 2 },
    { Item: "TruScript", Value: 3 },
  ];

  const handleSelectedLabelType = (
    selectedLabelType,
    selectedLabelTypeValue
  ) => {
    setLabelType(selectedLabelType);
  };

  const handleSelectedCanisterMachineType = (
    selectedCanisterMachineType,
    selectedCanisterMachineTypeValue
  ) => {
    setMachineType(selectedCanisterMachineType);
  };

  const handleHideLabelCanvas = () => {
    setShowLabelCanvas(false);
  };

  const handleCreateLabel = () => {
    setSelectedLabel(null);
    setNodesArray([]);
    setCreateLabel(true);
    setLabelName("");
  };

  const handlePrevious = (overrideLabelModificationInProgress = false) => {
    if (
      selectedLabel &&
      !overrideLabelModificationInProgress &&
      labelModificationInProgressViaContext
    ) {
      setShowModal(true);
    } else if (
      selectedLabel ||
      !labelModificationInProgressViaContext ||
      overrideLabelModificationInProgress
    ) {
      setCreateLabel(false);
      getLabels();
    }
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleClickedLabel = (label) => {
    setCreateLabel(false);
    setSelectedLabel(false);
    handleSetNodesArray(label);
    setClickedLabel(label);
    onShowTabBar(false);
  };

  const handleUnsetClickedLabel = () => {
    setClickedLabel(null);
  };

  if (!createLabel && !clickedLabel) {
    return (
      <div className={styles.CreateLabel__container}>
        {!createLabel && (
          <Fragment>
            <div className={styles.CreateLabel__header}>
              <SearchBar
                placeholder={t("searchPlaceholder")}
                onSearch={handleSearch}
                focus={true}
              />
              <div
                className={styles.CreateLabel__createButtonContainer}
                onClick={handleCreateLabel}
              >
                <CreateButton
                  labelName="Create New Label"
                  isEnabled={
                    labelName && labelName.trim().length ? true : false
                  }
                />
              </div>
            </div>

            <div
              className={`${styles.CreateLabel__labelsPage} ${styles["CreateLabel__labelsPage--custom"]}`}
            >
              {/* <LabelTile
                imageName={"barChart"}
                imageFillColor={"#A7BBCF"}
                title={"Create New Template"}
                isTemplate={true}
                onCreateTemplate={handleCreate}
              /> */}

              <Spacer space={20} unit={"px"} />

              <div className={styles.CreateLabel__labelsRow}>
                <div className={styles.CreateLabel__labelsTitle}>
                  {t("customLabelTitle")}
                </div>
              </div>

              <div className={styles.CreateLabel__labelsContainer}>
                {labels
                  .filter((filter) => {
                    return filter.name.toUpperCase().includes(filterText) ||
                      filterText === ""
                      ? true
                      : false;
                  })

                  .map((label) => {
                    return (
                      <LabelTile
                        key={label.name}
                        imageName={"barChart"}
                        imageFillColor={"#089BAB"}
                        title={label.name}
                        isTemplate={false}
                        actions={getActionMenu(label)}
                        onAction={(action) => {
                          handleAction(action, label);
                        }}
                        onSelectLabel={() => {
                          setSelectedLabel(label);
                        }}
                        onClickedLabel={() => {
                          handleClickedLabel(label);
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  } else if (!createLabel && clickedLabel) {
    return (
      <PrintLabels
        user={user}
        onPrevious={handleUnsetClickedLabel}
        clickedLabel={clickedLabel}
        nodesArrayForUpdate={nodesArray}
        onClickedLabel={handleClickedLabel}
        onRefreshToggle={handleRefreshToggle}
        handleNodesArray={handleNodesArray}
      />
    );
  } else if (createLabel && !showLabelCanvas) {
    return (
      <Fragment>
        {showModal && Modal}
        <div
          className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
        >
          <header>
            <p className={styles.CreateLabel__heading}>
              {selectedLabel ? "Update a Label" : "Create a Label"}
            </p>
          </header>
          <div className={styles.CreateLabel__inputContainers}>
            <div className={styles.CreateLabel__labelNameContainer}>
              <div className={styles.CreateLabel__inputContainer}>
                <TextInput
                  value={labelName}
                  labelName="Label Name"
                  focus={true}
                  isEnabled={true}
                  onChange={(val) => handleLabelName(val)}
                  placeholder={""}
                  type={"text"}
                />
                {nameError && (
                  <p className={styles.CreateLabel__nameError}>{nameError}</p>
                )}
              </div>
            </div>
            <div className={styles.CreateLabel__labelTypeContainer}>
              <SFDropDownList
                id={"LabelType"}
                label={"Label Type"}
                defaultValue={labelType}
                placeHolder={"Label Type"}
                dropDownList={dropdownListLabelType}
                onSelection={handleSelectedLabelType}
              />
            </div>
            {labelType === "Canister" && (
              <div className={styles.CreateLabel__machineTypeContainer}>
                <SFDropDownList
                  id={"CanisterMachineType"}
                  label={"Machine Type"}
                  defaultValue={machineType}
                  placeHolder={"Machine Type"}
                  dropDownList={dropdownListCanisterMachineType}
                  onSelection={handleSelectedCanisterMachineType}
                />
              </div>
            )}
          </div>
          <div className={styles.CreateLabel__buttonsContainer}>
            <Button
              labelName={"Previous"}
              minWidth={"123px"}
              isPrimary={false}
              onClick={() => handlePrevious()}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={selectedLabel ? "Update Label" : "Create Label"}
              isPrimary={true}
              minWidth={"213px"}
              onClick={() => setShowLabelCanvas(true)}
              isDisabled={
                !nameError && labelName && labelName.trim().length
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </Fragment>
    );
  } else if (showLabelCanvas) {
    return (
      <div
        className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
      >
        {createLabel && (
          <LabelEditor
            user={user}
            onResetCreateLabelStatesToDefaults={
              handleResetCreateLabelStatesToDefaults
            }
            labelName={labelName.trim()}
            labelType={labelType}
            machineType={machineType}
            customizedLabel={customizedLabel}
            onHideLabelCanvas={handleHideLabelCanvas}
            _controllerRef={_controllerRef}
            selectedLabel={selectedLabel}
            nodesArrayForUpdate={nodesArray}
            handleNodesArrayForUpdate={handleNodesArray}
            labelModificationInProgress={labelModificationInProgress}
            onLabelModificationInProgress={handleLabelModificationInProgress}
          />
        )}
      </div>
    );
  }
};

export default CreateLabel;
