import React from "react";
import styles from "./InventoryListCard.module.css";

const InventoryListCard = ({ data, index }) => {
  return (
    <div
      className={`${styles.inventoryListCard__container} ${
        index % 2 == 0
          ? styles["inventoryListCard__container--dark"]
          : ["inventoryListCard__container--light"]
      }`}
    >
      <div className={styles.inventoryListCard__dataList}>
        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.siteHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.site}
            </div>
          </div>

          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--2"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.idHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.id}
            </div>
          </div>
        </div>

        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.descriptionHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.description}
            </div>
          </div>
        </div>

        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.formHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.form}
            </div>
          </div>

          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--2"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.manufacturerHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.manufacturer}
            </div>
          </div>
        </div>

        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.locationHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.location}
            </div>
          </div>

          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--2"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.parentHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.parent}
            </div>
          </div>
        </div>

        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.lotHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.lot}
            </div>
          </div>

          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--2"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.expHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.exp}
            </div>
          </div>
        </div>

        <div className={styles.inventoryListCard__rowGroup}>
          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--1"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.minHeading}, {data.maxHeading}, {data.qohHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.min}, {data.max}, {data.qoh}
            </div>
          </div>

          <div
            className={`${styles.inventoryListCard__colGroup} ${styles["inventoryListCard__colGroup--2"]}`}
          >
            <div className={`${styles["inventoryListCard__heading"]}`}>
              {data.costHeading}
            </div>
            <div className={`${styles["inventoryListCard__value"]}`}>
              {data.cost}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryListCard;
