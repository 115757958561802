import ProductController from "../controllers/ProductController";

export default function validate(values) {
    const productController = new ProductController();

    let errors = {};

    if(!values.packageId) {
        errors.packageId = 'A unique package id is required'
    }

    if(!values.primaryName) {
        errors.primaryName = 'A primary name is required'
    }

    if(values.ev54DrugName && productController.getEv54DrugNameError(values.product)){
        errors.ev54DrugName = productController.getEv54DrugNameError(values.product)
    }

    if(values.ev54DrugId && productController.getEv54DrugIdError(values.product)){
        errors.ev54DrugId = productController.getEv54DrugIdError(values.product)
    }

    return errors;
}
