import React from "react";
import styles from "./TrayLabel.module.css";

const TrayLabel = ({ label }) => {
  return (
    <td key="grid-label" rowSpan={3} className={styles.TrayGridLabelContainer}>
      <div className={styles.TrayGridLabel}>{label}</div>
    </td>
  );
};

export default TrayLabel;
