import { PDFViewer } from "@react-pdf/renderer";

const PDFDocumentViewer = ({ Document }) => {
  if (!Document) {
    return null;
  }

  return (
    <PDFViewer style={{ height: "100%", width: "100%" }}>
      <Document />
    </PDFViewer>
  );
};

export default PDFDocumentViewer;
