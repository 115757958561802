import React from 'react';
import styled from "styled-components";

//<editor-fold desc="STYLES">
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const LabelText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #089BAB;
  margin: 0;
`
const Body = styled.textarea`
  margin-top: 9px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #121A2D;
  opacity: 0.7;
`
//</editor-fold>

const TextArea = ( { text, labelName, onChange, width, height} ) => {


    return (
        <Container style={{width: width}}>
            <Header>
                <LabelText>{labelName}</LabelText>
            </Header>
            <Body style={{width: width, minWidth: width, maxWidth: width, height: height, minHeight: height, maxHeight: height}}
            onChange={ e => {
                const val = e.currentTarget.value;
                onChange( val );
            }} value={ text }/>
            {/*    { text }*/}
            {/*</Body>*/}
        </Container>
    )
}

export default TextArea;
