import React, { useEffect, useState, useRef } from "react";
import styles from "./StockedItemsQtyInput.module.css";

const StockedItemsQtyInput = ({
  // stockedItemsQty,
  setInputFieldQty,
  setActiveStockedItemQty,
  setEnforceInputFocus,
  mapIndex,
  stockedItemObj,
  stockedItemObjs,
  handleStockedItemObjs,
}) => {
  const qtyInputElement = useRef(null);

  const [zeroValue, setZeroValue] = useState(0);

  const updateQtyHandler = (e) => {
    e.preventDefault();

    let tempStockedItemObj = { ...stockedItemObj };
    setInputFieldQty(parseInt(e.target.value));

    // allows a zero quantity to be backspaced (deleted) to an empty string.
    if (isNaN(parseInt(e.target.value))) {
      setZeroValue("");
    } else if (parseInt(e.target.value) === 0) {
      setZeroValue(0);
    }

    if (e.target.value) {
      tempStockedItemObj.totalQty = parseInt(e.target.value);
    } else {
      tempStockedItemObj.totalQty = 0;
    }

    // The if condition below was added to prevent manual change of input quantity and ensure that only multiple values of the package quantity can be entered (via the up and down arrows.
    if (
      parseInt(e.target.value) %
        parseInt(stockedItemObj.product.packageQuantity) ===
      0
    ) {
      let tempStockedItemObjs = [...stockedItemObjs];
      tempStockedItemObjs[mapIndex].totalQty = tempStockedItemObj.totalQty;
      handleStockedItemObjs(tempStockedItemObjs);
    }
  };

  const onClickHandler = () => {
    setEnforceInputFocus(false);
    qtyInputElement.current.focus();
  };

  const qtyInputOnFocusHandler = (index) => {
    setActiveStockedItemQty(index);
  };

  const qtyInputOnBlurHandler = () => {
    setActiveStockedItemQty(-1);
  };

  return (
    <div
      onFocus={() => {
        qtyInputOnFocusHandler(mapIndex);
      }}
      onBlur={() => {
        qtyInputOnBlurHandler();
      }}
    >
      <input
        ref={qtyInputElement}
        onClick={onClickHandler}
        onChange={updateQtyHandler}
        value={
          stockedItemObj.totalQty > 0 ? stockedItemObj.totalQty : zeroValue
        }
        type="number"
        min="0"
        step={stockedItemObj.product.packageQuantity}
        className={styles.input}
      ></input>
    </div>
  );
};

export default StockedItemsQtyInput;
