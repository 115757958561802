import React, { useState, Fragment } from "react";
import Button from "../../../components/Button";
import styles from "./Onboarding.module.css";

const Onboarding = ({
  user,
  selectedDevice,
  filterText,
  onPageTitle,
  onGoBackToHelpView,
}) => {
  return (
    <Fragment>
      {/* <h1>Sanity Check</h1> */}
      <div className={styles.onboarding__buttonGroup}>
        <Button
          onClick={onGoBackToHelpView}
          labelName="Back"
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
      </div>
    </Fragment>
  );
};

export default Onboarding;
