import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import PhoneList from "../../components/PhoneList/PhoneList";
import Address from "../../components/Address";
import validate from "../../validation/PharmacySiteValidationRules";
import PharmacyService from "../../services/PharmacyService";
import ConfigService from "../../services/ConfigService";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./CreatePharmacySite.module.css";

const CreatePharmacySite = ({ user, site }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation("createPharmacySite");

  const isNew = site == null;

  const [copyShippingAddressToBilling, setCopyShippingAddressToBilling] =
    useState(false);
  const copyShippingAddressToBillingRef = useRef(null);
  copyShippingAddressToBillingRef.current = copyShippingAddressToBilling;

  const [copyShippingAddressToMailing, setCopyShippingAddressToMailing] =
    useState(false);
  const copyShippingAddressToMailingRef = useRef(null);
  copyShippingAddressToMailingRef.current = copyShippingAddressToMailing;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [phisId, setPhisId] = useState("");
  const [region, setRegion] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([
    { type: { type: "mobile", description: "Mobile" }, number: "" },
  ]);
  const [shippingAddress, setShippingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [billingAddress, setBillingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [mailingAddress, setMailingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [pharmacyTypeListItems, setPharmacyTypeListItems] = useState([]);
  const [type, setType] = useState(null);
  const [autoroute, setAutoroute] = useState(false);
  const [multiFacilityBatchSplit, setMultiFacilityBatchSplit] = useState(false);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    ConfigService.pharmacyTypes().then((pharmacyTypes) => {
      let listItems = [];
      pharmacyTypes.forEach((item) => {
        listItems.push({
          type: item.name,
          description: item.name,
        });
      });
      setPharmacyTypeListItems(listItems);
    });

    return () => {
      setPharmacyTypeListItems([]);
    };
  }, []);

  useEffect(() => {
    if (site) {
      setName(site.name);
      setRegion(site.region);
      setPhoneNumbers(site.phoneNumbers);
      setShippingAddress(site.shippingAddress);
      setBillingAddress(site.billingAddress);
      setMailingAddress(site.mailingAddress);
      setPhisId(site.phisId || "");
      setType(site.type);
      setAutoroute(site.autoroute || false);
      setMultiFacilityBatchSplit(site.multiFacilityBatchSplit || false);
    }
  }, [site]);

  useEffect(() => {
    if (copyShippingAddressToBilling) {
      copyShippingToBilling();
    }
  }, [copyShippingAddressToBilling]);

  useEffect(() => {
    if (copyShippingAddressToMailing) {
      copyShippingToMailing();
    }
  }, [copyShippingAddressToMailing]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let validationErrors = validate({
        name: name,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
      });

      setErrors(validationErrors);
    }
  };

  const saveData = useCallback(() => {
    async function doSaveData() {
      try {
        if (isNew) {
          let exists = await PharmacyService.siteExists(user, name);
          if (exists) {
            setError(t("error1"));
            return;
          }

          let siteDoc = await PharmacyService.createSite(user, {
            site: {
              name: name,
              region: region,
              type: type,
              phoneNumbers: phoneNumbers,
              shippingAddress: shippingAddress,
              billingAddress: billingAddress,
              mailingAddress: mailingAddress,
              phisId: phisId,
              autoroute: autoroute,
              multiFacilityBatchSplit: multiFacilityBatchSplit
            },
          });

          if (siteDoc) {
            //onCreated(siteDoc);
            navigate("/settings/sites", {
              state: { refresh: true },
              replace: true,
            });
          } else {
            setError(t("error2"));
          }
        } else {
          let modifiedSite = {
            ...site,
            ...{
              name: name,
              region: region,
              phoneNumbers: phoneNumbers,
              shippingAddress: shippingAddress,
              billingAddress: billingAddress,
              mailingAddress: mailingAddress,
              type: type,
              phisId: phisId,
              autoroute: autoroute,
              multiFacilityBatchSplit: multiFacilityBatchSplit
            },
          };
          let siteDoc = await PharmacyService.update(user, modifiedSite);
          if (siteDoc) {
            //onUpdated(siteDoc);
            navigate(-1, { state: { refresh: true } });
          } else {
            setError(t("error3"));
          }
        }
      } catch (error) {
        setError(error);
      }
      setIsSubmitting(false);
    }
    doSaveData();
  }, [
    name,
    user,
    region,
    phoneNumbers,
    shippingAddress,
    billingAddress,
    mailingAddress,
    type,
    site,
    isNew,
    autoroute,
    phisId,
    multiFacilityBatchSplit
  ]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveData();
    } else if (Object.keys(errors).length > 0) {
      setError(t("error4"));
      setIsSubmitting(false);
    }
  }, [saveData, errors, isSubmitting]);

  const handleBillingCheckboxClick = () => {
    setCopyShippingAddressToBilling((prevState) => {
      return !prevState;
    });
  };

  const handleMailingCheckboxClick = () => {
    setCopyShippingAddressToMailing((prevState) => {
      return !prevState;
    });
  };

  const copyShippingToBilling = () => {
    let clonedObj = { ...shippingAddress };
    setBillingAddress(clonedObj);
  };

  const copyShippingToMailing = () => {
    let clonedObj = { ...shippingAddress };
    setMailingAddress(clonedObj);
  };

  const isSameAddress = (add1, add2) => {
    return (
      add1?.street1 === add2?.street1 &&
      add1?.street2 === add2?.street2 &&
      add1?.city === add2?.city &&
      add1?.state === add2?.state &&
      add1?.zipcode === add2?.zipcode
    );
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className={styles.createSite__overlay}>
      {smScreen && error && (
        <div className={styles.createSite__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}
      <section className={styles.createSite__view}>
        {smScreen && (
          <section className={styles.createSite__header}>
            <div
              className={styles.createSite__backArrow}
              onClick={goBackHandler}
            >
              <BackArrowMobile className={styles.createSite__backArrow__icon} />
              <p className={styles.createSite__goBackTitle}>
                {t("goBackTitle")}
              </p>
            </div>
            <p className={styles.createSite__title}>
              {isNew ? t("addNewLabel") : `${t("updateLabel")} ${site.name}`}
            </p>
          </section>
        )}

        {!smScreen && (
          <section className={styles.createSite__header}>
            <p className={styles.createSite__title}>
              {isNew ? t("addNewLabel") : `${t("updateLabel")} ${site.name}`}
            </p>
            <div className={styles.createSite__formCloseContainer}>
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </section>
        )}

        {!smScreen && error && (
          <div className={styles.createSite__errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
          </div>
        )}

        <div className={styles.createSite__scrollArea}>
          <div className={styles.createSite__accordions}>
            <div
              className={[
                styles["createSite__accordion"],
                styles["createSite__accordion--0"],
              ].join(" ")}
            >
              <Accordion title={t("tab0")} openByDefault={true}>
                <section className={styles.createSite__row}>
                  <TextInput
                    labelName={t("siteNameLabel")}
                    isEnabled={true}
                    placeholder={""}
                    type={"text"}
                    validationText={errors.name}
                    value={name}
                    onChange={(value) => setName(value)}
                    focus={true}
                  />
                  {lgScreen && <Spacer space={40} unit={"px"} />}
                  {mdScreen && <Spacer space={30} unit={"px"} />}
                  <TextInput
                    labelName={t("regionLabel")}
                    isEnabled={true}
                    placeholder={""}
                    type={"text"}
                    validationText={errors.region}
                    value={region}
                    onChange={(value) => setRegion(value)}
                    focus={false}
                  />
                </section>
                <Spacer space={smScreen || mdScreen ? 0 : 40} unit={"px"} />
                <section className={styles.createSite__row}>
                  <DropdownInput
                    labelName={t("typeLabel")}
                    value={type ? type.description : ""}
                    validationText={errors.type}
                    placeholder={""}
                    isEnabled={true}
                    listElements={pharmacyTypeListItems}
                    onSelection={(value) => {
                      setType(value);
                    }}
                    display={"type"}
                  />
                  {smScreen && <Spacer space={10} unit={"px"} />}
                  {lgScreen && <Spacer space={40} unit={"px"} />}
                  {mdScreen && <Spacer space={30} unit={"px"} />}
                  <PhoneList
                    phoneNumbers={phoneNumbers}
                    readonly={false}
                    errors={errors.phoneNumbers}
                    onAdd={(phoneNumber) => {
                      setPhoneNumbers((arr) => {
                        return [...arr, phoneNumber];
                      });
                    }}
                    onRemove={(index) => {
                      let newArray = [...phoneNumbers];
                      newArray.splice(index, 1);
                      setPhoneNumbers(newArray);
                    }}
                    onChange={(index, phoneNumber) => {
                      let newArray = [...phoneNumbers];
                      newArray[index] = phoneNumber;
                      setPhoneNumbers(newArray);
                    }}
                  />
                </section>
                <section className={styles.createSite__row}>
                  <div className={styles.createSite__col}>
                    <Checkbox
                      labelName="Auto-route orders"
                      isOn={autoroute}
                      onCheck={(isOn) => {
                        setAutoroute(isOn);
                      }}
                    />
                    {type?.name !== 'Retail' &&
                        <>
                          <br/>
                          <Checkbox
                            labelName="Multi-facility batch split"
                            isOn={multiFacilityBatchSplit}
                            onCheck={(isOn) => {
                              setMultiFacilityBatchSplit(isOn);
                            }}
                        />
                    </>
                    }
                  </div>
                  <div className={styles.createSite__col}>
                    <TextInput
                      labelName={"PhIS Id"}
                      isEnabled={true}
                      placeholder={""}
                      type={"text"}
                      validationText={errors.phisId}
                      value={phisId}
                      onChange={(value) => {
                        setPhisId(value);
                      }}
                      focus={false}
                    />
                  </div>
                </section>
              </Accordion>
            </div>

            <div
              className={[
                styles["createSite__accordion"],
                styles["createSite__accordion--1"],
              ].join(" ")}
            >
              <Accordion title={t("tab1")}>
                <Address
                  errors={errors.shippingAddress}
                  address={shippingAddress}
                  onChange={(address) => {
                    if (copyShippingAddressToMailingRef.current) {
                      copyShippingToMailing();
                    }
                    if (copyShippingAddressToBillingRef.current) {
                      copyShippingToBilling();
                    }
                    setShippingAddress((prevState) => {
                      return { ...prevState, ...address };
                    });
                  }}
                />
              </Accordion>
            </div>

            <div className={styles.createSite__groupedAccordion}>
              <div
                className={[
                  styles["createSite__accordion"],
                  styles["createSite__accordion--2"],
                ].join(" ")}
              >
                <Accordion title={t("tab2")}>
                  <Address
                    errors={errors.billingAddress}
                    address={billingAddress}
                    onChange={(address) => {
                      setBillingAddress((prevState) => {
                        return { ...prevState, ...address };
                      });
                    }}
                  />
                </Accordion>
              </div>

              <div
                className={[
                  styles["createSite__checkboxContainer"],
                  styles["createSite__checkboxContainer--2"],
                ].join(" ")}
              >
                <Checkbox
                  labelName={
                    smScreen
                      ? t("sameAsShippingLabelMobile")
                      : t("sameAsShippingLabel")
                  }
                  isOn={copyShippingAddressToBilling}
                  onCheck={() => handleBillingCheckboxClick()}
                  labelColor="#089bab"
                />
              </div>
            </div>

            <div
              className={[
                styles["createSite__groupedAccordion"],
                styles["createSite__groupedAccordion--noBottomMargin"],
              ].join(" ")}
            >
              <div
                className={[
                  styles["createSite__accordion"],
                  styles["createSite__accordion--3"],
                ].join(" ")}
              >
                <Accordion title={t("tab3")}>
                  <Address
                    address={mailingAddress}
                    onChange={(address) => {
                      setMailingAddress((prevState) => {
                        return { ...prevState, ...address };
                      });
                    }}
                  />
                </Accordion>
              </div>
              <div
                className={[
                  styles["createSite__checkboxContainer"],
                  styles["createSite__checkboxContainer--3"],
                ].join(" ")}
              >
                <Checkbox
                  labelName={
                    smScreen
                      ? t("sameAsShippingLabelMobile")
                      : t("sameAsShippingLabel")
                  }
                  isOn={copyShippingAddressToMailing}
                  onCheck={() => handleMailingCheckboxClick()}
                  labelColor="#089bab"
                />
              </div>
            </div>
          </div>
        </div>

        <section className={styles.createSite__footer}>
          <Button
            labelName={t("cancelButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            onClick={() => navigate(-1)}
            minWidth={smScreen ? 122 : null}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={isNew ? t("addNewButtonLabel") : t("updateButtonLabel")}
            isPrimary={true}
            isDisabled={type ? false : true}
            onClick={handleSubmit}
            minWidth={smScreen ? 122 : 213}
          />
        </section>
      </section>
    </div>
  );
};

export default CreatePharmacySite;
