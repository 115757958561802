import React, { useEffect, useContext } from "react";
import Popup from "reactjs-popup";
import cheveronDownIcon from "../../../assets/cheveron-down.svg";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import ButtonGen from "../../commonUI/ButtonGen";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./SelectDevice.module.css";

const SelectDevice = ({
  sites,
  selectedSiteIndex,
  onHandleGeneratePickList,
  selectedDevice,
  onHandleSetSelectedDevice,
  onHandleCloseGenPickListModal,
  onHandleModal,
}) => {
  let popup = null;

  const setRef = (element) => {
    popup = element;
  };

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    smScreen && handleShowSmScreenHeader(false);
  }, [smScreen]);

  const labelName = "Select Dispensing Device";

  const placeholder = "Select Device";

  const handleGoBack = () => {
    onHandleModal(false);
    handleShowSmScreenHeader(true);
  };

  return (
    <>
      <div className={styles.selectDevice__container}>
        {!smScreen && (
          <CloseModalIcon
            className={styles.selectDevice__closeModalIcon}
            onClick={onHandleCloseGenPickListModal}
          />
        )}

        {smScreen && (
          <section className={styles.selectDevice__headingGroup}>
            <div
              className={styles.selectDevice__backArrow}
              onClick={handleGoBack}
            >
              <BackArrowMobile
                className={styles.selectDevice__backArrow__icon}
              />
              <p className={styles.selectDevice__goBackTitle}>{"Back"}</p>
            </div>
            <p className={styles.selectDevice__title}>
              Select Dispensing Device
            </p>
          </section>
        )}

        <div className={(styles.controlGroup, styles.dropdown)}>
          {!smScreen && (
            <label className={styles.selectDevice__heading}>{labelName}</label>
          )}
          <div className={styles.controlButtonInputGroup}>
            <input
              className={styles.controlInput}
              type={"text"}
              placeholder={placeholder}
              disabled={false}
              value={selectedDevice.name || ""}
              readOnly={true}
              onClick={(e) => e.preventDefault()}
            />
            <Popup
              trigger={
                <button className={styles.controlButton}>
                  <img src={cheveronDownIcon} alt={"dropdown button"} />
                </button>
              }
              on={"click"}
              ref={setRef}
              position="bottom right"
              closeOnDocumentClick={true}
              arrow={true}
            >
              <div className={styles.controlButtonPopup}>
                {sites[selectedSiteIndex].devices.length > 0 &&
                  sites[selectedSiteIndex].devices.map((device, index) => {
                    return (
                      <p
                        className={styles.controlButtonPopupListItem}
                        key={index}
                        onClick={() => {
                          onHandleSetSelectedDevice(device);
                          popup.close();
                        }}
                      >
                        {sites[selectedSiteIndex].devices[index].name}
                      </p>
                    );
                  })}
              </div>
            </Popup>
          </div>
          <div className={styles.controlBorder} />
        </div>
        <div className={styles.selectDevice__buttonContainer}>
          <ButtonGen
            buttonClass={
              !selectedDevice
                ? [styles["button"], styles["button--disabled"]].join(" ")
                : styles.button
            }
            btnDisabled={selectedDevice ? false : true}
            onClickHandler={() => onHandleGeneratePickList()}
          >
            Generate Pick List
          </ButtonGen>
        </div>
      </div>
    </>
  );
};

export default SelectDevice;
