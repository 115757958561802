import React, { useState, useEffect, useRef, Fragment } from "react";
import { getNewProductName } from "./HelpFunctions";
import QuickGuidesCard from "./QuickGuidesCard";
import Button from "../../../components/Button";
import HelpController from "../HelpController";
import WaitIndicator from "../../../components/WaitIndicator";
import styles from "./QuickGuides.module.css";

const QuickGuides = ({ selectedDevice, filterText, onGoBackToHelpView }) => {
  const [quickGuidesData, setQuickGuidesData] = useState([]);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /************ Get Quick Guides List data ***********/
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const controller = new HelpController();
      const guides = await controller.getHelpGuideList();

      let tempPdfUrls = [];
      let urlIndexPromises = [];
      for (let i = 0; i < guides.length; i++) {
        if (guides[i]?.filename) {
          let promise = controller
            .getHelpGuidePdf(guides[i].filename)
            .then((pdf) => {
              tempPdfUrls.push(pdf);
              guides[i].urlIndex = i;
            });
          urlIndexPromises.push(promise);
        }
      }
      await Promise.all(urlIndexPromises);
      setPdfUrls(tempPdfUrls);
      setQuickGuidesData(guides);
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? (
    <WaitIndicator message={"Collecting data for Help Quick Guides"} />
  ) : (
    <Fragment>
      <ul className={styles.quickGuides__quickGuidesList}>
        {quickGuidesData
          .filter((obj) => {
            if (
              obj &&
              obj?.title &&
              obj?.type &&
              obj?.filename &&
              obj?.urlIndex >= 0
            ) {
              const type = getNewProductName(obj.type);
              return filterText
                ? (selectedDevice === "All Devices" ||
                    selectedDevice.toUpperCase() === type.toUpperCase()) &&
                    obj?.title?.toUpperCase().includes(filterText.toUpperCase())
                : selectedDevice === "All Devices" ||
                  selectedDevice.toUpperCase() === type.toUpperCase()
                ? obj?.title
                  ? true
                  : false
                : false;
            } else {
              return false;
            }
          })
          .map((obj) => (
            <li key={obj.filename}>
              <QuickGuidesCard
                cardTitle={obj.title}
                machineType={getNewProductName(obj.type)}
                pdfUrl={pdfUrls[obj.urlIndex]}
              />
            </li>
          ))}
      </ul>
      <div className={styles.quickGuides__buttonGroup}>
        <Button
          onClick={onGoBackToHelpView}
          labelName="Back"
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
      </div>
    </Fragment>
  );
};

export default QuickGuides;
