import React, { useContext } from "react";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getYAxisScale } from "./graphFunctions";
import {
  ChartComponent,
  SeriesCollectionDirective,
  AxesDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Legend,
  Category,
  Tooltip,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
function BarGraph({
  graphData,
  graphId,
  graphTitle,
  palette,
  columnsName,
  viewWidth,
}) {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // y represents the primary Y axis
  // Initializations
  let yMin = 0; // min data value
  let yMax = 0; // max data value
  let minimumYp = 0; // min scale label value
  let maximumYp = 0; // max scale label value
  let incYp = 0; // yAxis scale interval

  // variable as desired
  let numYpScaleValues = 6;

  if (graphData && graphData.length) {
    graphData.forEach((graphObj) => {
      if (graphObj.y > yMax) {
        yMax = graphObj?.y;
      }
    });

    incYp = getYAxisScale(yMin, yMax, numYpScaleValues);

    maximumYp = parseInt(minimumYp + (numYpScaleValues - 1) * incYp);
  }

  const primaryxAxis = { valueType: "Category" };

  const primaryyAxis = {
    // title: "Temperature (Fahrenheit)",
    minimum: 0,
    maximum: maximumYp,
    interval: incYp,
    lineStyle: { width: 1 },
  };

  return (
    <div>
      <ChartComponent
        style={
          smScreen
            ? { width: `${viewWidth - 50}px`, height: "288px" }
            : { width: "450px", height: "288px" }
        }
        titleStyle={{
          size: "16px",
          fontFamily: "Poppins",
          fontWeight: "Normal",
        }}
        id={graphId}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        title={graphTitle}
        palettes={palette}
      >
        <Inject
          services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}
        />
        <AxesDirective></AxesDirective>
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={graphData}
            xName="x"
            yName="y"
            name={columnsName}
            type="Column"
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
}
export default BarGraph;
