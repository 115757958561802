import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import PDFReportViewerPickListByProduct from "./PDFReportViewerPickListByProduct";
import PDFReportViewerPickListByLocation from "./PDFReportViewerPickListByLocation";
import SearchBar from "../../../components/SearchBar";
import TabBar from "../../../components/TabBar";
// import AlarmDateTime from "../../../components/AlarmDateTime/AlarmDateTime";
import { ReactComponent as BackIcon } from "../../../assets/back_icon.svg";
import Button from "../../../components/Button";
import styles from "./PickList.module.css";
import WaitIndicator from "../../../components/WaitIndicator";
import ListDisplayByProduct from "./ListDisplayByProduct";
import ListDisplayByLocation from "./ListDisplayByLocation";
import { printReportByProduct } from "./reports";
import { printReportByLocation } from "./reports";
import ProductController from "../../../controllers/ProductController";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import DispensingOrderService from "../../../services/DispensingOrderService";

const PickList = ({
                    user,
  dispensingOrdersSelected,
  selectedDevice,
  onHandleSetSelectedDevice,
  onHandleSetDispensingOrdersSelected,
  onHandleSetActiveChildComponent
}) => {
  const pcRef = useRef(null);

  useEffect(() => {
    pcRef.current = new ProductController();
  }, []);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [expandableContainersByProduct, setExpandableContainersByProduct] =
    useState([]);
  const [expandableContainersByLocation, setExpandableContainersByLocation] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [demands, setDemands] = useState([]);
  // For testing purposes - name may change
  const [stockLocationGroups, setStockLocationGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalQtyForProductArray, setTotalQtyForProductArray] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const [locationClickObject, setLocationClickObject] = useState({
    type: null,
    index: -1,
  });

  const [cardClickIndex, setCardClickIndex] = useState(-1);

  const reportHeadersByProduct = ["ID", "Description", "Lot", "Exp", "Need"];
  const reportTitleByProduct = selectedDevice.name + " Pick List by Product";
  const reportHeadersByLocation = ["Name", "Type", "Barcode", "QOH", "Need"];
  const reportTitleByLocation = selectedDevice.name + " Pick List by Location";

  useEffect(() => {
    setIsLoading(true);
    DispensingOrderService.getDispensingNeeds(user, selectedDevice, dispensingOrdersSelected)
        .then(results=> {
          let demandsList = results.demands;
          let stockLocationGroupsList = results.stockLocationGroups;
          if (demandsList) {
            const compare = (a, b) => {
              // Use toUpperCase() to ignore character casing
              const productA = pcRef.current.getDefaultName(a.product);
              const productB = pcRef.current.getDefaultName(b.product);

              let comparison = 0;
              if (productA > productB) {
                comparison = 1;
              } else if (productA < productB) {
                comparison = -1;
              }
              return comparison;
            };

            let tempArray1 = [...demandsList];

            tempArray1.sort(compare);
            demandsList = tempArray1;

            setDemands(demandsList);

            let tempArr2 = [];
            demandsList.forEach(() => {
              tempArr2.push("collapsed");
            });
            setExpandableContainersByProduct(tempArr2);
          } else {
            console.log("Error calling calculateNeeds");
          }

          if (stockLocationGroupsList) {
            setStockLocationGroups(stockLocationGroupsList);

            let tempArr3 = [];
            stockLocationGroupsList.forEach(() => {
              tempArr3.push("collapsed");
            });

            setExpandableContainersByLocation(tempArr3);
          }

          if (demandsList && stockLocationGroupsList) setIsLoading(false);
        }).catch(error=>{
          setIsLoading(false);
        })
  }, []);

  const handleSetTotalQtyForProductArray = (arr) => {
    return setTotalQtyForProductArray(arr);
  };

  const { t } = useTranslation("pickList");

  const tabs = [
    { index: 0, text: t("Product") },
    { index: 1, text: t("Location") },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    // Reset selected states to default if the screen size changes from mobile to tablet/desktop or vice versa.
    // This resets the PickList table to the starting point for Product or Location selection.
    setExpandableContainersByProduct([]);
    setExpandableContainersByLocation([]);
    setShowPDFViewer(false);
    setLocationClickObject({
      type: null,
      index: -1,
    });
    setCardClickIndex(-1);
  }, [smScreen]);

  useEffect(() => {
    // Reset referenced states to default values if the Product or Location tab is clicked.
    setLocationClickObject({
      type: null,
      index: -1,
    });

    setCardClickIndex(-1);

    if (selectedTab.index === 0) {
      collaspeAllLocationRows();
    } else if (selectedTab.index === 1) {
      collaspeAllProductRows();
    }
  }, [selectedTab.index]);

  const tabBarStyle = {
    display: "flex",
  };

  const handleSelectedTab = (index) => {
    setSelectedTab(tabs[index]);
  };

  const handleSetReportData = (arr) => {
    setReportData(arr);
  };

  const handleSetShowPDFViewer = (bool) => {
    setShowPDFViewer(bool);
  };

  const handlePrintReport = () => {
    if (selectedTab.index === 0) {
      printReportByProduct(
        demands,
        filterText,
        handleSetReportData,
        handleSetShowPDFViewer
      );
    } else {
      printReportByLocation(
        stockLocationGroups,
        filterText,
        handleSetReportData,
        handleSetShowPDFViewer,
        totalQtyForProductArray
      );
    }
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const collaspeAllProductRows = () => {
    let tempArray = [...expandableContainersByProduct];

    tempArray.forEach((element, index) => {
      tempArray[index] = "collapsed";
    });

    setExpandableContainersByProduct(tempArray);
  };

  const collaspeAllLocationRows = () => {
    let tempArray = [...expandableContainersByLocation];

    tempArray.forEach((element, index) => {
      tempArray[index] = "collapsed";
    });

    setExpandableContainersByLocation(tempArray);
  };

  const handleGoBack = () => {
    if (
      !smScreen ||
      (smScreen && cardClickIndex === -1 && !locationClickObject.type)
    ) {
      onHandleSetSelectedDevice("");
      onHandleSetDispensingOrdersSelected([]);
      onHandleSetActiveChildComponent("");
    } else if (smScreen && cardClickIndex !== -1 && !locationClickObject.type) {
      handleCardClickIndex(-1);

      if (selectedTab.index === 0) {
        collaspeAllProductRows();
      } else if (selectedTab.index == 1) {
        collaspeAllLocationRows();
      }
    } else if (smScreen && cardClickIndex !== -1 && locationClickObject.type) {
      handleLocationClick({
        type: null,
        index: -1,
      });
    }
  };

  const handleExpansionClick = (index) => {
    let tempArray = [...expandableContainersByProduct];
    if (selectedTab.index === 0) {
      if (expandableContainersByProduct[index] === "expanded") {
        tempArray[index] = "collapsed";
      } else {
        tempArray.fill("collapsed");
        tempArray[index] = "expanded";
      }
      setExpandableContainersByProduct(tempArray);
    } else {
      if (expandableContainersByLocation[index] === "expanded") {
        tempArray[index] = "collapsed";
      } else {
        tempArray.fill("collapsed");
        tempArray[index] = "expanded";
      }
      setExpandableContainersByLocation(tempArray);
    }
  };

  const handleOnBackPDFReport = () => {
    setShowPDFViewer(false);
    setFilterText("");
  };

  const handleCardClickIndex = (index) => {
    setCardClickIndex(index);
  };

  const handleLocationClick = (locationObj) => {
    setLocationClickObject(locationObj);
  };

  const handleViewClick = (e) => {
    e.stopPropagation();
  };

  if (isLoading) {
    return (
        <WaitIndicator
            message={`Calculating Pick List needs for ${selectedDevice.name}`}
        />
    );
  }else if (showPDFViewer) {
    return selectedTab.index === 0 ? (
      <PDFReportViewerPickListByProduct
        title={reportTitleByProduct}
        headers={reportHeadersByProduct}
        data={reportData}
        onBack={handleOnBackPDFReport}
      />
    ) : (
      <PDFReportViewerPickListByLocation
        title={reportTitleByLocation}
        headers={reportHeadersByLocation}
        data={reportData}
        onBack={handleOnBackPDFReport}
      />
    );
  } else {
    return (
      <div
        className={styles.pickList__view}
        onClick={(e) => handleViewClick(e)}
      >
        <header className={styles.header}>
          <div style={tabBarStyle}>
            <TabBar
              tabs={tabs}
              onSelectedTab={handleSelectedTab}
              fontSize={"14px"}
            />
          </div>
          {/* {!smScreen && <AlarmDateTime />} */}
        </header>
        <div className={styles.searchRowContainer}>
          <div className={styles.searchRow}>
            {(!smScreen || (smScreen && cardClickIndex === -1)) && (
              <SearchBar
                placeholder={
                  selectedTab.index === 0
                    ? t("searchPlaceholderByProduct")
                    : t("searchPlaceholderByLocation")
                }
                smFullWidth={smScreen ? true : false}
                onSearch={handleSearch}
              />
            )}
          </div>

          {!smScreen && (
            <Button
              labelName="Print"
              isPrimary={true}
              isDisabled={false}
              onClick={handlePrintReport}
              minWidth={smScreen ? "100%" : 123}
            />
          )}
        </div>
        <div className={styles.deviceTitleRow}>
          <BackIcon className={styles.backIcon} onClick={handleGoBack} />

          <div className={styles.selectedDeviceRow}>
            <p
              className={[
                styles["selectedDeviceRowItem"],
                styles["selectedDeviceRowItem--rightPadded"],
              ].join(" ")}
            >
              {selectedDevice.name}
            </p>
            <p className={styles.selectedDeviceRowItem}>{`${
              selectedTab.index === 0
                ? "Pick List By Product"
                : "Pick List By Location"
            }`}</p>
          </div>
        </div>
        {selectedTab.index === 0 && (
          <ListDisplayByProduct
            demands={demands}
            filterText={filterText}
            handleExpansionClick={handleExpansionClick}
            expandableContainers={expandableContainersByProduct}
            cardClickIndex={cardClickIndex}
            onHandleCardClickIndex={handleCardClickIndex}
            locationClickObject={locationClickObject}
            onHandleLocationClick={handleLocationClick}
          />
        )}

        {selectedTab.index !== 0 && (
          <ListDisplayByLocation
            demands={demands}
            stockLocationGroups={stockLocationGroups}
            filterText={filterText}
            handleExpansionClick={handleExpansionClick}
            expandableContainers={expandableContainersByLocation}
            totalQtyForProductArray={totalQtyForProductArray}
            handleSetTotalQtyForProductArray={handleSetTotalQtyForProductArray}
            cardClickIndex={cardClickIndex}
            onHandleCardClickIndex={handleCardClickIndex}
            locationClickObject={locationClickObject}
            onHandleLocationClick={handleLocationClick}
          />
        )}
      </div>
    );
  }
};

export default PickList;
