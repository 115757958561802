import React, {useState, useEffect} from 'react';
import backgroundView from "../../assets/Background.jpg";
import {ReactComponent as CloseIcon} from "../../assets/x.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import {useNavigate} from "react-router-dom";
import TextInput from "../../components/TextInput";
import validate from "../../validation/ResetPasswordValidationRules";
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";
import { translateError } from '../../utils/functions/translateError';

const ResetPasswordView = () => {

    //region css
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: '100vw',
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'norepeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundView})`
    }

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto 0',
        width: '586px',
        height: '424px',
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '20px'
    }

    const formHeaderStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '50px',
        marginLeft: '50px',
        marginRight: '20px',
        marginTop: '20px'
    }

    const formCloseContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '60%'
    }

    const formTitleStyle = {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '27px',
        color: '#121A2D',
        margin: 0
    }

    const formTextStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#121A2D',
        margin: 0,
    }

    const formConfirmationTextStyle = {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '33px',
        textAlign: 'center',
        color: '#089BAB',
        margin: 0,
    }

    const formBodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '50px',
        marginRight: '50px'
    }

    const formFooterStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginBottom: '50px',
        flex: 1
    }

    const formRowStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
    //endregion

    const { t } = useTranslation('resetPassword');

    const navigate = useNavigate();

    const views = [0, 1, 2]; // 0: reset 1: confirmation 2: user-based security

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [currentView, setCurrentView] = useState(views[0]);

    const handleCancel = () => {
        navigate(-1);
    }

    const handleChange = (val) => {
        setEmailAddress(val);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        let validationErrors = validate({
            emailAddress: emailAddress
        });
        setErrors(validationErrors);
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            UserService.resetPassword(emailAddress)
                .then(emailSent=>{
                    if(emailSent) {
                        setCurrentView(views[1]);
                    } else {

                    }
                })
                .catch(error=>{
                    setError(error);
                })
        }
    }, [errors]);

    return (
        <div style={containerStyle}>
            {currentView === views[0] &&
            <section style={formStyle}>
                <header style={formHeaderStyle}>
                    <div style={formCloseContainerStyle}>
                        <CloseIcon fill={'#121A2D'} style={{cursor: 'pointer'}} onClick={handleCancel}/>
                    </div>

                </header>
                <section style={formBodyStyle}>
                    <section style={formRowStyle}>
                        <h1 style={formTitleStyle}>{t('title')}</h1>
                    </section>
                    {error &&
                    <>
                        <Spacer space={10} unit={'px'}/>
                        <ErrorBanner message={translateError(error)} onClose={()=>setError(null)}/>
                        <Spacer space={10} unit={'px'}/>
                    </>
                    }
                    {!error && <Spacer space={30} unit={'px'}/>}
                    <TextInput labelName={t('emailLabel')}
                               isEnabled={true}
                               placeholder={''}
                               validationText={t(errors.emailAddress, {ns: "validation"})}
                               value={emailAddress}
                               focus={true}
                               onChange={handleChange}
                               type={'text'}/>
                    <Spacer space={20} unit={'px'}/>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        {!error && <p style={{...formTextStyle, width: 400}}>{t('instructions')}</p>}
                    </div>

                </section>
                <footer style={formFooterStyle} >
                    <Button labelName={t('sendEmailButtonLabel')} isDisabled={false} isPrimary={true}
                            onClick={handleSubmit}/>
                </footer>
            </section>
            }
            {currentView === views[1] &&
            <section style={formStyle}>
                <header style={formHeaderStyle}>
                    <div style={formCloseContainerStyle}>
                        <CloseIcon fill={'#121A2D'} style={{cursor: 'pointer'}} onClick={handleCancel}/>
                    </div>

                </header>
                <section style={formBodyStyle}>
                    <section style={formRowStyle}>
                        <h1 style={formTitleStyle}>{t('title')}</h1>
                    </section>
                    <Spacer space={50} unit={'px'}/>
                    <section style={formRowStyle}>
                        <p style={formConfirmationTextStyle}>{t('confirmationMessage')}</p>
                    </section>
                    <Spacer space={20} unit={'px'}/>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <p style={{...formTextStyle, width: 400}}>{t('confirmationInstructions')}</p>
                    </div>

                </section>
                <footer style={formFooterStyle} >
                    <Button labelName={t('continueButtonLabel')} isDisabled={false} isPrimary={true}
                            onClick={handleCancel}/>
                </footer>
            </section>
            }
        </div>
    )
}

export default ResetPasswordView;
