import React, {useState} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Space, Select, Radio, Button, message, Upload,Typography, Flex, Tabs, Checkbox } from 'antd';
import ApiService from '../../services/ApiService';
const { Title } = Typography;

const SuperAdmin = ({user}) => {
    const { Dragger } = Upload;

    const [useFDBNames, setUseFDBNames] = useState(true);
    const [fetchFDBImages, setFetchFDBImages] = useState(true);
    const [deleteExistingData, setDeleteExistingData] = useState(false);
    const [allowTruScript, setAllowTruScript] = useState(true);
    const [allowTruPak, setAllowTruPak] = useState(true);
    const [allowTruCard, setAllowTruCard] = useState(true);

    const props = {
        name: 'file',
        multiple: false,
        action: `${ApiService.BASE_URL}/accounts/${user.account._id || user.account}/products/csv?useFDBNames=${useFDBNames}&fetchFDBImages=${fetchFDBImages}&deleteExistingData=${deleteExistingData}&allowTruScript=${allowTruScript}&allowTruPak=${allowTruPak}&allowTruCard=${allowTruCard}&token=${user.token}`,
        onChange(info) {
            console.log(props.action)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const facProps = {
        name: 'file',
        multiple: false,
        action: `${ApiService.BASE_URL}/accounts/${user.account._id || user.account}/facilities/csv?deleteExistingData=${deleteExistingData}&token=${user.token}`,
        onChange(info) {
            console.log(props.action)
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onChange = (key) => {
        console.log(key);
    };

    const getProductConfig = () => {
        return (
            <Space direction={"vertical"}>
                <Checkbox checked={useFDBNames} onChange={(e)=>setUseFDBNames(e.target.checked)}>Use FDB Names (override what's in file)</Checkbox>
                <Checkbox checked={fetchFDBImages} onChange={(e)=>setFetchFDBImages(e.target.checked)}>Fetch FDB images</Checkbox>
                <Checkbox checked={deleteExistingData} onChange={(e)=>setDeleteExistingData(e.target.checked)}>Delete existing data before upload</Checkbox>
                <Checkbox checked={allowTruScript} onChange={(e)=>setAllowTruScript(e.target.checked)}>TruScript</Checkbox>
                <Checkbox checked={allowTruPak} onChange={(e)=>setAllowTruPak(e.target.checked)}>TruPak</Checkbox>
                <Checkbox checked={allowTruCard} onChange={(e)=>setAllowTruCard(e.target.checked)}>TruCard</Checkbox>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for CSV product upload. File format must be correct or server will not process!
                    </p>
                </Dragger>
            </Space>
        )
    }

    const getFacilityConfig = () => {
        return (
            <Space direction={"vertical"}>
                <Checkbox checked={deleteExistingData} onChange={(e)=>setDeleteExistingData(e.target.checked)}>Delete existing data before upload</Checkbox>
                <Dragger {...facProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for CSV facility upload. File format must be correct or server will not process!
                    </p>
                </Dragger>
            </Space>
        )
    }

    const items = [
        {
            key: '1',
            label: 'Product Config',
            children: getProductConfig(),
        },
        {
            key: '2',
            label: 'Facility Config',
            children: getFacilityConfig(),
        },
    ];

    return(
        <Flex vertical={true}>
            <Title level={3}>Super Admin</Title>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Flex>

    )
}

export default SuperAdmin;