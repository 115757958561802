import React from "react";
import styles from "./Input.module.css";

const Input = ({
  labelName,
  value,
  isEnabled,
  type,
  placeholder,
  onChange,
  focus,
  validationText,
  autoComplete,
}) => {
  return (
    <div className={styles.controlGroup}>
      <label className={"controlLabel"}>{labelName}</label>
      <input
        className={styles.input}
        type={type}
        placeholder={placeholder}
        disabled={!isEnabled}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        autoFocus={focus}
        autoComplete={autoComplete}
      />
      <div className={"controlBorder"} />
      {true && (
        <label className={"controlValidationLabel"}>{validationText}</label>
      )}
    </div>
  );
};

export default Input;
