import { Spin } from "antd";
import styles from "./AntSpin.module.css";
import "./AntSpin.css";

/* 
  Example of use
      <AntSpin
        message="Loading batches..."
        containerStyle="container-000"
        spinnerStyle="ant-spin-000"
        messageStyle="message-000"
        spinSize="default"  
      />
*/

// spinSize can be "small", "default", or "large"

const AntSpin = ({
  message,
  containerStyle,
  spinnerStyle,
  messageStyle,
  spinSize,
}) => {
  return (
    <div className={styles[containerStyle] || null}>
      <Spin className={spinnerStyle || null} size={spinSize || "default"} />
      {message && <p className={styles[messageStyle] || null}>{message}</p>}
    </div>
  );
};

export default AntSpin;
