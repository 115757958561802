import React from "react";
import styles from "./PillsortAlarmsCard.module.css";

const PillsortAlarmsCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.PillsortAlarmsCard__reportList}>
      <li className={styles.PillsortAlarmsCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.PillsortAlarmsCard__dataContainer}>
          <h1>{headingsObj.alarm}</h1>
          <p>{dataObj.alarm}</p>
        </div>
        <div className={styles.PillsortAlarmsCard__dataContainer}>
          <h1>{headingsObj.occurred}</h1>
          <p>{dataObj.occurred}</p>
        </div>
      </li>
      <li className={styles.PillsortAlarmsCard__reportListItem}>
        <div className={styles.PillsortAlarmsCard__dataContainer}>
          <h1>{headingsObj.description}</h1>
          <p>{dataObj.description}</p>
        </div>
      </li>
      <li className={styles.PillsortAlarmsCard__reportListItem}>
        <div className={styles.PillsortAlarmsCard__dataContainer}>
          <h1>{headingsObj.resolution}</h1>
          <p>{dataObj.resolution}</p>
        </div>
      </li>
    </ul>
  );
};

export default PillsortAlarmsCard;
