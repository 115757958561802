import React from "react";
import styles from "./StockLocationScanned.module.css";

const StockLocationScanned = ({ stockLocationScanned, bottomBodyPadding }) => {
  return (
    <React.Fragment>
      <p
        className={
          bottomBodyPadding
            ? [
                styles["locationScanned__stockLocationName"],
                styles["locationScanned__stockLocationName--withBottomPadding"],
              ].join(" ")
            : styles.locationScanned__stockLocationName
        }
      >
        {stockLocationScanned.name}
      </p>
    </React.Fragment>
  );
};

export default StockLocationScanned;
