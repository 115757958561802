import ProductController from "../../../controllers/ProductController";

export const printReportByProduct = (
  demands,
  filterText,
  handleSetReportData,
  handleSetShowPDFViewer
) => {

  const pc = new ProductController();
  let tempArray = [];
  demands.forEach((demand) => {
    let externalStockedItemsTotalQty = 0;
    if (
      !filterText ||
      ((demand.product.packageId.toUpperCase().includes(filterText) ||
        pc.getDefaultName(demand.product).toUpperCase().includes(filterText)) &&
        !(
          demand.outstandingNeed === 0 &&
          demand.externalCanisterNeeds.length === 0 &&
          demand.externalStockedItems.length === 0 &&
          demand.refillQty === 0
        ))
    ) {
      tempArray.push([
        demand.product.packageId || "",
        pc.getDefaultName(demand.product) || "",
        demand.product.lot || "",
        demand.product.exp || "",
        demand.totalQty,
      ]);
      demand.externalCanisterNeeds.forEach((need) => {
        tempArray.push([
          need.stockLocation.type.description || "",
          need.stockLocation.name || "",
          need.stockLocation.barcode || "",
          "",
          need.quantity,
        ]);
      });

      demand.externalStockedItems.forEach((item) => {
        externalStockedItemsTotalQty += item.quantity;
        tempArray.push([
          `   ${item.stockLocation.type.description}` || "",
          item.stockLocation.name || "",
          item.stockLocation.barcode || "",
          "",
          item.quantity,
        ]);
      });

      if (
        demand.refillQty > 0 &&
        externalStockedItemsTotalQty < demand.refillQty
      ) {
        tempArray.push([
          "Bulk",
          `${pc.getDefaultName(demand.product)} (${demand.product.packageId})` || "",
          "",
          "",
          demand.refillQty - externalStockedItemsTotalQty,
        ]);
      }
    }
  });

  handleSetReportData(tempArray);
  handleSetShowPDFViewer(true);
};

export const printReportByLocation = (
  stockLocationGroups,
  filterText,
  handleSetReportData,
  handleSetShowPDFViewer,
  totalQtyForProductArray
) => {
  const pc = new ProductController();

  const getTotalQty = (list, stockedItem) => {
    let i;
    const key = stockedItem.product.packageId;
    for (i = 0; i < list.length; i++) {
      if (key in list[i]) {
        return list[i][key];
      }
    }
    return null;
  };

  let tempArray = [];
  stockLocationGroups.forEach((group) => {
    if (
      !filterText ||
      group.stockLocation.name.toUpperCase().includes(filterText) ||
      group.stockLocation.type.description.toUpperCase().includes(filterText)
    ) {
      tempArray.push([
        group.stockLocation.name || "",
        group.stockLocation.type.description || "",
        group.stockLocation.barcode || "",
        "",
        "",
      ]);
      group.stockedItems.forEach((item) => {
        tempArray.push([
          pc.getDefaultName(item.product) || "",
          "",
          "",
          item.quantity,
          getTotalQty(totalQtyForProductArray, item),
        ]);
      });
    }
  });

  handleSetReportData(tempArray);
  handleSetShowPDFViewer(true);
};
