import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as ExpandIcon } from "../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../assets/subtract-circle-icon.svg";
import styles from "./TableExpandable.module.css";

const Table = ({
  headers,
  records,
  searchText,
  padScrollBar = false,
  onClickHandler = null,
}) => {
  const [sortOrder, setSortOrder] = useState([]);
  const [sortOrderSub, setSortOrderSub] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filteredRecordsSub, setFilteredRecordsSub] = useState([]);
  const [viewSubTables, setViewSubTables] = useState([]);
  const [mouseDrag, setMouseDrag] = useState(false);

  useEffect(() => {
    if (mouseDrag) {
      const timeoutId = setTimeout(function () {
        setMouseDrag(false);
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [mouseDrag]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < headers.length; i++) {
      arr.push(1);
    }
    setSortOrder(arr);
  }, [headers]);

  useEffect(() => {
    let tempArray;
    if (viewSubTables.length) {
      tempArray = [];
    } else {
      tempArray = [...viewSubTables];
    }

    for (const filteredRecord of filteredRecords) {
      tempArray.push(false);
    }
    setViewSubTables(tempArray);
  }, [filteredRecords, headers]);

  useEffect(() => {
    if (searchText) {
      const arr = [...records];
      const arr2 = [];
      for (let r = 0; r < arr.length; r++) {
        const record = arr[r];
        for (let i = 0; i < record.length; i++) {
          if (
            record[i] &&
            record[i]
              .toString()
              .toUpperCase()
              .includes(searchText.toUpperCase())
          ) {
            arr2.push(record);
            break;
          }
        }
      }
      setFilteredRecords(arr2);
    } else {
      setFilteredRecords(records);
    }
  }, [searchText, records]);

  useEffect(() => {
    if (filteredRecords?.length > 0) {
      let table = document.querySelector("#table");
      if (table) {
        resizableGrid(table);
      }
    }
  }, [filteredRecords]);

  useEffect(() => {
    if (viewSubTables?.length > 0) {
      let tableSub = document.querySelector("#table #tableSubRow #tableSub");
      if (tableSub) {
        resizableGridSub(tableSub);
      }
    }
  }, [viewSubTables]);

  const resizableGrid = (table) => {
    var row = table.getElementsByTagName("tr")[0],
      cols = row ? row.children : undefined;
    if (!cols) return;
    for (var i = 0; i < cols.length; i++) {
      //cols[i].innerHTML = "";
      let divs = cols[i].getElementsByClassName("divider");
      for (let d = 0; d < divs.length; d++) {
        cols[i].removeChild(divs[d]);
      }
      var div = createDiv(table.offsetHeight);
      cols[i].appendChild(div);
      cols[i].style.position = "relative";
      if (div.hasAttribute("listener") != true) {
        setListeners(div);
      }
    }
  };

  const resizableGridSub = (table) => {
    var row = table.getElementsByTagName("tr")[0],
      cols = row ? row.children : undefined;
    if (!cols) return;
    for (var i = 0; i < cols.length; i++) {
      //cols[i].innerHTML = "";
      let divs = cols[i].getElementsByClassName("dividerSub");
      for (let d = 0; d < divs.length; d++) {
        cols[i].removeChild(divs[d]);
      }
      var div = createDivSub(table.offsetHeight);
      cols[i].appendChild(div);
      cols[i].style.position = "relative";
      if (div.hasAttribute("listener") != true) {
        setListeners(div);
      }
    }
  };

  function createDiv(height) {
    var div = document.createElement("div");
    div.className = "divider";
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = "7px";
    div.style.position = "absolute";
    div.style.cursor = "col-resize";
    /* remove backGroundColor later */
    // div.style.backgroundColor = "lightGrey";
    div.style.userSelect = "none";
    /* table height */
    div.style.height = height + "px";
    return div;
  }

  function createDivSub(height) {
    var div = document.createElement("div");
    div.className = "dividerSub";
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = "7px";
    div.style.position = "absolute";
    div.style.cursor = "col-resize";
    /* remove backGroundColor later */
    // div.style.backgroundColor = "lightGrey";
    div.style.userSelect = "none";
    /* table height */
    div.style.height = height + "px";
    return div;
  }

  function setListeners(div) {
    var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
    div.addEventListener("mousedown", function (e) {
      setMouseDrag(true);
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;
      curColWidth = curCol.offsetWidth;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
    });

    div.addEventListener("click", function (e) {
      e.stopPropagation();
    });

    document.addEventListener("mousemove", function (e) {
      if (curCol) {
        var diffX = e.pageX - pageX;

        if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

        curCol.style.width = curColWidth + diffX + "px";
      }
    });

    document.addEventListener("mouseup", function (e) {
      curCol = undefined;
      nxtCol = undefined;
      pageX = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined;
      return false;
    });
  }

  const handleColumnSort = (index) => {
    let arr = [...sortOrder];
    let arr2 = [...filteredRecords];
    arr[index] *= -1;

    arr2 = arr2.sort((rowA, rowB) => {
      if (typeof rowA[index] === "string" && typeof rowB[index] === "string") {
        if (rowA[index].toLowerCase() < rowB[index].toLowerCase()) {
          if (arr[index] > 0) {
            return -1;
          }
          return 1;
        }
        if (rowB[index].toLowerCase() < rowA[index].toLowerCase()) {
          if (arr[index] > 0) {
            return 1;
          }
          return -1;
        }
      } else {
        if (rowA[index] < rowB[index]) {
          if (arr[index] > 0) {
            return -1;
          }
          return 1;
        }
        if (rowB[index] < rowA[index]) {
          if (arr[index] > 0) {
            return 1;
          }
          return -1;
        }
      }
      return 0;
    });

    setFilteredRecords(arr2);
    setSortOrder(arr);
  };

  const handleColumnSortSub = (index) => {
    let arr = [...sortOrderSub];
    let arr2 = [...filteredRecordsSub];
    arr[index] *= -1;
    arr2 = arr2.sort((rowA, rowB) => {
      if (rowA[index] < rowB[index]) {
        if (arr[index] > 0) {
          return -1;
        }
        return 1;
      }
      if (rowB[index] < rowA[index]) {
        if (arr[index] > 0) {
          return 1;
        }
        return -1;
      }
      return 0;
    });
    setFilteredRecordsSub(arr2);
    setSortOrderSub(arr);
  };

  const handleShowSubTable = (filteredRecordsRowIndex) => {
    if (!mouseDrag) {
      fillSortOrderSub(filteredRecordsRowIndex);
      let tempArray = [...viewSubTables];

      tempArray.forEach(function (item, index) {
        if (index !== filteredRecordsRowIndex) {
          if (item) {
            tempArray[index] = false;
          }
        }
      });

      tempArray[filteredRecordsRowIndex]
        ? (tempArray[filteredRecordsRowIndex] = false)
        : (tempArray[filteredRecordsRowIndex] = true);

      setViewSubTables(tempArray);

      setFilteredRecordsSub(
        filteredRecords[filteredRecordsRowIndex][
          filteredRecords[filteredRecordsRowIndex].length - 1
        ]
      );
    } else {
      setMouseDrag(false);
    }
  };

  const fillSortOrderSub = (mainRowIndex) => {
    let arr = [];
    for (
      let i = 0;
      i < records[mainRowIndex][records[mainRowIndex].length - 2].length;
      i++
    ) {
      arr.push(1);
    }
    setSortOrderSub(arr);
  };

  return (
    <div
      className={
        !padScrollBar
          ? styles.Table__container
          : `${styles.Table__container} ${styles["Table__container--rightPadding"]}`
      }
    >
      <table className={styles.Table__main}>
        {headers && headers.length > 0 && (
          <thead>
            <tr className={styles.Table__header_row}>
              {headers.map((header, headersColumnindex) => {
                if (headersColumnindex < headers.length) {
                  return (
                    <th
                      key={"h_" + headersColumnindex}
                      onClick={() => handleColumnSort(headersColumnindex)}
                    >
                      {header}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
        )}
        <tbody id="table">
          {filteredRecords &&
            filteredRecords.map((row, filteredRecordsRowIndex) => {
              let main_row_style;

              if (row[row.length - 1]) {
                main_row_style =
                  filteredRecordsRowIndex % 2 === 0
                    ? `${styles["Table__showHoverPointer"]} ${styles["Table__mainRow"]} ${styles["Table__mainRow--light"]}`
                    : `${styles["Table__showHoverPointer"]} ${styles["Table__mainRow"]} ${styles["Table__mainRow--dark"]}`;
              } else {
                main_row_style =
                  filteredRecordsRowIndex % 2 === 0
                    ? `${styles["Table__mainRow"]} ${styles["Table__mainRow--light"]}`
                    : `${styles["Table__mainRow"]} ${styles["Table__mainRow--dark"]}`;
              }

              return (
                <Fragment key={"r_" + filteredRecordsRowIndex}>
                  <tr
                    className={main_row_style}
                    onClick={() => handleShowSubTable(filteredRecordsRowIndex)}
                  >
                    {row &&
                      row.length &&
                      row.map((col, filteredRecordsColumnIndex) => {
                        if (filteredRecordsColumnIndex < row.length - 2) {
                          const showExpandIcon =
                            filteredRecordsColumnIndex === 0 &&
                            !viewSubTables[filteredRecordsRowIndex] &&
                            row[row.length - 1];

                          const showCollapseIcon =
                            filteredRecordsColumnIndex === 0 &&
                            viewSubTables[filteredRecordsRowIndex] &&
                            row[row.length - 1];

                          return (
                            <td key={"c_" + filteredRecordsColumnIndex}>
                              {showExpandIcon ? (
                                <div className={styles.Table__expandIcon}>
                                  <ExpandIcon />
                                  <span>{col}</span>
                                </div>
                              ) : showCollapseIcon ? (
                                <div className={styles.Table__collapseIcon}>
                                  <CollapseIcon />
                                  <span>{col}</span>
                                </div>
                              ) : (
                                col
                              )}
                            </td>
                          );
                        }
                      })}
                  </tr>
                  {viewSubTables[filteredRecordsRowIndex] &&
                  row[row.length - 2] ? (
                    <tr id="tableSubRow">
                      <td colSpan={headers.length}>
                        <table className={styles.Table__sub}>
                          {row[row.length - 2] &&
                            row[row.length - 2].length > 0 && (
                              <thead>
                                <tr className={styles.Table__header_row}>
                                  {row[row.length - 2].map(
                                    (headerSub, subHeadersColumnIndex) => {
                                      return (
                                        <th
                                          key={"hSub_" + subHeadersColumnIndex}
                                          onClick={() =>
                                            handleColumnSortSub(
                                              subHeadersColumnIndex
                                            )
                                          }
                                        >
                                          {headerSub}
                                        </th>
                                      );
                                    }
                                  )}
                                </tr>
                              </thead>
                            )}

                          {filteredRecordsSub && filteredRecordsSub.length > 0 && (
                            <tbody id="tableSub">
                              {filteredRecordsSub &&
                                filteredRecordsSub.map((row, index) => {
                                  return (
                                    <tr
                                      key={"rSub_" + index}
                                      className={
                                        index % 2 === 0
                                          ? `${styles["Table__subRow"]} ${styles["Table__subRow--light"]}`
                                          : `${styles["Table__subRow"]} ${styles["Table__subRow--dark"]}`
                                      }
                                    >
                                      {row &&
                                        row.length &&
                                        row.map((col, index) => {
                                          if (index < row.length) {
                                            return (
                                              <td key={"c" + index}>{col}</td>
                                            );
                                          }
                                        })}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
