import React, {useEffect, useRef, useState} from 'react';
import Table from "../../../../components/Table/Table";
import style from "./EV54Replenishment.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import EV54ReplenishCanister from "./EV54ReplenishCanister";
import ProductFieldController from "../../../../controllers/ProductFieldController";
import {useNavigate} from "react-router-dom";
import RoleCheckService from "../../../../services/RoleCheckService";
import ErrorBanner from "../../../../components/ErrorBanner";
import StockLocationController from "../../../../controllers/StockLocationController";
import Loader from 'react-loader-spinner';
import StockLocationService from "../../../../services/StockLocationService";

const EV54Replenishment = ({user, controllerRef, device}) => {
    const navigate = useNavigate();
    const productFieldController = new ProductFieldController();
    const stockLocationController = useRef(null);
    const mounted = useRef(false);

    const [selectedNeed, setSelectedNeed] = useState(null);
    const [needs, setNeeds] = useState([]);
    const [needRecs, setNeedRecs] = useState([]);
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [headers, _]= useState(["_selectedObject", "Canister", "NDC", "Product", "Manufacturer", "Current Qty","Qty Needed", "Stock Location"]);
    const [error, setError] = useState('');
    const [isStocking, setIsStocking] = useState(false);
    const [canisterNeeds, setCanisterNeeds] = useState(null);
    const [canisterRecs, setCanisterRecs] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(false);

    useEffect(()=>{
        mounted.current = true;

        return () => {
            mounted.current = false;
        }
    }, []);

    const handleStockNeeds = async (canisterNeeds) => {
        if(mounted.current){
            const tempRecs = [];
            for(let i = 0; i < canisterNeeds.length; i ++){
                const need = canisterNeeds[i];
                if(!need){return;}
                const canisterNo = need.stockLocation.devicePosition;
                const packageId = need.product.packageId;
                const productName = productFieldController.getDefaultName(need.product);
                const manufacturer = need.product.manufacturer.abbrName || need.product.manufacturer.fullName;
                const currentQty = need.totalQty;

                const qtyNeeded = need.qtyNeeded;
                const location = need.stockLocation.name;
                tempRecs.push([{_id: canisterNo, canisterNo: canisterNo}, canisterNo, packageId, productName, manufacturer, currentQty,  qtyNeeded, location]);

            }
            setCanisterRecs(tempRecs);
            setCanisterNeeds(canisterNeeds);
        }

    }

    // useEffect(()=>{
    //     StockLocationService.getStockedItems(user, device.site, device).then(stockNeeds=>{
    //         const tempArr = [];
    //         for (let i = 0; i < stockNeeds.length; i++) {
    //             const need = stockNeeds[i];
    //             //if(need.stockLocation){
    //             const canisterNo = need.stockLocation?.devicePosition || 'unassigned';
    //             const packageId = productFieldController.getPackageId(need.product);
    //             const productName = productFieldController.getDefaultName(need.product);
    //             const manufacturer = need.product.manufacturer.abbrName || need.product.manufacturer.fullName;
    //             const currentQty = need.qtyStocked;
    //             const qtyNeeded = need.qtyNeeded;
    //             const location = need.stockLocation?.name || 'unassigned';
    //             tempArr.push([{_id: canisterNo, canisterNo: canisterNo}, canisterNo, packageId, productName, manufacturer, currentQty,  qtyNeeded, location]);
    //             //}
    //         }
    //         setNeedRecs(tempArr);
    //         setNeeds(stockNeeds);
    //     }).catch(error=>{
    //         setError(error);
    //     })
    // }, [user, device]);

    useEffect(()=>{
        controllerRef.current.addReplenishmentNeedsCallback(handleReplenishmentNeeds, handleStockNeeds);
        controllerRef.current.getReplenishmentNeeds(handleReplenishmentNeeds);
        controllerRef.current.getCurrentStock(handleStockNeeds);
        return () => {
            controllerRef.current.removeReplenishmentNeedsCallback(handleReplenishmentNeeds, handleStockNeeds);
        }
    }, [user, controllerRef])

    const handleReplenishmentNeeds = (data) => {
        try {
            if(mounted.current){
                const tempArr = [];
                for (let i = 0; i < data.length; i++) {
                    const need = data[i];
                    //if(need.stockLocation){
                    const canisterNo = need.stockLocation?.devicePosition || 'unassigned';
                    const packageId = productFieldController.getPackageId(need.product);
                    const productName = productFieldController.getDefaultName(need.product);
                    const manufacturer = need.product.manufacturer.abbrName || need.product.manufacturer.fullName;
                    const currentQty = need.qtyStocked;
                    const qtyNeeded = need.qtyNeeded;
                    const location = need.stockLocation?.name || 'unassigned';
                    tempArr.push([{_id: canisterNo, canisterNo: canisterNo}, canisterNo, packageId, productName, manufacturer, currentQty,  qtyNeeded, location]);
                    //}
                }
                setNeedRecs(tempArr);
                setNeeds(data);
            }

        } catch (e) {
            //console.log(e);
        }
    }

    const replenishCanister = (obj) => {
        if(obj && !isStocking) {
            // get the needs data
            const need = needs.find(o =>o.stockLocation && o.stockLocation.devicePosition === obj.canisterNo);
            if(!need){ // unassigned canister
                return;
            }

            if (need.product.controlLevel?.type > 0 && !RoleCheckService.canHandleControlledSubstances(user)) {
                setError('You are not authorized to handle Controlled Substances.  See your Administrator to have this role added.');
            } else {
                setSelectedNeed(need);
            }
        } else if(obj) {
            const canisterNeed = canisterNeeds.find(o => o.stockLocation && o.stockLocation.devicePosition === obj.canisterNo);
            if(!canisterNeed){
                return; // unassigned canister
            }

            if (canisterNeed.product.controlLevel?.type > 0 && !RoleCheckService.canHandleControlledSubstances(user)) {
                setError('You are not authorized to handle Controlled Substances.  See your Administrator to have this role added.');
            } else {
                setSelectedNeed(canisterNeed);
            }
        }

    }

    // [need, canisterNo, packageId, productName, exp, lot, manufacturer, qtyNeeded, location]
    if(selectedNeed){
        return (
            <EV54ReplenishCanister parentDevice={device} controllerRef={controllerRef} user={user} need={selectedNeed} onClose={()=> {
                setSelectedNeed(null);
                //setCanisterRecs([]);
                //stockLocationController.current.getStockLocationByBarcode(device.barcode, handleStockLocation);
                controllerRef.current.getCurrentStock(device);
            }}/>
        )
    } else {
        return(
            <div className={style.EV54Replenishment_container}>
                <div className={style.EV54Replenishment_header_row}>
                    < FontAwesomeIcon icon={faPrint} color={"#089bab"} onClick={()=>{
                        //setShowPDFViewer(true)
                        const tempHeaders = [...headers];
                        tempHeaders.splice(0, 1);
                        const tempDataList = [];
                        if(!isStocking){
                            needRecs.forEach(rec=>{
                                let temp = [...rec];
                                temp.splice(0,1);
                                tempDataList.push(temp);
                            })
                        } else {
                            canisterRecs.forEach(rec=>{
                                let temp = [...rec];
                                temp.splice(0,1);
                                tempDataList.push(temp);
                            })
                        }
                        navigate("/pdf_viewer", {state: {title: `${device.name} Replenishment Needs`,
                                headers: tempHeaders,
                                data: tempDataList}});
                    }}/>
                    <button className={style.EV54Replenishment_button__primary} onClick={()=>{
                        setIsStocking(!isStocking);
                    }}>{isStocking ? 'Replenishment' : 'Stock'}</button>
                </div>

                {error &&
                    <>
                        <br/>
                        <ErrorBanner message={error} onClose={()=>setError('')}/>
                        <br/>
                    </>
                }
                <br/>
                {!loadingLocations &&<Table
                    onClickHandler={(record)=>{
                        //replenishCanister(record[0]);
                    }}
                    id='ev54ReplenishmentTable'
                    headers={headers}
                    records={isStocking ? canisterRecs : needRecs}
                />}
                {loadingLocations &&
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <Loader type="Rings" color="#089BAB" height={100} width={100}/>
                        <p style={{fontWeight: '500', fontSize: '14px', color: '#089BAB', margin: 0, textAlign: 'center'}}>Loading canister info...</p>
                    </div>
                }
            </div>
        )
    }




}

export default EV54Replenishment;
