import React, {useEffect, useRef, useState} from 'react';
import styles from "./CreateProduct.module.css";
import {useTranslation} from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";

const CreateProductEV54Naming = ({isNew, p, errors, onPropertyChange, onError, onAddImage, onDeleteImage}) => {
    const { t } = useTranslation('createProduct');
    const productController = new ProductController();

    useEffect(()=>{
        if(!p.ev54DrugId){
            onPropertyChange({ev54DrugId: productController.getEv54DrugId(p)})
        }
        if(!p.ev54DrugName){
            onPropertyChange({ev54DrugName: productController.getEv54DrugName(p)})
        }
        if(!p.ev54ChuteLimit){
            onPropertyChange({ev54ChuteLimit: 100})
        }
    }, [p])

    return (
        <Accordion title={"EV-54 Properties"}>
            <div className={styles.CreateProduct__productCategory_row}>
                {/*<div className={styles.CreateProduct__productCategory_col} style={{width: '200px'}}>*/}
                {/*    <TextInput*/}
                {/*        type={'text'}*/}
                {/*        labelName={'9-Digit ID'}*/}
                {/*        isEnabled={true}*/}
                {/*        value={p?.ev54DrugId}*/}
                {/*        validationText={errors.ev54DrugId}*/}
                {/*        focus={false}*/}
                {/*        onChange={val => {*/}
                {/*            onPropertyChange({ev54DrugId: val})*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className={styles.CreateProduct__productCategory_col}>*/}
                {/*    <TextInput*/}
                {/*        type={'text'}*/}
                {/*        labelName={'Drug Name'}*/}
                {/*        isEnabled={true}*/}
                {/*        value={p?.ev54DrugName}*/}
                {/*        validationText={errors.ev54DrugName}*/}
                {/*        focus={false}*/}
                {/*        onChange={val => {*/}
                {/*            onPropertyChange({ev54DrugName: val})*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className={styles.CreateProduct__productCategory_col} style={{width: '100px'}}>
                    <TextInput
                        type={'number'}
                        labelName={'Chute Limit'}
                        isEnabled={true}
                        value={p?.ev54ChuteLimit}
                        validationText={""}
                        focus={false}
                        onChange={val => {
                            onPropertyChange({ev54ChuteLimit: val})
                        }}
                    />
                </div>
            </div>
        </Accordion>
    )
}

export default CreateProductEV54Naming;
