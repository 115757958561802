import React from "react";
import ProductsWOPriceCard from "./ProductsWOPriceCard";
import styles from "./ProductsWOPriceList.module.css";

const ProductsWOPriceList = ({ headings, data, fetchingData }) => {
  const headingsObj = {
    packageId: headings[0],
    product: headings[1],
    priceFile: headings[2],
    cost: headings[3],
  };

  if (data.length || fetchingData) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.ProductsWOPriceList__container}>
        <ProductsWOPriceCard
          headingsObj={headingsObj}
          dataObj={{
            packageId: dataRow[0],
            product: dataRow[1],
            priceFile: dataRow[2],
            cost: dataRow[3],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.ProductsWOPriceList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.ProductsWOPriceList__noDataFoundMessage}>
        <p>No data found</p>
      </div>
    );
  }
};

export default ProductsWOPriceList;
