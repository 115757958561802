import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import GetProfilePhoto from "../../components/GetProfilePhoto/GetProfilePhoto";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import { ReactComponent as DefaultProfilePic } from "../../assets/default-profile-pic.svg";
import Dropzone from "react-dropzone";
import TextInput from "../../components/TextInput";
import PhoneList from "../../components/PhoneList/PhoneList";
import { ReactComponent as Camera } from "../../assets/camera.svg";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import validate from "../../validation/ProfileValidationRules";
import PharmacyService from "../../services/PharmacyService";
import ImageService from "../../services/ImageService";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./EditProfile.module.css";

const EditProfile = ({ user, onUpdate, onChangePassword }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("editProfile");
  const [sites, setSites] = useState([]);
  const [defaultSite, setDefaultSite] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [pendingFile, setPendingFile] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [source, setSource] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [cameraStreaming, setCameraStreaming] = useState(false);
  const [defaultLandingPage, setDefaultLandingPage] = useState("dashboard");

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setTitle(user.title);
    setDefaultSite(user?.defaultSite);
    setUsername(user.username);
    setEmailAddress(user.emailAddress);
    setPhoneNumbers(user.phoneNumbers);
    if (user.profilePic) {
      ImageService.read(user, user.profilePic).then((imageDoc) => {
        setProfilePic(imageDoc);
      });
    }
    setDefaultLandingPage(user.defaultLandingPage ?? "dashboard");
  }, [user]);

  useEffect(() => {
    (async () => {
      const sites = await PharmacyService.list(user);
      sites.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
      setSites(sites);
    })();
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let validationErrors = validate({
        firstName: firstName,
        lastName: lastName,
      });

      setErrors(validationErrors);
    }
  };

  const saveData = useCallback(() => {
    async function doSaveData() {
      try {
        setIsSubmitting(false);
        if (pendingFile) {
          if (profilePic) {
            await ImageService.delete(user, profilePic);
          }
          // save Image
          user.profilePic = await ImageService.create(
            user,
            pendingFile,
            handleUploadProgress
          );
        }

        user.firstName = firstName;
        user.lastName = lastName;
        user.phoneNumbers = phoneNumbers;
        user.title = title;
        user.defaultSite = defaultSite;
        user.defaultLandingPage = defaultLandingPage;
        let userDoc = await UserService.update(user, user);
        return onUpdate({ ...user, profilePic: userDoc.profilePic });
      } catch (error) {
        setError(error);
      }
    }
    doSaveData();
  }, [
    user,
    pendingFile,
    firstName,
    lastName,
    title,
    onUpdate,
    phoneNumbers,
    profilePic,
    defaultLandingPage,
  ]);

  const handleUploadProgress = (e) => {
    console.log(e);
  };

  useEffect(() => {
    // A short delay is done here to ensure that the 'isSubmitting' state is updated before calling the "saveData" function.  This is necessary to prevent a second image deletion call.
    setTimeout(() => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        saveData();
      } else if (Object.keys(errors).length > 0) {
        setIsSubmitting(false);
      }
    }, 200);
  }, [saveData, errors, isSubmitting]);

  const onDrop = async (files) => {
    let file = files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      setSource(reader.result);
    };
    reader.readAsDataURL(file);
    setPendingFile(file);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const photoGoBackHandler = () => {
    setCameraStreaming(false);
  };

  const handleCameraStreaming = (boolVal) => {
    setCameraStreaming(boolVal);
  };

  const handleCameraClick = (event) => {
    event.stopPropagation();
    setCameraStreaming(true);
  };

  // Mobile only
  const handlePhotoCapture = (imgArray) => {
    // Used with camera streaming and mobile devices (smart phones) only.

    // imgArray[0] gives the image mime type (data:image/png)
    // imgArray[1] gives the image base 64 string

    setCameraStreaming(false);

    function base64ImageToBlob() {
      // extract content type and base64 payload from original string
      const type = imgArray[0].substring(0, imgArray[0].length - 1);
      const b64 = imgArray[1];

      // decode base64
      const imageContent = atob(b64);

      // create an ArrayBuffer and a view (as unsigned 8-bit)
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);

      //   // fill the view, using the decoded base64
      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }

      // convert ArrayBuffer to Blob
      const blob = new Blob([buffer], { type: type });

      return blob;
    }

    setPendingFile(base64ImageToBlob());
    setIsSubmitting(true);
  };

  // This function provides the list elements for Default Sites Dropdown.
  const getSites = () => {
    let s = [];
    if (user?.isEnterprise) {
      // s.push({ type: "enterprise", description: "Enterprise" });
      for (let i = 0; i < sites.length; i++) {
        s.push({ type: sites[i].name, description: sites[i].name });
      }
    } else {
      for (let i = 0; i < sites.length; i++) {
        if (
          user.sites.some((o) => {
            return o === sites[i]._id;
          })
        ) {
          s.push({ type: sites[i].name, description: sites[i].name });
        }
      }
    }
    return s;
  };

  return !cameraStreaming ? (
    <Fragment>
      <div className={styles.editProfile__overlay}>
        <section className={styles.editProfile__view}>
          {smScreen && (
            <section className={styles.editProfile__header}>
              <div
                className={styles.editProfile__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.editProfile__backArrow__icon}
                />
                <p className={styles.editProfile__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <p className={styles.editProfile__title}>{t("title")}</p>
            </section>
          )}

          {!smScreen && (
            <section className={styles.editProfile__header}>
              <p className={styles.editProfile__title}>{t("title")}</p>
              <div className={styles.editProfile__closeIcon}>
                {lgScreen && (
                  <CloseIcon
                    fill={"#121A2D"}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                )}
              </div>
            </section>
          )}

          <div className={styles.editProfile__contentContainer}>
            <section className={styles.editProfile__body}>
              {error && (
                <>
                  <ErrorBanner message={error} onClose={() => setError(null)} />
                  <Spacer space={smScreen ? 10 : 10} unit={"px"} />
                </>
              )}
              <div
                className={[
                  styles["editProfile__row"],
                  styles["editProfile__row--alignCenter"],
                ].join(" ")}
              >
                <div className={styles.editProfile__profilePic}>
                  {!source && !profilePic ? (
                    <DefaultProfilePic />
                  ) : (
                    <img
                      className={styles.editProfile__image}
                      src={
                        source
                          ? source
                          : `data:image/${profilePic.mimeType};base64,${profilePic.base64EncodedString}`
                      }
                      alt={"profile pic"}
                    />
                  )}
                </div>
                {!smScreen && (
                  <div className={styles.editProfile__profilePicButton}>
                    <Dropzone
                      accept={"image/jpeg, image/png, image/svg+xml"}
                      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button
                              labelName={
                                !source && !profilePic
                                  ? t("addProfilePicBtnLabel1")
                                  : t("addProfilePicBtnLabel2")
                              }
                              isPrimary={true}
                              isInverse={true}
                              onClick={() => alert("here")}
                              minWidth={smScreen ? 335 : null}
                            />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )}

                {smScreen && (
                  <div className={styles.editProfile__cameraIconContainer}>
                    <Camera
                      className={styles.editProfile__cameraIcon}
                      onClick={(event) => {
                        handleCameraClick(event);
                      }}
                    />
                  </div>
                )}
                <div
                  className={styles.editProfile__DropdownMultiSelectContainer}
                >
                  <DropdownInput
                    value={user?.defaultSite?.name || ""}
                    validationText={""}
                    isEnabled={true}
                    placeholder={""}
                    listElements={getSites()}
                    labelName={t("defaultSite")}
                    onSelection={(listItem) => {
                      let sitesIndex = sites.findIndex(
                        (site) => site?.name === listItem?.description
                      );
                      setDefaultSite(sites[sitesIndex]);
                    }}
                  />
                </div>
              </div>
              <Spacer space={smScreen ? 22 : mdScreen ? 30 : 50} unit={"px"} />
              <section className={`${styles.editProfile__row}`}>
                <section className={styles.editProfile__col}>
                  <TextInput
                    labelName={t("label1")}
                    placeholder={""}
                    isEnabled={true}
                    validationText={errors.firstName}
                    value={firstName || ""}
                    type={"text"}
                    focus={true}
                    onChange={(val) => {
                      setFirstName(val);
                    }}
                  />

                  {smScreen && (
                    <TextInput
                      labelName={t("label2")}
                      placeholder={""}
                      isEnabled={true}
                      validationText={errors.lastName}
                      value={lastName || ""}
                      type={"text"}
                      focus={false}
                      onChange={(val) => {
                        setLastName(val);
                      }}
                    />
                  )}

                  <TextInput
                    labelName={t("label5")}
                    placeholder={""}
                    isEnabled={true}
                    validationText={errors.title}
                    value={title || ""}
                    type={"text"}
                    focus={true}
                    onChange={(val) => {
                      setTitle(val);
                    }}
                  />

                  {user.account.accountSecurity.type === "EMAIL" ? (
                    <TextInput
                      labelName={t("label3")}
                      placeholder={""}
                      isEnabled={false}
                      validationText={errors.emailAddress}
                      value={emailAddress || ""}
                      type={"text"}
                      focus={false}
                      onChange={(val) => {
                        setEmailAddress(val);
                      }}
                    />
                  ) : (
                    <TextInput
                      labelName={t("label4")}
                      placeholder={""}
                      isEnabled={true}
                      validationText={errors.userName}
                      value={username || ""}
                      type={"text"}
                      focus={true}
                      onChange={(val) => {
                        setUsername(val);
                      }}
                    />
                  )}

                  <Spacer space={smScreen ? 12 : 40} unit={"px"} />

                  <div className={styles.editProfile__row}>
                    <Button
                      style={
                        smScreen
                          ? { width: "228px", height: "40px" }
                          : { width: "228px", height: "40px" }
                      }
                      icon={<LockIcon />}
                      labelName={t("changePasswordButtonLabel")}
                      isInverse={true}
                      isDisabled={false}
                      isPrimary={true}
                      onClick={() => navigate("/change-password")}
                      minWidth={smScreen ? 280 : null}
                    />
                  </div>
                </section>
                <Spacer
                  unit={"px"}
                  space={smScreen ? 40 : mdScreen ? 20 : 60}
                />
                <section className={styles.editProfile__col}>
                  {!smScreen && (
                    <TextInput
                      labelName={t("label2")}
                      placeholder={""}
                      isEnabled={true}
                      validationText={errors.lastName}
                      value={lastName || ""}
                      type={"text"}
                      focus={false}
                      onChange={(val) => {
                        setLastName(val);
                      }}
                    />
                  )}

                  <PhoneList
                    phoneNumbers={phoneNumbers}
                    readonly={false}
                    errors={errors.phoneNumbers}
                    height={"200px"}
                    onAdd={(phoneNumber) => {
                      setPhoneNumbers((arr) => {
                        return [...arr, phoneNumber];
                      });
                    }}
                    onRemove={(index) => {
                      let newArray = [...phoneNumbers];
                      newArray.splice(index, 1);
                      setPhoneNumbers(newArray);
                    }}
                    onChange={(index, phoneNumber) => {
                      let newArray = [...phoneNumbers];
                      newArray[index] = phoneNumber;
                      setPhoneNumbers(newArray);
                    }}
                  />

                  <DropdownInput
                    labelName={t("defaultLandingPage")}
                    value={t(`${defaultLandingPage.toLowerCase()}MenuName`, {ns: "navBar"})}
                    listElements={[
                      "dashboard",
                      "devices",
                      "inventory",
                      "orders",
                      "reports",
                      "settings"
                    ].map(type => ({
                      type: type,
                      description: t(`${type}MenuName`, { ns: "navBar" })
                    }))}
                    onSelection={(listItem) => {
                      setDefaultLandingPage(listItem.type);
                    }}
                  />
                </section>
              </section>
            </section>

            <section className={styles.editProfile__footer}>
              {
                <Button
                  labelName={t("cancelButtonLabel")}
                  isPrimary={false}
                  isDisabled={false}
                  onClick={() => navigate(-1)}
                  minWidth={smScreen ? 122 : 135}
                />
              }
              <Spacer space={smScreen ? 20 : 20} unit={"px"} />
              <Button
                labelName={t("saveButtonLabel")}
                isPrimary={true}
                isDisabled={false}
                onClick={handleSubmit}
                minWidth={smScreen ? 122 : 213}
              />
            </section>
          </div>
        </section>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <GetProfilePhoto
        photoSize="120"
        onHandlePhotoCapture={handlePhotoCapture}
        onHandleCameraStreaming={handleCameraStreaming}
      />
    </Fragment>
  );
};

export default EditProfile;
