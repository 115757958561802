import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackArrow } from "../../assets/mobile-back-arrow.svg";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import validate from "../../validation/ChangePasswordValidationRules";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./ChangePassword.module.css";

const ChangePassword = ({ user, onClose, onUpdate }) => {
  const { t } = useTranslation("changePassword");
  const navigate = useNavigate();

  // STATE
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let validationErrors = validate({
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      });

      setErrors(validationErrors);
    }
  };

  const saveData = useCallback(() => {
    async function doSaveData() {
      try {
        await AuthService.changePassword(user, oldPassword, newPassword);
        onUpdate();
      } catch (error) {
        setError(error);
      }
      setIsSubmitting(false);
    }
    doSaveData();
  }, [oldPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveData();
    } else if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
    }
  }, [saveData, errors, isSubmitting]);

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div
      className={
        smScreen
          ? styles.changePassword__container
          : styles.changePassword__overlay
      }
    >
      <section className={styles.changePassword__view}>
        <section className={styles.changePassword__header}>
          {smScreen && (
            <BackArrow
              className={styles.changePassword__backArrow}
              onClick={goBackHandler}
            />
          )}

          <p className={styles.changePassword__title}>{t("title")}</p>
          {!smScreen && (
            <div className={styles.changePassword__closeIcon}>
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            </div>
          )}
        </section>
        <section className={styles.changePassword__body}>
          {error && (
            <>
              <ErrorBanner message={error} onClose={() => setError(null)} />
              <Spacer space={10} unit={"px"} />
            </>
          )}
          <form className={styles.changePassword__col}>
            <TextInput
              autoComplete="off"
              labelName={t("label1")}
              type={"password"}
              focus={true}
              value={oldPassword}
              validationText={errors.oldPassword}
              isEnabled={true}
              placeholder={""}
              onChange={(val) => {
                setOldPassword(val);
              }}
            />

            <TextInput
              autoComplete="off"
              labelName={t("label2")}
              type={"password"}
              focus={false}
              value={newPassword}
              validationText={errors.newPassword}
              isEnabled={true}
              placeholder={""}
              onChange={(val) => {
                setNewPassword(val);
              }}
            />

            <TextInput
              autoComplete="off"
              labelName={t("label3")}
              type={"password"}
              focus={false}
              value={confirmPassword}
              validationText={errors.confirmPassword}
              isEnabled={true}
              placeholder={""}
              onChange={(val) => {
                setConfirmPassword(val);
              }}
            />
          </form>
        </section>
        <section className={styles.changePassword__footer}>
          <Button
            labelName={t("cancelButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            onClick={() => navigate(-1)}
            minWidth={smScreen ? 122 : null}
          />
          {smScreen && <Spacer space={20} unit={"px"} />}
          {!smScreen && <Spacer space={50} unit={"px"} />}
          <Button
            labelName={t("saveButtonLabel")}
            isPrimary={true}
            isDisabled={false}
            onClick={handleSubmit}
            minWidth={smScreen ? 122 : 213}
          />
        </section>
      </section>
    </div>
  );
};

export default ChangePassword;
