import React, { useEffect, useRef, useState, useContext } from "react";
import cheveronDownIcon from "../../../../assets/cheveron-down.svg";
import styles from "./DropDownInput.module.css";
import { useKeyPress } from "../../../../components/InputHooks";
import { ScrollContext } from "../../../../contexts/ScrollContext";

const DropdownInput = ({
  labelName,
  listElements,
  isEnabled,
  value,
  placeholder,
  validationText,
  onSelection,
  display,
  style = null,
  cheveronStyle = null,
  closeDropdown = false,
  onOpenDropdowns = null,
  activeIndex = null,
  onActiveIndex = null,
  dropdownIndex = null,
}) => {
  const ref = useRef(null);

  const { xScroll, yScroll } = useContext(ScrollContext);

  const [showList, setShowList] = useState(false);
  const [filter, setFilter] = useState(value || "");

  if (useKeyPress("Escape")) {
    if (showList) {
      setShowList(false);
    }
  }

  useEffect(() => {
    if (closeDropdown) {
      setShowList(false);
      onOpenDropdowns();
    }
  }, [closeDropdown]);

  useEffect(() => {
    setFilter(value);
  }, [value]);

  useEffect(() => {
    if (dropdownIndex !== activeIndex) {
      setShowList(false);
    }
  }, [activeIndex]);

  const onMouseUp = (e) => {
    if (ref.current) {
      const element = ref.current;
      const rect = element.getBoundingClientRect();
      if (
        e.pageX - xScroll < rect.left ||
        e.pageX - xScroll > rect.left + rect.width ||
        e.pageY - yScroll < rect.top ||
        e.pageY - yScroll > rect.top + rect.height
      ) {
        setShowList(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", onMouseUp);

    return () => {
      document.removeEventListener("click", onMouseUp);
    };
  });

  const onChange = (text) => {
    setFilter(text);
    if (text.length > 0) {
      setShowList(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.code.toUpperCase() === "TAB") {
      // tab key
      if (
        filter &&
        listElements.filter((o) => {
          if (!filter.length) {
            return true;
          } else {
            return display
              ? o[display].toUpperCase().startsWith(filter.toUpperCase())
              : o.description.toUpperCase().startsWith(filter.toUpperCase());
          }
        }).length === 1
      ) {
        const li = listElements.filter((o) => {
          if (!filter.length) {
            return true;
          } else {
            return display
              ? o[display].toUpperCase().startsWith(filter.toUpperCase())
              : o.description.toUpperCase().startsWith(filter.toUpperCase());
          }
        })[0];
        onSelection(li);

        setFilter(li.description);

        setShowList(false);
      } else if (
        display &&
        listElements.some(
          (li) => li[display].toUpperCase() === filter.toUpperCase()
        )
      ) {
        const li = listElements.find(
          (li) => li[display].toUpperCase() === filter.toUpperCase()
        );
        onSelection(li);
        setFilter(li[display]);
        setShowList(false);
      } else if (
        !display &&
        listElements.some(
          (li) => li.description.toUpperCase() === filter.toUpperCase()
        )
      ) {
        const li = listElements.find(
          (li) => li.description.toUpperCase() === filter.toUpperCase()
        );
        onSelection(li);
        setFilter(li.description);
        setShowList(false);
      } else {
        setFilter(value);
      }
      setShowList(false);
    }
  };

  const onKeyUp = (event) => {
    const code = event.keyCode || event.which;
    if (code === 9) {
      setShowList(false);
    }
  };

  return (
    <div
      ref={ref}
      className={styles.DropDownList__group}
      style={style ? style : null}
      onKeyDown={(e) => onKeyDown(e)}
      onKeyUp={onKeyUp}
      onClick={(e) => e.stopPropagation()}
    >
      <label>{labelName}</label>
      <div className={styles.DropDownList__input_group}>
        <input
          type={"text"}
          placeholder={placeholder}
          disabled={isEnabled ? !isEnabled : false}
          value={filter}
          //readOnly={true}
          onClick={(e) => {
            e.preventDefault();
          }}
          onChange={(e) => onChange(e.target.value)}
        />
        <button
          className={styles.selectButton}
          onClick={(e) => {
            e.preventDefault();
            if (!showList && onActiveIndex) {
              onActiveIndex(dropdownIndex);
            }
            setShowList((prevState) => {
              return !prevState;
            });
          }}
        >
          <div>
            <img
              style={cheveronStyle}
              src={cheveronDownIcon}
              alt={"dropdown button"}
            />
          </div>
        </button>
      </div>
      {/* <div className={styles.DropDownList__control_border} /> */}
      {showList && (
        <div className={styles.DropDownList__list}>
          {listElements.length > 0 &&
            listElements
              .filter((o) => {
                if (!filter || filter === value) {
                  return true;
                } else {
                  return display
                    ? o[display].toUpperCase().startsWith(filter.toUpperCase())
                    : o.description
                        .toUpperCase()
                        .startsWith(filter.toUpperCase());
                }
              })
              .map((listElement, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => {
                      onSelection(listElement);
                      setFilter(
                        display ? listElement[display] : listElement.description
                      );
                      setShowList(false);
                    }}
                  >
                    {display ? listElement[display] : listElement.description}
                  </p>
                );
              })}
        </div>
      )}
      {validationText && (
        <label className={styles.DropDownList__validation}>
          {validationText}
        </label>
      )}
    </div>
  );
};

export default DropdownInput;
