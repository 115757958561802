import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./UsersDataCard.module.css";

const UsersDataCard = ({ data, onHideContextMenu }) => {
  const { t, i18n } = useTranslation("pharmacySites");
  return (
    <div
      className={styles.usersDataCard__container}
      onClick={onHideContextMenu}
    >
      <div className={styles.usersDataCard__dataList}>
        <div className={styles.usersDataCard__rowGroup}>
          <div
            className={`${styles.usersDataCard__colGroup} ${styles["usersDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["usersDataCard__heading"]}`}>
              <div className={styles.usersDataCard__fullName}>
                {data.nameHeading}
              </div>
              <div className={styles.usersDataCard__userGroup}>
                {data.userProfilePic}

                <div className={`${styles["usersDataCard__value"]}`}>
                  {data.name}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.usersDataCard__colGroup} ${styles["usersDataCard__colGroup--2"]}`}
          >
            <div className={`${styles["usersDataCard__heading"]}`}>
              {data.rolesHeading}
            </div>
            <div
              className={`${styles["usersDataCard__rolesContainer"]} ${styles["usersDataCard__value"]}}`}
            >
              {data.roles}
            </div>
          </div>
          <div className={styles.usersDataCard__actions}>{data.actions}</div>
        </div>

        <div className={styles.usersDataCard__rowGroup}>
          <div
            className={`${styles.usersDataCard__colGroup} ${styles["usersDataCard__colGroup--1"]} ${styles["usersDataCard__emailGroup"]} `}
          >
            <div className={`${styles["usersDataCard__heading"]}`}>
              {data.emailHeading}
            </div>
            <div className={`${styles["usersDataCard__value"]}`}>
              {data.email}
            </div>
          </div>
        </div>

        <div className={styles.usersDataCard__rowGroup}>
          <div
            className={`${styles.usersDataCard__colGroup} ${styles["usersDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["usersDataCard__heading"]}`}>
              {data.phoneNumberHeading}
            </div>
            <div className={`${styles["usersDataCard__value"]}`}>
              {data.phoneNumber}
            </div>
          </div>
        </div>

        <div className={styles.usersDataCard__rowGroup}>
          <div
            className={`${styles.usersDataCard__colGroup} ${styles["usersDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["usersDataCard__heading"]}`}>
              <div className={styles.usersDataCard__fullName}>
                {data.defaultSiteHeading}
              </div>
              <div className={`${styles["usersDataCard__value"]}`}>
                {data.defaultSite || "---"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersDataCard;
