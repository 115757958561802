import React, { useContext } from "react";
import styles from "./SiteHeader.module.css";
import { ReactComponent as BuildingIcon } from "../../../assets/building.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const SiteHeader = ({ user, site, isSelected, onSelect, onSelect2, siteReplenishmentNeeds, onDeviceReplenishment }) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  if (isSelected) {
    return (
      <SelectedSiteHeader
        user={user}
        site={site}
        onSelect={onSelect2}
        smScreen={smScreen}
        siteReplenishmentNeeds={siteReplenishmentNeeds || []}
        onDeviceReplenishment={onDeviceReplenishment}
      />
    );
  }
  return (
    <UnselectedSiteHeader site={site} onSelect={onSelect} smScreen={smScreen} />
  );
};

const SelectedSiteHeader = ({ user, site, onSelect, smScreen, siteReplenishmentNeeds, onDeviceReplenishment }) => {
  return (
    <div
      onClick={onSelect}
      className={
        smScreen
          ? styles.SiteHeader__container
          : `${styles.SiteHeader__container} ${styles.SiteHeader__container_selected}`
      }
    >
      <div
        className={
          smScreen
            ? styles.SiteHeader__noDisplay
            : `${styles.SiteHeader__iconContainer} ${styles.SiteHeader__iconContainer_selected}`
        }
      >
        <BuildingIcon fill={"#ffffff"} />
      </div>
      <h1>{site.name}</h1>
        {siteReplenishmentNeeds && getSiteReplenishmentNeeds(siteReplenishmentNeeds, onDeviceReplenishment)}
    </div>
  );
};

const getSiteReplenishmentNeeds = (deviceNeeds, onDeviceReplenishment) => {
    return (
      <div className={styles.SiteHeader__row}>
          {deviceNeeds.filter(o=>o.hasParLevelNeeds || o.hasOrderNeeds).map((deviceNeed, index)=>{
              return getDeviceNeed(deviceNeed, index, onDeviceReplenishment)
          })}
      </div>
    );
}

const getDeviceNeed = (deviceNeed, index, onDeviceReplenishment) => {
    let color = '#ffffff';
    if(deviceNeed.hasParLevelNeeds){
        color = '#cd8f0a';
    }
    if(deviceNeed.hasOrderNeeds){
        color = '#ff0000';
    }

    return(
        // <h1 key={index}>{deviceNeed.stockLocation.name}</h1>
        <div key={index} className={styles.SiteHeader__deviceNeed_col} onClick={()=>{
            if(onDeviceReplenishment) {
                onDeviceReplenishment(deviceNeed)
            }
        }}>
            <div className={styles.SiteHeader__replenishment_indicator} style={{backgroundColor: color}}/>
            <h1>{deviceNeed.stockLocation.name}</h1>
        </div>
    )
}

const UnselectedSiteHeader = ({ site, onSelect, smScreen }) => {
  return (
    <div className={styles.SiteHeader__container} onClick={onSelect}>
      <div
        className={
          smScreen
            ? styles.SiteHeader__noDisplay
            : styles.SiteHeader__iconContainer
        }
      >
        <BuildingIcon fill={"#ffffff"} />
      </div>
      <h1>{site.name}</h1>
    </div>
  );
};

export default SiteHeader;
