const trayCheckSummaryReportObj = {
  title: "",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: false,
  collections: ["tray", "user", "product", "stockLocation", "dispensingOrder"],
  fields: [
    {
      collection: "tray",
      dataType: "ObjectId",
      name: "stockLocation",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "String",
      name: "vendorBatchId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "String",
      name: "dispensingOrderId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "locationId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "Number",
      name: "trayCount",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "status",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "Date",
      name: "approvedAt",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "user",
      dataType: "String",
      name: "lastName",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "assignedTrayId",
      query: [],
      sum: false,
      average: false,
    },
  ],
  functionFields: [],
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "",
};

export default trayCheckSummaryReportObj;
