import ProductService from "../../services/ProductService";
import RoleCheckService from "../../services/RoleCheckService";

class AssociateProductBarcodeController {
    constructor(user) {
        this._user = user;
    }

    /*
    barcode: the scanned barcode
    defaultImage: set to true if you want the default image returned
    callback: a function to handle the returned results
     */
    getProductByBarcode = async (barcode, defaultImage, callback) => {
        ProductService.getProductByBarcode(this._user, barcode, defaultImage)
            .then(product=>{
                callback(null, product);
            })
            .catch(error=>{
                callback(error);
            })
    }

    /*
    Update the product
    product: the product you want to update
    callback: a function to handle the updated product
     */
    updateProduct = async (product, callback) => {
        ProductService.update(this._user, product)
            .then(product=>{
                callback(null, product);
            })
            .catch(error=>{
                callback(error);
            })
    }

    /*
    isUserAuthorized - check to see if the user has a role capable of associating barcodes
    userToAuthorized: the user to check (could be the same as the constructor user or a supervisor
    returns: true if authorized
     */
    isUserAuthorized = async (userToAuthorize) => {
        return RoleCheckService.canCreateProduct(userToAuthorize);
    }

    /*
    searchForProducts - get a list of products that match a series of characters
    searchText: a string of characters
    callback: a function to handle the list of products found
     */
    searchForProducts = (searchText, callback) => {
        ProductService.search(this._user, searchText)
            .then(products=>{
                callback(null, products);
            })
            .catch(error=>{
                callback(error);
            })
    }

}

export default AssociateProductBarcodeController;
