import React, { useEffect } from "react";
import { Table } from "antd";

const NestedTable = ({
  data,
  rowKey,
  columns,
  childTable,
  onRowExpand,
  rowClassName,
  onRowContract,
  expandedRowKeys,
}) => {
  const handleExpand = (expanded, row) => {
    if (expanded) onRowExpand(row);
    else onRowContract();
  };

  return (
    <Table
      bordered
      rowKey={rowKey}
      columns={columns}
      dataSource={data}
      rowClassName={rowClassName}
      expandable={{
        onExpand: handleExpand,
        expandRowByClick: true,
        expandedRowRender: childTable,
        expandedRowKeys: expandedRowKeys,
      }}
      onRow={() => {
        return {
          onClick: (e) => e.stopPropagation(),
        };
      }}
    />
  );
};

export default NestedTable;
