import React, {useState, useEffect} from 'react';
import {ReactComponent as CloseIcon} from "../../assets/x.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import TreeView from "../../components/TreeView";
import FacilityService from "../../services/FacilityService";
import {useTranslation} from "react-i18next";

const ManageFacilitySublocations = ({user, rootLocation, onClose}) => {

    const { t } = useTranslation('facilitySublocations');


    //region Styles
    const viewStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '1000px',
        height: '739px',
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '20px',
        margin: '100px auto',
        zIndex: 50,
    }
    const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        marginRight: '20px',
        marginLeft: '66px',
        height: '100px',
    }
    const bodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        height: '100%',
        marginRight: '66px',
        marginLeft: '66px'
    }
    const rowStyle = {
        display: 'flex',
        flexDirection: 'row'
    }
    const titleStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '34px',
        lineHeight: '27px',
        color: '#121A2D',
        userSelect: 'none'
    }
    const closeIconStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%'
    }
    const footerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '66px'
    }
    //endregion

    const [error, setError] = useState('');
    const [rootNode, setRootNode] = useState(null);

    useEffect(()=>{
        if(!rootLocation.subLocationTree || rootLocation.subLocationTree.length === 0){
            setRootNode({name: rootLocation.name, children: rootLocation.subLocations || [], parent: null});
        } else {
            setRootNode(rootLocation.subLocationTree);
        }

    },[rootLocation])

    const handleUpdate = () => {
        rootLocation.subLocationTree = rootNode;
        FacilityService.update(user, rootLocation, true)
            .then(()=>onClose())
            .catch(error=>setError(error))
    }

    return (
        <section style={viewStyle}>
            <section style={headerStyle}>
                <p style={titleStyle}>{`${t('title0')} ${rootLocation.name} ${t('title1')}`}</p>
                <div style={closeIconStyle}>
                    <CloseIcon fill={'#121A2D'} style={{cursor: 'pointer'}} onClick={onClose}/>
                </div>
            </section>
            <section style={bodyStyle}>
                {error &&
                <>
                    <ErrorBanner message={error} onClose={()=>setError(null)}/>
                    <Spacer space={10} unit={'px'}/>
                </>
                }
                {rootNode && <TreeView root={rootNode} onDone={handleUpdate} onError={(message)=>setError(message)}/>}

            </section>
            <section style={footerStyle}>
                {/*<Button labelName={'Cancel'} isPrimary={false} isDisabled={false} onClick={onClose}/>*/}
                {/*<Spacer space={50} unit={'px'}/>*/}
                <Button labelName={t('updateButtonLabel')} isPrimary={true} isDisabled={false} onClick={(tree)=>handleUpdate(tree)}/>
            </section>
        </section>
    )
}

export default ManageFacilitySublocations;
