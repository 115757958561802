import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import backIcon from "../../../assets/back_icon.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// Register font
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap",
      fontStyle: "italic",
      fontWeight: "bold",
    },
  ],
});

//region CSS
const MyView = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
  fontfamily: "Poppins";
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const PDFReportViewerPickListByProduct = ({ title, headers, data, onBack }) => {
  const { t } = useTranslation("pdfReportViewer");

  const pageWidth = 792;
  const columnWidth = pageWidth / (headers.length - 1);

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          padding: 20,
        }}
      >
        <Text
          style={{
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 12,
            color: "#121A2D",
            margin: 0,
            padding: 0,
          }}
        >
          {title}
        </Text>
        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            height: 50,
            borderBottom: "2px solid #089BAB",
          }}
        >
          {headers.map((header, index) => {
            return (
              <Text
                key={index}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 10,
                  color: "#121A2D",
                  paddingRight: index !== 4 ? "4px" : 0,
                  width:
                    index === 0
                      ? "134"
                      : index === 1
                      ? "340px"
                      : index === 4
                      ? "34px"
                      : "136px",

                  margin: 0,
                  padding: 0,
                }}
              >
                {header}
              </Text>
            );
          })}
        </View>
        {data.map((row, r) => {
          return (
            <View
              key={r}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                height: 25,
                backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
              }}
            >
              {!isNaN(parseInt(row[0]))
                ? row.map((text, t) => {
                    return (
                      <Text
                        key={t}
                        style={{
                          margin: 0,
                          padding: 0,
                          // Setting the font family drastically increases loading time of PDF page (issue unknown)
                          // fontFamily: "Poppins",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          fontSize: 12,
                          color: "#121A2D",

                          paddingRight: t !== 4 ? "4px" : 0,
                          width:
                            t === 0
                              ? "134px"
                              : t === 1
                              ? "340px"
                              : t === 4
                              ? "34px"
                              : "136px",
                        }}
                      >
                        {text}
                      </Text>
                    );
                  })
                : row.map((text, t) => {
                    return (
                      <Text
                        key={t}
                        style={
                          isNaN(parseInt(text)) && t === 0
                            ? {
                                margin: 0,
                                padding: 0,
                                paddingLeft: "20px",
                                // Setting the font family drastically increases loading time of PDF page (issue unknown)
                                // fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: 10,
                                color: "#121A2D",

                                paddingRight: t !== 4 ? "4px" : 0,
                                width:
                                  t === 0
                                    ? "134px"
                                    : t === 1
                                    ? "340px"
                                    : t === 4
                                    ? "34px"
                                    : "136px",
                              }
                            : {
                                margin: 0,
                                padding: 0,
                                // Setting the font family drastically increases loading time of PDF page (issue unknown)
                                // fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: 10,
                                color: "#121A2D",

                                paddingRight: t !== 4 ? "4px" : 0,
                                width:
                                  t === 0
                                    ? "134px"
                                    : t === 1
                                    ? "340px"
                                    : t === 4
                                    ? "34px"
                                    : "136px",
                              }
                        }
                      >
                        {text}
                      </Text>
                    );
                  })}
            </View>
          );
        })}
        <Text
          style={{
            margin: 0,
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 10,
            color: "#121A2D",
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>PDF Viewer</ReportTitle>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </MyView>
  );
};

export default PDFReportViewerPickListByProduct;
