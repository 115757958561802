import ApiService from "./ApiService";
import axios from "axios";

class ClientFDBService extends ApiService {
    static getImagesByNdc = async (user, ndc) => {
        try {
            const rc = {
                headers: { "x-access-token": user.token },
            };
            let response = await axios.get(
                `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/images?ndc=${ndc}`,
                rc
            );
            return response.data.images;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    };

    static getFdbPackagedDrugByNdc = async (user, ndc) => {
        try {
            const rc = {
                headers: { "x-access-token": user.token },
            };
            let response = await axios.get(
                `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb?ndc=${ndc}`,
                rc
            );
            return response.data.fdbPackagedDrug;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }


}

export default ClientFDBService;
