import React from "react";
import { ReactComponent as Pills } from "../../assets/pills.svg";
import styles from "./NDCProduct.module.css";
import ProductController from "../../controllers/ProductController";

const NDCProduct = ({ product, setSelectedProductForAssoc }) => {
  // Use productScanned.defaultImg
  let imgSource = null;

  let srcDBImage =
    imgSource && imgSource.mimeType
      ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
      : imgSource && imgSource.base64EncodedString;

  const pc = new ProductController();

  let productDescr;
  let productStrength = product.strength || "";

  if (pc.getDefaultName(product).length > 20) {
    productDescr = `${product.primaryName} ${productStrength}`;
  } else {
    productDescr = pc.getDefaultName(product) || "";
  }

  // if (productDescr.length > 13) {
  //   productDescr = productDescr.substring(0, 13) + "...";
  // }

  let packageDescr = product.packageDescription || "";
  // if (packageDescr.length > 22) {
  //   packageDescr = packageDescr.substring(0, 22) + "...";
  // }

  const selectHandler = (product) => {
    if (product) {
      setSelectedProductForAssoc(product);
    }
  };

  return (
    <div
      className={styles.NDCProduct__box}
      onClick={() => selectHandler(product)}
    >
      <div
        className={[
          styles["NDCproduct__imageContainer"],
          styles["NDCproduct__imageContainer--default"],
        ].join(" ")}
      >
        {imgSource ? (
          <img className={styles.NDCproduct__image} src={srcDBImage} alt="" />
        ) : (
          <Pills stroke={"#A7BBCF"} />
        )}
      </div>

      <div className={styles.NDCProduct__productInfo}>
        <div className={styles.NDCProduct__packageId}>
          id: {product.packageId}
        </div>
        <div className={styles.NDCProduct__productDescr}>{productDescr}</div>
        <div className={styles.NDCProduct__productDescr}>{packageDescr}</div>
      </div>
    </div>
  );
};

export default NDCProduct;
