import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import GetSiteController from "./GetSiteController";
import Header from "./commonUI/Header";
import styles from "./GetSiteView.module.css";

const GetSiteView = ({ title, setSite, user, setActiveIcon, onHideModal }) => {
  const [sites, setSites] = useState([]);
  const _controller = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    _controller.current = new GetSiteController(user);
    _controller.current.getSites(callback);
  }, []);

  const { smallScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const lgScreen = useMediaQuery(largeScreen);

  // getSites
  const callback = (error, sites) => {
    if (!error) {
      setSites(sites);
      if (sites.length === 1) {
        setSite(sites[0]);
      }
    } else {
      console.log("error:", error);
    }
  };

  const backArrowHandler = () => {
    if (!smScreen) {
      setActiveIcon("Home");
    }
    navigate("/inventory");
    onHideModal();
  };

  let maxItemsNoScroll;
  maxItemsNoScroll = lgScreen ? 3 : 4;

  return (
    <div className={styles.getSite__container}>
      <Header
        backArrowHandler={backArrowHandler}
        useInput={false}
        headerTitle={title}
        onHideModal={onHideModal}
      />

      <div className={styles.getSite}>
        <h2 className={styles.getSite__heading}>
          {sites && sites.length > 1 ? "Select Site" : null}
        </h2>
        <div className={styles.scrollArea}>
          <ul className={styles.getSite__list}>
            {sites.map((site, index) => {
              return (
                <li
                  key={sites[index].id}
                  className={styles.getSite__item}
                  onClick={() => {
                    setSite(site);
                  }}
                >
                  {sites[index].name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GetSiteView;
