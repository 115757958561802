import React, { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import backIcon from "../../assets/back_icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import { ReactComponent as Funnel } from "../../assets/funnel.svg";
import { ReactComponent as ExpandIcon } from "../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../assets/subtract-circle-icon.svg";
import { ReactComponent as GroupIcon } from "../../assets/brackets-curly.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Spacer from "../../components/Spacer";
import WaitIndicator from "../../components/WaitIndicator";
import ReportService from "../../services/ReportService";
import ErrorBanner from "../../components/ErrorBanner";
import FilterPopup from "../../components/FilterPopup";
import { useKeyPress } from "../../components/InputHooks";
import { dateSelectionRangeTimeAdjustment } from "../../functions/dates";
import PDFReportViewer from "./PDFReportViewer";
import ReportExcelExporter from "./ReportExcelExporter";
import { getPreferredFieldName } from "./lookups/getPreferredFieldName";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getDataFields } from "./reportViewerFunctions";
import styles from "./ReportViewer.module.css";

const ReportViewerEV54CanisterManagement = ({
  user,
  reportObj,
  isPreview,
  onClose,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("reportViewer");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [tableWidthWithScroll, setTableWidthWithScroll] = useState("100%");
  const [reportContainerHeight, setReportContainerHeight] = useState("100%");
  const [selectionRange, setSelectionRange] = useState({});
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState("");

  const [reportData, setReportData] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [sortPreference, setSortPreference] = useState({
    colIndex: 0,
    ascending: true,
  });
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [exportToExcel, setExportToExcel] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [functionLookup, setFunctionLookup] = useState({});
  const [runReport, setRunReport] = useState(false);
  const [collectionSequence, setCollectionSequence] = useState([]);
  const [largeViewData, setLargeViewData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [groupedDataColumnSums, setGroupedDataColumnSums] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState([]);
  const [selectedGroupRowIndex, setSelectedGroupRowIndex] = useState(-1);
  const [expansionActive, setExpansionActive] = useState(false);
  const [groupedField, setGroupedField] = useState("No grouping");
  const [activeGroupIconIndex, setActiveGroupIconIndex] = useState(null);

  const addedWidthAdjustment = 34;

  const keyPressed = useKeyPress("Escape");
  if (showFilter && keyPressed) {
    setShowFilter(null);
  }

  const combinedReportFieldsRegular = [
    ...reportObj.fields,
    ...reportObj.functionFields,
  ];

  let combinedReportFieldsEV54CanisterManagement = [];

  combinedReportFieldsEV54CanisterManagement = [
    ...combinedReportFieldsRegular,
    "Test",
  ];

  let combinedReportFields = combinedReportFieldsEV54CanisterManagement;

  useEffect(() => {
    let tempGroupedColumnSums = [];
    groupedData.forEach((groupedRows, groupIndex) => {
      tempGroupedColumnSums.push([]);
      tempGroupedColumnSums[groupIndex].push([]);
      groupedRows.forEach(
        (tempGroupedColumnSumsRow, tempGroupedColumnSumsRowIndex) => {
          tempGroupedColumnSumsRow.forEach((datum, datumIndex) => {
            if (columnDataTypes[datumIndex] === "Number") {
              if (tempGroupedColumnSumsRowIndex === 0) {
                if (isNaN(datum)) {
                  tempGroupedColumnSums[groupIndex][datumIndex] = 0;
                } else {
                  tempGroupedColumnSums[groupIndex][datumIndex] = datum;
                }
              } else if (!isNaN(datum) && datum !== "") {
                tempGroupedColumnSums[groupIndex][datumIndex] += datum;
              }
            } else {
              if (tempGroupedColumnSumsRowIndex === 0) {
                tempGroupedColumnSums[groupIndex][datumIndex] = null;
              }
            }
          });
        }
      );
    });
    setGroupedDataColumnSums(tempGroupedColumnSums);
  }, [groupedData]);

  useEffect(() => {
    setReportContainerHeight(document.body.clientHeight - 320);
    let onresize = function () {
      setReportContainerHeight(document.body.clientHeight - 320);
    };
    window.addEventListener("resize", onresize);
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    if (!smScreen) {
      // Use a setTimeout to make sure the table width is updated before getting its width.
      setTimeout(() => {
        let element = document.querySelector("#tableContainer");
        let widthWithoutScroll = element?.offsetWidth;
        let widthWithScroll = element?.scrollWidth;
        setTableWidthWithScroll(
          widthWithScroll > widthWithoutScroll ? widthWithScroll : "100%"
        );
      }, "1000");
    }
  }, [reportData, smScreen]);

  useEffect(() => {
    if (isPreview && reportObj?.ignoreDateRange) {
      handleRunReport();
    }
  }, [isPreview, reportObj]);

  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date();
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));

    const collections = reportObj.fields.map((field) => {
      return field.collection;
    });

    setCollectionSequence(collections);
  }, [reportObj]);

  useEffect(() => {
    if (reportObj?.groupedField && reportObj.groupedField !== "No grouping") {
      const groupedFieldIndex = reportObj.fields.findIndex((field) => {
        return `${field.collection}:${field.name}` === reportObj.groupedField;
      });
      if (groupedFieldIndex !== -1) {
        setActiveGroupIconIndex(groupedFieldIndex);
      }
    }
  }, []);

  // determine the maximum width of each column
  useEffect(() => {
    let maximumWidth = 40; // characters - not pixels
    let widths = [];
    for (let i = 0; i < columnFilters.length; i++) {
      let colFilter = columnFilters[i];
      let maxWidth =
        combinedReportFields[i]?.name?.length || combinedReportFields[i].length;
      for (let j = 0; j < colFilter.length; j++) {
        let d = colFilter[j];
        if (d.description.length > maxWidth) {
          maxWidth = d.description.length;
          if (maxWidth > maximumWidth) {
            maxWidth = maximumWidth;
          }
        }
      }
      if (maxWidth > maximumWidth) {
        maxWidth = maximumWidth;
      }
      widths.push(maxWidth * 10 + 12);
    }
    setColumnWidths(widths);
  }, [columnFilters]);

  useEffect(() => {
    if (reportData) {
      getDataFields(
        combinedReportFields,
        reportObj,
        reportData,
        formatDate,
        functionLookup,
        handleColumnFilters
      );
    }
  }, [reportData]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePopupZone);

    return () => {
      document.removeEventListener("click", handleClickOutsidePopupZone);
    };
  });

  useEffect(() => {
    if (reportObj?.groupedField) {
      setGroupedField(reportObj.groupedField);
    }
  }, [reportObj]);

  useEffect(() => {
    {
      if (columnFilters && columnFilters.length && reportData) {
        const filteredLargeViewData = reportData
          .map((data, dataIndex) => {
            if (isFiltered(dataIndex)) {
              return combinedReportFields.map((reportObject, index) => {
                let dataFieldText =
                  columnFilters[index][dataIndex]?.description;
                return dataFieldText;
              });
            }
          })
          // strip any undefined entries from the array
          .filter((data) => data);
        setLargeViewData(filteredLargeViewData);
      }
    }
  }, [columnFilters, reportData]);

  useEffect(() => {
    if (largeViewData.length) {
      // const groupedField = reportObj.groupedField;

      const columnIndex = reportObj.fields.findIndex((fieldObject) => {
        return `${fieldObject.collection}:${fieldObject.name}` === groupedField;
      });

      let searched = [];
      let tempGroupedData = [];
      let tempGroupedDataIndex = 0;
      if (largeViewData && largeViewData?.length && largeViewData) {
        largeViewData.forEach((dataRowFirstLevel) => {
          if (
            dataRowFirstLevel &&
            columnIndex >= 0 &&
            !searched.includes(dataRowFirstLevel[columnIndex])
          ) {
            searched.push(dataRowFirstLevel[columnIndex]);
            largeViewData.forEach((dataRowSecondLevel) => {
              if (
                dataRowSecondLevel &&
                dataRowFirstLevel[columnIndex] ===
                  dataRowSecondLevel[columnIndex]
              ) {
                if (tempGroupedData.length - 1 >= tempGroupedDataIndex) {
                  tempGroupedData[tempGroupedDataIndex].push(
                    dataRowSecondLevel
                  );
                } else {
                  tempGroupedData[tempGroupedDataIndex] = [dataRowSecondLevel];
                }
              }
            });
            tempGroupedDataIndex++;
          }
        });
        setGroupedData(tempGroupedData);
      }
    }
  }, [largeViewData, groupedField]);

  const handleClickOutsidePopupZone = () => {
    // Automatically closes all popups if a click is made outside of the popup zone.
    setShowFilter(null);
  };

  const handleClickInsidePopupZone = (event) => {
    // Stops the mouse-click event from bubbling up. If a click is made inside of the popup zone, this prevents a document click from calling handleClickOutsidePopupZone.
    event.stopPropagation();
  };

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
  };

  const handleRunReport = () => {
    const query = {
      startDate: selectionRange.startDate,
      stopDate: selectionRange.endDate,
      isPreview: isPreview,
      reportObj: reportObj,
    };

    // Get report data
    ReportService.run2(user, query)
      .then((results1) => {
        ReportService.getCanisterManuallyDispensedData(user, query)
          .then((results2) => {
            let results1Temp = [...results1];
            results1.forEach((results1Row, index) => {
              let matchObj = undefined;
              const stockLocationNameArray = results1Row[0].split("-");
              const canisterNo =
                stockLocationNameArray[stockLocationNameArray.length - 1];

              if (results2.length) {
                matchObj = results2.find((activityObj) => {
                  // results1Row[0] is the stockLocation.name
                  // resultsRow[1] is the processHistory.canisterNo
                  return canisterNo === activityObj.canisterPosition;
                });
              }
              if (matchObj) {
                results1Temp[index][results1Row.length] =
                  matchObj?.lastDispensed;
              } else {
                results1Temp[index][results1Row.length] = "";
              }
            });

            // Sort results1 by machine name (ascending), canister position (ascending), and expiration date (descending)
            results1Temp.sort(function (a, b) {
              let canisterNameArrayA = a[0].split("-");

              let machineNameA = "";
              for (let i = 0; i < canisterNameArrayA.length - 1; i++) {
                machineNameA += canisterNameArrayA[i];
              }

              let canisterNoA = Number(
                canisterNameArrayA[canisterNameArrayA.length - 1]
              );

              let canisterNameArrayB = b[0].split("-");
              let canisterNoB = Number(
                canisterNameArrayB[canisterNameArrayB.length - 1]
              );

              let machineNameB = "";
              for (let i = 0; i < canisterNameArrayB.length - 1; i++) {
                machineNameB += canisterNameArrayB[i];
              }

              let canisterUnixEpochExpTimeA = new Date(a[6]).getTime();
              let canisterUnixEpochExpTimeB = new Date(b[6]).getTime();

              return (
                machineNameA.localeCompare(machineNameB) ||
                canisterNoA - canisterNoB ||
                canisterUnixEpochExpTimeB - canisterUnixEpochExpTimeA
              );
            });

            // Select only data rows with a unique machine name, unique canister number, and highest expiration date
            let filteredResults = results1Temp.filter((dataRow, index) => {
              let previousItemName = null;
              if (index > 0) {
                previousItemName = results1Temp[index - 1][0];
              }
              let currentItemName = results1Temp[index][0];
              return currentItemName !== previousItemName;
            });

            setReportData(filteredResults);
          })
          .catch((error) => {
            setError(error);
          });

        setRunReport(true);
      })
      .catch((error) => {
        setError(error);
      });

    setFetchingData(false);
  };

  const handleColumnFilters = (val) => {
    setColumnFilters(val);
  };

  const formatDate = (item) => {
    if (item) {
      const date = new Date(item);
      return Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(date);
      //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    }
    return "";
  };

  const getPathValue = (path, data) => {
    let val = "";
    const parts = path.split(".");
    for (let i = 0; i < parts.length; i++) {
      if (!val) {
        val = data[`${parts[i]}`];
      } else {
        val = val[`${parts[i]}`];
      }
    }
    return val;
  };

  const handleFilterClick = (e, column, width) => {
    const list = columnFilters[column];
    if (list && list.length) {
      setFilterList(list);
      var rect = e.target.getBoundingClientRect();
      setShowFilter(
        showFilter
          ? null
          : { left: e.clientX, topaverage: e.clientY + rect.height * 2 }
      );
    }
  };

  const isFiltered = (dataIndex) => {
    // isFiltered return true if there are no selected filteres or a selected filter matches
    // this data element - look in each column
    let j = 0;
    for (let col = 0; col < combinedReportFields.length; col++) {
      let columnFilter = columnFilters[col];
      let description = columnFilter[dataIndex]?.description || "";
      if (
        columnFilter.some((o) => o.selected) &&
        !columnFilter.some((o) => o.description === description && o.selected)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleGroupClick = (index) => {
    const fieldToGroup = `${reportObj.fields[index].collection}:${reportObj.fields[index].name}`;
    setGroupedField(fieldToGroup);
    setSelectedGroupRowIndex(-1);
    setExpansionActive(false);
    setActiveGroupIconIndex(index);
  };

  const handleResetFieldGroupingToDefault = () => {
    if (reportObj?.groupedField) {
      setGroupedField(reportObj.groupedField);
      setExpansionActive(false);
      setSelectedGroupRowIndex(-1);
      if (reportObj?.groupedField && reportObj.groupedField !== "No grouping") {
        const groupedFieldIndex = reportObj.fields.findIndex((field) => {
          return `${field.collection}:${field.name}` === reportObj.groupedField;
        });
        if (groupedFieldIndex !== -1) {
          setActiveGroupIconIndex(groupedFieldIndex);
        }
      } else {
        setActiveGroupIconIndex(null);
      }
    } else {
      setActiveGroupIconIndex(null);
    }
  };

  const handleNoFieldGrouping = () => {
    setGroupedField("No grouping");
    setActiveGroupIconIndex(null);
    setExpansionActive(false);
    setSelectedGroupRowIndex(-1);
  };

  const getFunnelColor = (col) => {
    let columnFilter = columnFilters[col];
    if (columnFilter && columnFilter.some((o) => o.selected)) {
      return "#089BAB";
    }
    return "#778CA2";
  };

  const toggleSortOrder = (colIndex) => {
    let pref = { ...sortPreference };
    if (colIndex === pref.colIndex) {
      pref.ascending = !pref.ascending;
    } else {
      pref.colIndex = colIndex;
      pref.ascending = false;
    }
    setSortPreference(pref);
    sortData(pref);
  };

  const sortData = (pref) => {
    let rows = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = [];
      for (let colIndex = 0; colIndex < columnFilters.length; colIndex++) {
        row.push(columnFilters[colIndex][i]);
      }
      rows.push(row);
    }
    rows.sort((a, b) => {
      if (a[pref.colIndex].description < b[pref.colIndex].description) {
        return pref.ascending ? -1 : 1;
      }
      if (b[pref.colIndex].description < a[pref.colIndex].description) {
        return pref.ascending ? 1 : -1;
      }
      return 0;
    });
    transposeData(rows);
  };

  const transposeData = (rows) => {
    let cols = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = rows[i];
      for (let rowIndex = 0; rowIndex < row.length; rowIndex++) {
        if (cols[rowIndex]) {
          cols[rowIndex] = cols[rowIndex].concat(row[rowIndex]);
        } else {
          cols.push([row[rowIndex]]);
        }
      }
    }
    setColumnFilters(cols);
  };

  const NormalTableHeadings = (obj, width, index) => {
    const increasedWidth = width + addedWidthAdjustment;
    return (
      <div
        className={styles.ReportViewer__reportHeaderGroup}
        key={index}
        style={{
          minWidth: `${increasedWidth}px`,
          maxWidth: `${increasedWidth}px`,
          minHeight: "20px",
        }}
      >
        <p
          className={styles.ReportViewer__reportHeaderText}
          onClick={() => toggleSortOrder(index)}
        >
          {index <= reportObj.fields.length - 1 ? (
            getPreferredFieldName(
              obj.collection,
              obj.name,
              collectionFieldDictionary,
              false,
              false
            )
          ) : (
            <span className={styles.ReportViewer__tooltip}>
              {obj.name}
              <span className={styles.ReportViewer__tooltiptext}>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {getPreferredFieldName(
                    obj.operands[0].collection,
                    obj.operands[0].name,
                    collectionFieldDictionary,
                    false,
                    false
                  )}
                </span>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {obj.operator.description}
                </span>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {getPreferredFieldName(
                    obj.operands[1].collection,
                    obj.operands[1].name,
                    collectionFieldDictionary,
                    false,
                    false
                  )}
                </span>
              </span>
            </span>
          )}
        </p>
        <div
          onClick={(event) => handleClickInsidePopupZone(event)}
          style={{ cursor: "pointer" }}
        >
          <Funnel
            fill={getFunnelColor(index)}
            onMouseOver={(e) => {
              e.target.fill = "#089BAB";
            }}
            onClick={(e) =>
              handleFilterClick(e, index, 1000 / combinedReportFields.length)
            }
          />
        </div>
        <div
          className={
            activeGroupIconIndex === index
              ? `${styles.ReportViewer__groupIcon} ${styles["ReportViewer__groupIcon--active"]}`
              : `${styles.ReportViewer__groupIcon}`
          }
          onClick={() => handleGroupClick(index)}
        >
          <GroupIcon />
        </div>
        {showFilter && (
          <div onClick={(event) => handleClickInsidePopupZone(event)}>
            <FilterPopup
              list={filterList}
              left={showFilter.left}
              top={showFilter.top}
              onSelect={(item) => {
                let tempColumnFilters = [...columnFilters];
                let tempFilter = tempColumnFilters[item.index];
                let obj = tempFilter.find(
                  (o) => o.description === item.description
                );
                obj.selected = item.selected;
                setColumnFilters(tempColumnFilters);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const TableHeadingsEV54CanisterManagement = (obj, width, index) => {
    return (
      <div
        className={styles.ReportViewer__reportHeaderGroup}
        key={index}
        style={{
          minWidth: width ? `${width + addedWidthAdjustment}px` : "",
          maxWidth: width ? `${width + addedWidthAdjustment}px` : "",
          minHeight: "20px",
        }}
      >
        <p
          className={styles.ReportViewer__reportHeaderText}
          onClick={() => toggleSortOrder(index)}
        >
          <span className={styles.ReportViewer__tooltip}>Last Dispensed</span>
        </p>
        <div
          onClick={(event) => handleClickInsidePopupZone(event)}
          style={{ cursor: "pointer" }}
        >
          <Funnel
            fill={getFunnelColor(index)}
            onMouseOver={(e) => {
              e.target.fill = "#089BAB";
            }}
            onClick={(e) =>
              handleFilterClick(e, index, 1000 / combinedReportFields.length)
            }
          />
        </div>
        {showFilter && (
          <div onClick={(event) => handleClickInsidePopupZone(event)}>
            <FilterPopup
              list={filterList}
              left={showFilter.left}
              top={showFilter.top}
              onSelect={(item) => {
                let tempColumnFilters = [...columnFilters];
                let tempFilter = tempColumnFilters[item.index];
                let obj = tempFilter.find(
                  (o) => o.description === item.description
                );
                obj.selected = item.selected;
                setColumnFilters(tempColumnFilters);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  let tempColumnDataTypes = [];

  const TableHeadings = combinedReportFields.map((obj, index) => {
    tempColumnDataTypes[index] = obj.dataType;
    let width = columnWidths[index];
    if (index === combinedReportFields.length - 1 && !columnDataTypes.length) {
      setColumnDataTypes(tempColumnDataTypes);
    }
    if (index < combinedReportFields.length - 1) {
      {
        return NormalTableHeadings(obj, width, index);
      }
    } else {
      return TableHeadingsEV54CanisterManagement(obj, width, index);
    }
  });

  const getViewerData = () => {
    let columnHeaders = [];
    for (let colIndex = 0; colIndex < combinedReportFields.length; colIndex++) {
      if (!columnHeaders[colIndex]) {
        columnHeaders.push(combinedReportFields[colIndex].name);
      }
    }

    columnHeaders[columnHeaders.length - 1] = "Last Dispensed";
    let data = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = [];
      let include = true;
      for (let colIndex = 0; colIndex < columnFilters.length; colIndex++) {
        if (isFiltered(i)) {
          row.push(columnFilters[colIndex][i].description);
        } else {
          include = false;
        }
      }
      if (include) {
        data.push(row);
      }
    }
    return { data: data, headers: columnHeaders };
  };

  if (showPDFViewer) {
    if (!reportData) {
      setShowPDFViewer(false);
      return;
    }

    let obj = getViewerData();

    return (
      <PDFReportViewer
        title={reportObj.title}
        headers={obj.headers}
        groupedField={groupedField}
        data={groupedField === "No grouping" ? largeViewData : groupedData}
        collectionSequence={collectionSequence}
        onBack={() => setShowPDFViewer(false)}
        selectionRange={selectionRange}
      />
    );
  } else if (exportToExcel) {
    if (!reportData) {
      setExportToExcel(false);
      return;
    }
    let obj = getViewerData();
    return (
      <ReportExcelExporter
        title={reportObj.title}
        headers={obj.headers}
        groupedField={groupedField}
        data={groupedField === "No grouping" ? largeViewData : groupedData}
        collectionSequence={collectionSequence}
        onBack={() => setExportToExcel(false)}
        selectionRange={selectionRange}
      />
    );
  } else if (false && fetchingData) {
    return <WaitIndicator message={"Generating report.  Please be patient."} />;
  }

  const handleGroupSelection = (rowIndex) => {
    if (selectedGroupRowIndex === rowIndex && expansionActive) {
      setExpansionActive(false);
      setSelectedGroupRowIndex(-1);
    } else {
      setExpansionActive(true);
      setSelectedGroupRowIndex(rowIndex);
    }
  };

  // Browswer view
  const LargeView = (
    <div
      className={
        !showPDFViewer
          ? styles.ReportViewer__main
          : styles.ReportViewer__mainWithCustomPDF
      }
    >
      {showDateTimeRangePicker && (
        <div className={styles.ReportViewer__overlay}>
          <div className={styles.ReportViewer__modal}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleDateRangeSelect}
              rangeColors={["#089BAB"]}
            />
            <br />
            <Button
              labelName={"Continue"}
              isPrimary={true}
              onClick={() => setShowDateTimeRangePicker(false)}
            />
          </div>
        </div>
      )}

      {error && (
        <div className={styles.ReportViewer__row}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
          <Spacer space={10} unit={"px"} />
        </div>
      )}

      {!showPDFViewer && (
        <div className={styles.ReportViewer__header}>
          <div className={styles.ReportViewer__titleGroup}>
            <div className={styles.ReportViewer__backButton}>
              <img src={backIcon} onClick={onClose} />
            </div>
            <p className={styles.ReportViewer__reportTitle}>
              {reportObj.title}
            </p>
            <Fragment>
              <button
                className={styles.ReportViewer__resetButtons}
                onClick={handleResetFieldGroupingToDefault}
              >
                Default
              </button>
              <button
                className={styles.ReportViewer__resetButtons}
                onClick={handleNoFieldGrouping}
              >
                No Groups
              </button>
            </Fragment>
          </div>
          {!reportObj.ignoreDateRange && (
            <div className={styles.ReportViewer__dateRangeGroup}>
              <p className={styles.ReportViewer__dateRangeLabel}>
                {t("dateFilterLabel")}
              </p>
              <p
                className={styles.ReportViewer__dateRange}
                onClick={() => setShowDateTimeRangePicker(true)}
              >
                {selectionRange.startDate?.toLocaleDateString() || ""}-
                {selectionRange.endDate?.toLocaleDateString() || ""}
              </p>
            </div>
          )}
          <div className={styles.ReportViewer__buttonGroup}>
            {runReport && reportObj?.fields?.length && (
              <div className={styles.ReportViewer__fileIconContainer}>
                <PDFIcon
                  className={styles.ReportViewer__fileIcon}
                  onClick={() => setShowPDFViewer(true)}
                />
              </div>
            )}
            {runReport && reportObj?.fields?.length && (
              <div className={styles.ReportViewer__fileIconContainer}>
                <ExcelIcon
                  className={styles.ReportViewer__fileIcon}
                  onClick={() => setExportToExcel(true)}
                />
              </div>
            )}
            <div className={styles.ReportViewer__runReportButtonContainer}>
              {(!isPreview || !reportObj.ignoreDateRange) && (
                <Button
                  labelName={t("runReportLabel")}
                  minWidth={"123px"}
                  isPrimary={true}
                  onClick={handleRunReport}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div id="tableContainer" className={styles.ReportViewer__tableContainer}>
        <div
          style={{ width: tableWidthWithScroll }}
          className={
            !groupedField || groupedField === "No grouping"
              ? styles.ReportViewer__reportHeader
              : `${styles.ReportViewer__reportHeader} ${styles["ReportViewer__reportHeader--grouping"]}`
          }
        >
          {TableHeadings}
        </div>
        <div
          style={{
            width: tableWidthWithScroll,
            height: reportContainerHeight,
          }}
          className={styles.ReportViewer__reportDataScrollView}
        >
          {(!groupedField || groupedField === "No grouping") &&
            columnFilters &&
            columnFilters.length > 0 &&
            reportData &&
            largeViewData.map((dataRow, index) => {
              return (
                <div
                  key={index}
                  className={styles.ReportViewer__reportDataContainer1}
                >
                  <div className={styles.ReportViewer__reportDataContainer2}>
                    {dataRow.map((datum, datumIndex) => {
                      let width = columnWidths[datumIndex];
                      return (
                        <div
                          key={datumIndex}
                          className={styles.ReportViewer__reportDataText}
                          style={{
                            minWidth: width
                              ? `${width + addedWidthAdjustment}px`
                              : "",
                            maxWidth: width
                              ? `${width + addedWidthAdjustment}px`
                              : "",
                          }}
                        >
                          {datum}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {groupedField !== "No grouping" &&
            columnFilters &&
            columnFilters.length > 0 &&
            reportData &&
            groupedData.map((groupedRow, groupedRowIndex) => {
              let groupHeight;
              if (groupedRow.length > 1 && groupedRow.length * 46 <= 500) {
                groupHeight = `${groupedRow.length * 46}px`;
              } else if (groupedRow.length === 1) {
                groupHeight = "80px";
              } else {
                groupHeight = "500px";
              }

              return (
                <div
                  key={groupedRowIndex}
                  className={styles.ReportViewer__reportDataContainerGrouping}
                  style={{
                    overflowY:
                      groupedRowIndex === selectedGroupRowIndex
                        ? "auto"
                        : "hidden",
                    minHeight:
                      groupedRowIndex === selectedGroupRowIndex &&
                      expansionActive
                        ? groupHeight
                        : "80px",
                    maxHeight:
                      groupedRowIndex === selectedGroupRowIndex &&
                      expansionActive
                        ? "500px"
                        : "80px",
                    justifyContent:
                      groupedRowIndex === selectedGroupRowIndex &&
                      expansionActive
                        ? "flex-start"
                        : "center",
                    border:
                      groupedRowIndex === selectedGroupRowIndex &&
                      expansionActive
                        ? "1px solid #089bab"
                        : "1px solid #e8ecef",
                    cursor:
                      groupedData[groupedRowIndex].length > 1
                        ? "pointer"
                        : "default",
                  }}
                  onClick={
                    groupedData[groupedRowIndex].length > 1
                      ? () => {
                          handleGroupSelection(groupedRowIndex);
                        }
                      : () => {}
                  }
                >
                  {groupedRow
                    .map((dataRow, dataRowIndex) => {
                      return (
                        <div
                          key={dataRowIndex}
                          className={`${styles.ReportViewer__dataRow} ${styles["ReportViewer__dataRow--grouping"]}`}
                          style={{
                            borderBottom:
                              groupedRowIndex === selectedGroupRowIndex &&
                              expansionActive &&
                              dataRowIndex !== groupedRow.length - 1
                                ? "1px solid rgba(211, 211, 211, 0.5"
                                : "none",
                          }}
                        >
                          {groupedData[groupedRowIndex].length > 1 &&
                            dataRowIndex === 0 &&
                            (groupedRowIndex === selectedGroupRowIndex &&
                            expansionActive ? (
                              <div
                                className={
                                  styles.ReportViewer__expandOrCollapseIconContainer
                                }
                              >
                                <CollapseIcon />
                              </div>
                            ) : (
                              <div
                                className={
                                  styles.ReportViewer__expandOrCollapseIconContainer
                                }
                              >
                                <ExpandIcon />
                              </div>
                            ))}

                          {dataRow.map((datum, datumIndex) => {
                            let width = columnWidths[datumIndex];

                            return (
                              <div
                                key={datumIndex}
                                className={styles.ReportViewer__reportDataText}
                                style={{
                                  minWidth: width
                                    ? `${width + addedWidthAdjustment}px`
                                    : "",
                                  maxWidth: width
                                    ? `${width + addedWidthAdjustment}px`
                                    : "",
                                }}
                              >
                                {columnDataTypes.length &&
                                groupedDataColumnSums.length &&
                                groupedDataColumnSums.length ===
                                  groupedData.length &&
                                columnDataTypes[datumIndex] === "Number" &&
                                (!expansionActive ||
                                  selectedGroupRowIndex !== groupedRowIndex)
                                  ? groupedDataColumnSums[groupedRowIndex][
                                      datumIndex
                                    ]
                                  : datum}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                    .filter(
                      (filteredRow, index) =>
                        index === 0 ||
                        (groupedRowIndex === selectedGroupRowIndex &&
                          expansionActive)
                    )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  return LargeView;
};

export default ReportViewerEV54CanisterManagement;
