import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import stylesAll from "./view.module.css";
import styles from "./InventoryTransferPullProductView.module.css";
import InventoryTransferPullProductViewController from "./InventoryTransferPullProductViewController";
import ErrorBanner from "../../../components/ErrorBanner";
import Header from "../commonUI/Header";
import Modal from "../commonUI/ModalTransparent";
import Button from "../../../components/Button";
import Button2 from "../commonUI/Button2";
import QtyInput from "./QtyInput";
import { containsObject } from "../../../functions/utilities";
import { ReactComponent as Pills } from "../../../assets/pills.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import ProductController from "../../../controllers/ProductController";

const InventoryTransferPullProductView = ({
  onStateBack,
  onStateBackDone,
  user,
  site,
  unfilteredStockedItems,
  smartProduct,
  smartCanister,
  scannedStockLocation,
  onHideModal,
}) => {
  const pcRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const useReverse = true;
  const [activateErrorModal, setActivateErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);
  const [reversedScannedProducts, setReversedScannedProducts] = useState([]);
  const [filteredStockedItems, setFilteredStockedItems] = useState([]);
  const [reversedFilteredStockedItems, setReversedFilteredStockedItems] =
    useState([]);
  const [quantities, setQuantities] = useState([]);
  const [showPartialVsFullMsg, setShowPartialVsFullMsg] = useState(false);
  const _controller = useRef(null);
  const [currentMinValue, setCurrentMinValue] = useState(Infinity);
  const [currentFullValue, setCurrentFullValue] = useState(-1);
  const [enforceInputFocus, setEnforceInputFocus] = useState(true);
  const [activeStockedItemQty, setActiveStockedItemQty] = useState(-1);
  const [blankInput, setBlankInput] = useState();

  const backArrowHandler = () => {
    onStateBack();
  };

  useEffect(() => {
    _controller.current = new InventoryTransferPullProductViewController(
      user,
      site
    );
    pcRef.current = new ProductController(user);
  }, [user, site]);

  useEffect(() => {
    let tempArray = [];
    let tempQuantitiesArray = quantities.reverse();
    let index = 0;

    if (filteredStockedItems.length - quantities.length !== 2) {
      filteredStockedItems.forEach((item) => {
        if (tempQuantitiesArray[index] === 0 || tempQuantitiesArray[index]) {
          tempArray.push(tempQuantitiesArray[index]);
        } else {
          tempArray.push(item.quantity);
        }
        index++;
      });
    }

    if (useReverse) {
      if (filteredStockedItems.length - quantities.length !== 2) {
        setQuantities(tempArray.slice().reverse());
      }

      setReversedFilteredStockedItems(filteredStockedItems.slice().reverse());
      setReversedScannedProducts(scannedProducts.slice().reverse());
    } else {
      if (filteredStockedItems.length - quantities.length !== 2) {
        setQuantities(tempArray);
      }
    }
  }, [filteredStockedItems]);

  useEffect(() => {
    if (
      smartProduct &&
      unfilteredStockedItems &&
      unfilteredStockedItems.length > 0
    ) {
      let quantityArray = [];
      let quantityTotal = 0;
      for (let i = 0; i < unfilteredStockedItems.length; i++) {
        quantityArray.push(unfilteredStockedItems[i].quantity);
        quantityTotal += unfilteredStockedItems[i].quantity;
      }
      setQuantities(quantityArray);
    }
  }, [smartProduct, unfilteredStockedItems]);

  const qtyInputOnClickHandler = () => {
    setEnforceInputFocus(false);
  };

  const qtyInputOnFocusHandler = (index) => {
    setActiveStockedItemQty(index);
  };

  const qtyInputOnBlurHandler = (index, quantity) => {
    if (isNaN(quantity) || quantity === 0) {
      let tempArray = [...quantities];
      tempArray[index] = 0;
      setQuantities(tempArray);
    }
    setActiveStockedItemQty(-1);
  };

  const inputKeyUpHandler = (e) => {
    if (e.which === 13) {
      setBlankInput(true);
      let barcode = e.target.value;
      let products = [];

      const callback = (error, product) => {
        if (!error && product) {
          const result = unfilteredStockedItems.filter(
            checkForStockedItemMatch
          );

          function checkForStockedItemMatch(stockedItem) {
            const duplicateStockedItemCheck = containsObject(
              stockedItem,
              filteredStockedItems
            );

            if (!duplicateStockedItemCheck) {
              if (stockedItem.product.packageId === product.packageId) {
                products.push(product);
              }

              return stockedItem.product.packageId === product.packageId;
            }
          }

          // Verify that there are stocked items for the chosen product and location.
          // If not, give modal alert and pause further processing.
          if (result && result.length >= 1) {
            let minValue = Infinity;
            let fullValue = -1;
            let newResult = [];
            let newProducts = [];
            let minIndex;
            let fullIndex;

            result.forEach(function (value, i) {
              if (
                result.length === 1 ||
                (value.quantity < minValue &&
                  value.quantity !== product.packageQuantity)
              ) {
                minValue = value.quantity;
                minIndex = i;
              } else if (value.quantity === product.packageQuantity) {
                fullValue = value.quantity;
                fullIndex = i;
              }
            });

            if (fullValue > -1 && minValue !== Infinity) {
              setShowPartialVsFullMsg(true);
            }

            setCurrentMinValue(minValue);
            setCurrentFullValue(fullValue);

            if (minIndex < Infinity) {
              newResult.push(result[minIndex]);
              newProducts.push(products[minIndex]);
            }

            if (fullIndex > -1) {
              newResult.push(result[fullIndex]);
              newProducts.push(products[fullIndex]);
            }
            const newScannedProducts = [...scannedProducts, ...newProducts];
            setScannedProducts(newScannedProducts);

            const newFilteredStockedItems = [
              ...filteredStockedItems,
              ...newResult,
            ];

            setFilteredStockedItems(newFilteredStockedItems);
            setEnforceInputFocus(true);
          } else {
            setErrorMsg(
              "There are no stocked items for this product and location."
            );
            setActivateErrorModal(true);
          }
        } else {
          setErrorMsg(
            "The entered barcode does not match a product at the chosen location."
          );
          setActivateErrorModal(true);
          console.log("error:", error);
        }
      };
      _controller.current.getProductByBarcode(barcode, callback);
    }
  };

  const handleSetBlankInput = () => {
    return setBlankInput();
  };

  const handleSetQuantities = (newValue, index) => {
    const tempArray = [...quantities];
    tempArray[index] = newValue;
    setQuantities(tempArray);
  };

  const ErrorModal = () => {
    return (
      <div className={stylesAll.errorBannerContainer}>
        <ErrorBanner
          message={errorMsg}
          onClose={() => {
            setActivateErrorModal(false);
            setErrorMsg("");
          }}
        />
      </div>
    );
  };

  const doneBtnHandler = () => {
    // updateStockedItem
    const callback = (error, stockedItem) => {
      if (!error && stockedItem && stockedItem !== null) {
        // console.log("callback: Updated stocked item:", stockedItem);
      } else {
        console.log("error:", error);
      }
    };

    let stockedItems;
    stockedItems = useReverse
      ? reversedFilteredStockedItems
      : filteredStockedItems;

    stockedItems.forEach((stockedItem, index) => {
      let updatedStockedItem = { ...stockedItem };

      // console.log("stockedItem", stockedItem);
      // console.log("updatedStockedItem", updatedStockedItem);

      if (!isNaN(quantities[index]) && quantities[index] !== 0) {
        updatedStockedItem.quantity = quantities[index];

        _controller.current.processPull(
          stockedItem,
          updatedStockedItem,
          callback
        );
      }
      onStateBack();
    });
  };

  const doneSmartBtnHandler = () => {
    if (!errorMsg) {
      // updateStockedItem
      const callback = (error, stockLocation) => {
        if (!error && stockLocation && stockLocation !== null) {
          // console.log("callback: Updated stock location:", stockLocation);
        } else {
          console.log("error:", error);
        }
      };

      /** NEW CODE TO HANDLE CHANGES TO SMART-CANISTER REQUIREMENTS **/
      const originalStockLocation = scannedStockLocation; // smart canister
      let updatedStockLocation = { ...originalStockLocation };
      updatedStockLocation.parent = null;
      updatedStockLocation.user = user;

      // console.log("originalStockLocation", originalStockLocation); // scanned smart canister
      // console.log("updatedStockLocation", updatedStockLocation);

      let parentStockLocation;

      if (originalStockLocation && originalStockLocation.parent) {
        parentStockLocation = originalStockLocation.parent;
      } else {
        parentStockLocation = null;
      }

      _controller.current.processSmartCanisterPull(
        updatedStockLocation,
        parentStockLocation,
        callback
      );

      onStateBackDone();
    }
  };

  let stockedItems;

  stockedItems = useReverse
    ? reversedFilteredStockedItems
    : filteredStockedItems;

  const ListStockedItems = stockedItems.map((item, index) => {
    const product = useReverse
      ? reversedScannedProducts[index]
      : scannedProducts[index];

    const stockedItem = useReverse
      ? reversedFilteredStockedItems[index]
      : filteredStockedItems[index];

    // Use productScanned.defaultImg
    let imgSource = null;

    let srcDBImage =
      imgSource && imgSource.mimeType
        ? `data:image/${imgSource.mimeType};base64,${imgSource.base64EncodedString}`
        : imgSource && imgSource.base64EncodedString;

    let productDesc;

    if (pcRef.current.getDefaultName(product) < 16) {
      productDesc = pcRef.current.getDefaultName(product);
    } else {
      productDesc = product.primaryName + " " + product.strength;
    }

    let packageDesc = `${product.packageDescription}`;

    return (
      <li
        key={item.id + Math.random(1)}
        className={
          activeStockedItemQty === index
            ? [styles["listItem"], styles["listItem--active"]].join(" ")
            : styles.listItem
        }
      >
        <div
          className={[
            styles["imageContainer"],
            styles["imageContainer--default"],
          ].join(" ")}
        >
          {imgSource ? (
            <img className={styles.product__image} src={srcDBImage} alt="" />
          ) : (
            <Pills stroke={"#A7BBCF"} />
          )}
        </div>

        <div className={styles.descriptionGroup}>
          <div className={styles.productDesc}>{productDesc}</div>
          <div className={styles.packageDesc}>{packageDesc}</div>
        </div>

        <div className={styles.stockedItemQtyGroup}>
          <QtyInput
            inputFields={{
              type: "number",
              min: "0",
              onClick: qtyInputOnClickHandler,
              onFocus: () => {
                qtyInputOnFocusHandler(index);
              },
              onBlur: () => {
                qtyInputOnBlurHandler(index, quantities[index]);
              },
            }}
            inputExtras={{
              index: index,
              startValue: stockedItem.quantity,
              updateValue: quantities[index],
              handleSetQuantities: handleSetQuantities,
            }}
          />
          {index <= scannedProducts.length - 1 &&
          scannedProducts[index].packageQuantity &&
          scannedProducts[index].packageQuantity > 0 ? (
            <span className={styles.stockedItemQtyNumOfItems}>
              (
              {(quantities[index] && scannedProducts[index].packageQuantity
                ? quantities[index] / scannedProducts[index].packageQuantity
                : 0
              ).toFixed(2)}{" "}
              item)
            </span>
          ) : null}
        </div>
      </li>
    );
  });

  /** New code for smart items **/
  let smartStockedItems;

  let totalQty = 0;
  if (
    smartProduct &&
    unfilteredStockedItems &&
    unfilteredStockedItems.length > 0
  ) {
    let quantityArray = [];
    let quantityTotal = 0;
    for (let i = 0; i < unfilteredStockedItems.length; i++) {
      quantityArray.push(unfilteredStockedItems[i].quantity);
      quantityTotal += unfilteredStockedItems[i].quantity;
    }
    totalQty = quantityTotal;
  }

  let ListSmartStockedItems;

  if (smartProduct) {
    smartStockedItems = useReverse
      ? reversedFilteredStockedItems
      : filteredStockedItems;

    // Use productScanned.defaultImg
    let smartImgSource = null;

    let smartSrcDBImage =
      smartImgSource && smartImgSource.mimeType
        ? `data:image/${smartImgSource.mimeType};base64,${smartImgSource.base64EncodedString}`
        : smartImgSource && smartImgSource.base64EncodedString;

    let smartProductDesc;

    if (smartProduct.defaultName && smartProduct.defaultName < 16) {
      smartProductDesc = smartProduct.defaultName;
    } else {
      smartProductDesc = smartProduct.primaryName;
      if (smartProduct.strength) {
        smartProductDesc += ` ${smartProduct.strength}`;
      }
    }

    let smartPackageDesc = "";

    if (smartProduct.packageDescription) {
      smartPackageDesc = `${smartProduct.packageDescription}`;
    }

    ListSmartStockedItems = (
      <li className={styles.listItem}>
        <div
          className={[
            styles["imageContainer"],
            styles["imageContainer--default"],
          ].join(" ")}
        >
          {smartImgSource ? (
            <img
              className={styles.product__image}
              src={smartSrcDBImage}
              alt=""
            />
          ) : (
            <Pills stroke={"#A7BBCF"} />
          )}
        </div>
        <div className={styles.descriptionGroup}>
          <div className={styles.productDesc}>{smartProductDesc}</div>
          <div className={styles.packageDesc}>{smartPackageDesc}</div>
        </div>
        <div
          className={`${styles.stockedItemQtyGroup} ${styles["stockedItemQtyGroup--smart"]}`}
        >
          <p>Qty</p>
          <p>
            {unfilteredStockedItems[0] && unfilteredStockedItems[0].quantity
              ? unfilteredStockedItems[0].quantity
              : null}
          </p>
        </div>
      </li>
    );
  } else {
    ListSmartStockedItems = null;
  }

  const partialBtnHandler = () => {
    let arrLength = filteredStockedItems.length;

    let tempArray1 = [...filteredStockedItems];
    let tempArray2 = [...quantities].reverse();
    let tempArray3 = [...scannedProducts];

    if (filteredStockedItems[arrLength - 2].quantity === currentFullValue) {
      tempArray1.splice(arrLength - 2, 1);
      tempArray3.splice(arrLength - 2, 1);
    } else if (
      filteredStockedItems[arrLength - 1].quantity === currentFullValue
    ) {
      tempArray1.splice(arrLength - 1, 1);
      tempArray3.splice(arrLength - 1, 1);
    }
    setShowPartialVsFullMsg(false);
    setScannedProducts(tempArray3);
    setFilteredStockedItems(tempArray1);
    setQuantities([currentMinValue, ...tempArray2]);
  };

  const fullBtnHandler = () => {
    let arrLength = filteredStockedItems.length;

    let tempArray1 = [...filteredStockedItems];
    let tempArray2 = [...quantities].reverse();
    let tempArray3 = [...scannedProducts];

    if (filteredStockedItems[arrLength - 2].quantity === currentMinValue) {
      tempArray1.splice(arrLength - 2, 1);
      tempArray3.splice(arrLength - 2, 1);
    } else if (
      filteredStockedItems[arrLength - 1].quantity === currentMinValue
    ) {
      tempArray1.splice(arrLength - 1, 1);
      tempArray3.splice(arrLength - 1, 1);
    }
    setShowPartialVsFullMsg(false);
    setScannedProducts(tempArray3);
    setFilteredStockedItems(tempArray1);
    setQuantities([currentFullValue, ...tempArray2]);
  };

  return (
    <Fragment>
      <div className={stylesAll.container}>
        {activateErrorModal && <ErrorModal />}
        {activateErrorModal && <Modal />}

        <Header
          headerTitle="Pull"
          backArrowHandler={backArrowHandler}
          useInput={!showPartialVsFullMsg ? true : false}
          onHideModal={onHideModal}
          smartLocation={smartCanister ? true : false}
          inputFields={{
            onKeyUp: inputKeyUpHandler,
            placeholder: "Scan product",
            type: "text",
            disabled: `${errorMsg}`,
          }}
          inputExtras={{
            enforceFocus: enforceInputFocus,
            blankInput: blankInput,
            onSetBlankInput: handleSetBlankInput,
          }}
        />
        {!showPartialVsFullMsg && (
          <Fragment>
            <div className={styles.listItemsContainer}>
              <p className={styles.stockLocationName}>
                {!showPartialVsFullMsg && scannedStockLocation.name}
              </p>
              <ul
                className={
                  smScreen
                    ? `${styles.listItems} ${styles.scrollArea}`
                    : filteredStockedItems.length <= 3
                    ? styles.listItems
                    : [styles["listItems"], styles["scrollArea"]].join(" ")
                }
              >
                {!smartCanister && scannedProducts && ListStockedItems}
                {smartCanister && ListSmartStockedItems}
              </ul>
            </div>

            {!showPartialVsFullMsg &&
              ((filteredStockedItems && filteredStockedItems.length > 0) ||
                smartCanister) && (
                <div className={styles.buttonContainer}>
                  <Button
                    labelName={"Done"}
                    isPrimary={true}
                    isDisabled={false}
                    onClick={
                      smartCanister ? doneSmartBtnHandler : doneBtnHandler
                    }
                    minWidth={smScreen ? "100%" : 213}
                  />
                </div>
              )}
          </Fragment>
        )}

        {showPartialVsFullMsg && (
          <div className={styles.partialAndFullBtnGroup}>
            <div className={styles.partialAndFullMsg}>
              There is a partial stocked item alongside a full stocked item.
              Please indicate which item you want to pull.
            </div>
            <div className={styles.btnGroup}>
              <Button2
                className={styles.btn}
                onClickHandler={partialBtnHandler}
              >
                Partial
              </Button2>
              <Button2 invStyle onClickHandler={fullBtnHandler}>
                Full
              </Button2>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default InventoryTransferPullProductView;
